import { Injectable } from '@angular/core';
import { Call } from '@cloudtalk/sip-service';

import { CommandBusService } from '../../_core/command-bus.service';
import { APIs } from '../../_core/enums/apis.enum';
import { CtiProvider } from '../../_core/models/cti-providers.models';
import { CallMonitorService } from '../../_core/services/call-monitor.service';
import { CallingService } from '../../_core/services/calling/calling.service';
import { DocumentService } from '../../_core/services/document.service';
import { LocalStorageService } from '../../_core/services/local-storage.service';
import { EndpointService } from '../../_core/services/networking/endpoint.service';
import { StatusService } from '../../_core/services/status.service';
import { UserService } from '../../_core/services/user/user.service';
import { cleanPhoneNumber } from '../../_shared/helpers/helper-functions';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { CtiServiceBase } from '../CtiServiceBase';
import { SalesforceAction } from './enums/salesforceAction.enum';
import { SalesforceListeners } from './enums/salesforceListeners.enum';
import { OnWorkStartPayload } from './models/onWorkStartPayload';
import {
  CtDialNumberData,
  CtHvsWorkStartData,
  CtReqResponse,
  SalesforceIncomingMessage,
} from './models/salesforceIncomingMessage';
import { SalesforceSettings } from './models/salesforceSettings';
import { WorkCompleteData } from './models/workCompleteData';

const DEFAULT_SALESFORCE_SETTINGS: SalesforceSettings = {
  shouldRing: false,
  openDialed: false,
  openAll: false,
};

const DEFAULT_WORK_COMPLETE_DATA: WorkCompleteData = {
  call_uuid: undefined,
  wasConnected: undefined,
  disposition: undefined,
};

@Injectable()
export class SalesforceService extends CtiServiceBase {
  #settings: SalesforceSettings;
  #isSalesEngagement: boolean;
  #salesEngagementWorkStartData: OnWorkStartPayload;
  #salesEngagementWorkCompleteData: WorkCompleteData =
    DEFAULT_WORK_COMPLETE_DATA;

  constructor(
    private commandBusService: CommandBusService,
    private documentService: DocumentService,
    private localStorageService: LocalStorageService,
    private endpointService: EndpointService,
    private userService: UserService,
    statusService: StatusService,
    callingService: CallingService,
    callMonitorService: CallMonitorService,
  ) {
    super(
      callingService,
      statusService,
      callMonitorService,
      CtiProvider.SALESFORCE,
    );

    this.documentService.nativeWindow.addEventListener(
      'message',
      this.#processMessage.bind(this),
    );

    this.#settings =
      this.localStorageService.getItem('sf-settings') ||
      DEFAULT_SALESFORCE_SETTINGS;
  }

  get isSalesEngagement(): boolean {
    return this.#isSalesEngagement;
  }

  isSalesEngagementCall(calledNumber: string): boolean {
    return (
      cleanPhoneNumber(this.#salesEngagementWorkStartData?.attributes?.to) ===
      calledNumber
    );
  }

  saveTask(workCompleteData: WorkCompleteData): void {
    try {
      if (this.isSalesEngagement) {
        this.#salesEngagementWorkCompleteData = workCompleteData;
      }
      this.#postMessage(SalesforceAction.CT_SAVE_LOG, {
        entityApiName: 'Task',
      });
    } catch (e) {
      LoggerUtil.error('[SalesforceService]: saveTask failed', {}, e);
    }
  }

  setSettings(key: keyof SalesforceSettings, value: boolean): void {
    this.#settings[key] = value;
    this.localStorageService.setItem('sf-settings', this.#settings);
  }

  shouldOpenOnRinging(): boolean {
    return this.#checkSetting('shouldRing', true);
  }

  shouldOpenDialed(): boolean {
    return this.#checkSetting('openDialed', true);
  }

  shouldOpenAll(): boolean {
    return this.#checkSetting('openAll', true);
  }

  shouldOpenContact(call: Call): boolean {
    if (
      this.#checkSetting('openDialed', true) &&
      (call.info.isGeneratedCall || call.info.isSmartDialer)
    ) {
      return true;
    }
    return this.#checkSetting('openAll', false);
  }

  salesForceOpenContact(contacts: any[]): boolean {
    this.#postMessage(SalesforceAction.CT_OPEN_SOFTPHONE);
    let anyContactOpened = false;
    for (const contactItem of contacts) {
      const tags = contactItem?.contact?.tags || [];
      for (const tag of tags) {
        if (
          (tag.name === 'salesforce' ||
            tag.integration_name === 'salesforce') &&
          tag._joinData?.service_key
        ) {
          this.#postMessage(SalesforceAction.CT_OPEN_CONTACT, {
            contact_id: tag._joinData.service_key,
          });
          anyContactOpened = true;
        }
      }
    }
    return anyContactOpened;
  }

  onRinging(call: Call): void {
    super.onRinging(call);
    this.#postMessage(SalesforceAction.CT_OPEN_SOFTPHONE);
    if (this.shouldOpenOnRinging()) {
      this.#openContact(call);
    }
  }

  onDialing(call: Call): void {
    super.onDialing(call);
    if (this.shouldOpenContact(call)) {
      this.#openContact(call);
    }
  }

  #processMessage(message: SalesforceIncomingMessage): void {
    const { data } = message;
    switch (data.type) {
      case SalesforceListeners.CT_DIAL_NUMBER:
        this.onCtDialedNumberResponse(data as CtDialNumberData);
        break;
      case SalesforceListeners.CT_HVS_WORK_START:
        this.onCtHvwWorkStart(data as CtHvsWorkStartData);
        break;
      case SalesforceListeners.CT_SAVE_LOG_RESPONSE:
        if (this.isSalesEngagement) {
          this.onCtSaveLogResponse(message.data as CtReqResponse);
        }
        break;
      case SalesforceListeners.CT_HVS_COMPLETE_WORK_RESPONSE:
        this.onCtHvsCompleteWorkResponse(message);
        break;
    }
  }

  onCtHvwWorkStart(data: CtHvsWorkStartData): void {
    if (data?.workId !== 'NO_WORK_ID') {
      this.#isSalesEngagement = true;
      this.#salesEngagementWorkStartData = data;
      this.#dial(data.attributes.to);
    }
  }

  onCtDialedNumberResponse(data: CtDialNumberData): void {
    if (!data.phoneNumber) {
      return;
    }
    this.#dial(data.phoneNumber);
  }

  onCtHvsCompleteWorkResponse({ data }: SalesforceIncomingMessage): void {
    this.#isSalesEngagement = false;
    this.#salesEngagementWorkStartData = undefined;
    this.#salesEngagementWorkCompleteData = DEFAULT_WORK_COMPLETE_DATA;
    LoggerUtil.info('CT_HVS_COMPLETE_WORK_RESPONSE', data);
  }

  onCtSaveLogResponse({ response }: CtReqResponse): void {
    if (response.success) {
      this.#salesEngagementCompleteWork(
        response.returnValue?.recordId,
        this.#salesEngagementWorkCompleteData.wasConnected,
        this.#salesEngagementWorkCompleteData.disposition,
      );
      this.endpointService
        .post(APIs.Integrations, 'salesforce/enrich-ticket', {
          task_id: response.returnValue?.recordId,
          call_uuid: this.#salesEngagementWorkCompleteData.call_uuid,
        })
        .subscribe();
    }
  }
  #checkSetting(key: keyof SalesforceSettings, defaultValue: boolean): boolean {
    return this.#settings?.[key] ?? defaultValue;
  }

  #postMessage(message: SalesforceAction, data?: unknown): void {
    this.documentService.nativeWindow.parent.postMessage(
      { message, content: data },
      '*',
    );
  }

  #dial(phoneNumber: string): void {
    this.commandBusService.emit({
      command: 'insert-number',
      data: { number: phoneNumber },
    });
  }

  /**
   * Opens contact in salesforce based on passed contacts
   * @param call
   */
  #openContact(call: Call): void {
    this.contactSubscription = {
      callID: call.ID,
      subscription: call.contacts$.subscribe(contacts => {
        // Do not open contact in salesEngagement
        // Salesforce in this case open contact by itself
        if (!this.isSalesEngagement && contacts?.length > 0) {
          this.salesForceOpenContact(contacts);
        }
      }),
    };
  }

  #salesEngagementCompleteWork(
    taskId: string,
    wasConnected: boolean,
    callDisposition: string,
  ): void {
    this.#postMessage(SalesforceAction.CT_HVS_COMPLETE_WORK, {
      workId: this.#salesEngagementWorkStartData.workId,
      attributes: {
        taskId,
        disposition: callDisposition,
        wasConnected,
      },
    });
  }
}

import { inject, Injectable } from '@angular/core';
import { Observable, shareReplay, map } from 'rxjs';

import { RepositoryBase } from '../../base.repository';
import { APIs } from '../../enums/apis.enum';
import {
  AgentStatuses,
  AgentStatus,
  AgentStatusPayload,
  CustomAgentStatus,
} from '../../interfaces/agent-status/agent-status.interface';
import { CTResponse } from '../../interfaces/ctresponse.interface';
import { LocalStorageService } from '../local-storage.service';

export const KEY_CUSTOM_STATUSES = 'custom-statuses';

@Injectable({
  providedIn: 'root',
})
export class AgentStatusRepositoryService extends RepositoryBase {
  private readonly localStorageService: LocalStorageService =
    inject(LocalStorageService);

  agentStatuses(): Observable<AgentStatuses> {
    return this.endpointService
      .get<AgentStatuses>(APIs.Realtime, 'app/agent-statuses')
      .pipe(shareReplay(1));
  }

  agentCustomStatuses(): CustomAgentStatus[] {
    return Object.values(
      this.localStorageService.getItem(KEY_CUSTOM_STATUSES) || {},
    );
  }

  setAgentStatus(agentStatus: AgentStatus): Observable<AgentStatus> {
    return this.endpointService
      .post<CTResponse<AgentStatus>, AgentStatusPayload>(
        APIs.Realtime,
        'app/agent-statuses/user-status',
        agentStatus,
      )
      .pipe(map((response: CTResponse<AgentStatus>) => response?.data));
  }

  setCustomStatuses(customStatuses: AgentStatus[]) {
    if (customStatuses) {
      this.localStorageService.updateItem(KEY_CUSTOM_STATUSES, customStatuses);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { ContactNameTypeEnum } from '../../../_core/enums/contact-name-type.enum';

export const HIDDEN_CALLER_VALUE = $localize`hidden_caller`;

@Pipe({
  name: 'contactNamePipe',
})
export class ContactNamePipe implements PipeTransform {
  transform(contactName: string): string {
    return contactName === ContactNameTypeEnum.HIDDEN_CALLER
      ? HIDDEN_CALLER_VALUE
      : contactName;
  }
}

import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';

@Injectable()
export class LocalizationService {
  constructor(private localStorageService: LocalStorageService) {}

  changeLocale(value: string, reload: boolean): void {
    if (!this.getLocale() || this.getLocale() !== value) {
      this.localStorageService.updateItem('lang', { value }, false);

      if (reload) {
        window.location.reload();
      }
    }
  }

  getLocale(): string {
    return this.localStorageService.getItem('lang', false)?.value;
  }

  getBrowserLocale(): string {
    return window.navigator.language.slice(0, 2);
  }
}

import { featureFlagsConfig } from './config/feature-flags.config';

export enum UserDataEnum {
  USERS = 'users',
  COMPANIES = 'companies',
}

export class FeatureFlagsService {
  static isFeatureFlagEnabledById(
    flag: keyof typeof featureFlagsConfig,
    userData: { [key in UserDataEnum]?: number },
  ): boolean {
    const userDataKey = Object.keys(userData)[0];
    return featureFlagsConfig[flag][userDataKey].includes(
      userData[userDataKey],
    );
  }
}

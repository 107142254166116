import { dynamicId } from '../utils/utils';

export enum ContactsScreenIdsEnum {
  NAVBAR_EXTERNAL_BUTTON = 'NavbarExternalButton',
  NAVBAR_INTERNAL_BUTTON = 'NavbarInternalButton',
  EXTERNAL_CONTACT_CELL = 'ExternalContactCell',
  INTERNAL_CONTACT_CELL = 'InternalContactCell',
  ADD_CONTACT_ICON = 'AddContactIcon',
  CONTACTS_HEADER = 'ContactsHeader',
  CONTACTS_BACK_BUTTON = 'ContactsBackButton',
  CONTACT_LIST_ITEM = 'ContactListItem',
  CONTACT_LIST_SEARCH = 'ContactListSearch',
  CONTACT_LIST_ITEM_FLAG = 'ContactListItemFlag',
  CONTACT_LIST_ITEM_NAME = 'ContactListItemName',
  CONTACT_LIST_DIALPAD_BUTTON = 'ContactListDialpadButton',
  CONTACT_FILTER_BUTTON = 'ContactFilterButton',
  CONTACT_FILTER_EXTERNAL = 'ContactFilterExternal',
  CONTACT_FILTER_INTERNAL = 'ContactFilterInternal',
  CONTACT_FILTER_SUBMIT_BUTTON = 'ContactFilterSubmitButton',
}

export const name_external_janJakubec = dynamicId(
  ContactsScreenIdsEnum.EXTERNAL_CONTACT_CELL,
  '-Jan Jakubec',
);

export const name_internal_agent = dynamicId(
  ContactsScreenIdsEnum.INTERNAL_CONTACT_CELL,
  '-Test Agent',
);

export const name_external_detox = dynamicId(
  ContactsScreenIdsEnum.EXTERNAL_CONTACT_CELL,
  '-Detox Test',
);

export const name_detox = 'Detox Test';
export const name_agent = 'Agent';

import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appTestId]',
})
export class TestIdDirective {
  @Input() set appTestId(id: string) {
    this.elementRef.nativeElement.setAttribute('data-test-id', id);
  }

  constructor(private elementRef: ElementRef) {}
}

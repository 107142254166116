import { CountryPresetsEnum } from '../other';
import { dynamicId } from '../utils/utils';

export enum DialPadInputIDsEnum {
  NUMBER_INPUT = 'NumberInput',
  NUMBER_INPUT_PREFIX = 'DialPadNumberInputPrefix',
  DELETE_BUTTON = 'DeleteButton',
  SEND_SMS_BUTTON = 'SendSmsButton',
  COPY_CLIPBOARD = 'CopyClipboard',
  PASTE_CLIPBOARD = 'PasteClipboard',
}

export const input_prefix_uk = dynamicId(
  DialPadInputIDsEnum.NUMBER_INPUT_PREFIX,
  `-${CountryPresetsEnum.UK}`,
);

export const input_prefix_sk = dynamicId(
  DialPadInputIDsEnum.NUMBER_INPUT_PREFIX,
  `-${CountryPresetsEnum.SK}`,
);

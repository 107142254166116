import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as Helper from './helpers';
import { SnackBarWrapperModule } from './components/snack-bar-wrapper/snack-bar-wrapper.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SnackBarWrapperModule,

    SharedDirectivesModule,
    SharedPipesModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    SnackBarWrapperModule,

    // helpers
    Helper.InitialsModule,
    Helper.OnlineStatusModule,

    // directives
    SharedDirectivesModule,

    // pipes
    SharedPipesModule,
  ],
})
export class SharedModule {}

export interface Language {
  value: string;
  name: string;
}

export const languagesCodes = [
  'en',
  'es',
  'fr',
  'de',
  'sk',
  'cz',
  'pt',
  'nl',
] as const;

export type LanguageCode = (typeof languagesCodes)[number];

export const LANGUAGE_NAMES = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  sk: 'Slovenčina',
  cz: 'Čeština',
  pt: 'Português',
  nl: 'Dutch',
};

export const defaultFallbackLanguage: LanguageCode = 'en';

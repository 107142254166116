import { Subscription, TeardownLogic } from 'rxjs';
import { Call, CallEvent, ConnectionState } from '@cloudtalk/sip-service';

import {
  CallActionEvent,
  CallingService,
} from '../_core/services/calling/calling.service';
import { StatusService } from '../_core/services/status.service';
import {
  PhoneStatusProperties,
  PhoneStatusSimpleChange,
} from '../_core/services/phone-status.service';
import { CtiProvider } from '../_core/models/cti-providers.models';
import { CallMonitorService } from '../_core/services/call-monitor.service';
import { ActiveCallData } from '../_core/interfaces/active-call-data';

export class CtiServiceBase {
  ringing = false;
  isIncomingCallWidget = false;

  protected callingService: CallingService;
  protected statusService: StatusService;
  protected callMonitorService: CallMonitorService;
  protected isAlreadyRegistered: boolean;
  protected numberToCall = null;

  private _subscription: Subscription = new Subscription();
  private _contactSubscriptions: {
    callID: number;
    subscription: Subscription;
  }[] = [];
  private consumer: CtiProvider = null;
  private activeCallId: number | null = null;
  private triggeredCallEvents = new Set<string>();

  #callingConnectionState: ConnectionState;
  get callingConnectionState(): ConnectionState {
    return this.#callingConnectionState;
  }

  constructor(
    callingService: CallingService,
    statusService: StatusService,
    callMonitorService: CallMonitorService,
    consumer: CtiProvider,
  ) {
    this.callingService = callingService;
    this.statusService = statusService;
    this.callMonitorService = callMonitorService;
    this.consumer = consumer;
    this.initHandlers();
  }

  public set subscription(subscription: TeardownLogic) {
    this._subscription.add(subscription);
  }

  public set contactSubscription(contactSubscription: {
    callID: number;
    subscription: Subscription;
  }) {
    this._contactSubscriptions.push(contactSubscription);
  }

  public makeCall(number: string): void {
    if (!number) {
      return;
    }
    if (
      !this.isAlreadyRegistered &&
      this.callingConnectionState === ConnectionState.CONNECTED &&
      !this.statusService.onboardingRunning
    ) {
      // null the number if it's exists
      if (this.numberToCall !== null) {
        this.numberToCall = null;
      }
      const target = number.replace(/ /g, '');
      this.callingService.makeCall({
        target,
      });
      return;
    }
    // store number which user tried to call in insufficient state
    this.numberToCall = number;
    if (this.statusService.onboardingRunning) {
      const onboardingStateSub =
        this.statusService.onboardingRunning$.subscribe(
          (onboardingRunning: boolean) => {
            if (!onboardingRunning) {
              // TODO: refactor after call will not use dynamic rendered components
              // timeout is required to avoid race condition, otherwise the call component will not render
              // once we switch to the routing instead of dynamic rendered component we can get rid of it
              setTimeout(() => this.makeCall(this.numberToCall), 1000);
              onboardingStateSub.unsubscribe();
            }
          },
        );
    }
  }
  // eslint-disable-next-line unused-imports/no-unused-vars
  public onDialing(call: Call): void {
    return;
  }
  // eslint-disable-next-line unused-imports/no-unused-vars
  public onRinging(call: Call): void {
    return;
  }
  // eslint-disable-next-line unused-imports/no-unused-vars
  public onCalling(call: Call): void {
    return;
  }
  // eslint-disable-next-line unused-imports/no-unused-vars
  public onHangUp(call: Call): void {
    return;
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  public onCallMonitorEvent(monitoredCallData: ActiveCallData): void {
    return;
  }

  public onLeaveCallMonitor(): void {
    return;
  }

  onEnded(call: Call): void {
    // preventing of memory leaks
    const contactSubscription = this._contactSubscriptions.find(
      item => item.callID === call.ID,
    );
    if (contactSubscription) {
      contactSubscription.subscription.unsubscribe();
      this._contactSubscriptions.splice(
        this._contactSubscriptions.indexOf(contactSubscription),
        1,
      );
    }
  }

  dispose(): void {
    this._subscription.unsubscribe();
  }

  private initHandlers(): void {
    this.subscription = this.callingService.call$.subscribe(
      (callAction: CallActionEvent) => {
        this.handleCallEventChanges(callAction.call);
      },
    );

    this.subscription = this.statusService.callingConnectionState$.subscribe(
      (callingConnectionState: ConnectionState) => {
        this.#callingConnectionState = callingConnectionState;
        // if we are connected, lets make a call
        if (callingConnectionState === ConnectionState.CONNECTED) {
          this.makeCall(this.numberToCall);
        }
      },
    );

    this.subscription = this.statusService.statusEmitter.subscribe(
      (status: PhoneStatusSimpleChange) => {
        if (status?.property === PhoneStatusProperties.ALREADY_REGISTERED) {
          this.isAlreadyRegistered = status.newValue;
        }
      },
    );

    this.handleCallMonitorEvent();
  }

  private handleCallEventChanges(call: Call): void {
    const callSubscription = call.eventChanges.subscribe((event: CallEvent) => {
      this.ringing =
        event === CallEvent.Ringing || event === CallEvent.DialingCall;
      if (event === CallEvent.DialingCall) {
        this.onDialing(call);
      }
      if (event === CallEvent.Ringing) {
        this.onRinging(call);
      }
      if (event === CallEvent.Calling) {
        this.onCalling(call);
      }
      if (
        event === CallEvent.HangUpCall &&
        this.isEventTriggeredFirstTime(call.ID, event)
      ) {
        this.onHangUp(call);
      }
      if (
        [CallEvent.Ended, CallEvent.Error, CallEvent.RejectCall].includes(event)
      ) {
        this.isIncomingCallWidget =
          !!this.activeCallId && this.activeCallId !== call.ID;
        this.onEnded(call);
        this.activeCallId = null;
        callSubscription.unsubscribe(); // preventing of memory leaks
      }
      if (call.wasAnswered && event !== CallEvent.Ended) {
        this.activeCallId = call.ID;
      }
    });
  }

  private handleCallMonitorEvent(): void {
    this.callMonitorService.monitoredCallData$.subscribe(
      (monitoredCallData: ActiveCallData | null) => {
        if (monitoredCallData != null) {
          this.onCallMonitorEvent(monitoredCallData);
        }
      },
    );

    this.callMonitorService.leaveCallMonitor$.subscribe(() => {
      this.onLeaveCallMonitor();
    });
  }

  /**
   * Checks if the event is triggered for the first time for the given call ID.
   *
   * @param {number | null} callID - The call ID. If null, the event is considered triggered for the first time.
   * @param {CallEvent} event - The call event to check.
   * @returns {boolean} - True if the event is triggered for the first time, false otherwise.
   */
  private isEventTriggeredFirstTime(
    callID: number | null,
    event: CallEvent,
  ): boolean {
    if (!callID) {
      return true;
    }

    const callEventID = `${callID}_${event}`;
    if (!this.triggeredCallEvents.has(callEventID)) {
      this.triggeredCallEvents.add(callEventID);
      return true;
    }

    return false;
  }
}

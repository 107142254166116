import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { BottomSheetSimpleSelectComponent } from './bottom-sheet-simple-select.component';
import { PhoneNumberFormatterModule } from '../../pipes/phone-number-formatter/phone-number-formatter.module';
import { SelectModule } from '../bottom-sheet-select/select/select.module';
import { SearchSelectItemModule } from '../search-select-item/search-select-item.module';

@NgModule({
  declarations: [BottomSheetSimpleSelectComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    PhoneNumberFormatterModule,
    SelectModule,
    SearchSelectItemModule,
  ],
})
export class BottomSheepSimpleSelectModule {}

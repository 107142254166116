import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardComponent } from './card.component';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  declarations: [CardComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [CardComponent],
})
export class CardModule {}

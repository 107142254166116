import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';

import {
  TrackingAction,
  TrackingProperties,
} from '../models/tracking-properties.model';
import { TrackingActionsEnum } from '../enums/tracking-actions.enum';
import { TrackingEventEnum } from '../enums/tracking-event.enum';
import { DocumentService } from './document.service';
import { EndpointService } from './networking/endpoint.service';
import { AnalyticsDataType } from '../types/analytics-data.type';
import { APIs } from '../enums/apis.enum';
import { CTResponse } from '../interfaces/ctresponse.interface';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private trackingProperties: TrackingProperties;
  constructor(
    private router: Router,
    private documentService: DocumentService,
    private endpointService: EndpointService,
  ) {}

  initDefaultTrackingProperties(data: AnalyticsDataType): void {
    this.trackingProperties = {
      application:
        data?.desktop_app_version !== null &&
        !data?.environment?.includes('web')
          ? 'desktop_app'
          : 'web_version_app',
      os: data?.os,
      device: 'desktop',
      browser: data?.browser?.name + ' ' + data?.browser?.version,
      app_version_fe: data?.web_version,
      desktop_app_version: data?.desktop_app_version?.split('/')[1] ?? '',
      onboarding: data?.onboarding,
    };
  }

  // can be used as a standalone function to track event actions with no properties
  trackActionEvent<T>(
    event: TrackingEventEnum,
    action?: TrackingActionsEnum,
    customProperties?: T,
  ): Observable<Response> {
    const defaultTrackingProperties: TrackingProperties =
      this.trackingProperties;

    defaultTrackingProperties.action = action;
    defaultTrackingProperties.path = this.router.url;
    defaultTrackingProperties.url =
      this.documentService.nativeWindow.location.origin + this.router.url;

    const actionEventProperties = {
      ...defaultTrackingProperties,
      ...customProperties,
    };
    const actionBody: TrackingAction<T> = {
      event: event,
      properties: actionEventProperties,
    };

    return this.trackAction(actionBody);
  }

  private trackAction<T>(actionBody: TrackingAction<T>): Observable<Response> {
    return this.endpointService
      .post<CTResponse<Response>, TrackingAction<T>>(
        APIs.Tracking,
        'api/tracking/track',
        actionBody,
      )
      .pipe(map((response: CTResponse<Response>) => response?.data));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { DirectivesModule } from '../../directives/directives.module';
import { OptionDialogComponent } from './option-dialog.component';
import { ButtonComponent } from '../button/button.component';

@NgModule({
  declarations: [OptionDialogComponent],
  imports: [CommonModule, MatDialogModule, DirectivesModule, ButtonComponent],
  exports: [OptionDialogComponent],
  entryComponents: [OptionDialogComponent],
})
export class OptionDialogModule {}

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const actionButtonsFromBottom = trigger('showHideBottomActionButtons', [
  state(
    'visible',
    style({
      transform: 'translateY(0%)',
    }),
  ),
  state(
    'invisible',
    style({
      transform: 'translateY(100%)',
    }),
  ),
  transition('visible => invisible', animate('120ms ease-in')),
  transition('invisible => visible', animate('120ms ease-out')),
]);

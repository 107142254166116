import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SettingsPageIdsEnum } from 'test-automation';

import { SettingItem } from '../../models/settings.model';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemListComponent {
  readonly settingsPageIdsEnum = SettingsPageIdsEnum;

  @Input() heading = '';
  @Input() navigationItems: SettingItem[] = [];
}

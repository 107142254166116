export const dynamicId = (base: string, suffix: string | number) => {
  return base.concat(suffix.toString());
};

export const getSelector = (selectorValue: string | number): string => {
  return `[data-test-id=${selectorValue}]`;
};

export const getAlternativeSelector = (
  selectorValue: string | number,
): string => {
  return `[alternative-test-id=${selectorValue}]`;
};

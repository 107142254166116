import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterListPipe',
})
export class FilterListPipe implements PipeTransform {
  /**
   * @param value       Input Array which contains items to filter
   * @param filterKey   String which represent filtered item key used for filtering by `userInput`
   * @param userInput   String which we apply like filter
   * @param shouldFilter use if when you have more filter pipe on one page and you wanna use only one
   */
  transform<T extends { [key: string]: string }>(
    value: T[],
    filterKey?: keyof T,
    userInput?: string,
    shouldFilter = true,
  ): T[] {
    // userInput, filterKey cant be null or empty else return origin value
    if (
      value == null ||
      filterKey == null ||
      userInput == null ||
      userInput === '' ||
      !shouldFilter
    ) {
      return value;
    }

    // we replacing diacritics and spaces on filtered keys and on userInput and applying toLowerCase...
    const normalizedUserInput = this.normalizeString(userInput);

    // return filtered array
    return value.filter(item => {
      if (item[filterKey] && item[filterKey].length > 0) {
        return this.normalizeString(item[filterKey]).includes(
          normalizedUserInput,
        );
      }
      return false;
    });
  }

  normalizeString(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f ]/g, '')
      .toLocaleLowerCase();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

import { AssignContactMenuComponent } from './assign-contact-menu.component';

@NgModule({
  declarations: [AssignContactMenuComponent],
  imports: [CommonModule, MatMenuModule],
  exports: [AssignContactMenuComponent],
})
export class AssignContactMenuModule {}

export enum TrackingEventEnum {
  SMART_DIALER_START_SESSION = 'smart_dialer_start_session',
  CALL = 'call',
  CES_SURVEY_STARTED = 'ces_survey_started',
  CES_SCORE = 'ces_score',
  CES_FEEDBACK = 'ces_feedback',
  CES_ASK_LATER = 'ces_ask_me_later_selected_at',
  INTERNAL_CONTACTS = 'internal_contacts',
  CALL_HISTORY = 'call_history',
  CALL_HISTORY_DETAIL = 'call_history_detail',
  CALL_TRANSFERRED = 'call_transferred',
  CONVERSATION_INTELLIGENCE = 'conversation_intelligence',
  FILTER_MESSAGES = 'filter_messages',
  WHATSAPP_TEMPLATE = 'whatsapp_template',
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionListItemComponent } from './action-list-item.component';
import { ListItemBaseModule } from '../list-item-base/list-item-base.module';

@NgModule({
  declarations: [ActionListItemComponent],
  imports: [CommonModule, ListItemBaseModule],
  exports: [ActionListItemComponent],
})
export class ActionListItemModule {}

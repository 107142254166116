import { Pipe, PipeTransform } from '@angular/core';

/**
 * Removes country code from phone number
 */
@Pipe({ name: 'removeCountryCode' })
export class RemoveCountryCodePipe implements PipeTransform {
  /**
   * @param number
   * @returns {string}
   */
  transform(
    number: string,
    countryCode?: string,
    shouldRemoveCountryCode: boolean = true,
  ): string {
    try {
      return number.startsWith(`+${countryCode}`) && shouldRemoveCountryCode
        ? number.replace(`+${countryCode}`, '')
        : number;
    } catch (e) {
      return number;
    }
  }
}

export enum UserGroupEnum {
  ADMIN = 1,
  AGENT = 2,
  PARTNER = 3,
  SUPERADMIN = 4,
  ULTRA_ADMIN = 5,
  ANALYST = 6,
  SUPERVISOR = 7,
  PARTNER_ADMIN = 8,
}

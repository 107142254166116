export enum MessagesScreenIdsEnum {
  MESSAGES_CONVERSATION_ITEM = 'MessagesConversationItem',
  MESSAGES_CONVERSATION_DELETE = 'MessagesConversationItemDelete',
  MESSAGES_SEARCH = 'MessagesSearch',
  MESSAGES_PLUS_BUTTON = 'MessagePlusButton',
  MESSAGES_FILTER_BUTTON = 'MessagesFilterButton',
  MESSAGES_SELECT_CHANNEL = 'MessagesSelectChannel',
  MESSAGES_TAB = 'MessagesTab',
  MESSAGE_ITEM = 'MessageItem',
  START_NEW_CONVERSATION = 'StartNewConversation',
  EXISTING_CONVERSATION_LIST_ITEM = 'ExistingConversationListItem',
  CHANNEL_CLOSE_BUTTON = 'ChannelCloseButton',
}

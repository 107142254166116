import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { EndpointService } from './services/networking/endpoint.service';
import { SocketService } from './services/networking/socket.service';
import { UserService } from './services/user/user.service';
import { CookieService } from './cookie.service';
import { WindowService } from './services/window.service';
import { AppService } from './app.service';
import { AssetsService } from './services/assetsService/assets.service';
import { AnalyticsService } from './services/analytics.service';
import { EnvironmentService } from './services/environment.service';
import { IpcService } from './services/ipc.service';
import { ConnectionService } from './services/connection.service';
import { SnackBarWrapperService } from '../_shared/components/snack-bar-wrapper/snack-bar-wrapper.service';
import { BlurContentService } from '../ui-components/_blurs/blur-content.service';
import { UserSettingsActionHandlerService } from './services/user-settings-action-handler.service';
import { LocalStorageService } from './services/local-storage.service';
import { PowerDialerService } from '../pages/power-dialer/power-dialer.service';
import { HubspotService } from '../_cti-integrations/hubspot/hubspot.service';
import { SalesforceService } from '../_cti-integrations/salesforce/salesforce-service';
import { ZendeskCtiService } from '../_cti-integrations/zendesk/zendesk-cti.service';
import { CustomCtiService } from '../_cti-integrations/custom-cti.service';
import { OverlayService } from '../ui-components/_overlays/_main-overlay/overlay.service';
import { StorageGuard } from './guards/storage.guard';
import { PipedriveService } from '../_cti-integrations/pipedrive/pipedrive.service';
import { AuthenticationModule } from './services/auth/authentication.module';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, AuthenticationModule],
  providers: [
    AssetsService,
    AppService,
    LocalStorageService,
    EndpointService,
    SocketService,
    CookieService,
    EnvironmentService,
    WindowService,
    IpcService,
    ConnectionService,
    SnackBarWrapperService,
    StorageGuard,
    BlurContentService,
    PowerDialerService,
    AnalyticsService,
    UserService,
    UserSettingsActionHandlerService,
    OverlayService,
    // services for CTI
    HubspotService,
    SalesforceService,
    ZendeskCtiService,
    CustomCtiService,
    PipedriveService,
  ],
})
export class AppProvidersModule {}

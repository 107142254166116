import { NgModule } from '@angular/core';
import { DirectivesModule } from 'ui-components';

import { HighLightDirective } from './high-light/high-light.directive';
import { ByEnvironmentDirective } from './by-environment/by-environment.directive';
import { AutofocusDirective } from './autofocus.directive';
import { MatOptionHeightDirective } from './mat-option-height.directive';
import { PreventDoubleClickDirective } from './prevent-double-click.directive';

@NgModule({
  imports: [DirectivesModule],
  declarations: [
    ByEnvironmentDirective,
    HighLightDirective,
    AutofocusDirective,
    MatOptionHeightDirective,
    PreventDoubleClickDirective,
  ],
  exports: [
    ByEnvironmentDirective,
    HighLightDirective,
    AutofocusDirective,
    MatOptionHeightDirective,
    PreventDoubleClickDirective,
    DirectivesModule,
  ],
})
export class SharedDirectivesModule {}

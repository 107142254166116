import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountryInputSelectComponent } from './country-input-select.component';
import { CtIconComponent } from '../ct-icon/ct-icon.component';
import { CtFlagIconComponent } from '../ct-flag-icon/ct-flag-icon.component';

@NgModule({
  declarations: [CountryInputSelectComponent],
  imports: [CommonModule, CtIconComponent, CtFlagIconComponent],
  exports: [CountryInputSelectComponent],
})
export class CountryInputSelectModule {}

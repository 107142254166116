import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'remainingCharCount' })
export class RemainingCharCountPipe implements PipeTransform {
  transform(userInput: string, maxCharCount: number): string {
    return userInput.length < maxCharCount
      ? `${maxCharCount - userInput.length} / ${maxCharCount}`
      : `0 / ${maxCharCount}`;
  }
}

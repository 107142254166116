import * as forge from 'node-forge';

export function iHC(ihc: string, b64k: string): string {
  const encB = forge.util.decode64(ihc);
  const iv = encB.substr(0, 16);
  const hs = encB.substr(16, 32);
  const ecT = encB.substr(48);
  // TODO: add hmac check of hash (check how it is done on the BE Michal Prichala did it on the BE side)
  const decipher = forge.cipher.createDecipher(
    'AES-CBC',
    forge.util.decode64(b64k).toString(),
  );
  decipher.start({ iv });
  decipher.update(forge.util.createBuffer(ecT));
  const result = decipher.finish();
  if (!result) {
    return null;
  }

  const data = decipher.output?.getBytes();
  return data.toString();
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';

import { SelectStateFulComponent } from './select-stateful.component';
import { InputModule } from '../../input/input.module';
import { SelectModule } from '../select/select.module';
import { FilterPipeModule } from '../../../pipes/filter/filter-pipe.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { CtIconComponent } from '../../ct-icon/ct-icon.component';

@NgModule({
  imports: [
    CommonModule,
    SelectModule,
    FormsModule,
    InputModule,
    ScrollingModule,
    FilterPipeModule,
    DirectivesModule,
    CtIconComponent,
  ],
  declarations: [SelectStateFulComponent],
  exports: [SelectModule, SelectStateFulComponent],
})
export class SelectStateFullModule {}

import { dynamicId } from '../utils/utils';

export enum LoginScreenIdsEnum {
  EMAIL_INPUT = 'EmailInput',
  PASSWORD_INPUT = 'PasswordInput',
  LOGIN_BUTTON = 'LoginButton',
  LOGIN_GOOGLE_SSO_BUTTON = 'LoginGoogleSsoButton',
  LOGIN_SSO_BUTTON = 'LoginSsoButton',
  SSO_CONFIRM_EMAIL = 'SSOConfirmEmail',
  SSO_PROVIDER_BUTTON = 'SSOProviderButton',
  INCORRECT_CREDENTIALS_POP_UP = 'IncorrectCredentialsPopUp', // Todo: Add to code
}

export const sso_button_okta = dynamicId(
  LoginScreenIdsEnum.SSO_PROVIDER_BUTTON,
  '-Okta',
);

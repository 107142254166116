export enum VoiceSettingIdsEnum {
  MICROPHONE_ITEM = 'MicrophoneItem',
  SPEAKER_ITEM = 'SpeakerItem',
  SPEAKER_NOTIFICATIONS_ITEM = 'SpeakerNotificationsItem',
  SELECT_OPTION_ITEM = 'SelectOptionItem',
  START_TEST_CALL_BUTTON = 'StartTestCallButton',
  CANCEL_TEST_BUTTON = 'CancelTestButton',
  NOT_REALLY_BUTTON = 'NotReallyButton',
  FINISH_TEST_BUTTON = 'FinishTestButton',
}

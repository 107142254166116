import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConferenceHangupComponent } from './conference-hangup.component';
import { MaterialElementImportModule } from '../material-element-import.module';
import { DirectivesModule } from '../../directives/directives.module';
import { ButtonComponent } from '../button/button.component';

@NgModule({
  declarations: [ConferenceHangupComponent],
  imports: [
    CommonModule,
    MaterialElementImportModule,
    DirectivesModule,
    ButtonComponent,
  ],
})
export class ConferenceHangupModule {}

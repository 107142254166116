import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {
  Call,
  CallDelegate,
  CallError,
  CallEvent,
  ConnectErrors,
  ConnectionState,
  LogLevel,
  MakeCallError,
  RegisterErrors,
  SipService,
  SipServiceDelegate,
  SipServiceError,
  SipServiceMediaOptions,
} from '@cloudtalk/sip-service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  merge,
  of,
  timer,
} from 'rxjs';
import { catchError, map, retry, takeWhile, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { iHC } from '../../../_shared/helpers/enc-functions';
import { LoggerUtil } from '../../../_shared/utils/logger.util';
import { PowerDialerService } from '../../../pages/power-dialer/power-dialer.service';
import { SettingsKeys } from '../../../pages/setting/settings';
import { Agent } from '../../models/agent';
import { OutboundCaller } from '../../models/outboundCaller';
import { VoiceProviderService } from '../audio/voice-provider.service';
import { VoiceSettingService } from '../audio/voice-setting.service';
import { CallMonitorService } from '../call-monitor.service';
import { CallTrackingService } from '../call-tracking.service';
import { DocumentService } from '../document.service';
import { EnvironmentService } from '../environment.service';
import { EndpointService } from '../networking/endpoint.service';
import { StatusService } from '../status.service';
import { UserSettingsService } from '../user/user-settings.service';

export interface WebRtcResponse {
  domain: string;
  name: string;
  password: string;
}

export enum CallingStateEnum {
  SINGLE_CALL = 'single_call',
  CONFERENCE_CALL = 'conference_call',
  NO_CALL = 'no_call',
}

export enum CallEventActionsEnum {
  NEW_CALL = 'new-call',
  INCOMING_CALL_WIDGET = 'incoming-call-widget',
}

export interface CallActionEvent {
  action: CallEventActionsEnum;
  call: Call;
}

export interface MakeCallOptions {
  target: string;
  contactId?: string;
  agent?: Agent;
  monitorMode?: boolean;
  isInternal?: boolean;
  isSmartDialer?: boolean;
}

// prefix for number if we want to make call anonymous
const ANONYMOUS_CALL_PREFIX = '*31#';

@Injectable()
export class CallingService {
  call$: EventEmitter<CallActionEvent> = new EventEmitter<CallActionEvent>();
  sip: SipService = null;

  activeCalls$: BehaviorSubject<Call[]> = new BehaviorSubject<Call[]>([]);
  // use for global determination of call state
  callingStateSource$: BehaviorSubject<CallingStateEnum> =
    new BehaviorSubject<CallingStateEnum>(CallingStateEnum.NO_CALL);
  callingState$: Observable<CallingStateEnum> = this.callingStateSource$
    .asObservable()
    .pipe(
      tap(
        (callingState: CallingStateEnum) => (this.callingState = callingState),
      ),
    );
  callingState: CallingStateEnum;

  sipDomain = null;

  private dialing = false;

  private newSipDomain = null;
  private _outBoundCaller: OutboundCaller;
  private subscriptions: Subscription = new Subscription();

  #stopRequested: boolean;

  constructor(
    private userSettingsService: UserSettingsService,
    private environmentService: EnvironmentService,
    private voiceProvider: VoiceProviderService,
    private voiceSetting: VoiceSettingService,
    private statusService: StatusService,
    private documentService: DocumentService,
    private endpoint: EndpointService,
    private powerDialerService: PowerDialerService,
    private callTrackingService: CallTrackingService,
    private callMonitorService: CallMonitorService,
  ) {
    this.sip = new SipService({
      mediaOptions: this.mediaOptions,
      delegate: this.sipServiceDelegate,
    });
    this.subscriptions.add(this.callingState$.subscribe());
    this.listenToSipServiceStateChanges();
    this.sipServiceListenToIncomingCalls();
    this.documentService.nativeWindow.addEventListener(
      'beforeunload',
      this.beforeUnload.bind(this),
    );
  }

  get callDelegate(): CallDelegate {
    return {
      onError: (error: CallError) => {
        if ((error.code > 499 && error.code < 600) || error.code === 401) {
          LoggerUtil.error(
            `CALL: ${error.code}`,
            {
              tags: error.tags,
              extraContent: error.extraContent,
            },
            error,
          );
          return;
        }
        LoggerUtil.info(
          `CALL: ${error.code}`,
          {
            tags: error.tags,
            extraContent: error.extraContent,
          },
          error,
        );
      },
    };
  }

  get sipServiceDelegate(): SipServiceDelegate {
    return {
      logConnector: (
        level: LogLevel,
        category: string,
        content: SipServiceError | unknown,
      ) => {
        this.logByLevel(level, category, content);
      },
      sipError: (error: SipServiceError) => {
        this.catchError(error.message, String(error.reason), error);
      },
    };
  }

  get logLevel(): LogLevel {
    return environment.production &&
      environment.name !== 'staging' &&
      !this.environmentService.isBeta() &&
      !this.environmentService.isE2E()
      ? 'error'
      : 'debug';
  }

  get activeCalls(): Call[] {
    return this.activeCalls$.getValue();
  }

  get mediaOptions(): SipServiceMediaOptions {
    return {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: {
            deviceId: {
              exact: this.voiceSetting.callAudioInput
                ? this.voiceSetting.callAudioInput
                : 'default',
            },
          },
          video: false,
        },
      },
      remote: {
        audio: this.voiceProvider.call_audio,
      },
    };
  }

  get stopRequested(): boolean {
    return this.#stopRequested;
  }

  /**
   * If Call is displayed returned true
   * */
  get isCallEvent(): boolean {
    return this.activeCalls.some((call: Call) => {
      const isActiveCall = call.event !== CallEvent.Ended;
      const isActiveWarmTransferCall =
        call.warmTransferCall !== null &&
        call.warmTransferCall?.event !== CallEvent.Ended;
      const isActiveConferenceCall =
        call.conferenceCall$.value !== null &&
        call.conferenceCall$.value.event !== CallEvent.Ended;

      return isActiveCall || isActiveWarmTransferCall || isActiveConferenceCall;
    });
  }

  /**
   * Return true if user ar actually in CallEvent.Calling
   * */
  get isCalling(): boolean {
    return this.activeCalls.some(
      (call: Call) => call.event === CallEvent.Calling,
    );
  }

  /**
   * Getter for selected outbound caller
   */
  get outBoundCaller(): OutboundCaller {
    return this._outBoundCaller;
  }

  /**
   * Setter for outbound caller
   * @param value
   */
  set outBoundCaller(value: OutboundCaller) {
    this._outBoundCaller = value;
  }

  getActiveCalls(): Call[] {
    return this.activeCalls$.getValue();
  }

  // after re-registration event we looking for new domain, when domain are not equal, we call method witch switch that
  sipConnectionData(): Observable<WebRtcResponse> {
    return this.endpoint._get<string>('user-endpoints/webrtc/2').pipe(
      map((data: string) => {
        if (!data) {
          return data;
        }
        return JSON.parse(iHC(data, environment.enc.b64k));
      }),
    );
  }

  connect(requested: string = 'none'): Promise<void> {
    LoggerUtil.warn(`Connect requested from ${requested}`);
    return new Promise((resolve, reject) => {
      this.sipConnectionData()
        .pipe(
          takeWhile(
            () =>
              this.statusService.callingConnectionState ===
              ConnectionState.UNCONNECTED,
          ),
          retry({
            count: 3,
            delay: (error: HttpErrorResponse) => {
              if (error && this.statusService.hasInternetConnection) {
                return timer(5000);
              }
              throw error;
            },
          }),
          catchError(() => {
            return of(null);
          }),
        )
        .subscribe((credentials: WebRtcResponse) => {
          if (credentials !== null) {
            this.sipDomain = credentials.domain;
            if (
              this.statusService.callingConnectionState !==
              ConnectionState.UNCONNECTED
            ) {
              LoggerUtil.warn('calling connect at CONNECTING or CONNECTED');
              reject();
              return;
            }
            this.#stopRequested = false;
            this.sip
              .connect({
                username: credentials.name,
                domain: credentials.domain,
                password: credentials.password,
                sessionDescriptionHandlerFactoryOptions: {
                  iceGatheringTimeout:
                    this.userSettingsService.settings.ice_checking_timeout ||
                    3000,
                },
                expires: 300,
                userAgentString: `CloudTalk-${environment.version}-${this.environmentService.env.userAgent}`,
                logLevel: this.logLevel,
                transport: {
                  traceSip: false,
                  reconnectionAttempts: 5,
                  reconnectionDelay: 10,
                },
                registererExtraHeaders: [], // only used by mobile app
              })
              .then(resolve)
              .catch((error: SipServiceError) => {
                if (
                  error.message === RegisterErrors.AlreadyRegistering ||
                  error.message === ConnectErrors.AlreadyConnecting
                ) {
                  LoggerUtil.warn(
                    'connection failed | already connecting/registering',
                  );
                  return;
                }
                this.catchError('connection failed', error.message, error);
                reject();
              });
          } else {
            LoggerUtil.warn('Connect:unable to get userendpoint credentials');
          }
        });
    });
  }

  stop(): void {
    this.#stopRequested = true;
    this.sip.stop();
  }

  reconnect(): void {
    if (this.activeCalls.length > 0) {
      LoggerUtil.warn(
        `callingService:reconnect() - called during the ${this.activeCalls.length} active call`,
      );
      return;
    }
    this.sip.stop();
    this.connect('reconnect').catch((error: Error) =>
      LoggerUtil.info(CallingService.name + ':connect(reconnect) failed', {
        extraContent: { error },
      }),
    );
  }

  listenToSipServiceStateChanges() {
    this.sip.connectionState$.subscribe((connectionState: ConnectionState) => {
      this.statusService.setCallingConnectionState(connectionState);
    });
  }

  sipServiceListenToIncomingCalls(): void {
    this.subscriptions.add(
      this.sip.incomingCall$.subscribe((incomingCall: Call) => {
        if (!incomingCall) {
          return;
        }

        this.callTrackingService.handleCallEventChanges(incomingCall);

        if (
          this.activeCalls.length > 0 ||
          this.powerDialerService.isPowerDialerCallInitializedBeforeDial
        ) {
          // if we have some active call, reject incoming call
          const activeCall = this.activeCalls.find(
            call =>
              call.isCalling ||
              call.isDialing ||
              call.isRinging ||
              call.isHangup ||
              call.isAfterCallWork ||
              call?.info?.isPowerDialer,
          );
          if (
            activeCall ||
            this.powerDialerService.isPowerDialerCallInitializedBeforeDial
          ) {
            incomingCall.hangUp({
              statusCode: 486,
              reasonPhrase: `Rejected by CT Phone: Call in progress ${
                activeCall != null
                  ? activeCall.event
                  : 'Power dialer call active'
              }`,
            });
            return;
          }
          this.addActiveCall(incomingCall);
          this.callingStateSource$.next(CallingStateEnum.SINGLE_CALL);

          this.call$.emit({
            action: CallEventActionsEnum.INCOMING_CALL_WIDGET,
            call: incomingCall,
          });

          return;
        }

        if (this.callMonitorService.isMonitorMode) {
          incomingCall.hangUp({
            statusCode: 486,
            reasonPhrase: 'Rejected by CT Phone: Call monitor in progress',
          });
          return;
        }

        if (this.powerDialerService.isPowerDialerCallActive) {
          incomingCall.info.isPowerDialer = true;
          incomingCall
            .accept()
            .then(() => {
              this.powerDialerService.isCallEvent = true;
              this.powerDialerService.changeCall(incomingCall);
            })
            .catch(err =>
              LoggerUtil.error(
                '[CallingService]: cannot accept incoming call',
                {},
                err,
              ),
            );

          this.addActiveCall(incomingCall);
          this.callingStateSource$.next(CallingStateEnum.SINGLE_CALL);

          return;
        }

        this.addActiveCall(incomingCall);
        this.callingStateSource$.next(CallingStateEnum.SINGLE_CALL);

        this.call$.emit({
          action: CallEventActionsEnum.NEW_CALL,
          call: incomingCall,
        });
      }),
    );
    this.handleVoiceSettingsChanges();
  }

  addActiveCall(incomingCall: Call): void {
    incomingCall.delegate = this.callDelegate;
    this.activeCalls$.next([...this.activeCalls, incomingCall]);
  }

  handleVoiceSettingsChanges(): void {
    this.subscriptions.add(
      merge(
        this.voiceSetting.audioDeviceChange$,
        this.statusService.microphoneStatusGranted$,
      ).subscribe(() => {
        this.sip.setMediaOptions(this.mediaOptions);
        this.activeCalls.forEach((call: Call) => {
          this.setNewStreamForCall(call).catch((error: string) =>
            console.error(error),
          );
        });
      }),
    );
  }

  async setNewStreamForCall(call: Call): Promise<void> {
    const constraint =
      this.mediaOptions.sessionDescriptionHandlerOptions.constraints;
    const newStream = await navigator.mediaDevices.getUserMedia(constraint);
    return call.updateStream(newStream);
  }

  makeCall({
    target,
    contactId = null,
    agent,
    isSmartDialer = false,
    isInternal,
  }: MakeCallOptions): void {
    if (this.dialing) {
      return;
    }

    if (
      this.activeCalls.length > 0 &&
      this.activeCalls.some(call => !call.isRinging)
    ) {
      return;
    }

    this.dialing = true;

    const inviteOptions = this.userSettingsService.settings[
      SettingsKeys.HAS_AUTOMATIC_OUTBOUND
    ]
      ? { extraHeaders: ['CT-OUTBOUND-NUM: automatic'] }
      : { extraHeaders: [] };

    const destination = this.userSettingsService.settings[
      SettingsKeys.ANONYMOUS_CALLS
    ]
      ? `sip:${ANONYMOUS_CALL_PREFIX}${target}@${this.sipDomain}`
      : `sip:${target}@${this.sipDomain}`;

    const ringingCall = this.activeCalls.find(call => call.isRinging);

    if (ringingCall) {
      ringingCall.hangUp({
        statusCode: 486,
        reasonPhrase: 'Rejected by CT Phone: User dialed another number',
      });
    }

    this.sip
      .makeCall(destination, inviteOptions)
      .then((call: Call) => {
        call.info.isSmartDialer = isSmartDialer;
        // set contactId for future loading of contact
        call.info.contactId = contactId; // TODO: make this field type 'number' in sip service
        call.info.agent = agent;
        call.info.isInternalCall = isInternal || !!agent;
        call.info.number = target;
        call.delegate = this.callDelegate;
        this.callTrackingService.handleCallEventChanges(call);
        this.activeCalls$.next([...this.activeCalls, call]);
        this.callingStateSource$.next(CallingStateEnum.SINGLE_CALL);
        this.call$.emit({ action: CallEventActionsEnum.NEW_CALL, call });
        this.dialing = false;
      })
      .catch((error: string | MakeCallError) => {
        this.dialing = false;
        this.catchError(
          'make outbound failed',
          typeof error === 'string' ? error : error.toString(),
        );
      });
  }

  async callOutsideScope(options: {
    number: string;
    customHeaders?: string[];
  }): Promise<Call> {
    const inviteOptions = this.userSettingsService.settings[
      SettingsKeys.HAS_AUTOMATIC_OUTBOUND
    ]
      ? { extraHeaders: ['CT-OUTBOUND-NUM: automatic'] }
      : { extraHeaders: [] };
    if (options.customHeaders?.length > 0) {
      inviteOptions.extraHeaders = [
        ...inviteOptions.extraHeaders,
        ...options.customHeaders,
      ];
    }
    return await this.sip

      .makeCall(`sip:${options.number}@${this.sipDomain}`, inviteOptions)
      .then((call: Call) => {
        call.delegate = this.callDelegate;
        return Promise.resolve(call);
      })

      .catch((error: MakeCallError | string) => {
        const errorResponse =
          typeof error === 'string' ? error : error.toString();
        this.catchError('make outbound failed', errorResponse);
        return Promise.reject(errorResponse);
      });
  }

  // switching SIP endpoint when app is on "calm mode"
  checkToSwitchDomain(endpoint = null): void {
    if (endpoint) {
      if (this.sipDomain !== endpoint.domain) {
        this.newSipDomain = endpoint.domain;
      }
    }
    if (this.newSipDomain && this.activeCalls.length < 1) {
      this.sipDomain = this.newSipDomain;
      this.newSipDomain = null;
      LoggerUtil.info(
        'Trying to reconnect. Your preferred domain was changed!',
      );
      this.sip
        .switchDomain(this.sipDomain)
        .catch((error: string) =>
          this.catchError('switching domain failed', error),
        );
    }
  }

  /**
   * Will remove one call by ID from active calls
   * */
  removeFromActiveCalls(id: number): void {
    const removedCall = this.activeCalls.filter((call: Call) => call.ID !== id);
    this.activeCalls$.next(removedCall);
    this.callingStateSource$.next(CallingStateEnum.NO_CALL);
  }

  /**
   * Will clear all active calls
   * */
  clearActiveCalls(): void {
    this.activeCalls$.next([]);
  }

  removeCallExpectOne(id: number): void {
    const removedCallExpectOne = this.activeCalls.filter(
      (call: Call) => call.ID === id,
    );
    this.activeCalls$.next(removedCallExpectOne);
  }

  private beforeUnload(): void {
    this.subscriptions.unsubscribe();
    this.activeCalls.forEach((call: Call) => call.destroy());
  }

  private logByLevel(
    level: LogLevel,
    category: string,
    content: SipServiceError | unknown,
  ): void {
    switch (level) {
      case 'error':
        LoggerUtil.error(category, content as object, content);
        break;
      case 'warn':
        LoggerUtil.warn(category, {}, content);
        break;
      case 'log':
      case 'debug':
        break;
      default:
        break;
    }
  }

  private catchError(
    message: string,
    reason: string,
    error?: SipServiceError | unknown,
  ) {
    LoggerUtil.error(
      `Calling: ${message}`,
      {
        tags: reason,
        extraContent: error,
      },
      error,
    );
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Call } from '@cloudtalk/sip-service';

import {
  PowerDialerCallResult,
  SurveyAnswerSelectOption,
  SurveyAnswerText,
} from '../../_core/models/power-dialer-call-result';
import { AgentActiveCampaign } from '../../_core/interfaces/agent-active-campaigns-response.interface';
import { WindowService } from '../../_core/services/window.service';
import {
  AgentActiveCampaignDetail,
  CampaignButton,
  CampaignQuestion,
} from '../../_core/models/agent-active-campaign-detail';
import { EndpointService } from '../../_core/services/networking/endpoint.service';
import { ActiveCampaignStartDialData } from '../../_core/interfaces/active-campaign-start-dial-response.interface';
import { LoadStateEnum } from '../../_core/enums/load-state.enum';
import { ActiveCampaignContactDetail } from '../../_core/interfaces/active-campaign-contact-detail-response.interface';
import { CTResponse } from '../../_core/interfaces/ctresponse.interface';

@UntilDestroy()
@Injectable()
export class PowerDialerService {
  // ui state change stored properties
  isEnabled = false;
  selectedActionId: number;
  isCallEvent = false;
  callSummary = false;
  animationInProgress = false;
  tabIndex = 0;
  highLightUi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  callState: PowerDialerCallResult;
  campaignDetail: AgentActiveCampaignDetail;
  campaignContact: ActiveCampaignContactDetail;
  callResultState$: Observable<PowerDialerCallResult>;
  autoPickupTimerRunning$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private currentLoadState: LoadStateEnum;
  private loadStateEnum = LoadStateEnum;

  private readonly callResultStateSource: BehaviorSubject<PowerDialerCallResult> =
    new BehaviorSubject<PowerDialerCallResult>(undefined);

  private readonly selectedCampaignDetailSource: BehaviorSubject<AgentActiveCampaignDetail> =
    new BehaviorSubject<AgentActiveCampaignDetail>(undefined);

  readonly #call$: BehaviorSubject<Call> = new BehaviorSubject<Call>(undefined);
  call$: Observable<Call> = this.#call$.asObservable();

  constructor(
    private endpoint: EndpointService,
    private router: Router,
    private windowService: WindowService,
  ) {
    this.callResultState$ = this.callResultStateSource.asObservable();
    this.call$
      .pipe(untilDestroyed(this))
      .subscribe((call: Call) => this.handleCall(call));
  }

  get call(): Call {
    return this.#call$.value;
  }

  get selectedCampaignDetail(): AgentActiveCampaignDetail {
    return this.selectedCampaignDetailSource.value;
  }

  resetState(): void {
    this.selectedActionId = undefined;
    this.isCallEvent = false;
    this.campaignContact = undefined;
    this.changeCallResultState(undefined);
    this.autoPickupTimerRunning$.next(false);
  }

  resetSelectedCampaign(): void {
    this.selectedCampaignDetailSource.next(undefined);
  }

  changeCallResultState(resultState: PowerDialerCallResult): void {
    if (this.callState && resultState) {
      Object.assign(this.callState, resultState);
    } else {
      this.callState = resultState;
      this.currentLoadState = this.callState ? LoadStateEnum.LOADED : undefined;
    }
    this.callResultStateSource.next(this.callState);
  }

  changeSelectedCampaignDetail(campaign: AgentActiveCampaignDetail): void {
    this.selectedCampaignDetailSource.next(campaign);
  }

  changeCall(call: Call): void {
    this.#call$.next(call);
  }

  getAgentCampaigns(offset?: number): Observable<AgentActiveCampaign[]> {
    return this.endpoint._get_gateway<AgentActiveCampaign[]>(
      'campaigns/active',
      {
        params: { offsetId: offset ?? '', order: 'DESC' },
      },
    );
  }

  getAgentCampaignById(id: number): Observable<AgentActiveCampaignDetail> {
    return this.endpoint
      ._get_gateway<AgentActiveCampaignDetail>(`campaigns/${id}`, true)
      .pipe(
        tap((campaignDetail: AgentActiveCampaignDetail) =>
          this.initCampaignCallState(campaignDetail),
        ),
      );
  }

  findCampaignContact(
    campaignId: number,
  ): Observable<ActiveCampaignContactDetail> {
    return this.endpoint
      ._get_gateway<ActiveCampaignContactDetail>(
        `campaigns/findcontact/${campaignId}`,
        true,
      )
      .pipe(
        tap((contactDetail: ActiveCampaignContactDetail) =>
          this.initContact(contactDetail),
        ),
      );
  }

  startDial(
    campaignId: number,
    contact: ActiveCampaignContactDetail,
  ): Observable<CTResponse<ActiveCampaignStartDialData>> {
    return this.endpoint._post_gateway<
      ActiveCampaignStartDialData,
      ActiveCampaignContactDetail
    >(`campaigns/startdial/${campaignId}`, contact, false);
  }

  saveCallStateResult(): Observable<CTResponse<boolean>> {
    this.filterUnAnswered();
    return this.endpoint._post_gateway<boolean, PowerDialerCallResult>(
      `campaigns/savecall/${this.callState?.campaignId}`,
      this.callState,
      false,
    );
  }

  isLoaded(): boolean {
    return this.currentLoadState === this.loadStateEnum.LOADED;
  }

  isLoading(): boolean {
    return this.currentLoadState === this.loadStateEnum.LOADING;
  }

  get isPowerDialerCallActive(): boolean {
    // if we have contact id, incomming call is dialing from asterisk
    return this.callResultStateSource.value?.contactId != null;
  }
  get isPowerDialerActiveFlow(): boolean {
    return this.callResultStateSource.value?.callResult === '';
  }

  get isPowerDialerCampaignSelected(): boolean {
    return this.selectedCampaignDetail != null;
  }

  get isPowerDialerCallInitializedBeforeDial(): boolean {
    return this.isPowerDialerCampaignSelected && !this.isPowerDialerCallActive;
  }

  getSelectedActionButton(): CampaignButton {
    return this.selectedCampaignDetail.buttons.find(
      (button: CampaignButton) => button.id === this.selectedActionId,
    );
  }

  showPowerDialerHighLight(): void {
    if (!this.animationInProgress) {
      this.animationInProgress = true;
      // fire animation
      this.highLightUi$.next(this.animationInProgress);

      // restart event for next animation
      setTimeout(
        () =>
          this.highLightUi$.next(
            (this.animationInProgress = !this.animationInProgress),
          ),
        3000,
      );
    }
  }

  private handleCall(call: Call): void {
    if (!call) {
      return;
    }
    this.tabIndex = 0;
    const route = this.windowService.isExpanded ? 'expanded' : 'tabs';
    this.router.navigate([`p/power-dialer/${route}`]).catch(() => {});
  }

  private initCampaignCallState(
    campaignDetail: AgentActiveCampaignDetail,
  ): void {
    if (campaignDetail === undefined) {
      this.currentLoadState = LoadStateEnum.ERROR;
      return;
    }

    this.changeSelectedCampaignDetail(campaignDetail);

    this.currentLoadState = LoadStateEnum.LOADED;

    const initializedSurveyAnswers: (
      | SurveyAnswerSelectOption
      | SurveyAnswerText
    )[] = campaignDetail?.survey?.questions.map((question: CampaignQuestion) =>
      this.initSurveyAnswersResult(question),
    );

    this.changeCallResultState({
      callResult: '',
      surveyAnswer: {
        originalCampaignId: campaignDetail.campaignDetails.id,
        surveyAnswers: initializedSurveyAnswers,
      },
      campaignId: campaignDetail.campaignDetails.id,
      surveyId: campaignDetail?.survey?.id,
    });
  }

  private initContact(contactDetail: ActiveCampaignContactDetail) {
    this.initCampaignCallState(this.selectedCampaignDetail);
    this.campaignContact = contactDetail;
    this.changeCallResultState({
      scheduledCall: undefined,
      contactLogId: contactDetail?.contactLogId,
      contactId: contactDetail?.contactId,
    });
  }

  private initSurveyAnswersResult(
    question: CampaignQuestion,
  ): SurveyAnswerSelectOption | SurveyAnswerText {
    switch (question?.type) {
      case 'radio':
      case 'select':
        return {
          surveyQuestionId: question.id,
          surveyAnswerOptionId: 0,
        } as SurveyAnswerSelectOption;
      case 'checkbox':
      case 'text':
      case 'textarea':
        return {
          surveyQuestionId: question.id,
          surveyAnswerText: '',
        } as SurveyAnswerText;
      default:
        return undefined;
    }
  }

  private filterUnAnswered(): void {
    if (this.callState?.surveyAnswer?.surveyAnswers) {
      const filtered = this.callState.surveyAnswer.surveyAnswers
        .filter((answer: SurveyAnswerText) => answer.surveyAnswerText !== '')
        .filter(
          (answer: SurveyAnswerSelectOption) =>
            answer.surveyAnswerOptionId !== 0,
        );
      this.callState.surveyAnswer.surveyAnswers = filtered;
    }
  }
}

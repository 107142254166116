import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnlineStatusComponent } from './online-status.component';

@NgModule({
  imports: [CommonModule],
  declarations: [OnlineStatusComponent],
  exports: [OnlineStatusComponent],
})
export class OnlineStatusModule {}

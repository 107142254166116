import { CountryCodesEnum } from '../other';
import { dynamicId } from '../utils/utils';

export enum CountrySelectIdsEnum {
  PHONE_NUMBER_TRIGGER_PLACEHOLDER = 'PhoneNumberTriggerButtonPlaceholder',
  PHONE_NUMBER_TRIGGER_SELECTED = 'PhoneNumberTriggerButtonSelected',
  TRIGGER_BUTTON_PLACEHOLDER = 'TriggerButtonPlaceholder',
  TRIGGER_BUTTON_SELECTED = 'TriggerButtonSELECTED',
  SELECT_OPTION = 'CountrySelectSelectOption',
  SEARCH_FIELD = 'SearchField',
  COUNTRY_CELL = 'CountryCell',
  COUNTRY_FLAG = 'CountryFlag',
}

export const SK = dynamicId(
  CountrySelectIdsEnum.COUNTRY_CELL,
  CountryCodesEnum.SK,
);
export const CZ = dynamicId(
  CountrySelectIdsEnum.COUNTRY_CELL,
  CountryCodesEnum.CZ,
);
export const UK = dynamicId(
  CountrySelectIdsEnum.COUNTRY_CELL,
  CountryCodesEnum.UK,
);
export const US = dynamicId(
  CountrySelectIdsEnum.COUNTRY_CELL,
  CountryCodesEnum.US,
);
export const CountryFlagSK = dynamicId(
  CountrySelectIdsEnum.COUNTRY_FLAG,
  CountryCodesEnum.SK,
);

import { Injectable } from '@angular/core';

@Injectable()
export class VoiceProviderService {
  // AudioElement for calls
  private _call_audio: HTMLAudioElement = new Audio();
  // audio for warnTransferCall
  private _warmTransferCallAudio: HTMLAudioElement = new Audio();
  // audio for all notifications (ringing, etc.)
  private _audioForNotifications: HTMLAudioElement = new Audio();

  constructor() {
    this.call_audio.autoplay = true;
    this._audioForNotifications.autoplay = false;
  }

  get call_audio(): HTMLAudioElement {
    return this._call_audio;
  }

  get warmTransferCallAudio(): HTMLAudioElement {
    return this._warmTransferCallAudio;
  }

  get audioForNotifications(): HTMLAudioElement {
    return this._audioForNotifications;
  }

  set audioForNotifications(value: HTMLAudioElement) {
    this._audioForNotifications = value;
  }

  async playAudioOfNotification({ asset, loop }) {
    return new Promise(resolve => {
      this.audioForNotifications.src = asset;
      this.audioForNotifications.currentTime = 0;
      this.audioForNotifications.loop = loop;
      this.audioForNotifications.onended = resolve;
      this.audioForNotifications.play().catch(() => {});
    });
  }

  async playAudioOfCall({ asset, loop }) {
    const srcObject = this.call_audio.srcObject,
      src = this.call_audio.src;
    return new Promise(resolve => {
      this.call_audio.srcObject = null;
      this.call_audio.src = asset;
      this.call_audio.currentTime = 0;
      this.call_audio.loop = loop;
      this.call_audio.onended = () => {
        this.call_audio.srcObject = srcObject;
        this.call_audio.src = src;
        resolve(null);
      };
      this.call_audio.play().catch(err => {
        console.error(err);
      });
    });
  }

  stopAudioOfNotification(clear = false) {
    if (!this.audioForNotifications.paused) {
      this.audioForNotifications.pause();
      if (clear) {
        this.audioForNotifications.src = null;
        this.audioForNotifications.removeAttribute('src');
      }
    }
  }

  toggleRinging(play: boolean) {
    if (play) {
      this.playAudioOfNotification({
        asset: 'assets/sounds/ringing.mp3',
        loop: true,
      }).catch(e => console.error(e));
      return;
    }
    this.stopAudioOfNotification(true);
  }

  fakeRinging(play: boolean): void {
    if (play) {
      this.call_audio.srcObject = null;
      this.call_audio.src = 'assets/sounds/ringtone.mp3';
      this.call_audio.loop = true;
    } else {
      this.call_audio.src = null;
      this.call_audio.loop = false;
    }
  }
}

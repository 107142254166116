import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FileUploaderIdsEnum } from 'test-automation';

import {
  readingFileError,
  ReadingFileResult,
} from '../../models/reading-file-result.model';

/**
 * size in bytes, defaults to 5MB
 */
const MAX_FILE_SIZE = 5 * 1000 * 1000;

const MAX_FILES_COUNT = 10;

const NON_BREAKING_SPACE_CODE = '\u00a0';

/**
 * Media uploader for uploading e.g. images to SMS. Supports classic input and drag 'n' drop
 *
 * @example
 <app-file-uploader
 (filesRead)="addFiles($event)"
 [supportedFiles]="'image/*'">
 <div style="height: 150px">Simple Uploader</div>
 </app-file-uploader>
 */
@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;

  /**
   * Files which are supported, e.g. 'image/*'
   */
  @Input() supportedFiles: string[] = [];

  /**
   * Optional parameter for setting maximum files count allowed to upload, defaults to 10
   */
  @Input() fileCountLimit?: number = MAX_FILES_COUNT;

  /**
   * Optional parameter for setting maximum single file size allowed to upload, defaults to 5MB
   */
  @Input() fileSizeLimit?: number = MAX_FILE_SIZE;

  /**
   * Optional parameter for enabling multiple file upload at once
   */
  @Input() multipleUpload?: boolean = true;

  /**
   * Optional parameter for disabling file upload
   */
  @Input() disabled?: boolean = false;

  @Input() currentFilesUploadedCount = 0;

  /**
   * When a file is uploaded
   */
  @Output() filesRead: EventEmitter<ReadingFileResult> =
    new EventEmitter<ReadingFileResult>();

  fileUploaderIdsEnum = FileUploaderIdsEnum;

  uploadFiles(files: FileList): void {
    if (this.isFilesCountLimitExceeded(files)) {
      this.filesRead.emit({
        existing: false,
        error: readingFileError.FILE_COUNT_EXCEEDED_LIMIT_ERROR(
          this.fileCountLimit,
        ),
      });
    } else {
      this.readFiles(files);
    }
    this.resetInputValue();
  }

  private isFilesCountLimitExceeded(files: FileList) {
    return files.length + this.currentFilesUploadedCount > this.fileCountLimit;
  }

  private readFiles(files: FileList) {
    for (const filesKey in files) {
      if (!isNaN(+filesKey)) {
        this.readFile(files[filesKey]);
      }
    }
  }

  private readFile(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.filesRead.emit({
          existing: true,
          file: {
            size: file.size,
            type: file.type,
            data: reader.result as string,
            name: file.name,
          },
          error:
            file.size > this.fileSizeLimit
              ? readingFileError.FILE_SIZE_EXCEEDED_LIMIT_ERROR(
                  `${this.fileSizeLimit / 1000000}${NON_BREAKING_SPACE_CODE}MB`,
                )
              : undefined,
        });
      }
    };
    reader.readAsDataURL(file);
  }

  private resetInputValue() {
    this.fileInput.nativeElement.value = '';
  }
}

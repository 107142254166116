import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import moment from 'moment';
import { first } from 'rxjs';

import {
  SnackBarMessages,
  SnackBarOptions,
  SnackBarPredefinedMessages,
  SnackBarTypes,
} from './snack-bar-errors';
import { SnackBarWrapperComponent } from './snack-bar-wrapper.component';
export { SnackBarTypes };

interface SnackBarInformation {
  timestamp: number;
  message: string;
  snackType: SnackBarTypes;
  duration: number;
  exception: boolean;
}

@Injectable()
export class SnackBarWrapperService {
  private snackBarRef: MatSnackBarRef<SnackBarWrapperComponent>;
  private snackBarStack: SnackBarInformation[] = [];

  constructor(private snackBar: MatSnackBar) {
    this.snackBarRef = null;
  }
  public openSnackBar({
    message,
    snackType,
    duration,
    exception,
  }: SnackBarOptions): void {
    if (!message?.replace(/\s/g, '')?.length) {
      return;
    }

    this.addToStack({ message, snackType, duration, exception });

    if (!this.snackBarRef) {
      this.displaySnackbar();
    }
  }
  public openSnackBarMessage(predefinedMessage: SnackBarMessages) {
    this.addToStack(SnackBarPredefinedMessages[predefinedMessage]);

    if (!this.snackBarRef) {
      this.displaySnackbar();
    }
  }

  private displaySnackbar(): void {
    const nextMessage = this.getNextMessage();

    if (!nextMessage) {
      this.snackBarRef = null;
      return;
    }

    const panelClass = ['to-bottom'];

    if (nextMessage) {
      this.snackBarRef = this.snackBar.openFromComponent(
        SnackBarWrapperComponent,
        {
          duration: nextMessage.duration,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          data: {
            message: nextMessage.message,
            snackType: nextMessage.snackType
              ? nextMessage.snackType
              : SnackBarTypes.SUCCESS,
          },
          panelClass: panelClass,
        },
      );

      this.snackBarRef.instance.closed
        .pipe(first())
        .subscribe(() => this.snackBarRef.dismiss());
      this.snackBarRef
        .afterDismissed()
        .pipe(first())
        .subscribe(() => this.displaySnackbar());
    }
  }

  private getNextMessage(): SnackBarInformation | undefined {
    for (let i = 0; i < this.snackBarStack.length; i++) {
      const nextMessage = this.snackBarStack.shift();
      if (nextMessage.timestamp > moment.now()) {
        return nextMessage;
      }
    }
    return undefined;
  }

  private addToStack({
    message,
    snackType,
    duration,
    exception,
  }: SnackBarOptions): void {
    if (!duration) {
      const result = message.replace(/\s/g, '')?.length * 50;
      if (result > 7000) {
        duration = 7000;
      } else if (result < 2000) {
        duration = 2000;
      } else {
        duration = result;
      }
    }

    this.snackBarStack.push({
      timestamp: moment.now() + duration + 5000,
      message,
      snackType,
      duration,
      exception,
    });
  }
}

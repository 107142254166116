// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SnackBarTypes {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  CONNECTION_SUCCESSFUL = 'connection-successful',
  CONNECTION_ERROR = 'connection-error',
}

export interface SnackBarOptions {
  message: string;
  snackType?: SnackBarTypes;
  duration?: number;
  exception?: boolean; // exception in logging to sentry
}

export enum StateMessages {
  MICROPHONE_PERMISSIONS = 'microphone_permissions',
  CALLING_CONNECTION = 'calling_connection',
}

export type SnackBarMessages = StateMessages;

export const StatePredefinedMessages: Record<
  SnackBarMessages,
  SnackBarOptions
> = {
  [StateMessages.MICROPHONE_PERMISSIONS]: {
    message: $localize`Cannot make calls with disabled microphone`,
    snackType: SnackBarTypes.WARNING,
  },
  [StateMessages.CALLING_CONNECTION]: {
    message: $localize`Cannot make calls while offline`,
    snackType: SnackBarTypes.WARNING,
  },
};
export const SnackBarPredefinedMessages: Record<
  SnackBarMessages,
  SnackBarOptions
> = { ...StatePredefinedMessages };

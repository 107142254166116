import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

/**
 * Vrati region z tel. cisla
 *
 * number: cislo v medzinar. formate
 * regionFromNumber: napr. SK pre +421900111222
 */
@Pipe({ name: 'regionFromNumber' })
export class RegionFromNumberPipe implements PipeTransform {
  /**
   * Vrati region z tel. cisla
   *
   * @param number
   * @param numberCountryCode
   * @param internal indicating if its internal call (returning the CT flag)
   * @returns {string}
   */
  transform(
    number: string,
    numberCountryCode = null,
    isCircle = false,
  ): string {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      if (numberCountryCode !== null) {
        number = ['+', numberCountryCode, number].join('');
      }
      const countryCode = phoneUtil.getRegionCodeForNumber(
        phoneUtil.parse(number.toString(), 'AUTOMATIC'),
      );
      return countryCode
        ? countryCode
        : isCircle
        ? 'AUTOMATIC-circle'
        : 'AUTOMATIC';
    } catch (e) {
      return isCircle ? 'AUTOMATIC-circle' : 'AUTOMATIC';
    }
  }
}

<div class="list-navigation">
  <h5 *ngIf="heading !== ''" class="list-navigation__heading">
    {{ heading }}
  </h5>
  <ng-container *ngFor="let item of navigationItems">
    <app-list-item
      *ngIf="item.display"
      [title]="item.title"
      [iconLeft]="item.icon">
      <app-router-link-item
        *ngIf="item.routerLink"
        [appTestId]="settingsPageIdsEnum.SETTING_ITEM + item.key"
        [navigationItem]="item">
      </app-router-link-item>
    </app-list-item>
  </ng-container>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountryCodeItemIdsEnum } from 'test-automation';

@Component({
  selector: 'app-country-code-item',
  templateUrl: './country-code-item.component.html',
  styleUrls: ['./country-code-item.component.scss'],
})
export class CountryCodeItemComponent {
  @Input() countryCode: string;
  @Input() callingCode: string;
  @Input() disabled = false;
  @Output() selectCountry: EventEmitter<void> = new EventEmitter<void>();

  countryCodeItemIdsEnum = CountryCodeItemIdsEnum;

  onClick(): void {
    this.selectCountry.emit();
  }
}

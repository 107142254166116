import { NgModule } from '@angular/core';

import { PhoneNumberFormatterPipe } from './phone-number-formatter.pipe';
import { RegionFromNumberPipe } from './region-from-number.pipe';
import { RemoveCountryCodePipe } from './remove-country-code.pipe';

@NgModule({
  declarations: [
    PhoneNumberFormatterPipe,
    RegionFromNumberPipe,
    RemoveCountryCodePipe,
  ],
  exports: [
    PhoneNumberFormatterPipe,
    RegionFromNumberPipe,
    RemoveCountryCodePipe,
  ],
  providers: [
    PhoneNumberFormatterPipe,
    RegionFromNumberPipe,
    RemoveCountryCodePipe,
  ],
})
export class PhoneNumberFormatterModule {}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OverlayTypes = {
  ContactDetailOverlay: 'contactDetail',
  AddEditContactOverlay: 'addEditContact',
  CallHistoryDetailOverlay: 'callHistoryDetail',
  CallContactAssignOverlay: 'callContact',
  ActivitiesListOverlay: 'activitiesList',
  CallQualityOverlay: 'callQuality',
  SmsConversationOverlay: 'smsConversation',
  SmsSelectContactOverlay: 'smsSelectContact',
  TransferListOverlay: 'transferListOverlay',
  InviteAgentOverlay: 'inviteAgentOverlay',
  CueCardsOverlay: 'cueCardsOverlay',
  DialpadOverlay: 'dialpadOverlay',
  SmsTemplatesOverlay: 'smsTemplatesOverlay',
} as const;

export type OverlayTypesType = (typeof OverlayTypes)[keyof typeof OverlayTypes];

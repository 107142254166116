import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { Socket, io } from 'socket.io-client';

import { environment } from '../../../../environments/environment';
import { LoggerUtil } from '../../../_shared/utils/logger.util';
import { AuthenticationService } from '../auth/authentication.service';

const EXPIRED_TOKEN_ERROR_MESSAGE = 'jwt expired';

interface SocketConnectionError {
  data?: string;
  message?: string;
  stack?: string;
}

type EventListener<T = any> = (
  data: T,
  ack: (response: string) => void,
) => void;

@Injectable()
export class SocketService {
  private socket?: Socket;
  private listeners: Map<string, EventListener[]> = new Map();

  constructor(private authenticationService: AuthenticationService) {}

  /**
   * Connect the user to the socket server
   * Singleton service
   */
  connect(): Socket {
    if (!this.socket) {
      const extraHeaders = {
        authorization: `Bearer ${this.authenticationService.accessToken}`,
        'ngsw-bypass': 'true',
      };

      this.socket = io(environment.socketUrl, {
        query: {
          will_use_call_monitor: true,
          will_use_webhooks: true,
        },
        transportOptions: {
          polling: {
            extraHeaders,
          },
        },
        reconnectionDelay: 60 * 1000, // 60s
        reconnectionDelayMax: 75 * 1000, // 75s
      });

      this.applyListeners();
    }

    this.handleConnectionErrors();
    return this.socket;
  }

  public removeAllListeners(): void {
    if (this.socket) {
      this.socket.removeAllListeners();
    }
  }

  /**
   * Disconnect the socket
   */
  disconnect(): void {
    if (this.socket) {
      this.offConnectionErrors();
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  /**
   * Force a reconnection after disconnecting
   */
  forceReconnect(): void {
    if (this.socket) {
      this.disconnect();
      setTimeout(() => {
        this.connect();
      }, 1000); // Prevents immediate reconnects
    }
  }

  /**
   * Check if the socket is connected
   */
  public get isConnected(): boolean {
    return this.socket?.connected ?? false;
  }

  /**
   * Register a custom event handler
   */
  on<T>(evt: string, callback: EventListener<T>): void {
    if (!this.socket) {
      LoggerUtil.error('[SocketService]: socket is not initialized');
      return;
    }
    if (typeof callback !== 'function') {
      throw new Error('You need to define a callback to listen to events');
    }

    if (!this.listeners.has(evt)) {
      this.listeners.set(evt, []);
    }

    this.listeners.get(evt)?.push(callback);

    // Register the listener on the socket
    this.socket!.on(evt, (data: T, ack: (response: string) => void) => {
      callback(data, ack);
    });
  }

  /**
   * Remove an event handler
   */
  off<T>(evt: string, callback?: EventListener<T>): void {
    if (!this.socket) {
      return;
    }

    if (this.listeners.has(evt)) {
      if (callback) {
        const updatedListeners = this.listeners
          .get(evt)
          ?.filter(l => l !== callback);
        this.listeners.set(evt, updatedListeners || []);
      } else {
        this.listeners.delete(evt);
      }
    }

    // Remove from socket.io as well
    if (callback) {
      this.socket.off(evt, callback);
    } else {
      this.socket.off(evt);
    }
  }

  /**
   * Apply all registered listeners to the new socket connection
   */
  private applyListeners(): void {
    if (this.socket) {
      this.listeners.forEach((listeners, event) => {
        listeners.forEach(listener => {
          this.socket?.on(event, listener);
        });
      });
    }
  }

  /**
   * Emit an event to the server
   */
  emit<T>(evt: string, data?: unknown, callback?: (args: T) => void): void {
    if (!this.socket) {
      LoggerUtil.error('[SocketService]: emit() Socket is not initialized');
      return;
    }
    this.socket?.emit(evt, data, callback);
  }

  /**
   * Handle expired tokens by forcing reconnection or redirecting to login
   */
  handleTokenExpired() {
    this.authenticationService
      .authRefreshToken()
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.forceReconnect();
        },
        error: (err: HttpErrorResponse) => {
          LoggerUtil.warn('[SocketService]: Token refresh failed', {}, err);
          this.authenticationService.navigateToLogin(
            true,
            true,
            'SocketService:handleTokenExpired',
          );
        },
      });
  }

  private offConnectionErrors(): void {
    if (this.socket) {
      this.off('connect_error');
      this.off('reconnect_error');
      this.off('reconnect_failed');
    }
  }

  /**
   * Set up default event handling for errors
   */
  private handleConnectionErrors(): void {
    if (this.socket) {
      this.on('connect_error', (event: SocketConnectionError) => {
        if (event.message === EXPIRED_TOKEN_ERROR_MESSAGE) {
          LoggerUtil.warn(
            '[SocketService]: Token expired, attempting to refresh',
            { extraContent: event },
          );
          this.handleTokenExpired();
        }
        LoggerUtil.info('[SocketService]: connection error', {
          message: event.message,
        });
      });
      this.on('reconnect_error', (event: SocketConnectionError) => {
        LoggerUtil.info('[SocketService]: reconnection error', {
          extraContent: { event },
        });
      });
      this.on('reconnect_failed', (event: SocketConnectionError) => {
        LoggerUtil.info('[SocketService]: reconnection failed', {
          extraContent: { event },
        });
      });
    }
  }
}

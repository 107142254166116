<app-header>
  <span right-content class="c-ces-survey__header--close" (click)="close()" i18n
    >Close</span
  >
</app-header>

<ng-container *ngIf="!(ratingSent$ | async); else nextStep">
  <h4 class="c-ces-survey__title" i18n="@@toWhatExtent">
    To what extent do you agree with the following statement:
  </h4>
  <div class="c-ces-survey__sub-title" i18n>
    Most of the time I experience no issues with calling.
  </div>

  <mat-radio-group
    class="c-ces-survey__radio-group"
    name="score"
    [(ngModel)]="trackedScore">
    <mat-radio-button
      class="c-ces-survey__radio-button"
      color="primary"
      *ngFor="let option of cesRadioOptions"
      [value]="option"
      [checked]="option.score === trackedScore?.score"
      (change)="ratingSelected($event)">
      <p class="c-ces-survey__radio-button-label">
        {{ option.label }}
      </p>
    </mat-radio-button>
  </mat-radio-group>

  <div class="c-ces-survey__footer-section">
    <app-button
      btnType="gray"
      [size]="12"
      mat-ripple
      (clicked)="askLater()"
      i18n="@@askLater"
      class="c-ces-survey__footer-section__buttons"
      >Ask me later
    </app-button>

    <app-button
      btnType="primary"
      [size]="12"
      mat-ripple
      [isDisabled]="!trackedScore"
      (clicked)="sendRating()"
      i18n="@@submit"
      class="c-ces-survey__footer-section__buttons"
      >Submit
    </app-button>
  </div>
</ng-container>

<ng-template #nextStep>
  <h4 class="c-ces-survey__title" i18n>
    What could we have done differently in order to improve your calling
    experience?
  </h4>
  <form>
    <textarea
      class="c-ces-survey__answer"
      [(ngModel)]="answer"
      name="answer"
      i18n-placeholder
      placeholder="Please type at least 6 characters..."></textarea>
    <app-button
      btnType="primary"
      [size]="12"
      mat-ripple
      [isDisabled]="!answer || answer.trimStart().length < 6"
      (clicked)="submitAnswer()"
      i18n="@@submit"
      class="c-ces-survey__submit-btn"
      >Submit
    </app-button>
  </form>
  <div class="c-ces-survey__footer-section-label">
    <span (click)="close()" i18n>I don't want to answer</span>
  </div>
</ng-template>

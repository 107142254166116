import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type OnTouchFn = () => void;
type OnChangeFn = (value: string) => void;

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @ViewChild('inputRef', { static: false }) inputRef: ElementRef;

  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() type: 'text' | 'password' | 'number' | 'time';
  @Input() label: string;
  @Input() invalidate = false;
  @Input() errorText: string;
  @Input() suffixIcon: boolean;
  @Input() prefixIcon: boolean;
  @Input() countrySelect: boolean;
  @Input() clickAble: boolean;
  @Input() readOnly: boolean;
  @Input() styleClass: 'primary' | 'secondary' | 'dialpad' | 'login' =
    'primary';

  @Output() readonly focusEvent = new EventEmitter<FocusEvent>();
  @Output() readonly blurEvent = new EventEmitter<FocusEvent>();

  @Output() readonly suffixClick = new EventEmitter<null>();
  @Output() readonly prefixClick = new EventEmitter<null>();

  value: string;

  onChange: OnChangeFn = () => null;
  onTouch: OnTouchFn = () => null;

  writeValue(value: string): void {
    this.value = value || '';
  }
  onValueChange(value: string): void {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouch = fn;
  }

  handleFocus(event: FocusEvent): void {
    this.focusEvent.emit(event);
  }

  handleBlur(event: FocusEvent): void {
    this.blurEvent.emit(event);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleSuffixClick(): void {
    this.suffixClick.emit();
  }

  handlePrefixClick(): void {
    this.prefixClick.emit();
  }

  forceFocus(): void {
    this.inputRef.nativeElement.blur();
    this.inputRef.nativeElement.focus();
  }
}

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { EnvironmentService } from '../services/environment.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private environmentService: EnvironmentService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.setHeaders(request));
  }

  private setHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const isAssetsRequest = request.url?.includes('/assets/');

    if (isAssetsRequest) {
      return request;
    }

    const setHeaders = {
      'ct-app-version': `${environment.version}-${this.environmentService.env.userAgent}`,
      'content-type': 'application/json',
      'ngsw-bypass': 'true',
    };

    if (request.body instanceof FormData) {
      delete setHeaders['content-type'];
    }

    return request.clone({ setHeaders });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'select-body-option',
  templateUrl: './select-body-option.component.html',
  styleUrls: ['./select-body-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBodyOptionComponent {
  @Input()
  selectedClassName: 'all-selected' | 'icon-selected' = 'icon-selected';
  @Input()
  selected = false;
  @Input()
  customLabelClass = '';

  @Output()
  readonly click = new EventEmitter();

  @HostBinding('class')
  get classes(): string {
    return `select-option ${this.selected ? this.selectedClassName : ''}`;
  }
  @HostBinding('tabindex')
  tabindex = '0';
}

import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  Renderer2,
} from '@angular/core';
import { first } from 'rxjs/operators';

import { Browser } from '../../_core/services/browser.service';
import { StorageUtils } from '../../_shared/utils/storage.utils';
import { StorageEnum } from '../../_core/enums/storage.enum';
import { DocumentService } from '../../_core/services/document.service';
import { defaultFallbackLanguage, languagesCodes } from '../../languages';
import { UserService } from '../../_core/services/user/user.service';
import { User } from '../../_core/models/user';
import { WindowService } from '../../_core/services/window.service';
import { LocalizationService } from '../../_core/services/localization.service';
import { WindowSizeStateEnum } from '../../_core/enums/window-size-state.enum';
import { EnvironmentService } from '../../_core/services/environment.service';
import {
  CrmParamKey,
  CustomCTIPartnerStorageKey,
  CustomCtiService,
} from '../../_cti-integrations/custom-cti.service';
import { HubspotService } from '../../_cti-integrations/hubspot/hubspot.service';
import { PipedriveService } from '../../_cti-integrations/pipedrive/pipedrive.service';
import { SalesforceService } from '../../_cti-integrations/salesforce/salesforce-service';
import { ZendeskCtiService } from '../../_cti-integrations/zendesk/zendesk-cti.service';
import { AppService } from '../../_core/app.service';
import { LocalStorageService } from '../../_core/services/local-storage.service';

@Component({
  selector: 'app-bootstrap',
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.scss'],
})
export class BootstrapComponent implements OnInit {
  currentBrowser = null;
  isSupportedBrowser = false;
  isSupportedVersion = false;

  isLocalStorageEnabled = StorageUtils.storageAvailable(
    StorageEnum.LOCAL_STORAGE,
  );

  constructor(
    private documentService: DocumentService,
    private localizationService: LocalizationService,
    private userService: UserService,
    private windowService: WindowService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private injector: Injector,
    private environmentService: EnvironmentService,
    private localStorageService: LocalStorageService,
    private appService: AppService,
  ) {}

  ngOnInit(): void {
    if (!this.localizationService.getLocale()) {
      this.localizationService.changeLocale(defaultFallbackLanguage, false);
    }

    this.userService.user$.pipe(first()).subscribe((user: User) => {
      const userLang = user?.lang || this.localizationService.getLocale();

      const availableCodes: string[] = [...languagesCodes];
      if (userLang && availableCodes.includes(userLang)) {
        this.localizationService.changeLocale(userLang, false);
      }
    });

    this.browser();

    this.windowService.currentWindowSizeStateObserver$.subscribe(state => {
      const dimensions =
        state === WindowSizeStateEnum.COLLAPSED
          ? this.windowService.collapsedDimensions
          : this.windowService.expandedDimensions;
      this.renderer.setStyle(
        this.elRef.nativeElement,
        'maxWidth',
        dimensions.width + 'px',
      );
    });

    const splashScreen: HTMLElement = document.getElementById('splash');
    if (splashScreen) {
      splashScreen.classList.add('removed');
      splashScreen.ontransitionend = () => {
        splashScreen.remove();
      };
    }

    this.initCtiService();
  }

  updateBrowserUrl(): void {
    let updateUrl = '';
    switch (this.currentBrowser) {
      case 'Chrome':
        updateUrl = 'https://www.google.com/chrome/browser/desktop/index.html';
        break;
      case 'Edge':
        updateUrl = 'https://www.microsoft.com/en-us/windows/microsoft-edge';
        break;
      case 'Firefox':
        updateUrl = 'https://www.mozilla.org/en-US/firefox/new/';
        break;
      case 'Safari':
        updateUrl = 'https://support.apple.com/downloads/safari';
        break;
      case 'Opera':
        updateUrl = 'https://www.opera.com/download';
        break;
    }

    this.documentService.nativeWindow.location.href = updateUrl;
  }

  reloadApp(): void {
    this.documentService.nativeWindow.location.reload();
  }

  private browser(): void {
    const browser = Browser.specs();
    this.currentBrowser = browser.name;
    switch (browser.name) {
      case 'Chrome':
        this.isSupportedBrowser = true;
        if (browser.version >= 58) {
          this.isSupportedVersion = true;
        }
        break;
      case 'Edge':
        this.isSupportedBrowser = false;
        if (browser.version >= 15) {
          this.isSupportedVersion = true;
        }
        break;
      case 'Firefox':
        this.isSupportedBrowser = false;
        if (browser.version >= 52) {
          this.isSupportedVersion = true;
        }
        break;
      case 'Safari':
        this.isSupportedBrowser = false;
        if (browser.version >= 11) {
          this.isSupportedVersion = true;
        }
        break;
      case 'Opera':
        this.isSupportedBrowser = true;
        if (browser.version >= 48) {
          this.isSupportedVersion = true;
        }
        break;
    }
  }

  private initCtiService(): void {
    const partnerParam = this.documentService.documentParams?.get(CrmParamKey);
    const partnerParamPersistent = this.localStorageService.getItem(
      CustomCTIPartnerStorageKey,
      false,
    );

    if (this.environmentService.isSalesforce()) {
      this.appService.ctiService = this.injector.get(SalesforceService);
    } else if (this.environmentService.isHubSpot()) {
      this.appService.ctiService = this.injector.get(HubspotService);
    } else if (this.environmentService.isZendesk()) {
      this.appService.ctiService = this.injector.get(ZendeskCtiService);
    } else if (this.environmentService.isPipedrive()) {
      this.appService.ctiService = this.injector.get(PipedriveService);
    } else if (partnerParam || partnerParamPersistent) {
      this.appService.ctiService = this.injector.get(CustomCtiService);
      this.appService.ctiService.setPartner(
        partnerParam || partnerParamPersistent,
      );
    }
  }
}

<div class="voicemail-drop-dialog">
  <div class="voicemail-drop-dialog-title">
    <span i18n>Pick a voicemail drop message</span>
  </div>
  <div class="voicemail-drop-dialog-content">
    <div
      *ngFor="
        let record of dialogData.voicemailDropsRecords;
        trackBy: recordItemFn
      "
      class="voicemail-drop-dialog-content-item"
      (click)="selectVoicemailDrop(record)">
      <div class="voicemail-drop-dialog-content-item__left-side">
        <ct-icon
          class="voicemail-drop-dialog-content-item__icon"
          colorClass="white"
          [isMaskIcon]="true"
          name="voicemail-outline"
          [size]="24"></ct-icon>
      </div>
      <div class="voicemail-drop-dialog-content-item__middle">
        <strong class="voicemail-drop-dialog-content-item__middle-name">{{
          record.internal_name
        }}</strong>
        <span
          *ngIf="record.isDefault"
          class="voicemail-drop-dialog-content-item__middle-default"
          i18n
          >Default</span
        >
      </div>
      <div
        class="voicemail-drop-dialog-content-item__right-side"
        *ngIf="record.isDefault">
        <span class="badge badge--voicemail"><strong>{{ osAltKey | osKeyName }}</strong></span>
        <strong>+</strong>
        <span class="badge badge--voicemail"><strong>V</strong></span>
      </div>
    </div>
  </div>
</div>

import { dynamicId } from '../utils/utils';

export enum CallTagsIdsEnum {
  TAG_PREFIX = 'CallTags',
  SEARCH_INPUT = 'CallTagsSearchInput',
  DONE_BUTTON = 'CallTagsDoneButton',
  CALL_TAG_ITEM = 'CallTagItem',
}

export const tag_sales = dynamicId(CallTagsIdsEnum.TAG_PREFIX, '-Sales');

export const tag_lead = dynamicId(CallTagsIdsEnum.TAG_PREFIX, '-Lead');

export const tag_support = dynamicId(CallTagsIdsEnum.TAG_PREFIX, '-Support');

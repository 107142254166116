export interface SmsResponseTypePhone {
  success: boolean;
  data?: any;
}

export type SmsMockDataType = {
  [key in SmsCase]: SmsResponseTypePhone;
};

export enum SmsCase {
  // APIv3 GET /app/sms/conversations.json
  SMS_CONVERASTIONS = 0,
  // APIv3 GET /app/sms/templates.json
  SMS_TEMPLATE = 1,
  // APIv3 GET /app/sms/conversations/:phoneNumber.json
  SMS_MESSAGE = 2,
  // APIv3 GET /app/contacts/paginate/matching/contact/.json
  SMS_SELECT_CONTACT = 3,
  // APIv3 GET /app/sms/outbound-numbers.json
  SMS_OUTBOUND_NUMBER = 4,
  // APIv3 GET /app/sms/conversation-exists/:phoneNumber.json
  SMS_CONVERSATION_EXISTS = 5,
  // APIv3 GET /app/numbers/contact-numbers/:contactId.json
  SMS_CONTACT_NUMBERS = 6,
  // APIv3 GET /app/sms/can-send-messages-to/:countryCode.json
  SMS_CAN_SENT_MESSAGES = 7,
  // APIv3 POST /app/sms/send.json
  SMS_SEND = 8,
  // APIv3 PATCH/DELETE /app/sms/templates/:templateId.json
  SMS_TEMPLATE_EDITED = 9,
  // APIv3 POST /app/sms/templates.json
  SMS_CREATE_TEMPLATE = 10,
  // APIv3 GET /app/sms/templates.json
  SMS_UPDATED_TEMPLATE = 11,
  // APIv3 GET /app/sms/templates.json
  SMS_EMPTY_TEMPLATES = 12,
  // APIv3 GET /app//sms/read-conversation-by-company/.json
  SMS_READ_MESSAGES_BY_COMPANY = 13,
  // APIv3 GET /app/sms/checkLimit.json
  SMS_CHECK_LIMIT = 14,
  // APIv3 GET /app/sms/is-feature-enabled
  SMS_IS_FEATURE_ENABLE = 15,
  // APIv3 GET /app/sms/unread-messages-count
  SMS_UNREAD_MESSAGES_COUNT = 16,
  // APIv3 GET /app/sms/conversations/:phoneNumber/:whatsapp
  WHATSAPP_MESSAGE = 17,
  // APIv3 POST /app/sms/send.json
  WHATSAPP_SEND = 18,
  // REALTIME DELETE /app/sms/message/id
  WHATSAPP_DELETE_MESSAGE = 19,
  // REALTIME GET /app/sms/conversations/:phoneNumber/:whatsapp
  WHATSAPP_MESSAGES = 20,
  // APIv3 GET /app/sms/conversations/:phoneNumber/:whatsapp
  WHATSAPP_EXPIRED_MESSAGE = 21,
  WHATSAPP_EMPTY_MESSAGE = 22,
}

export const Sms: SmsMockDataType = {
  [SmsCase.SMS_CONVERASTIONS]: {
    success: true,
    data: {
      conversations_list: [
        {
          last_message: {
            id: 16995,
            direction: 'inbound',
            content: 'Test_postman_1',
            created: '2022-02-14T12:31:29.000Z',
            via: {
              number: '+16287772269',
              internal_name: 'Voxbone - outgoing SMS Supported 2',
            },
            user: null,
            is_read: false,
            is_deleted: false,
            with_external_number: '+16287772269',
          },
          with_external: {
            number: '+16287772269',
            contact_names: ['Test Marek'],
          },
          unread_messages_count: 18,
          type: 'sms',
        },
        {
          last_message: {
            id: 18957,
            direction: 'outbound',
            content: 'Hello',
            created: '2022-09-09T13:24:24.000Z',
            via: {
              number: '+12144804707',
              internal_name: 'Whatsapp',
            },
            user: {
              id: 28161,
              fullname: 'Jan Stevcik',
            },
            is_read: true,
            is_mms: false,
            is_deleted: false,
            with_external_number: '+421903275529',
            sms_media_urls: [],
          },
          with_external: {
            number: '+16287772269',
            contact_names: ['Test Marek'],
          },
          unread_messages_count: 0,
          type: 'whatsapp',
        },
        {
          last_message: {
            id: 16988,
            direction: 'inbound',
            content: '',
            created: '2022-02-14T12:31:29.000Z',
            via: {
              number: '+16287772269',
              internal_name: 'Voxbone - outgoing SMS Supported 2',
            },
            user: null,
            is_read: false,
            is_mms: false,
            is_deleted: false,
            with_external_number: '+16287772266',
            sms_media_urls: [],
          },
          with_external: {
            number: '+16287772266',
            contact_names: ['Test Marek'],
          },
          unread_messages_count: 18,
          type: 'whatsapp',
        },
        {
          last_message: {
            id: 16975,
            direction: 'inbound',
            content: '',
            created: '2022-02-14T12:31:29.000Z',
            via: {
              number: '+16287772269',
              internal_name: 'Voxbone - outgoing SMS Supported 2',
            },
            user: null,
            is_read: false,
            is_mms: true,
            is_deleted: false,
            with_external_number: '+16287772260',
            sms_media_urls: [
              {
                created: '2023-02-02T13:50:40.000Z',
                extension: 'audio/mpeg',
                id: 3723,
                media_url: '100552/0-KzQyMTkxMDk4ODM0Mg==-1675345837',
                sms_id: 24703,
              },
            ],
          },
          with_external: {
            number: '+16287772260',
            contact_names: ['Test Marek'],
          },
          unread_messages_count: 18,
          type: 'whatsapp',
        },
        {
          last_message: {
            id: 16959,
            direction: 'outbound',
            content: 'TEST MESSAGE',
            created: '2022-02-14T10:04:35.000Z',
            via: {
              number: '+16287772269',
              internal_name: 'Voxbone - outgoing SMS Supported 2',
            },
            user: {
              id: 86279,
              fullname: 'Matej Cupka',
            },
            is_read: true,
            is_deleted: false,
            with_external_number: '+421911951010',
          },
          with_external: {
            number: '+421911951010',
            contact_names: ['Matej Č - test2'],
          },
          unread_messages_count: 0,
          type: 'sms',
        },
        {
          last_message: {
            id: 16831,
            direction: 'outbound',
            content: 'whoaMB\n',
            created: '2022-02-07T14:11:24.000Z',
            via: {
              number: '+12013503554',
              internal_name: 'Messagebird cislo na sms',
            },
            user: {
              id: 16463,
              fullname: 'Jan Jakubec',
            },
            is_read: true,
            is_deleted: false,
            with_external_number: '+421944116519',
          },
          with_external: {
            number: '+421944116519',
            contact_names: ['Juraj Zovinec'],
          },
          unread_messages_count: 0,
          type: 'sms',
        },
        {
          last_message: {
            id: 16827,
            direction: 'outbound',
            content: 'a',
            created: '2022-02-07T13:34:40.000Z',
            via: {
              number: '+48732489020',
              internal_name: 'Voxbone - outgoing SMS Supported 3',
            },
            user: {
              id: 28057,
              fullname: 'Stefan Sarmir',
            },
            is_read: true,
            is_deleted: false,
            with_external_number: '+421905482885',
          },
          with_external: {
            number: '+421905482885',
            contact_names: ['Stefan Sarmir'],
          },
          unread_messages_count: 0,
          type: 'sms',
        },
      ],
    },
  },
  [SmsCase.SMS_TEMPLATE]: {
    success: true,
    data: {
      templates_list: [
        {
          id: 521,
          title: 'Test',
          content: 'Test',
        },
      ],
    },
  },
  [SmsCase.SMS_MESSAGE]: {
    success: true,
    data: {
      messages: [
        {
          id: 17631,
          direction: 'outbound',
          content: 'test',
          created: '2022-04-05T15:28:09.000Z',
          is_read: true,
          is_deleted: false,
          is_mms: 0,
          user: {
            id: 28153,
            fullname: 'Marek Wimmer',
          },
          via: {
            number: '+48732489020',
            internal_name: null,
          },
          sms_media_urls: [],
          with_external_number: '+420777518242',
        },
      ],
    },
  },
  [SmsCase.SMS_SELECT_CONTACT]: {
    success: true,
    data: [
      {
        company: null,
        contact_numbers: null,
        id: 29482913,
        name: 'Test Marek',
        public_number: '+420777518242',
        _machingData: {
          ContactNumbers: {
            contact_id: 29482913,
            country_code_id: 420,
          },
        },
      },
    ],
  },
  [SmsCase.SMS_OUTBOUND_NUMBER]: {
    success: true,
    data: {
      outbound_numbers: [
        {
          id: 1,
          caller_id_e164: '+16287772269',
          internal_name: 'Mocked Voxbone 1',
          sms_partner_id: 1,
          sms_partner: {
            id: 1,
            name: 'Voxbone',
          },
          cant_receive_messages: false,
        },
      ],
    },
  },
  [SmsCase.SMS_CONVERSATION_EXISTS]: {
    success: true,
    data: {
      result: false,
    },
  },
  [SmsCase.SMS_CONTACT_NUMBERS]: {
    success: true,
    data: {
      contact_numbers: ['+420777518242'],
    },
  },
  [SmsCase.SMS_CAN_SENT_MESSAGES]: {
    success: true,
    data: {
      from_same_country: [1, 2],
      from_different_country: [1, 2],
    },
  },
  [SmsCase.SMS_SEND]: {
    success: true,
    data: {
      recipient: '+420777518242',
      sender: '+16287772269',
      message: 'test',
    },
  },
  [SmsCase.SMS_TEMPLATE_EDITED]: {
    success: true,
  },
  [SmsCase.SMS_CREATE_TEMPLATE]: {
    success: true,
    data: {
      created_template_id: 521,
    },
  },
  [SmsCase.SMS_UPDATED_TEMPLATE]: {
    success: true,
    data: {
      templates_list: [
        {
          id: 521,
          title: 'Test1',
          content: 'Test1',
        },
      ],
    },
  },
  [SmsCase.SMS_EMPTY_TEMPLATES]: {
    success: true,
    data: {
      templates_list: [],
    },
  },
  [SmsCase.SMS_READ_MESSAGES_BY_COMPANY]: {
    success: true,
    data: {
      readed_messages_count: 0,
    },
  },
  [SmsCase.SMS_CHECK_LIMIT]: {
    success: true,
    data: {
      result: true,
    },
  },
  [SmsCase.SMS_IS_FEATURE_ENABLE]: {
    success: true,
    data: {
      enabled: true,
    },
  },
  [SmsCase.SMS_UNREAD_MESSAGES_COUNT]: {
    success: true,
    data: {
      unread_messages_count: 0,
    },
  },
  [SmsCase.WHATSAPP_MESSAGE]: {
    success: true,
    data: {
      messages: [
        {
          id: 19010,
          direction: 'inbound',
          content: 'test',
          created: new Date(),
          is_read: true,
          is_deleted: false,
          is_mms: false,
          user: {
            id: 86403,
            fullname: 'Test Tester',
          },
          via: {
            number: '+12144804707',
            internal_name: 'Whatsapp',
          },
          sms_media_urls: [],
          with_external_number: '+12136313623',
        },
        {
          id: 19011,
          direction: 'outbound',
          content: 'whatsapp message',
          created: new Date(),
          is_read: true,
          is_deleted: false,
          is_mms: false,
          user: {
            id: 86403,
            fullname: 'Test',
          },
          via: {
            number: '+12144804705',
            internal_name: 'Whatsapp',
          },
          sms_media_urls: [],
          with_external_number: '+12136313621',
        },
      ],
    },
  },
  [SmsCase.WHATSAPP_SEND]: {
    success: true,
    data: {
      recipient: '+12136313623',
      sender: '+12144804707',
      message: 'test',
      media_count: 0,
      number_prefix: 'whatsapp',
      direction: 'outbound',
      fragments: '1',
      is_mms: false,
      created: new Date(),
      call_number: {
        id: 42567,
        public_caller_id: '44804707',
        country_code: 1,
        trunk_name: 'trunk_twilio',
        internal_name: 'Whatsapp',
        country_id: 236,
        company_id: 100552,
      },
      user: {
        id: 86403,
        fullname: 'Test Tester',
      },
      via: {
        number: '+12144804707',
        internal_name: 'Whatsapp',
      },
      with_external_number: '+12136313623',
    },
  },
  [SmsCase.WHATSAPP_DELETE_MESSAGE]: {
    success: true,
  },
  [SmsCase.WHATSAPP_MESSAGES]: {
    success: true,
    data: {
      messages: [
        {
          id: 19010,
          direction: 'inbound',
          content: 'test',
          created: new Date(),
          is_read: true,
          is_deleted: false,
          is_mms: false,
          user: {
            id: 86403,
            fullname: 'Test Tester',
          },
          via: {
            number: '+12144804707',
            internal_name: 'Whatsapp',
          },
          sms_media_urls: [],
          with_external_number: '+12136313623',
        },
        {
          id: 19010,
          direction: 'outbound',
          content: 'new message',
          created: new Date(),
          is_read: true,
          is_deleted: false,
          is_mms: false,
          user: {
            id: 86403,
            fullname: 'Test Tester',
          },
          via: {
            number: '+12136313623',
            internal_name: 'Whatsapp',
          },
          sms_media_urls: [],
          with_external_number: '+12136313623',
        },
      ],
    },
  },
  [SmsCase.WHATSAPP_EXPIRED_MESSAGE]: {
    success: true,
    data: {
      messages: [
        {
          id: 19010,
          direction: 'inbound',
          content: 'test',
          created: '2022-04-10T15:28:09.000Z',
          is_read: true,
          is_deleted: false,
          is_mms: false,
          user: {
            id: 86403,
            fullname: 'Test Tester',
          },
          via: {
            number: '+12144804707',
            internal_name: 'Whatsapp',
          },
          sms_media_urls: [],
          with_external_number: '+12136313623',
        },
      ],
    },
  },
  [SmsCase.WHATSAPP_EMPTY_MESSAGE]: {
    success: true,
    data: {
      messages: [
        {
          id: 19010,
          direction: 'inbound',
          content: '',
          created: new Date(),
          is_read: true,
          is_deleted: false,
          is_mms: false,
          user: {
            id: 86403,
            fullname: 'Test Tester',
          },
          via: {
            number: '+12144804707',
            internal_name: 'Whatsapp',
          },
          sms_media_urls: [],
          with_external_number: '+12136313623',
        },
      ],
    },
  },
};

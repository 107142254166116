import { Injectable } from '@angular/core';
import { Call } from '@cloudtalk/sip-service';

import { LoggerUtil } from '../../_shared/utils/logger.util';
import { AppService } from '../app.service';
import {
  NotificationPreferenceEnum,
  SettingsKeys,
} from '../../pages/setting/settings';
import { UserSettingsService } from './user/user-settings.service';
import { VoiceProviderService } from './audio/voice-provider.service';

export type ActionType = 'answer';

@Injectable()
export class UserSettingsActionHandlerService {
  constructor(
    private userSettingsService: UserSettingsService,
    private appService: AppService,
    private voiceProviderService: VoiceProviderService,
  ) {}

  onRingingEvent(
    call: Call,
    customAction: (actionType: ActionType) => void,
  ): void {
    if (
      this.userSettingsService.settings[
        SettingsKeys.BRING_RINGING_PHONE_TO_FOREGROUND
      ]
    ) {
      this.appService.focusApp();
    }
    if (
      this.userSettingsService.settings[SettingsKeys.PICKUP_GENERATED_CALLS] &&
      call.info.isGeneratedCall
    ) {
      this.voiceProviderService
        .playAudioOfCall({ asset: '/assets/sounds/beep.mp3', loop: false })
        .then(() => {
          customAction('answer');
        })
        .catch((err: Error) =>
          LoggerUtil.info(
            UserSettingsActionHandlerService.name + ':playAudioOfCall() failed',
            {
              extraContent: { err, call },
            },
          ),
        );
    }
    if (
      this.userSettingsService.settings[SettingsKeys.PICKUP_INBOUND_CALLS] > -1
    ) {
      setTimeout(() => {
        if (call.isRinging) {
          this.voiceProviderService
            .playAudioOfCall({ asset: '/assets/sounds/beep.mp3', loop: false })
            .then(() => {
              customAction('answer');
            })
            .catch((err: Error) =>
              LoggerUtil.info(
                UserSettingsActionHandlerService.name +
                  ':playAudioOfCall() failed',
                {
                  extraContent: { err, call },
                },
              ),
            );
        }
      }, this.userSettingsService.settings[SettingsKeys.PICKUP_INBOUND_CALLS] * 1000);
    }
  }

  updateNotificationText(): string {
    return this.userSettingsService.settings[
      SettingsKeys.INCOMING_NOTIFICATION_PREFERENCE
    ] !== NotificationPreferenceEnum.PICKUP
      ? $localize`Focus by clicking`
      : $localize`Answer by clicking`;
  }

  updateNotificationAction(): string {
    return this.userSettingsService.settings[
      SettingsKeys.INCOMING_NOTIFICATION_PREFERENCE
    ] !== NotificationPreferenceEnum.PICKUP
      ? $localize`Focus`
      : $localize`Answer`;
  }

  onNotificationClick(customAction: (actionType: ActionType) => void): void {
    if (
      this.userSettingsService.settings[
        SettingsKeys.INCOMING_NOTIFICATION_PREFERENCE
      ] === NotificationPreferenceEnum.FOCUS
    ) {
      this.appService.focusApp();
      return;
    }
    if (
      this.userSettingsService.settings[
        SettingsKeys.INCOMING_NOTIFICATION_PREFERENCE
      ] === NotificationPreferenceEnum.PICKUP
    ) {
      customAction('answer');
      return;
    }
  }
}

<button
  class="c-outbound-select"
  [appTestId]="outboundSelectIdsEnum.OUTBOUND_SELECT_BUTTON"
  [ngClass]="{ 'c-outbound-select--disabled': disabled }"
  (click)="onClick()">
  <div
    *ngIf="prefix"
    class="c-outbound-select__column c-outbound-select__prefix">
    <span>{{ prefix }}</span>
  </div>
  <div class="c-outbound-select__column">
    <ct-flag-icon
      [country]="countryCode"
      [width]="24"
      [isRounded]="true"></ct-flag-icon>
  </div>
  <div class="c-outbound-select__column c-outbound-select__label">
    <div
      *ngIf="subLabel"
      class="c-outbound-select__subLabel"
      [appTestId]="outboundSelectIdsEnum.OUTBOUND_SUB_LABEL">
      <span>{{ subLabel }}</span>
    </div>
    <div class="c-outbound-select__mainLabel-container">
      <div
        class="c-outbound-select__mainLabel"
        [appTestId]="outboundSelectIdsEnum.OUTBOUND_MAIN_LABEL">
        <span>{{ mainLabel }}</span>
      </div>
      <ct-icon
        *ngIf="anonymous"
        name="visibility-off"
        [shouldCover]="true"
        [size]="18"></ct-icon>
    </div>
  </div>
  <div class="c-outbound-select__column">
    <ct-icon
      class="c-outbound-select__icon c-outbound-select__more-icon"
      name="chevron-down-shadow"
      [shouldCover]="true"
      [size]="20"></ct-icon>
  </div>
</button>

import { dynamicId } from '../utils/utils';

export enum SmsTemplateSelectIdsEnum {
  TEMPLATE_ADD_NEW_BUTTON = 'TemplatePlusAddNewButton',
  TEMPLATE_MODAL = 'TemplateModal',
  TEMPLATE_LIST = 'TemplateList',
  TEMPLATE_LIST_ITEM = 'TemplateListItem',
  TEMPLATE_LIST_ITEM_DELETE = 'TemplateListItemDelete',
  TEMPLATE_LIST_ITEM_EDIT = 'TemplateListItemEdit',
  TEMPLATE_TITLE = 'TemplateTitle',
  TEMPLATE_MESSAGE = 'TemplateMessage',
  TEMPLATE_SAVE = 'TemplateSave',
  TEMPLATE_CLOSE = 'TemplateClose',
}

export const sms_template_detox = dynamicId(
  SmsTemplateSelectIdsEnum.TEMPLATE_LIST_ITEM,
  '-Detox Test',
);

export const sms_template_detox_changed = dynamicId(
  SmsTemplateSelectIdsEnum.TEMPLATE_LIST_ITEM,
  '-Detox Test Changed',
);

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import moment from 'moment';

import { environment } from '../../../environments/environment';
import { CookieService } from '../cookie.service';
import { EnvironmentType } from '../types/environment.type';
import { EnvironmentDataType } from '../types/environment-data.type';
import { OsEnum } from '../enums/os.enum';
import { DocumentService } from './document.service';
import { CtiProvider } from '../models/cti-providers.models';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { ElectronService } from './electron/electron.service';
import { AppService } from '../app.service';
import { CustomCtiService } from '../../_cti-integrations/custom-cti.service';

export const ENVIRONMENT: EnvironmentType = {
  prod: {
    className: 'web',
    env: 'production-web',
    userAgent: 'browser',
  },
  beta: {
    className: 'web',
    env: 'beta-web',
    userAgent: 'beta-browser',
  },
  e2e: {
    className: 'web',
    env: 'e2e-web',
    userAgent: 'e2e-browser',
  },
  electron: {
    className: 'electron',
    env: 'production-electron',
    userAgent: 'desktop',
  },
  electronBeta: {
    className: 'electron',
    env: 'beta-electron',
    userAgent: 'beta-desktop',
  },
  salesforce: {
    className: CtiProvider.SALESFORCE,
    env: 'production-salesforce',
    userAgent: CtiProvider.SALESFORCE,
  },
  hubspot: {
    className: CtiProvider.HUBSPOT,
    env: 'production-hubspot',
    userAgent: CtiProvider.HUBSPOT,
  },
  front: {
    className: CtiProvider.FRONTAPP,
    env: 'production-front',
    userAgent: CtiProvider.FRONTAPP,
  },
  zendesk: {
    className: CtiProvider.ZENDESK,
    env: 'production-zendesk',
    userAgent: CtiProvider.ZENDESK,
  },
  pipedrive: {
    className: CtiProvider.PIPEDRIVE,
    env: 'production-pipedrive',
    userAgent: CtiProvider.PIPEDRIVE,
  },
  customCti: {
    className: CtiProvider.CUSTOM,
    env: 'production-custom-cti',
    userAgent: CtiProvider.CUSTOM,
  },
};

export const USER_ENVIRONMENTS = {
  PROD: { key: 'prod', site: 'https://phone.cloudtalk.io/' },
  BETA: { key: 'beta', site: 'https://phone-beta.cloudtalk.io/' },
};
const CTI_TEST_COOKIE = 'cti_test';
@Injectable()
export class EnvironmentService {
  env: EnvironmentDataType;

  constructor(
    private electronService: ElectronService,
    private titleService: Title,
    private cookiesService: CookieService,
    private documentService: DocumentService,
    private appService: AppService,
  ) {
    this.environment();
  }

  get origin(): string {
    return this.documentService.nativeWindow.location.origin;
  }

  get isProduction(): boolean {
    return environment.production;
  }

  isDevelopment(): boolean {
    return !environment.production;
  }

  isElectron(): boolean {
    return this.electronService.isElectronApp;
  }

  isElectronBeta(): boolean {
    return this.getElectronAppVersion().includes('beta');
  }

  isSalesforce(): boolean {
    return (
      this.cookiesService.getCookie(CTI_TEST_COOKIE) ===
        CtiProvider.SALESFORCE ||
      this.isThatOrigin('https://phone-salesforce.cloudtalk.io') ||
      this.documentLocationInclude(`partner=${CtiProvider.SALESFORCE}`)
    );
  }

  isHubSpot(): boolean {
    return (
      this.cookiesService.getCookie(CTI_TEST_COOKIE) === CtiProvider.HUBSPOT ||
      this.referrerInclude('hubspot.com') ||
      this.documentLocationInclude(`partner=${CtiProvider.HUBSPOT}`) ||
      this.isThatOrigin('phone-hubspot.cloudtalk.io')
    );
  }

  isPipedrive(): boolean {
    return (
      this.cookiesService.getCookie(CTI_TEST_COOKIE) ===
        CtiProvider.PIPEDRIVE ||
      this.referrerInclude('pipedrive.com') ||
      this.documentLocationInclude(`partner=${CtiProvider.PIPEDRIVE}`) ||
      this.isThatOrigin('phone-pipedrive.cloudtalk.io')
    );
  }

  isFront(): boolean {
    return (
      this.cookiesService.getCookie(CTI_TEST_COOKIE) === CtiProvider.FRONTAPP ||
      this.referrerInclude('frontapp.com') ||
      this.documentLocationInclude(`partner=${CtiProvider.FRONTAPP}`) ||
      this.isThatOrigin('phone-frontapp.cloudtalk.io')
    );
  }

  isZendesk(): boolean {
    return (
      this.cookiesService.getCookie(CTI_TEST_COOKIE) === CtiProvider.ZENDESK ||
      this.referrerInclude('zendesk.com') ||
      this.documentLocationInclude(`partner=${CtiProvider.ZENDESK}`) ||
      this.isThatOrigin('phone-zendesk.cloudtalk.io')
    );
  }

  isCustomCti(): boolean {
    return (
      this.cookiesService.getCookie(CTI_TEST_COOKIE) === CtiProvider.CUSTOM ||
      this.appService.ctiService instanceof CustomCtiService
    );
  }

  isLive(): boolean {
    return this.isThatOrigin('phone.cloudtalk.io');
  }

  isBeta(): boolean {
    return this.isThatOrigin('phone-beta.cloudtalk.io');
  }

  isE2E(): boolean {
    return (
      this.isThatOrigin('phone-e2e.cloudtalk.io') ||
      this.isThatOrigin('phone-e2e-local.cloudtalk.io')
    );
  }

  isStage(): boolean {
    return this.isThatOrigin('phone.stage.cloudtalk.io');
  }

  isThatOrigin(location: string): boolean {
    return this.documentService.nativeWindow.location.origin.includes(location);
  }

  referrerInclude(partOfUrl: string): boolean {
    return document.referrer.includes(partOfUrl);
  }

  documentLocationInclude(parentLocation: string): boolean {
    try {
      return this.documentService.nativeWindow.location.href.includes(
        parentLocation,
      );
    } catch (e) {
      LoggerUtil.info(e);
      return false;
    }
  }

  isCTIIntegration(): boolean {
    return (
      this.isSalesforce() ||
      this.isHubSpot() ||
      this.isFront() ||
      this.isPipedrive() ||
      this.isZendesk()
    );
  }

  getChannel(): string {
    if (environment.name === 'prod' && this.isBeta()) {
      return 'beta';
    }
    return environment.name;
  }

  getOS(): OsEnum {
    const appVersion = window.navigator.appVersion;
    if (appVersion) {
      if (appVersion.indexOf('Win') !== -1) {
        return OsEnum.WINDOWS;
      }
      if (appVersion.indexOf('Mac') !== -1) {
        return OsEnum.MACOS;
      }
      if (appVersion.indexOf('X11') !== -1) {
        return OsEnum.UNIX;
      }
      if (appVersion.indexOf('Linux') !== -1) {
        return OsEnum.LINUX;
      }
    }
    return OsEnum.UNKNOWN;
  }

  setFormat(format: 'UK' | 'US'): void {
    if (format) {
      let local: string;
      switch (format) {
        case 'US':
          local = 'en';
          break;
        default:
          local = 'en-gb';
          break;
      }
      this.updateLocal(local);
      moment.locale(local);
    }
  }

  updateLocal(local = 'en'): void {
    moment.updateLocale(local, {
      calendar: {
        sameDay: '[Today]',
        nextDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'll',
      },
    });
  }

  getElectronAppVersion(): string {
    const ua = navigator.userAgent;
    const matchArray =
      ua.match(/(CloudTalkPhone\/([0-9]+).([0-9]+).([0-9]+)(-beta)?)/i) || [];
    return matchArray[0] || '';
  }

  get electronAppVersionNumber(): number {
    return Number(this.getElectronAppVersion()?.match(/\d/g)?.join(''));
  }

  private environment(): void {
    if (this.isElectron() && this.isLive()) {
      this.env = ENVIRONMENT.electron;
    } else if (this.isElectron() && this.isBeta()) {
      this.env = ENVIRONMENT.electronBeta;
    } else if (this.isSalesforce()) {
      this.env = ENVIRONMENT.salesforce;
    } else if (this.isHubSpot()) {
      this.env = ENVIRONMENT.hubspot;
    } else if (this.isFront()) {
      this.env = ENVIRONMENT.front;
    } else if (this.isZendesk()) {
      this.env = ENVIRONMENT.zendesk;
    } else if (this.isPipedrive()) {
      this.env = ENVIRONMENT.pipedrive;
    } else if (this.isCustomCti()) {
      this.env = ENVIRONMENT.customCti;
    } else if (this.isBeta()) {
      this.titleService.setTitle('CloudTalk Phone Beta');
      this.env = ENVIRONMENT.beta;
    } else if (this.isE2E()) {
      this.env = ENVIRONMENT.e2e;
    } else {
      this.env = ENVIRONMENT.prod;
    }

    this.cookiesService.sharedDomain = this.isBeta() || this.isLive();
  }
}

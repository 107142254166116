<span
  class="ct-icon"
  [appTestId]="'icon-' + name"
  [style.mask-image]="
    isMaskIcon
      ? 'url(/assets/img/' + category + '/' + name + '.' + suffix + ')'
      : 'none'
  "
  [style.-webkit-mask-image]="
    isMaskIcon
      ? 'url(/assets/img/' + category + '/' + name + '.' + suffix + ')'
      : 'none'
  "
  [style.-webkit-mask-size]="shouldCover ? 'cover' : 'auto'"
  [style.background-image]="
    !isMaskIcon
      ? 'url(/assets/img/' + category + '/' + name + '.' + suffix + ')'
      : 'none'
  "
  [style.background-size]="shouldCover ? 'cover' : 'auto'"
  [style.width.px]="size"
  [style.height.px]="size"
  [class.ct-icon-active]="isActive"
  [class.ct-icon-disabled]="isDisabled"
  [ngClass]="colorClass ? 'ct-icon-color-' + colorClass : ''"
  [class.ct-icon-mask]="isMaskIcon">
</span>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialElementImportModule } from '../material-element-import.module';
import { DividerComponent } from './divider.component';

@NgModule({
  imports: [CommonModule, MaterialElementImportModule],
  declarations: [DividerComponent],
  exports: [DividerComponent],
})
export class DividerModule {}

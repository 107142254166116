import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { WindowService } from '../../../_core/services/window.service';

@Component({
  templateUrl: './snack-bar-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarWrapperComponent implements OnInit, AfterViewInit {
  public closed: Subject<void> = new Subject();

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router,
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private windowsService: WindowService,
  ) {}

  close(): void {
    this.closed.next();
  }

  ngAfterViewInit() {
    const anchor = this.el.nativeElement.querySelector('a');
    if (anchor) {
      anchor.addEventListener('click', this.onClick.bind(this));
    }
  }

  onClick(event) {
    event.preventDefault();
    const anchor = event.srcElement;
    const href: string = anchor.getAttribute('href');
    if (href?.includes('https')) {
      this.windowsService.maximized(href);
      return;
    }
    this.router.navigateByUrl(href).catch(() => {});
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }
}

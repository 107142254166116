import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmptyListComponent } from './empty-list/empty-list.component';
import { CtIconComponent } from '../ct-icon/ct-icon.component';

@NgModule({
  declarations: [EmptyListComponent],
  imports: [CommonModule, CtIconComponent],
  exports: [EmptyListComponent],
})
export class DataStateComponentsModule {}

export enum VoicemailDropsIdsEnum {
  ADD_MESSAGE_BUTTON = 'AddMessageButton',
  RECORD_NAME_INPUT = 'RecordNameInput',
  RECORD_MESSAGE_BUTTON = 'RecordMessageButton',
  UPLOAD_FILE_BUTTON = 'UploadFileButton',
  MAIN_RECORD_BUTTON = 'MainRecordButton',
  PLAY_RECORD_BUTTON = 'PlayRecordButton',
  DELETE_RECORD_BUTTON = 'DeleteRecordButton',
  SAVE_RECORD_BUTTON = 'SaveRecordButton',
}

import { Pipe, PipeTransform } from '@angular/core';

import {
  OsEnum,
  OsKeyListType,
  OsKeysDefinitions,
} from '../../../_core/enums/os.enum';
import { EnvironmentService } from '../../../_core/services/environment.service';

@Pipe({
  name: 'osKeyName',
  standalone: true,
})
export class OsKeyNamePipe implements PipeTransform {
  constructor(private environmentService: EnvironmentService) {}

  /**
   * Pipe return defined shor name depending on OS, if not defined it will return default value if not defined will return keyId
   * @param keyId
   */
  transform(keyId: OsKeyListType): string {
    const os = this.environmentService.getOS();

    let prop = 'short_name_default';
    switch (os) {
      case OsEnum.MACOS:
        prop = 'short_name_macos';
        break;
      case OsEnum.WINDOWS:
        prop = 'short_name_windows';
        break;
      case OsEnum.LINUX:
        prop = 'short_name_linux';
        break;
      case OsEnum.UNIX:
        prop = 'short_name_unix';
        break;
    }

    if (prop in OsKeysDefinitions[keyId]) {
      return OsKeysDefinitions[keyId][prop];
    }

    if ('short_name_default' in OsKeysDefinitions[keyId]) {
      return OsKeysDefinitions[keyId]['short_name_default'];
    } else {
      return keyId;
    }
  }
}

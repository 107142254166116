import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { environment } from '../../../environments/environment';

export class LoggerUtil {
  public static setUser(user: { user_id: number; company_id: number }): void {
    const id = user.user_id == null ? undefined : user.user_id.toString();
    datadogRum.setUser({ id, ...user });
    datadogLogs.setUser({ id, ...user });
  }
  public static info(
    message: string,
    messageContext?: object,
    err: unknown = null,
  ) {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log(message, messageContext, err);
    }
    datadogLogs.logger.info(message, messageContext, LoggerUtil.toError(err));
  }

  public static warn(
    message: string,
    messageContext?: object,
    err: unknown = null,
  ) {
    if (!environment.production) {
      console.warn(message, messageContext, err);
    }
    datadogLogs.logger.warn(message, messageContext, LoggerUtil.toError(err));
  }

  public static error(
    message: string,
    messageContext?: object,
    err: unknown = null,
  ) {
    if (!environment.production) {
      console.error(message, messageContext, err);
    }
    datadogLogs.logger.error(message, messageContext, LoggerUtil.toError(err));
  }

  private static toError(value: unknown) {
    if (value instanceof Error) {
      return value;
    }
    if (value === null || value === undefined || value === '') {
      return new Error('An unknown error occurred');
    }
    if (typeof value === 'object') {
      try {
        return new Error(JSON.stringify(value));
      } catch {
        return new Error('An error occurred with a cyclic object');
      }
    }

    return new Error(String(value));
  }
}

export enum PhoneTabNavigatorIdsEnum {
  DIAL_PAD_ROUTER_LINK_BUTTON = 'MiddleButton',
  CALL_HISTORY_BUTTON = 'CallHistoryButton',
  CONTACTS_BUTTON = 'ContactsButton',
  SMS_BUTTON = 'SmsButton',
  MORE_BUTTON = 'MoreButton',
  PROFILE_BUTTON = 'ProfileButton',
  SETTINGS_BUTTON = 'SettingsButton',
  SMART_DIALER_SUB_MENU_BUTTON = 'SmartDialerSubMenuButton',
  POWER_DIALER_SUB_MENU_BUTTON = 'PowerDialerSubMenuButton',
  SMART_DIALER_BUTTON = 'SmartDialerButton',
  POWER_DIALER_BUTTON = 'PowerDialerButton',
  DIALER_BUTTON = 'DialerButton',
  CALL_MONITOR_SUB_MENU_BUTTON = 'CallMonitorSubMenuButton',
  SETTINGS_SUB_MENU_BUTTON = 'SettingsSubMenuButton',
  UNREAD_MESSAGES_COUNT = 'UnreadMessagesCount',
}

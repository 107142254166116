import { EventEmitter, Injectable } from '@angular/core';
import { ConnectionState } from '@cloudtalk/sip-service';
import moment from 'moment/moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { hasOwnProperty } from '../../_shared/helpers/helper-functions';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { CTResponse } from '../interfaces/ctresponse.interface';
import { User } from '../models/user';
import { VoiceSettingService } from './audio/voice-setting.service';
import { EnvironmentService } from './environment.service';
import { FeatureFlagsService } from './feature-flags/feature-flags.service';
import { CT_PHONE_META, LocalStorageService } from './local-storage.service';
import { EndpointService } from './networking/endpoint.service';
import {
  PhoneStatusConst,
  PhoneStatusProperties,
  PhoneStatusService,
  PhoneStatusSimpleChange,
} from './phone-status.service';
import { UserService } from './user/user.service';

export interface StatusBarState {
  connectionStatusValue: string | null;
  microphoneStatusValue: string | null;
  notificationStatusValue: string | boolean | null;
  hideStatusBar: boolean;
}

const NOTIFICATION_DISABLED_KEY = 'notification_disabled';

export type IdleTypeSetting = 'no_calls' | 'internal' | 'direct';

@Injectable()
export class StatusService {
  statusEmitter = new EventEmitter();
  idleStatusTypeSetting: IdleTypeSetting = 'no_calls';
  // CallingConnectionState
  #callingConnectionState$ = new BehaviorSubject<ConnectionState>(
    ConnectionState.UNCONNECTED,
  );
  callingConnectionState$: Observable<ConnectionState> =
    this.#callingConnectionState$.asObservable();
  #callingConnectionState: ConnectionState = ConnectionState.UNCONNECTED;
  // Connection SipService
  #statusBar$: BehaviorSubject<StatusBarState> =
    new BehaviorSubject<StatusBarState>({
      connectionStatusValue: null,
      microphoneStatusValue: null,
      notificationStatusValue: null,
      hideStatusBar: true,
    });
  statusBar$ = this.#statusBar$.asObservable();
  #onboardingRunning$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onboardingRunning$: Observable<boolean> =
    this.#onboardingRunning$.asObservable();

  microphoneStatusGranted$: Observable<boolean> = this.statusBar$.pipe(
    map(
      (status: StatusBarState) =>
        status.microphoneStatusValue === PhoneStatusConst.MEDIA_STREAM ||
        status.microphoneStatusValue === PhoneStatusConst.MEDIA_STREAM_OS,
    ),
  );

  constructor(
    public phoneStatus: PhoneStatusService,
    public voiceSetting: VoiceSettingService,
    private endpointService: EndpointService,
    private userService: UserService,
    private environmentService: EnvironmentService,
    private localStorageService: LocalStorageService,
  ) {}

  get hasInternetConnection() {
    return (
      this.phoneStatus.status[PhoneStatusProperties.INTERNET_ACCESS] ===
      PhoneStatusConst.CONNECTED
    );
  }

  set callingConnectionState(value: ConnectionState) {
    this.#callingConnectionState = value;
  }

  get callingConnectionState(): ConnectionState {
    return this.#callingConnectionState;
  }

  set onboardingRunning(value: boolean) {
    this.#onboardingRunning$.next(value);
  }

  get onboardingRunning(): boolean {
    return this.#onboardingRunning$.value;
  }

  get statusBar(): StatusBarState {
    return this.#statusBar$.getValue();
  }

  check(): void {
    this.phoneStatus.resetStatus();

    // prida listenery na online / offline pripojenia na internet
    window.addEventListener('online', () =>
      this.onlineStatus(PhoneStatusConst.CONNECTED),
    );
    window.addEventListener('offline', () =>
      this.onlineStatus(PhoneStatusConst.NO_INTERNET_ACCESS),
    );
    // handling network changes
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (
        hasOwnProperty(Navigator.prototype, 'connection') &&
        (navigator as any)?.connection?.onchange === null
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (navigator as any).connection.onchange = event =>
          this.emitNetworkStatusChanges(event);
      }
    } catch (error) {
      LoggerUtil.error(
        '[StatusService]: navigator.connection.onchange',
        {},
        error,
      );
    }

    // handling media changes (devices like headset)
    navigator.mediaDevices.ondevicechange = () =>
      this.voiceSetting.updateDevices();

    this.handleMediaError();

    // handle permissions changes
    if ('permissions' in navigator) {
      if (this.shouldShowNotificationStatus()) {
        this.checkPermission(
          'notifications',
          'notificationStatusValue',
          false,
          true,
        );
      }

      this.checkPermission(
        'microphone',
        'microphoneStatusValue',
        PhoneStatusConst.NO_MEDIA_STREAM,
        PhoneStatusConst.MEDIA_STREAM,
      );
    }
  }

  get isMicrophoneGranted(): boolean {
    return [
      PhoneStatusConst.MEDIA_STREAM,
      PhoneStatusConst.MEDIA_STREAM_OS,
    ].includes(this.statusBar.microphoneStatusValue);
  }

  setStatus(property, value): void {
    if (this.phoneStatus.status[property] === value) {
      this.statusEmitter.emit();
      return;
    }
    const oldV = this.phoneStatus.status[property];
    this.phoneStatus.status[property] = value;
    this.statusEmitter.emit(new PhoneStatusSimpleChange(oldV, value, property));
  }

  handleMediaError(): void {
    this.voiceSetting.mediaAcquired$.subscribe(acquired => {
      if (acquired) {
        this.mediaStream(PhoneStatusConst.MEDIA_STREAM);
      } else {
        if (
          !this.phoneStatus.errorList.includes(PhoneStatusConst.NO_MEDIA_STREAM)
        ) {
          this.mediaStream(PhoneStatusConst.NO_MEDIA_STREAM);
        }
      }
    });
  }

  // internet status
  onlineStatus(value): void {
    this.setStatus(PhoneStatusProperties.INTERNET_ACCESS, value);
  }

  emitNetworkStatusChanges(event): void {
    this.setStatus(PhoneStatusProperties.NETWORK_CHANGES, event);
  }

  // emitted if the user turn on multiple phones
  alreadyRegistered(value: boolean): void {
    const user: User = this.userService.getUser();
    if (
      !FeatureFlagsService.isFeatureFlagEnabledById(
        'doNotShowAlreadyRegisteredPage',
        { users: user.id },
      )
    ) {
      this.setStatus(PhoneStatusProperties.ALREADY_REGISTERED, value);
    }
  }

  // if user testing her audio setup
  isTestingVoice(isTestingVoice: boolean): void {
    this.setStatus(PhoneStatusProperties.TESTING_VOICE, isTestingVoice);
  }

  //  unused yet
  isCorrectlyConnected(isCorrectlyConnected): void {
    this.setStatus(
      PhoneStatusProperties.CORRECTLY_CONNECTED,
      isCorrectlyConnected,
    );
  }

  // accessibility of user media (mic) on the web app
  mediaStream(state): void {
    this.setStatus(PhoneStatusProperties.MEDIA_STREAM, state);
  }

  // this one should be emitted from the electron, only working for MAC OS X, windows does not supporting this feature
  mediaStreamOS(state): void {
    this.setStatus(PhoneStatusProperties.OS_MEDIA_STREAM, state);
  }

  notificationAllowed(allowed: boolean): void {
    this.setStatus(PhoneStatusProperties.NOTIFICATION_ALLOWED, allowed);
  }

  setCallingConnectionState(connectionState: ConnectionState): void {
    this.#callingConnectionState$.next(connectionState);
    this.callingConnectionState = connectionState;
  }

  updateStatusBar<T extends keyof StatusBarState>(
    property: keyof StatusBarState,
    value: StatusBarState[T],
  ) {
    this.#statusBar$?.next({
      ...this.statusBar,
      [property]: value,
    });
  }

  loadIdleStatusTypeSetting(): Observable<
    CTResponse<{ properties: { idle_rule: IdleTypeSetting } }>
  > {
    return this.endpointService
      ._post_realtime<
        { properties: { idle_rule: IdleTypeSetting } },
        { properties_to_get: string[] }
      >(
        'properties',
        {
          properties_to_get: ['idle_rule'],
        },
        false,
        'my-company/',
      )
      .pipe(
        tap(
          (
            results: CTResponse<{ properties: { idle_rule: IdleTypeSetting } }>,
          ) => {
            if (results.data) {
              this.idleStatusTypeSetting = results.data.properties.idle_rule;
            }
          },
        ),
      );
  }

  private checkPermission(
    permisionName: string,
    permissionStatusValue: keyof StatusBarState,
    permissionDenied: string | boolean,
    permissionGranted: string | boolean,
  ) {
    const permissionQuery = permisionName as PermissionName;
    navigator.permissions
      .query({ name: permissionQuery })
      .then((permissionStatus: PermissionStatus) => {
        // initial change
        this.updateStatusBar(
          permissionStatusValue,
          permissionStatus.state === 'denied'
            ? permissionDenied
            : permissionGranted,
        );
        permissionStatus.onchange = () => {
          // reacting on changes
          this.updateStatusBar(
            permissionStatusValue,
            permissionStatus.state === 'denied'
              ? permissionDenied
              : permissionGranted,
          );
          this.voiceSetting.getAllAvailableDevices();
        };
      })
      .catch(console.error);
  }

  public shouldShowNotificationStatus(): boolean {
    if (
      this.environmentService.isCTIIntegration() ||
      this.environmentService.isCustomCti()
    ) {
      return false;
    }
    const statusInfo = this.localStorageService.getItem(CT_PHONE_META);
    if (!statusInfo) {
      return true;
    }
    if (statusInfo[NOTIFICATION_DISABLED_KEY]) {
      return (
        moment()
          .subtract(14, 'days')
          .diff(
            moment.unix(Number(statusInfo[NOTIFICATION_DISABLED_KEY])),
            'days',
          ) > 0
      );
    }
    return true;
  }
}

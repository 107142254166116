import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { SchedulleModalEnum } from 'test-automation';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface ScheduleData {
  scheduledDate: string;
  scheduledNote: string | null;
  sameUser: boolean;
}

@Component({
  selector: 'app-scheduler-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss'],
})
export class ScheduleModalComponent implements OnInit {
  readonly schedulleModalEnum = SchedulleModalEnum;

  minDate = new Date();
  selectedDate: string | Date = new Date();
  time: string | null = null;
  note: string | null = null;
  sameUser: boolean;
  scheduleForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ScheduleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { initialValues?: ScheduleData },
  ) {}

  ngOnInit(): void {
    this.minDate.setDate(this.minDate.getDate() - 1);

    if (this.data?.initialValues) {
      this.setInitialValues();
    }

    this.scheduleForm = new FormGroup({
      time: new FormControl(this.time, [Validators.required]),
      note: new FormControl(this.note),
      sameUser: new FormControl(this.sameUser),
    });
  }

  submit(): void {
    if (this.scheduleForm.valid) {
      const data: ScheduleData = {
        scheduledDate: this.getScheduledDate(),
        scheduledNote: this.scheduleForm.value?.note || null,
        sameUser: true,
      };
      this.dialogRef.close(data);
    } else {
      this.validateAllFormFields(this.scheduleForm);
    }
  }

  updateSameUser({ checked }: MatCheckboxChange): void {
    this.sameUser = checked;
  }

  setInitialValues(): void {
    this.selectedDate = this.data.initialValues.scheduledDate;
    this.time = moment(this.data.initialValues.scheduledDate).format('LT');
    this.note = this.data.initialValues.scheduledNote;
  }

  getScheduledDate(): string {
    let time = null,
      scheduledDateTime: Date = null;
    if (this.scheduleForm.value.time) {
      time = this.scheduleForm.value.time.split(':');
    }
    if (this.selectedDate) {
      scheduledDateTime = new Date(this.selectedDate);
    }
    if (scheduledDateTime && time && time.length > 0) {
      scheduledDateTime.setHours(parseInt(time[0], 10), parseInt(time[1], 10));
    }
    return moment(scheduledDateTime).format();
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }
}

import { ActivatedRoute } from '@angular/router';

import { languagesCodes, LANGUAGE_NAMES } from '../../languages';

export enum SettingsKeys {
  ENABLE_KEYBOARD_SHORTCUTS = 'enable_keyboard_shortcuts',
  INCOMING_NOTIFICATION_PREFERENCE = 'incoming_notification_preference',
  COUNTRY_ID = 'country_id',
  APP_LANGUAGE = 'app_language',
  CLICK_TO_CALL_DIAL_IMMEDIATELY = 'click_to_call_dial_immediately',
  BRING_RINGING_PHONE_TO_FOREGROUND = 'bring_ringing_phone_to_foreground',
  PICKUP_GENERATED_CALLS = 'pickup_generated_calls',
  PICKUP_INBOUND_CALLS = 'pickup_inbound_calls',
  TIMEZONE_OF_CALLED = 'timezone_of_called',
  HAS_AUTOMATIC_OUTBOUND = 'has_automatic_outbound',
  DEFAULT_VOICEMAIL_DROP = 'default_voicemail_drop',
  VOICE_SETTINGS = 'voice_settings',
  TROUBLESHOOTING = 'troubleshooting',
  DIALPAD_PREFIX = 'dialpad_prefix',
  USER_STATUS = 'user_status',
  ANONYMOUS_CALLS = 'anonymous_calls',
}

export enum SettingsPages {
  GENERAL = 'general_page',
  CTI = 'cti_page',
  CALL = 'call_page',
  MIC_AND_SPEAKERS = 'mic_and_speakers_page',
  NOTIFICATIONS = 'incoming_notification_preference',
  TROUBLESHOOTING = 'troubleshooting_page',
  LANGUAGE = 'language_page',
  KEYBOARD_SHORTCUTS = 'keyboard_shortcuts_page',
  APP_BEHAVIOR = 'app_behavior_page',
  VOICEMAIL_DROP = 'voicemail_drop_page',
  DEFAULT_COUNTRY_PREFIX = 'default_country_prefix_page',
  CALL_AUTOMATION = 'call_automation_page',
}

export enum SettingsEnvironmentEnum {
  ALL = 'all',
  ELECTRON = 'electron',
}

export interface SettingItem {
  key: string;
  icon: string;
  title: string;
  hint?: string;
  placeholder?: string;
  slide: boolean;
  select: boolean;
  routerLink?: {
    link?: string | string[];
    relativeTo?: ActivatedRoute | null;
    ownRoute: boolean;
  };
  notifyElectron: boolean;
  display: boolean;
  environment: string;
}

export interface SettingsItemExpandable {
  title: string;
  icon: string;
}

export enum NotificationPreferenceEnum {
  PICKUP = 'pickUp',
  FOCUS = 'focus',
}

export interface MultipleOptionsOptionObject {
  value: string;
  title: string;
  hint: string;
}

export interface MultipleOptionsObject {
  [key: string]: {
    page_title: string;
    options: MultipleOptionsOptionObject[];
  };
}

export interface SelectionOptionObject {
  [key: string]: {
    options: SelectionOptionObjectItem[];
  };
}

export interface SelectionOptionObjectItem {
  name: string;
  [key: string]: any;
}

export const SETTINGS_SELECT_OPTIONS: SelectionOptionObject = {
  [SettingsKeys.APP_LANGUAGE]: {
    options: languagesCodes.map(value => ({
      value,
      name: LANGUAGE_NAMES[value],
    })),
  },
  [SettingsKeys.PICKUP_INBOUND_CALLS]: {
    options: [
      { name: 'Off', value: -1 },
      { name: '1 seconds', value: 1 },
      { name: '2 seconds', value: 2 },
      { name: '5 seconds', value: 5 },
      { name: '10 seconds', value: 10 },
    ],
  },
};

import { NgModule } from '@angular/core';

import { InitialsPipe } from './name-initials.pipe';

@NgModule({
  declarations: [InitialsPipe],
  exports: [InitialsPipe],
  providers: [InitialsPipe],
})
export class NameInitialsModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ListItemComponent } from './list-item/list-item.component';
import { ItemListComponent } from './item-list.component';
import { RouterLinkItemComponent } from './router-link-item/router-link-item.component';
import { DirectivesModule } from '../../directives/directives.module';
import { CtIconComponent } from '../ct-icon/ct-icon.component';

@NgModule({
  imports: [CommonModule, RouterModule, DirectivesModule, CtIconComponent],
  declarations: [ItemListComponent, ListItemComponent, RouterLinkItemComponent],
  exports: [ItemListComponent, ListItemComponent, RouterLinkItemComponent],
})
export class ItemListModule {}

export type ReadingFileResult =
  | SuccessfulReadingFileResult
  | UnsuccesfulReadingFileResult;

export interface SuccessfulReadingFileResult {
  existing: true;
  file: UploadedFile;
  error?: string;
}

export interface UnsuccesfulReadingFileResult {
  existing: false;
  error: string;
}

export interface UploadedFile {
  size: number;
  name: string;
  data: string;
  type: string;
}

export const readingFileError = {
  FILE_SIZE_EXCEEDED_LIMIT_ERROR: (limit: string | number): string =>
    $localize`Photo size exceeded limit ${limit}`,
  FILE_COUNT_EXCEEDED_LIMIT_ERROR: (limit: string | number): string =>
    $localize`Maximum files count exceeded limit ${limit}`,
};

<label>
  <input
    [appTestId]="fileUploaderIdsEnum.FILE_INPUT"
    #fileInput
    [accept]="supportedFiles"
    type="file"
    (change)="fileInput.files && uploadFiles(fileInput.files)"
    [multiple]="multipleUpload"
    [disabled]="disabled" />
  <ng-content></ng-content>
</label>

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class AssetsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.registerAssetsIcons();
  }

  registerAssetsIcons() {
    try {
      this.matIconRegistry.addSvgIcon(
        'google-logo',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          './assets/img/icons/g-logo.svg',
        ),
      );
      this.matIconRegistry.addSvgIcon(
        'smart-dialer',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          './assets/img/icons/icon-smart-dialer.svg',
        ),
      );
      this.matIconRegistry.addSvgIcon(
        'icon-dialer',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          './assets/img/icons/icon-dialer.svg',
        ),
      );
      this.matIconRegistry.addSvgIcon(
        'cloud-warning',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          './assets/img/icons/cloud-warning-light.svg',
        ),
      );
    } catch (e) {
      console.warn(e);
    }
  }
}

interface AgentInitOptions {
  id: number;
  type: string;
  extension?: string;
  firstname?: string;
  lastname?: string;
  online_status?: string;
  internal_name?: string;
  name?: number;
  email?: string;
  is_member?: boolean;
}
/**
 * @deprecated we should use sip-service definition of agent, and later the definition has to be moved under phone app
 * */
export class Agent {
  id: number;
  firstname: string;
  lastname: string;
  internal_name: string;
  name: number;
  online_status: string;
  email: string;
  type: string;
  // znak z abecedy pre prvy prvok
  letter: string;
  // searchable string
  searchable: string;
  is_member: boolean;

  _extension: string;

  constructor(agent: AgentInitOptions) {
    this.id = agent?.id ?? null;
    this.firstname = agent?.firstname ?? '';
    this.lastname = agent?.lastname ?? '';
    this.internal_name = agent?.internal_name ?? '';
    this._extension = agent?.extension ?? null;
    this.letter = null;
    this.searchable = '';
    this.online_status = agent?.online_status ?? '';
    this.name = agent?.name ?? 0;
    this.email = agent?.email ?? '';
    this.type = agent?.type ?? 'agent';
    this.is_member = agent.is_member ?? null;
  }

  public get extension() {
    if (this.type === 'agent') {
      return this._extension;
    }
    if (this.type === 'group') {
      return `8${this.name.toString().slice(-2)}`;
    }
    return null;
  }

  public set extension(extension) {
    if (this.type === 'agent') {
      this._extension = extension;
    } else {
      throw new Error('Can not set extension if type is not agent.');
    }
  }

  public get fullname(): string {
    if (this.type === 'agent') {
      return this.firstname + ' ' + this.lastname;
    }
    if (this.type === 'group') {
      return this.internal_name;
    }
    return null;
  }

  public get monogram() {
    return `${this.firstname.charAt(0)}${this.lastname.charAt(
      0,
    )}`.toUpperCase();
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { SearchSelectIdsEnum } from 'test-automation';

@Component({
  selector: 'app-search-select-item',
  templateUrl: './search-select-item.component.html',
  styleUrls: ['./search-select-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSelectItemComponent {
  @Input() countryCode: string;
  @Input() countryClass: string;
  @Input() customClass: string;
  @Input() mainLabel: string;
  @Input() subLabel: string;
  @Input() tooltip: string;
  @Input() isSelected = false;
  @Input() isDisabled = false;
  @Input() displayFlag = true;
  @Input() icon: string | null = null;

  @ContentChild('mainLabelTemplate', { static: false })
  mainLabelTemplate: TemplateRef<any>;

  @Output() readonly selected: EventEmitter<void> = new EventEmitter<void>();

  readonly searchSelectIdsEnum = SearchSelectIdsEnum;

  selectItem(): void {
    this.isSelected = true;
    this.selected.emit();
  }
}

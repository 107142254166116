import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';

import { defaultFallbackLanguage } from '../../languages';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root',
})
class I18n {
  locale = this.localizationService.getLocale();

  constructor(private localizationService: LocalizationService) {}

  async setLocale() {
    const userLocale = this.localizationService.getLocale();

    // If the user has a preferred language stored in localStorage, use it.
    if (userLocale) {
      this.locale = userLocale;
    }

    // Use web pack magic string to only include required locale data
    let local = this.locale;
    if (this.locale === 'cz') {
      local = 'cs';
    }

    const localeModule = await import(
      /* webpackInclude: /(cs|de|en|en-GB|es|fr|pt|sk|nl)\.mjs$/ */
      `@/../node_modules/@angular/common/locales/${local}.mjs`
    ).catch(() => {
      return import(
        /* webpackInclude: /(en)\.mjs$/ */
        `@/../node_modules/@angular/common/locales/${defaultFallbackLanguage}.mjs`
      );
    });

    // Set locale for built in pipes, etc.
    registerLocaleData(localeModule.default);

    // Load translation file
    const localeTranslationsModule = await import(
      `src/assets/localize/${this.locale}.json`
      // eslint-disable-next-line
      // @ts-ignore
    ).catch(() => import('src/assets/localize/messages.json'));

    loadTranslations(localeTranslationsModule.default.translations);
  }
}

// Load locale data at app start-up
function setLocale() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (i18n: I18n) => () => i18n.setLocale(),
    deps: [I18n],
    multi: true,
  };
}

// Set the runtime locale for the app
function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => i18n.locale,
    deps: [I18n],
  };
}

export const I18N_SERVICE = {
  setLocale: setLocale,
  setLocaleId: setLocaleId,
};

import { Observable } from 'rxjs';
import { inject, InjectionToken } from '@angular/core';

import { ThemeService } from './theme.service';

export type ActiveThemeProvider = Observable<string>;
export const ACTIVE_THEME = new InjectionToken<ActiveThemeProvider>(
  'Active theme',
  {
    factory() {
      return inject(ThemeService).theme$;
    },
  },
);

import { NgModule } from '@angular/core';

import { IsValidPhoneNumberPipe } from './is-valid-phone-number.pipe';

@NgModule({
  declarations: [IsValidPhoneNumberPipe],
  exports: [IsValidPhoneNumberPipe],
  providers: [IsValidPhoneNumberPipe],
})
export class IsValidPhoneNumberModule {}

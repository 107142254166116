import { ContactListItem } from '../../../../app/pages/contacts/contacts.model';

interface Tag {
  id?: number;
  name: string;
  color: string;
  tag?: string;
}

interface CallHistoryCallType {
  type?: string;
  typeLabel?: string;
  icon?: string;
  color?: string;
}

interface CallHistoryUser {
  firstname?: string;
  lastname?: string;
  email?: string;
}

interface CallHistoryCallNumber {
  internal_name?: string;
}

interface CallHistoryContact {
  name?: string;
  company?: string;
  type?: string;
}

interface CdrTransferFrom {
  public_external?: string;
}

interface CallHistoryNumbers {
  number?: string;
  fullNumber?: string;
  fullNumberDisplay?: string;
}

interface CallHistoryAgents {
  agentName?: string;
  groupName?: string;
  publicCallerId?: string;
}

interface BaseContactsResponseType {
  success: boolean;
}

export interface ContactsPaginationResponseType
  extends BaseContactsResponseType {
  data: object;
}

export interface ContactsPageResponseType extends BaseContactsResponseType {
  data: ContactListItem[];
}

export interface ContactsContactResponseType extends BaseContactsResponseType {
  data: {
    id: number;
    company_id: number;
    user_id: number;
    name: string;
    title: string;
    company: string;
    industry: string;
    website: string;
    address: string;
    city: string;
    zip: string;
    state: string;
    country_id: number;
    favorite_agent: number;
    external_url: {
      name: string;
      url: string;
    }[];
    avatar_url: string;
    source: string;
    delete_status: string;
    created: string;
    modified: string;
    type: string;
    contact_notes: string[];
    contact_emails: any[];
    country: {
      id: number;
      name_sk: string;
    };
    contact_numbers: {
      contact_id: number;
      id: number;
      public_number: string;
      country_code_id: number;
    }[];
    tags: [];
    integration_links: {
      ''?: string;
      freshdesk?: string;
      intercom?: string;
      pipedrive?: string;
      salesforce?: string;
      helpscout?: string;
      shopify?: string;
    };
  };
}

export interface ContactsCallHistoryResponseType
  extends BaseContactsResponseType {
  data: {
    id?: number;
    src?: string;
    dst?: string;
    billsec?: number;
    calldate?: Date;
    disposition?: string;
    dstnumber?: string;
    type?: string;
    country_code?: string;
    lastapp?: string;
    callid?: string;
    dstchannel?: string;
    public_external?: string;
    public_internal?: string;
    company_id?: number;
    recorded?: boolean;
    is_redirected?: number;
    redirected_from?: string;
    transferred_from?: string;
    call_type?: CallHistoryCallType;
    user_name?: number;
    is_voicemail?: boolean;
    is_queue_callback?: number;
    is_business_hour_closed?: boolean;
    note?: string;
    qc_attempt_count?: number;
    qc_result?: any;
    qc_attempt_id?: number;
    user_id?: number;
    contact_id?: number;
    click_to_call_request_id?: number;
    resolved_by_cdr_id?: number;
    call_uuid?: string;
    group?: any;
    click_to_call_request?: any;
    user?: CallHistoryUser;
    call_number?: CallHistoryCallNumber;
    contact?: CallHistoryContact;
    CdrTransferFrom?: CdrTransferFrom;
    numbers?: CallHistoryNumbers;
    agents?: CallHistoryAgents;
    resolved_past?: any;
    resolved_active?: any;
    resolver_call_detail?: any;
    calendar?: string;
    user_fullname_deleted?: string;
  }[];
}

export interface ContactsCustomFieldsResponseType
  extends BaseContactsResponseType {
  data: {
    custom_fields: {
      title: string;
      value: any;
    }[];
  };
}

export interface ContactsActivitiesResponseType
  extends BaseContactsResponseType {
  data: {
    id?: number;
    type?: string;
    name?: string;
    status?: string;
    created?: string;
    description?: {
      priority_type?: string;
      stage_status_name?: string;
      org_name?: string;
      description_price?: string;
      description_content?: string;
      description_status_color?: string;
      description_status_text?: string;
      stage_name?: string;
      title?: string;
      next_activity_date?: string;
      next_activity_time?: string;
      value?: number;
      state?: string;
      financial_status?: string;
      folderId?: number;
      TOTAL_PRICE?: { PRICE_TO_PAY?: string };
      CURRENCY?: { CODE?: string };
      id?: number;
      total_price?: string;
      price?: string;
      currency?: string;
      order_currency_code: string;
      total_due: number;
      created_at?: string;
      DATE?: string;
      created?: string;
      internal_signature?: string;
      isDraft?: false;
      mailbox?: { id?: number; name?: string };
      mailboxName?: string;
      modifiedAt?: string;
      number?: number;
      owner?: null;
      preview?: string;
      source?: { type?: string; via?: string };
      status?: string;
      STATUS?: string;
      subject?: string;
      tags?: Tag[];
      threadCount?: number;
      threads?: any[];
      type?: string;
      note?: string;
      createddate?: string;
      description?: string;
    };
    activity_author?: string;
    external_url?: string;
    external_id?: number;
    activity_date?: string;
    external_service?: string;
  }[];
}

export interface ContactsTagSaveResponseType extends BaseContactsResponseType {
  data: undefined[];
}

export interface ContactsNoteSaveResponseType extends BaseContactsResponseType {
  data: {
    id: number;
  };
}

export type ContactsNoteDeleteResponseType = BaseContactsResponseType;

export interface CreateContactResponseType extends BaseContactsResponseType {
  data: {
    id: number;
  };
}

export interface DeleteContactResponseType extends BaseContactsResponseType {
  data: {
    id: number;
  };
}

export interface ContactsIntegrationLinksType extends BaseContactsResponseType {
  data: [
    {
      company_id: number;
      contact: {
        company: string;
        contact_emails: unknown[];
        external_url?: string;
        id: number;
        integration_links: unknown;
        name: string;
        tags: unknown[];
        type: string;
      };
      contact_id: number;
      country_code_id: number;
      country_id: number;
      created: string;
      id: number;
      modified: string;
      public_number: string;
    },
  ];
  pagination: {
    page_count: number;
    current_page: number;
    has_next_page: false;
    has_prev_page: false;
    count: number;
    limit: null;
  };
  success: true;
}

export type ContactsResponseType<T> = T extends ContactsCase.CONTACTS_PAGINATION
  ? ContactsPaginationResponseType
  : T extends ContactsCase.CONTACTS_PAGE
  ? ContactsPageResponseType
  : T extends ContactsCase.CONTACT
  ? ContactsContactResponseType
  : T extends ContactsCase.CALL_HISTORY
  ? ContactsCallHistoryResponseType
  : T extends ContactsCase.CUSTOM_FIELDS
  ? ContactsCustomFieldsResponseType
  : T extends ContactsCase.CONTACT_ACTIVITIES
  ? ContactsActivitiesResponseType
  : T extends ContactsCase.CONTACT_TAG_SAVE
  ? ContactsTagSaveResponseType
  : T extends ContactsCase.CONTACT_NOTE_SAVE
  ? ContactsNoteSaveResponseType
  : T extends ContactsCase.CONTACT_NOTE_DELETE
  ? ContactsNoteDeleteResponseType
  : T extends ContactsCase.CREATE_CONTACT
  ? CreateContactResponseType
  : T extends ContactsCase.NEW_CONTACT
  ? ContactsContactResponseType
  : T extends ContactsCase.DELETE_CONTACT
  ? DeleteContactResponseType
  : T extends ContactsCase.INTEGRATION_LINKS
  ? ContactsIntegrationLinksType
  : T extends ContactsCase.CONTACTS_PAGE_EMPTY
  ? ContactsPageResponseType
  : never;

export type ContactsMockType = {
  [key in ContactsCase]: ContactsResponseType<key>;
};

export enum ContactsCase {
  CONTACTS_PAGINATION = 0,
  CONTACTS_PAGE = 1,
  CONTACT = 2,
  CALL_HISTORY = 3,
  CUSTOM_FIELDS = 4,
  CONTACT_ACTIVITIES = 5,
  CONTACT_TAG_SAVE = 6,
  CONTACT_NOTE_SAVE = 7,
  CONTACT_NOTE_DELETE = 8,
  CREATE_CONTACT = 9,
  NEW_CONTACT = 10,
  DELETE_CONTACT = 11,
  INTEGRATION_LINKS = 12,
  CONTACTS_PAGE_EMPTY = 13,
}

export const Contacts: ContactsMockType = {
  // https://api.cloudtalk.io/app/numbers/contacts.json
  [ContactsCase.CONTACTS_PAGINATION]: {
    success: true,
    data: {
      ' ': 0,
      '#': 4,
      $: 9,
      '%': 45,
      '&': 46,
      '(': 81,
      '+': 83,
      '-': 109,
      '.': 110,
      '1': 114,
      '2': 120,
      '3': 123,
      '4': 143,
      '5': 149,
      '6': 150,
      '9': 151,
      '?': 152,
      '@': 154,
      A: 156,
      B: 1409,
      C: 2155,
      D: 3206,
      E: 4141,
      F: 4805,
      G: 5197,
      H: 5898,
      I: 6310,
      J: 6675,
      K: 7999,
      L: 8697,
      M: 9630,
      N: 11805,
      O: 12344,
      P: 12613,
      Q: 13400,
      R: 13421,
      S: 14224,
      T: 15219,
      U: 16304,
      V: 16530,
      W: 16975,
      X: 17220,
      Y: 17239,
      Z: 17289,
    },
  },
  // https://api.cloudtalk.io/app/contacts/paginate/all/contact/0.json
  [ContactsCase.CONTACTS_PAGE]: {
    success: true,
    data: [
      {
        id: 1,
        name: 'Mock Contact',
        company: 'Mock Company',
        type: 'contact',
        public_number: '+421800123456',
        _matchingData: {
          ContactNumbers: {
            contact_id: 1,
            country_code_id: 421,
          },
        },
      },
      {
        id: 29432629,
        name: 'John Carrot',
        company: null,
        type: 'contact',
        public_number: '+421944969069',
        _matchingData: {
          ContactNumbers: {
            contact_id: 29432629,
            country_code_id: 421,
          },
        },
      },
    ],
  },
  // https://api.cloudtalk.io/app/contacts/1.json
  [ContactsCase.CONTACT]: {
    success: true,
    data: {
      id: 1,
      company_id: 1,
      user_id: 1,
      name: 'Mock Contact',
      title: '',
      company: 'Mock Company',
      industry: '',
      website: '',
      address: '',
      city: '',
      zip: '',
      state: '',
      country_id: 201,
      favorite_agent: null,
      external_url: null,
      avatar_url: null,
      source: 'internal',
      delete_status: 'none',
      created: '2022-01-01T00:00:00+01:00',
      modified: '2022-01-00T00:00:00+01:00',
      type: 'contact',
      contact_notes: [],
      contact_emails: [],
      country: {
        id: 201,
        name_sk: 'Slovensko',
      },
      contact_numbers: [
        {
          contact_id: 1,
          id: 1,
          public_number: '+421800123456',
          country_code_id: 421,
        },
      ],
      tags: [],
      integration_links: {
        intercom:
          'https://app.intercom.com/a/apps/c8zklw1d/users/623355ecd0573517501a09d7',
        salesforce:
          'https://d1t000000rrggeae-dev-ed.my.salesforce.com/0031t00001EhHpEAAV',
      },
    },
  },
  // https://api.cloudtalk.io/app/call-history/contact/1.json
  [ContactsCase.CALL_HISTORY]: {
    success: true,
    data: [
      {
        id: 135562851,
        src: '144657031004',
        dst: '+17206178069',
        billsec: 5,
        calldate: new Date('2021-07-16T15:55:12+00:00'),
        disposition: 'ANSWERED',
        user_fullname_deleted: null,
        dstnumber: '144657031004',
        type: 'outgoing',
        country_code: '1',
        lastapp: 'Dial',
        callid: '8olcrkksho05oad874cd',
        dstchannel: 'SIP/trunk_voxout-00940e23',
        public_external: '+17206178069',
        public_internal: '+12133256429',
        company_id: 144657,
        recorded: true,
        is_redirected: 0,
        redirected_from: '',
        transferred_from: null,
        call_type: {
          type: 'outgoing',
          typeLabel: 'Outbound',
          icon: 'call_made',
          color: 'green',
        },
        user_name: 144657031004,
        is_voicemail: false,
        is_queue_callback: 0,
        is_business_hour_closed: false,
        note: null,
        qc_attempt_count: null,
        qc_result: null,
        qc_attempt_id: null,
        user_id: 137399,
        contact_id: 254597495,
        click_to_call_request_id: null,
        resolved_by_cdr_id: null,
        call_uuid: '4bdb19a1-ddfe-4f82-aa49-99911a20f68c',
        click_to_call_request: { click_to_call_app: null },
        user: {
          firstname: 'Test',
          lastname: 'Gmail Agent',
          email: 'cloudtalktesting@gmail.com',
        },
        call_number: { internal_name: 'Test number' },
        contact: {
          name: 'Detox Test',
          company: 'CloudTalk s.r.o.',
          type: 'contact',
        },
        CdrTransferFrom: { public_external: null },
        numbers: {
          number: '+17206178069',
          fullNumber: '+17206178069',
          fullNumberDisplay: '+1 720-617-8069',
        },
        agents: {
          agentName: 'Test Gmail Agent',
          groupName: null,
          publicCallerId: '+1 213-325-6429',
        },
        resolved_past: null,
        resolved_active: null,
      },
    ],
  },
  // https://realtime.cloudtalk.io/app/contacts/1/custom-fields
  [ContactsCase.CUSTOM_FIELDS]: {
    success: true,
    data: {
      custom_fields: [{ title: 'Test Field', value: null }],
    },
  },
  // https://api.cloudtalk.io/app/contact-activities/index/1/100.json
  [ContactsCase.CONTACT_ACTIVITIES]: {
    success: true,
    data: [
      {
        activity_author: null,
        activity_date: '2022-05-02T02:30:46+00:00',
        external_id: 103127300007320,
        external_service: 'intercom',
        external_url:
          'https://app.intercom.com/a/apps/c8zklw1d/inbox/inbox/all/conversations/103127300007320',
        type: 'conversation',
      },
    ],
  },
  // https://realtime.cloudtalk.io/app/contacts/contact/tags
  [ContactsCase.CONTACT_TAG_SAVE]: {
    success: true,
    data: [null],
  },
  // https://realtime.cloudtalk.io/app/contacts/contact/note
  [ContactsCase.CONTACT_NOTE_SAVE]: {
    success: true,
    data: {
      id: 1,
    },
  },
  // https://realtime.cloudtalk.io/app/contacts/contact/note/1
  [ContactsCase.CONTACT_NOTE_DELETE]: {
    success: true,
  },
  // https://realtime.cloudtalk.io/app/contacts/contact
  [ContactsCase.CREATE_CONTACT]: {
    success: true,
    data: {
      id: 2,
    },
  },
  // https://api.cloudtalk.io/app/call-history/contact/2.json
  [ContactsCase.NEW_CONTACT]: {
    success: true,
    data: {
      id: 2,
      company_id: 1,
      user_id: 1,
      name: 'Test Automation',
      title: 'Automation Title',
      company: 'Automation Company',
      industry: 'Automation Industry',
      website: 'Automation Website',
      address: 'Automation Street',
      city: 'Automation City',
      zip: '00000',
      state: '',
      country_id: 201,
      favorite_agent: 16463,
      external_url: null,
      avatar_url: null,
      source: 'internal',
      delete_status: 'none',
      created: '2022-01-01T00:00:00+01:00',
      modified: '2022-01-00T00:00:00+01:00',
      type: 'contact',
      contact_notes: [],
      contact_emails: [
        {
          contact_id: 2,
          email: 'mobile@automation.com',
        },
      ],
      country: {
        id: 201,
        name_sk: 'Slovensko',
      },
      contact_numbers: [
        {
          contact_id: 2,
          id: 1,
          public_number: '+421949123456',
          country_code_id: 421,
        },
      ],
      tags: [],
      integration_links: {},
    },
  },
  // https://realtime.cloudtalk.io/app/contacts/contact/1
  [ContactsCase.DELETE_CONTACT]: {
    success: true,
    data: {
      id: 1,
    },
  },
  // https://realtime.cloudtalk.io/app/numbers/contacts/1.json
  [ContactsCase.INTEGRATION_LINKS]: {
    data: [
      {
        company_id: 100552,
        contact: {
          company: 'test',
          contact_emails: [
            { contact_id: 29394837, email: '+421221009932@cloudtalk.io' },
          ],
          external_url: null,
          id: 29394837,
          integration_links: {
            zoho: 'https://crmplus.zoho.eu/crm/tab/Contacts/216888000002932277',
            hubspot: 'https://app.hubspot.com/contacts/6128712/contact/129053',
          },
          name: 'Erik Cypress 518445',
          tags: [
            {
              name: 'zohoXX',
              ContactsTags: {
                contact_id: 29394837,
                created: '2022-01-27T10:45:00+01:00',
                entity: 'contact',
                external_url:
                  'https://crmplus.zoho.eu/crm/tab/Contacts/290023000000830336',
                id: 76234881,
                integration_id: 5577,
                integration_name: 'zoho',
                intgr_id: null,
                service_key: '290023000000830336',
                tag_id: 96899,
              },
              _joinData: {
                contact_id: 29394837,
                created: '2022-01-27T10:45:00+01:00',
                entity: 'contact',
                external_url:
                  'https://crmplus.zoho.eu/crm/tab/Contacts/290023000000830336',
                id: 76234881,
                integration_id: 5577,
                integration_name: 'zoho',
                intgr_id: null,
                service_key: '290023000000830336',
                tag_id: 96899,
              },
            },
          ],
          type: 'contact',
        },
        contact_id: 29394837,
        country_code_id: 421,
        country_id: 201,
        created: '2021-09-06T11:30:00+02:00',
        id: 15767625,
        modified: '2021-09-06T11:30:00+02:00',
        public_number: '+421221009932',
      },
    ],
    pagination: {
      page_count: 1,
      current_page: 1,
      has_next_page: false,
      has_prev_page: false,
      count: 25,
      limit: null,
    },
    success: true,
  },

  // https://api.cloudtalk.io/app/contacts/paginate/all/contact/0.json
  [ContactsCase.CONTACTS_PAGE_EMPTY]: {
    success: true,
    data: [],
  },
};

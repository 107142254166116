import { animate, style, transition, trigger } from '@angular/animations';

export const verticalShow = trigger('verticalShow', [
  transition(':enter', [
    style({ transform: 'translateY(100%)', opacity: 0.5 }),
    animate('0.5s ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0)', opacity: 1 }),
    animate(
      '0.5s ease-out',
      style({ transform: 'translateY(100%)', opacity: 0.5 }),
    ),
  ]),
]);

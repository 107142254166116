import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ConnectionState } from '@cloudtalk/sip-service';
import { lastValueFrom, Observable } from 'rxjs';
import { OptionDialogComponent } from 'ui-components';

import { LoggerUtil } from '../../_shared/utils/logger.util';
import { AgentStatusActAs } from '../interfaces/agent-status/agent-status.interface';
import { AgentStatusService } from '../services/agent-status/agent-status.service';
import { PhoneStatusService } from '../services/phone-status.service';
import { StatusService } from '../services/status.service';

@Injectable()
export class StatusGuard implements CanActivate {
  constructor(
    private statusService: StatusService,
    private phoneStatus: PhoneStatusService,
    private agentStatusService: AgentStatusService,
    private matDialog: MatDialog,
    private router: Router,
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this.statusService.callingConnectionState === ConnectionState.UNCONNECTED
    ) {
      if (!this.phoneStatus?.status?.mediaStream) {
        this.router.navigate(['p/dialpad']).catch(() => {});
        return false;
      }
      return this.switchStatus();
    }
    return true;
  }

  private async switchStatus(): Promise<boolean> {
    const dialogResult = await lastValueFrom(
      this.matDialog
        .open(OptionDialogComponent, {
          width: '356px',
          data: {
            title: $localize`You are offline`,
            content: $localize`Some features may be unavailable. Switch online?`,
            leftButton: {
              name: $localize`Cancel`,
            },
            rightButton: {
              name: $localize`Yes`,
            },
            deleteChoosen: false,
          },
          panelClass: 'mat-dialog',
        })
        .afterClosed(),
    ).catch(err => {
      LoggerUtil.info(
        '[StatusGuard]: switchStatus() MatDialog:afterClosed()',
        err,
      );
    });

    if (dialogResult) {
      const onlineStatus = this.agentStatusService.agentSystemStatuses.find(
        status => status.act_as === AgentStatusActAs.ONLINE,
      );
      this.agentStatusService.changeAgentStatus(onlineStatus).catch(err => {
        LoggerUtil.error(
          '[StatusGuard]: switchStatus() changeAgentStatus',
          err,
          err,
        );
      });
      return true;
    }
    return Promise.reject();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

/*
 * Prida pocitadlo na sekundy
 * result: 00:23...00:24...
 * Usage:
 *   value | clockTicking:default
 * Example:
 *   {{ 2 |  clockTicking}}
 */
@Pipe({ name: 'clockTicking' })
export class ClockTickingPipe implements PipeTransform {
  transform(value, def?: string): any {
    if (value < 0) {
      return def ? def : '-';
    }
    if (value === null) {
      value = 0;
    }

    const pad = '00';
    value = parseInt(value, 10);
    const min = Math.floor(value / 60).toString();
    const sec = (value % 60).toString();

    const paddedMin = pad.substring(0, pad.length - min.length) + min;
    const paddedSec = pad.substring(0, pad.length - sec.length) + sec;
    return paddedMin + ':' + paddedSec;
  }
}

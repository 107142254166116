import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SnackbarIdsEnum } from 'test-automation';

import { SnackBarTypes } from '../../../_shared/components/snack-bar-wrapper/snack-bar-errors';

export interface SnackBarData {
  snackType: SnackBarTypes;
  message: string;
  closeHidden?: boolean;
}

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  @Input() data: SnackBarData;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  snackbarIdsEnum = SnackbarIdsEnum;
}

import { Injectable } from '@angular/core';

import { VoiceProviderService } from './services/audio/voice-provider.service';
import { CustomCtiService } from '../_cti-integrations/custom-cti.service';
import { HubspotService } from '../_cti-integrations/hubspot/hubspot.service';
import { PipedriveService } from '../_cti-integrations/pipedrive/pipedrive.service';
import { SalesforceService } from '../_cti-integrations/salesforce/salesforce-service';
import { ZendeskCtiService } from '../_cti-integrations/zendesk/zendesk-cti.service';
import { ElectronService } from './services/electron/electron.service';

type CtiService =
  | HubspotService
  | SalesforceService
  | ZendeskCtiService
  | PipedriveService
  | CustomCtiService;

@Injectable()
export class AppService {
  #ctiService: CtiService;
  constructor(
    private electronService: ElectronService,
    private voiceProviderService: VoiceProviderService,
  ) {}

  get ctiService(): CtiService {
    return this.#ctiService;
  }

  set ctiService(ctiService: CtiService) {
    this.#ctiService = ctiService;
  }

  focusApp(): void {
    if (
      this.electronService.isElectronApp &&
      this.electronService.ipcRenderer
    ) {
      this.electronService.ipcRenderer.send('show-app');
    } else {
      window.focus();
    }
  }

  notifyAboutAlreadyRegistered(): void {
    // base URL where static file is hosted
    const baseUrl = window.location.origin;
    window.location.assign(
      `${baseUrl}/static/already-registered.html?origin=${encodeURIComponent(
        window.location.origin,
      )}`,
    );
  }

  reloadApp(url = window.location.href): void {
    window.location.assign(url);
  }

  muteNotifications(): void {
    this.voiceProviderService.stopAudioOfNotification();
  }
}

import {
  PhoneNumberFormat as PNF,
  PhoneNumberUtil,
} from 'google-libphonenumber';
import { parseInt } from 'lodash-es';

export enum PhoneNumberFormat {
  E164,
  INTERNATIONAL,
  NATIONAL,
  RFC3966,
}

export class PhoneNumberUtilService {
  /**
   * Sformatuje cislo do daneho formatu
   *
   * @param value
   * @param countryCode
   * @param format
   * @returns {string}
   */
  static format(value: string, countryCode: string, format?: number): any {
    const phoneUtil = PhoneNumberUtil.getInstance();

    // E164:0, INTERNATIONAL:1, NATIONAL:2, RFC3966:3
    let phoneFormat;
    switch (format) {
      case PhoneNumberFormat.E164:
        phoneFormat = PNF.E164;
        break;
      case PhoneNumberFormat.INTERNATIONAL:
        phoneFormat = PNF.INTERNATIONAL;
        break;
      case PhoneNumberFormat.NATIONAL:
        phoneFormat = PNF.NATIONAL;
        break;
      case PhoneNumberFormat.RFC3966:
        phoneFormat = PNF.RFC3966;
        break;
      default:
        phoneFormat = PNF.NATIONAL;
        break;
    }

    let region;

    // ak je country code ZZ tak cislo musi byt v Internation formate
    if (countryCode === 'ZZ') {
      region = 'ZZ';
    } else {
      region = phoneUtil.getRegionCodeForCountryCode(parseInt(countryCode, 10));
    }
    try {
      const phoneNumber = phoneUtil.parse(value.toString(), region);
      return phoneUtil.isValidNumber(phoneNumber)
        ? phoneUtil.format(phoneNumber, phoneFormat)
        : value;
    } catch (e) {
      return value;
    }
  }

  public static isValidNumber(value: string, countryCallingCode = 'ZZ') {
    const phoneUtil = PhoneNumberUtil.getInstance();

    if (this.isBrazilianOldFormat(value)) {
      return true;
    }
    const phoneNumber = phoneUtil.parse(value.toString(), countryCallingCode);
    return phoneUtil.isValidNumber(phoneNumber);
  }

  public static formatOutOfCountryCallingNumber(value, countryCode = 'ZZ') {
    const phoneUtil = PhoneNumberUtil.getInstance();

    let region;
    // ak je country code ZZ tak cislo musi byt v Internation formate
    if (countryCode === 'ZZ') {
      region = 'ZZ';
    } else {
      region = phoneUtil.getRegionCodeForCountryCode(parseInt(countryCode, 10));
    }

    try {
      const phoneNumber = phoneUtil.parse(value.toString(), region);
      return phoneUtil.isValidNumber(phoneNumber)
        ? phoneUtil.formatOutOfCountryCallingNumber(phoneNumber, region)
        : value;
    } catch (e) {
      return value;
    }
  }

  /**
   * Vrati pre country code skratku regionu napr. 421 -> SK
   *
   * @param number - cislo v medzinarodnom formate
   * @param ignoreIfInvalid
   * @returns {string}
   */
  static countryCodeFromNumber(
    number: string,
    ignoreIfInvalid = false,
  ): number {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneUtil.parse(number.toString(), 'ZZ');

      if (ignoreIfInvalid) {
        return phoneNumber.getCountryCode();
      }
      return phoneUtil.isValidNumber(phoneNumber)
        ? phoneNumber.getCountryCode()
        : null;
    } catch (e) {
      return null;
    }
  }

  static regionCodeFromNumber(
    number: string,
    ignoreIfInvalid: boolean = false,
  ): string {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneUtil.parse(number.toString(), 'ZZ');

      if (ignoreIfInvalid) {
        return phoneUtil.getRegionCodeForNumber(phoneNumber);
      }

      return phoneUtil.isValidNumber(phoneNumber)
        ? phoneUtil.getRegionCodeForNumber(phoneNumber)
        : null;
    } catch (e) {
      return null;
    }
  }

  static isBrazilianOldFormat(value: string): boolean {
    // eslint-disable-next-line no-useless-escape
    const oldFormatRegex = new RegExp(/\(?\d{2,}\)?[ -]?\d{4,}[\-\s]?\d{4}/g);
    const match = value.match(oldFormatRegex);
    const digits = value.replace(/[^0-9]/g, '');
    return value.startsWith('+55') && digits.length === 12 && match != null;
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

export interface BottomSheetSimpleSelectItem {
  id: string | number;

  /**
   * Country code associated with the item. If this is `undefined`, number will be used to get the country code.
   *
   * @type {string | undefined}
   * @example "sk"
   */
  countryCode?: string;

  /**
   * The primary label for the item. If this is `undefined`, number will be shown instead.
   *
   * @type {string | undefined}
   * @example "John Doe"
   */
  mainLabel?: string;

  /**
   * A descriptor for the item. If this is `undefined`, the descriptor will not be shown.
   *
   * @type {string | undefined}
   * @example "CloudTalk"
   */
  subLabel?: string;

  /**
   * The phone number is used as a fallback if the `mainLabel` or `countryCode` is not set.
   *
   * @type {string | undefined}
   * @example "+421 123 456 789"
   */
  number?: string;
}

interface BottomSheetSimpleSelectData {
  items: BottomSheetSimpleSelectItem[];
  title: string;
  emptyText: string;
  displayFlag: boolean;
}

@Component({
  templateUrl: './bottom-sheet-simple-select.component.html',
  styleUrls: ['./bottom-sheet-simple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetSimpleSelectComponent {
  selectedId: string | number;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: BottomSheetSimpleSelectData,
    private bottomSheet: MatBottomSheetRef<BottomSheetSimpleSelectComponent>,
  ) {}

  select(selectedItem: BottomSheetSimpleSelectItem): void {
    this.selectedId = selectedItem.id;
    this.close(selectedItem);
  }

  close(result: BottomSheetSimpleSelectItem): void {
    setTimeout(() => this.bottomSheet.dismiss(result), result ? 500 : 0);
  }
}

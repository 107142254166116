import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import moment from 'moment';
import {
  Observable,
  filter,
  map,
  finalize,
  of,
  concatMap,
  take,
  catchError,
} from 'rxjs';

import { UserInfo } from '../../_core/interfaces/user-info.interface';
import { User } from '../../_core/models/user';
import { CallingService } from '../../_core/services/calling/calling.service';
import { EndpointService } from '../../_core/services/networking/endpoint.service';
import { CesSurveyComponent } from './ces-survey.component';

export const CES_SURVEY_ROUTE_ITERATIONS = 10;

@Injectable()
export class CesSurveyService {
  private routerNavigationCount = 0;

  constructor(
    private readonly endpointService: EndpointService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly router: Router,
    private readonly callingService: CallingService,
  ) {}

  checkCesSurvey(user: User): Observable<number | undefined> {
    return this.shouldShowCesSurvey(user).pipe(
      concatMap((showNpsSurvey: boolean) => {
        return showNpsSurvey
          ? this.trackRouterEvent(CES_SURVEY_ROUTE_ITERATIONS).pipe(take(1))
          : of(undefined);
      }),
    );
  }

  showCesSurvey(): void {
    this.bottomSheet.open(CesSurveyComponent, {
      panelClass: 'ces-survey-sheet',
      disableClose: true,
    });
  }

  setNextSurveyDate(daysCount: number): Observable<boolean> {
    const surveyDate = moment().add(daysCount, 'days').toISOString();

    return this.endpointService
      ._put_realtime<boolean, { surveyDate: string }>('user/info/phone', {
        surveyDate,
      })
      .pipe(map(() => true));
  }

  trackRouterEvent(routeIterationsToCheck: number): Observable<number> {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(() => (this.routerNavigationCount += 1)),
      filter(
        (count: number) =>
          count >= routeIterationsToCheck && !this.callingService.isCallEvent,
      ),
      finalize(() => (this.routerNavigationCount = 0)),
    );
  }

  private shouldShowCesSurvey(user: User): Observable<boolean> {
    if (
      !user?.email?.includes('@cloudtalk.io') &&
      user?.company?.status?.includes('live')
    ) {
      return this.endpointService
        ._get_realtime<UserInfo>('user/info/phone')
        .pipe(
          map((res: UserInfo) =>
            res?.nextSurveyDate
              ? !moment().isBefore(res.nextSurveyDate)
              : false,
          ),
          // user has no records, start tracking
          catchError(() => of(true)),
        );
    }
    return of(false);
  }
}

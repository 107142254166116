import { BrowserInfoType } from '../types/browser-info.type';
import { BrowserSpecsType } from '../types/browser-specs.type';

export class Browser {
  static info(): BrowserInfoType {
    const ua = navigator.userAgent;
    let tem: string[],
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || null };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return { name: M[0], version: M[1] };
  }

  static specs(): BrowserSpecsType {
    const info = Browser.info();
    return {
      name: info.name,
      version: info.version ? parseInt(info.version, 10) : null,
    };
  }
}

import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'ct-flag-icon',
  templateUrl: './ct-flag-icon.component.html',
  styleUrls: ['./ct-flag-icon.component.scss'],
})
export class CtFlagIconComponent {
  @Input() width: number;
  @Input() country: string;
  @Input() isRounded: boolean;
  @Input() radiusSize = 0;
}

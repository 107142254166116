import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { SelectBodyComponent } from './select-body/select-body.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
})
export class SelectComponent implements OnDestroy, AfterContentInit {
  private static openedDialogs = new Set<SelectComponent>([]);

  @ViewChild('bodyContainer', { read: ElementRef, static: false })
  bodyRef: ElementRef;
  @ViewChild('bodyContent', { static: true }) contentTemplate: TemplateRef<any>;

  @ContentChild(SelectBodyComponent, { static: true })
  bodyComponent: SelectBodyComponent;

  @Input() disabled = false;
  @Input() isAutocomplete: boolean;
  @Input() innerInput = true;
  @Input() standalone = false;

  @Output() readonly closed = new EventEmitter<void>();
  @Output() readonly opened = new EventEmitter<void>();

  public isOpen = false;
  private isDestroyed = false;
  private closeSub = new Subject<void>();

  private bottomSheetRef: MatBottomSheetRef<any> | null = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matBottomSheetService: MatBottomSheet,
    private elRef: ElementRef,
  ) {}

  private static closeAllDialogs() {
    SelectComponent.openedDialogs.forEach(dialog => dialog.close());
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
    this.close();
    this.closeSub.next();
    this.closeSub.complete();
  }

  ngAfterContentInit(): void {
    this.bodyComponent.close
      .pipe(takeUntil(this.closeSub))
      .subscribe(() => this.matBottomSheetService.dismiss());
  }

  toggle($event: MouseEvent): void {
    $event.stopImmediatePropagation();
    if (!this.disabled) {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    }
  }

  close(): void {
    if (this.bottomSheetRef) {
      this.bottomSheetRef = null;
      this.matBottomSheetService.dismiss();
    }
  }

  private open(): void {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    SelectComponent.closeAllDialogs();
    SelectComponent.openedDialogs.add(this);

    this.bottomSheetRef = this.matBottomSheetService.open(this.contentTemplate);

    this.bottomSheetRef
      .afterDismissed()
      .pipe(first())
      .subscribe(() => this.afterClose());

    this.focusInput();
    this.opened.emit();
  }

  private afterClose() {
    this.isOpen = false;
    if (this.bottomSheetRef) {
      this.bottomSheetRef = null;
      this.matBottomSheetService.dismiss();
    }
    SelectComponent.openedDialogs.delete(this);
    this.closed.emit();
    if (!this.isDestroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }

  private focusInput() {
    setTimeout(() => {
      const input = this.elRef?.nativeElement?.querySelector('input');
      if (input) {
        input.focus();
      }
    });
  }
}

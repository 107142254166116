<mat-tab-group
  (selectedTabChange)="switchMissedCalls($event)"
  [disableRipple]="true"
  mat-stretch-tabs>
  <div *ngFor="let tabName of tabNames">
    <mat-tab>
      <ng-template mat-tab-label>
        <span
          [appTestId]="
            callHistoryScreenIdsEnum.CALL_HISTORY_MISSED_CALLS_SUB_FILTER +
            tabName
          "
          i18n
          >{{ tabName }}
        </span>
      </ng-template>
    </mat-tab>
  </div>
</mat-tab-group>

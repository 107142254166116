import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { DirectivesModule } from '../../directives/directives.module';
import { KeypadComponent } from './keypad.component';
import { CtIconComponent } from '../ct-icon/ct-icon.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    DirectivesModule,
    CtIconComponent,
  ],
  declarations: [KeypadComponent],
  exports: [KeypadComponent],
})
export class KeypadModule {}

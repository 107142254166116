import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import {
  ActivitiesArray,
  Call,
  CallContact,
  CallEvent,
  ConnectionState,
  CueCardModel,
} from '@cloudtalk/sip-service';
import {
  BehaviorSubject,
  concat,
  merge,
  Observable,
  of,
  Subject,
  takeWhile,
  timer,
} from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

import { OsEnum } from '../../_core/enums/os.enum';
import { AssignContactEvent } from '../../_core/interfaces/assign-contact-event.interface';
import { ContactActivities } from '../../_core/models/activity';
import { AgentService } from '../../_core/services/agent.service';
import { AnalyticsService } from '../../_core/services/analytics.service';
import { VoiceProviderService } from '../../_core/services/audio/voice-provider.service';
import { BrowserNotificationService } from '../../_core/services/browser-notification.service';
import { CallMonitorService } from '../../_core/services/call-monitor.service';
import {
  CallingService,
  CallingStateEnum,
} from '../../_core/services/calling/calling.service';
import { EnvironmentService } from '../../_core/services/environment.service';
import { EndpointService } from '../../_core/services/networking/endpoint.service';
import { SocketService } from '../../_core/services/networking/socket.service';
import { RealtimeTriggersService } from '../../_core/services/realtime-triggers.service';
import { StatusService } from '../../_core/services/status.service';
import { UserSettingsActionHandlerService } from '../../_core/services/user-settings-action-handler.service';
import { ClockTickingPipe } from '../../_shared/pipes/clock-ticking/clock-ticking.pipe';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { VoicemailDropSelectDialogComponent } from '../../lib/components/voicemail-drop-select-dialog/voicemail-drop-select-dialog.component';
import {
  CallNoteService,
  NoteChanges,
} from '../../ui-components/_overlays/call-note-overlay/call-note.service';
import {
  CallTaggingService,
  TagChanges,
} from '../../ui-components/_overlays/call-tagging/call-tagging.service';
import { ActivitiesService } from '../../ui-components/activities/activities.service';
import { ContactListItem } from '../contacts/contacts.model';
import {
  RecordItem,
  VoicemailDropsService,
} from '../setting/settings-page/voicemail-drops/voicemail-drops.service';
import {
  ConferenceControlEvent,
  ControllingActions,
  ControllingEvent,
  DtmfControlEvent,
  InviteAgentControlEvent,
  MinimizeControlEvent,
  TransferControlEvent,
  WarmTransferControlEvent,
} from './call-shared-types';

@Injectable()
export class CallService {
  call: Call;

  hasSipConnection = true;

  // TODO: is it good practise?
  calling = {
    duration: null,
  };
  isCalling = false;

  cueCards$: BehaviorSubject<CueCardModel[]> = new BehaviorSubject<
    CueCardModel[]
  >([]);

  // this is for controlling through shortcuts, dialpad etc... (every callComponent will listening for this events)
  hangUp$: Subject<ControllingEvent> = new Subject<ControllingEvent>();
  answer$: Subject<ControllingEvent> = new Subject<ControllingEvent>();
  voicemailDrop$: Subject<ControllingEvent> =
    new Subject<TransferControlEvent>();
  warmTransferShortcut$: Subject<void> = new Subject<void>();
  // controlling through overlays
  dtmf$: Subject<DtmfControlEvent> = new Subject<DtmfControlEvent>();
  transfer$: Subject<TransferControlEvent> =
    new Subject<TransferControlEvent>();
  warmTransfer$: Subject<WarmTransferControlEvent> =
    new Subject<WarmTransferControlEvent>();
  conference$: Subject<ConferenceControlEvent> =
    new Subject<ConferenceControlEvent>();
  inviteAgent$: Subject<InviteAgentControlEvent> =
    new Subject<InviteAgentControlEvent>();
  // hiding call from screen
  minimize$: BehaviorSubject<MinimizeControlEvent> =
    new BehaviorSubject<MinimizeControlEvent>({ minimize: false });
  assignContact$: Subject<AssignContactEvent> =
    new Subject<AssignContactEvent>();

  createConferenceCall$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  transferContact$: BehaviorSubject<ContactListItem> =
    new BehaviorSubject<ContactListItem>(null);

  voicemailDialogRef: MatDialogRef<VoicemailDropSelectDialogComponent>;

  private defaultDocTitle = this.title.getTitle();
  private agentList = [];

  constructor(
    private endpointService: EndpointService,
    private title: Title,
    private agentService: AgentService,
    private clockTicking: ClockTickingPipe,
    private activitiesService: ActivitiesService,
    private callTaggingService: CallTaggingService,
    private callNoteService: CallNoteService,
    private callingService: CallingService,
    private statusService: StatusService,
    private voiceProviderService: VoiceProviderService,
    private userSettingsActionHandlerService: UserSettingsActionHandlerService,
    private cmService: CallMonitorService,
    private browserNotificationService: BrowserNotificationService,
    private realtimeTriggersService: RealtimeTriggersService,
    private voicemailDropsService: VoicemailDropsService,
    private analyticsService: AnalyticsService,
    private socketService: SocketService,
    private environmentService: EnvironmentService,
  ) {
    this.statusService.callingConnectionState$.subscribe(
      (connectionState: ConnectionState) => {
        this.hasSipConnection = connectionState !== ConnectionState.UNCONNECTED;
      },
    );
    this.agentService
      .getAgentList('agentList')
      .subscribe(result => (this.agentList = result.agentList));
  }

  /**
   * Returning the subscription of setting call by CallType
   * */
  loadSettings(callType) {
    return this.endpointService._get(`calls/settings/${callType}`);
  }

  /**
   * Returning Promise of correct subscription.
   * If we have contactId we will return endpoint for API with contactId
   * If we have number we will return endpoint for API with numbers which will return all contacts for this number
   * @param {Object}
   * */
  getContacts(contactId?: string, number?: string): Observable<CallContact[]> {
    if (contactId === 'null') {
      LoggerUtil.error('[CallService]: getContacts failed', {
        extraContent: { call: this.call },
      });
      return of([]);
    }
    if (contactId != null && contactId !== '') {
      return this.endpointService._get(`numbers/by-contact-id/${contactId}`);
    }

    if (number != null && number !== '') {
      const encodedNumber = encodeURIComponent(number);
      return this.endpointService._get(`numbers/contacts/${encodedNumber}`);
    }

    return of([]);
  }

  get isMinimized(): boolean {
    return this.minimize$.getValue().minimize;
  }

  /**
   * Will make request for end-after-call-work
   * */
  endAfterCallWork(callUuid): void {
    this.endpointService
      ._get(`calls/end-after-call-work/${callUuid}`)
      .subscribe();
  }

  /**
   * Loading activities of contacts
   *
   * @param {Array<any>} contacts
   * @return ActivitiesArray
   * */
  loadContactActivities(
    contacts: CallContact[],
  ): Observable<ActivitiesArray<ContactActivities>> {
    return contacts?.length > 0
      ? this.activitiesService.loadActivities(contacts)
      : of(new ActivitiesArray<ContactActivities>());
  }

  /**
   * Will return timer which is triggered every second
   * */
  countCallDuration(
    call: Call,
    durationKey: 'dialing_duration' | 'calling_duration',
  ) {
    return timer(1000, 1000).pipe(tap(() => (call.info[durationKey] += 1)));
  }

  countAfterCallWorkTime(call: Call) {
    return timer(1000, 1000).pipe(
      map(() => call.settings.after_call_work - 1),
      tap(t => (call.settings.after_call_work = t < 0 ? 0 : t)),
      takeWhile(t => t >= 0),
    );
  }

  /**
   * Will trigger updating document title and call title
   * */
  updateTitles(call: Call): void {
    this.setCallTitle(call);
    this.setDocumentTitle(call);
  }

  /**
   * Handling changes of note, tags, contact
   * */
  subscribeToChanges(call: Call) {
    return merge(
      this.callNoteService.notesChanges.pipe(
        tap((noteChanges: NoteChanges) => {
          if (noteChanges.id === call.info.call_uuid) {
            call.note = noteChanges.note;
          }
        }),
      ),
      this.callTaggingService.tagsChanges$.pipe(
        tap((tagChanges: TagChanges) => {
          if (tagChanges.id === call.info.call_uuid) {
            call.tags = tagChanges.activeTags;
            if (call.settings.is_mandatory_tagging_enabled) {
              call.settings.is_mandatory_tagging_enabled = call.tags.length < 1;
            }
          }
        }),
      ),
      this.handleContactChanges(call),
    );
  }

  handleNotification() {
    return this.browserNotificationService.onClick$.pipe(
      tap(() => {
        this.userSettingsActionHandlerService.onNotificationClick(() => {
          this.answer$.next({ action: ControllingActions.ANSWER });
        });
      }),
    );
  }

  /**
   * Listening for call controlling events
   * */
  subscribeControlling(call: Call): Observable<ControllingEvent> {
    return merge(
      this.dtmf$.pipe(
        tap(
          (controllingEvent: DtmfControlEvent) =>
            (controllingEvent.action = ControllingActions.DTMF),
        ),
        map((controllingEvent: DtmfControlEvent) =>
          controllingEvent.call_uuid === call.info.call_uuid
            ? controllingEvent
            : null,
        ),
      ),
      this.transfer$.pipe(
        tap(
          (controllingEvent: TransferControlEvent) =>
            (controllingEvent.action = ControllingActions.TRANSFER),
        ),
        map((controllingEvent: TransferControlEvent) =>
          controllingEvent.call_uuid === call.info.call_uuid
            ? controllingEvent
            : null,
        ),
      ),
      this.warmTransfer$.pipe(
        tap(
          (controllingEvent: WarmTransferControlEvent) =>
            (controllingEvent.action = ControllingActions.WARM_TRANSFER),
        ),
        map((controllingEvent: WarmTransferControlEvent) =>
          controllingEvent.call_uuid === call.info.call_uuid
            ? controllingEvent
            : null,
        ),
      ),
      this.conference$.pipe(
        map((controllingEvent: ConferenceControlEvent) => {
          console.warn('conference event', controllingEvent);
          return controllingEvent.call_uuid === call.info.call_uuid
            ? { action: ControllingActions.CONFERENCE, ...controllingEvent }
            : null;
        }),
      ),
      this.inviteAgent$.pipe(
        tap(
          (controllingEvent: InviteAgentControlEvent) =>
            (controllingEvent.action = ControllingActions.INVITE_AGENT),
        ),
        map((controllingEvent: InviteAgentControlEvent) =>
          controllingEvent.call_uuid === call.info.call_uuid
            ? controllingEvent
            : null,
        ),
      ),
      this.hangUp$.pipe(
        map(() => ({
          action: ControllingActions.HANGUP,
        })),
      ),
      this.answer$.pipe(map(() => ({ action: ControllingActions.ANSWER }))),
      this.voicemailDrop$.pipe(
        map((controllingEvent: ControllingEvent) => ({
          ...controllingEvent,
          action: ControllingActions.VOICEMAIL_DROP,
        })),
      ),
      this.minimize$.pipe(
        tap((controllingEvent: MinimizeControlEvent) => {
          controllingEvent.action = ControllingActions.MINIMIZE;
          // update for good recognition of analytics
          this.analyticsService.callIsMinimized = controllingEvent.minimize;
        }),
      ),
    );
  }

  // TODO: can I do it more simple?
  onRinging(call: Call): string {
    this.userSettingsActionHandlerService.onRingingEvent(call, action => {
      if (action === 'answer') {
        if (call.isRinging) {
          call.accept().catch((error: Error) =>
            LoggerUtil.info('Call: answer inbound failed', {
              extraContent: { error },
            }),
          );
        } else if (call.isMonitoringInvitationRinging) {
          this.cmService.acceptInvitation();
        }
      }
    });
    const remoteUser = call.remoteUser;
    if (remoteUser) {
      remoteUser.splice(1, 0, $localize`via`);
      const groupName = call?.info?.queueName;

      if (groupName) {
        const osTypeEnum = this.environmentService.getOS();
        if (osTypeEnum === OsEnum.WINDOWS) {
          remoteUser.push(`\n ${groupName}`);
        } else {
          remoteUser.push(`, group ${groupName}`);
        }
      }
    }
    return remoteUser ? remoteUser.join(' ') : null;
  }

  notificationClicked() {
    this.userSettingsActionHandlerService.onNotificationClick(() => {
      this.answer$.next({ action: ControllingActions.ANSWER });
    });
  }

  makeWarmTransfer(number: string, originalCall: Call): Promise<Call> {
    const customHeaders = [
      `CT-ORIGINAL-UUID: ${originalCall.info.call_uuid}`,
      `CT-OUT-TRANSFER: ${originalCall.isOutgoing ? 1 : 0}`,
      `CT-REFERRED-CONTACT-ID: ${originalCall.info.contactId}`,
      `CT-REFERRED-NUMBER: ${originalCall.info.number}`,
    ];
    return this.callingService.callOutsideScope({ number, customHeaders });
  }

  toggleRinging(play: boolean): void {
    this.voiceProviderService.toggleRinging(play);
  }

  toggleNotification(show?: boolean, title?: string): void {
    if (show) {
      this.browserNotificationService.show({ title });
      return;
    }
    this.browserNotificationService.close();
  }

  getTargetUri(target: string): string {
    return `sip:${target}@${this.callingService.sipDomain}`;
  }

  dropVoicemail(call: Call, selectedVoicemailDrop?: RecordItem) {
    if (!this.call.isOutgoing && !this.call.info.isGeneratedCall) {
      return;
    }
    if (
      !this.voicemailDropsService.isEnabled ||
      !call.info.call_uuid ||
      call.info.droppedVoicemail !== null
    ) {
      return;
    }
    if (!selectedVoicemailDrop) {
      selectedVoicemailDrop = this.voicemailDropsService.records.find(
        record => record.isDefault,
      );
    }
    if (!selectedVoicemailDrop) {
      return;
    }
    if (this.voicemailDialogRef) {
      this.voicemailDialogRef.close(null);
    }

    call.info.droppedVoicemail = selectedVoicemailDrop;
    const note = `Voicemail "${selectedVoicemailDrop.internal_name}" dropped.`;
    this.callNoteService
      .addNote(call.info.call_uuid, call.info.call_uuid, null, 'call', { note })
      .subscribe();

    this.voicemailDropsService
      .emitVoicemailDrop(
        call.info.call_uuid,
        selectedVoicemailDrop,
        call.info.isInternalCall,
      )
      .subscribe();
  }

  getAgentByExtension(extension) {
    return this.agentList?.find(agent => agent.extension === extension);
  }

  handleCueCards(call: Call): void {
    this.cueCards$.next(call.cueCards || []);

    this.socketService.on('cue-cards', (cueCard: CueCardModel) => {
      if (call.info.call_uuid === cueCard.call_uuid) {
        const cueCards = this.cueCards$.getValue();
        cueCards.push(cueCard);
        call.cueCards = cueCards;

        this.cueCards$.next(cueCards);
      }
    });
  }

  triggerConferenceCall(fullNumber: string): void {
    if (
      !this.call &&
      this.callingService.activeCalls.length > 0 &&
      this.callingService.callingStateSource$.getValue() !==
        CallingStateEnum.NO_CALL
    ) {
      this.hangUp$.next({ action: ControllingActions.HANGUP });
      this.callingService.callingStateSource$.next(CallingStateEnum.NO_CALL);
      return;
    }
    this.conference$.next({
      call_uuid: this.call.info.call_uuid,
      externalNumber: fullNumber,
    });
    this.minimize$.next({ minimize: false });
  }

  async conferenceInit(call: Call, number: string): Promise<Call> {
    const customHeaders = [`Contact: ${call.session._contact}`];
    return this.callingService
      .callOutsideScope({ number, customHeaders })
      .then((newCall: Call) => {
        call.conferenceAddCall(newCall, this.voiceProviderService.call_audio);
        newCall.info.isPowerDialer = call.info.isPowerDialer;
        return Promise.resolve(newCall);
      })
      .catch((error: Error) => {
        LoggerUtil.info('Call: conference init failed', {
          extraContent: { error },
        });
        return Promise.reject(error);
      });
  }

  /**
   * With this function wi will assign contact to call, need to be called after every contact change/assignments
   * @param {String} call_uuid
   * @param {String|Number} contactId
   * */
  private assignContact(call_uuid: string, contactId: string) {
    return call_uuid && contactId
      ? this.endpointService._get(
          `calls/assign-contact/${call_uuid}/${contactId}`,
        )
      : of(null);
  }

  /**
   * Returning base event title
   * @return {String}
   * */
  private eventName(event): string {
    switch (event) {
      case CallEvent.Ringing:
        return $localize`Incoming`;
      case CallEvent.DialingCall:
        return $localize`Dialing`;
      case CallEvent.HangUpCall:
        return $localize`Call ended`;
      case CallEvent.RejectCall:
        return $localize`Call rejected`;
      case CallEvent.Calling:
        return $localize`Call in progress`;
      case CallEvent.AfterCallWork:
        return $localize`After call work`;
      case CallEvent.CallSummary:
        return $localize`Call summary`;
      default:
        return null;
    }
  }

  /**
   * This function will setup document title
   */
  private setDocumentTitle(call: Call): void {
    const eventName = this.eventName(call.event);
    if (call.isCalling) {
      this.title.setTitle(
        eventName +
          ' ' +
          this.clockTicking.transform(call.info.calling_duration, '0'),
      );
    } else if (call.isEnded) {
      this.title.setTitle(this.defaultDocTitle);
    } else {
      this.title.setTitle(eventName + '...');
    }
  }

  /**
   * This will update the title of call which is in object info
   */
  private setCallTitle(call: Call) {
    if (call.isDialing) {
      call.info.title =
        $localize`Dialing` +
        ' - ' +
        this.clockTicking.transform(call.info.dialing_duration, '-');
    } else if (call.isCalling) {
      this.calling.duration = call.info.calling_duration;
      call.info.title = this.clockTicking.transform(
        call.info.calling_duration,
        '-',
      );
    } else if (call.isAfterCallWorkOrCallSummary || call.isHangup) {
      call.info.title = this.eventName(call.event);
    } else {
      const eventName = this.eventName(call.event);
      call.info.title = eventName ? eventName + '...' : call.info.title;
    }
  }

  /**
   * Handling contact changes and doing related work
   * */
  private handleContactChanges(call: Call) {
    return concat(
      this.assignContact$.pipe(
        tap((event: AssignContactEvent) => {
          if (event?.contactId) {
            call.info.contactId = event.contactId.toString();
            this.realtimeTriggersService.notifyWebhooksAboutCallModified({
              callUuid: call.info.call_uuid,
            });
          }
        }),
        concatMap(() =>
          this.getContacts(call.info.contactId).pipe(
            tap((contacts: CallContact[]) => (call.contacts = contacts)),
          ),
        ),
        concatMap(() =>
          this.assignContact(call.info.call_uuid, call.info.contactId),
        ),
      ),
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ScheduleModalComponent } from './schedule-modal.component';
import { DirectivesModule } from '../../directives/directives.module';
import { InputModule } from '../input/input.module';
import { ButtonComponent } from '../button/button.component';
import { CtIconComponent } from '../ct-icon/ct-icon.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCheckboxModule,
    DirectivesModule,
    InputModule,
    ButtonComponent,
    CtIconComponent,
  ],
  declarations: [ScheduleModalComponent],
  exports: [ScheduleModalComponent],
  entryComponents: [ScheduleModalComponent],
})
export class ScheduleModalModule {}

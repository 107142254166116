import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

import { DirectivesModule } from '../../directives/directives.module';

/**
 * Currently used categories
 * New category can be added
 * Category name is based on dir name in path assets/img/$name
 */
type IconCategory = 'icons' | 'brands';
export type CtIconColor = 'normal' | 'white' | 'gray' | 'primary';
@Component({
  standalone: true,
  selector: 'ct-icon',
  templateUrl: './ct-icon.component.html',
  styleUrls: ['./ct-icon.component.scss'],
  imports: [NgClass, DirectivesModule],
})
export class CtIconComponent {
  @Input() name: string;
  @Input() size: number;
  @Input() colorClass: CtIconColor = 'normal';
  @Input() category: IconCategory = 'icons';
  @Input() isActive = false;
  @Input() isDisabled = false;
  @Input() isMaskIcon = false;
  @Input() shouldCover = false;
  @Input() suffix: 'svg' | 'gif' = 'svg';
}

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum THEMES {
  LIGHT = 'light',
  DARK = 'dark',
}

@Injectable()
export class ThemeService {
  private renderer: Renderer2;

  private themeSub$ = new BehaviorSubject<THEMES>(THEMES.LIGHT);
  theme$ = this.themeSub$.asObservable();

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public setTheme(theme: THEMES): void {
    this.renderer.addClass(document.body, theme);
  }
}

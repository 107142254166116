import { datadogLogs } from '@datadog/browser-logs';

import { environment } from '../../../environments/environment';

export class CloudtalkDatadogLogs {
  init() {
    let env = environment.name;

    if (env === 'local') {
      return;
    }

    if (
      env === 'prod' &&
      window.location.origin?.includes('phone-beta.cloudtalk.io')
    ) {
      env = 'beta';
    }
    try {
      datadogLogs.init({
        clientToken: environment.monitoring.datadog.clientToken,
        site: environment.monitoring.datadog.site,
        service: environment.monitoring.datadog.service,
        env,
        version: environment.version,
        forwardErrorsToLogs: true,
        useCrossSiteSessionCookie: true,
        sessionSampleRate: 100, // needs to have 100 otherwise we will miss logs of users
      });
    } catch (e) {
      console.error(e);
    }
  }
}

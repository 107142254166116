import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WrapperModule } from 'ui-components';

import { appRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AppProvidersModule } from './_core/app-providers.module';
import { MaterialElementImportModule } from './_shared/material-element-import.module';
import { environment } from '../environments/environment';
import { UnsupportedBrowserBlurModule } from './ui-components/_blurs/contents/unsupported-browser-blur/unsupported-browser-blur.module';
import { SnackBarWrapperModule } from './_shared/components/snack-bar-wrapper/snack-bar-wrapper.module';
import { CountryCodeToRegionPipe } from './_shared/pipes/country-code-to-region/country-code-region.pipe';
import { CoreProvidersModule } from './_core/core-providers.module';
import { SharedModule } from './_shared/shared.module';
import { HeaderInterceptor } from './_core/interceptors/header-interceptor.service';
import { CloudtalkErrorHandler } from './_core/errors/cloudtalk-error-handler';
import { DocumentService } from './_core/services/document.service';
import { BootstrapComponent } from './ui-components/bootstrap/bootstrap.component';
import { LocalizationService } from './_core/services/localization.service';
import { I18N_SERVICE } from './_core/services/i18n.service';
import { ButtonComponent } from './lib/components/button/button.component';
import { CtIconComponent } from './lib/components/ct-icon/ct-icon.component';
import { TokenInterceptor } from './_core/interceptors/token-interceptor.service';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatDatepickerModule,
    MaterialElementImportModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
    HttpClientModule,
    UnsupportedBrowserBlurModule,
    WrapperModule,
    SnackBarWrapperModule,
    SharedModule,
    MatAutocompleteModule,
    // app providers
    AppProvidersModule,
    CoreProvidersModule,
    ButtonComponent,
    CtIconComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  declarations: [BootstrapComponent, AppComponent],
  providers: [
    CountryCodeToRegionPipe,
    { provide: ErrorHandler, useClass: CloudtalkErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    DocumentService,
    LocalizationService,
    I18N_SERVICE.setLocale(),
    I18N_SERVICE.setLocaleId(),
  ],
  bootstrap: [BootstrapComponent],
})
export class AppModule {
  constructor() {}
}

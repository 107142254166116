<h4
  [appTestId]="headerIdsEnum.HEADER_TITLE"
  class="c-conference-hangup__title"
  i18n>
  Hang up options
</h4>

<app-button
  class="c-conference-hangup__button"
  [size]="8"
  btnType="texts"
  (click)="close($event, 'leave')"
  [testId]="callButtonsIdsEnum.CONFERENCE_HANG_UP_BUTTON">
  <p
    class="c-conference-hangup__button-text"
    i18n>
    Leave call
  </p>
  <p i18n>Keep the call running for other parties</p>
</app-button>

<app-button
  class="c-conference-hangup__button c-conference-hangup__button--finish"
  btnType="outline-primary"
  (click)="close($event, 'finish')"
  [testId]="callButtonsIdsEnum.CONFERENCE_HANG_UP_FOR_EVERYONE_BUTTON">
  <span class="c-conference-hangup__button-text" i18n
    >Finish call for everyone</span
  >
</app-button>

<a
  class="c-conference-hangup__link"
  (click)="close($event)"
  mat-flat-button
  i18n
  >Cancel</a
>

import { Injectable } from '@angular/core';
import { Observable, filter, Subject } from 'rxjs';

export interface Command<T = unknown> {
  command: string;
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class CommandBusService {
  #commandBus: Subject<Command> = new Subject<Command>();

  emit<T = unknown>(command: Command<T>): void {
    this.#commandBus.next(command);
  }

  on<T>(command: string): Observable<Command<T>> {
    return this.#commandBus.pipe(
      filter(event => event.command === command),
    ) as Observable<Command<T>>;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MaterialElementImportModule } from '../material-element-import.module';
import { InputComponent } from './input.component';

@NgModule({
  imports: [CommonModule, MaterialElementImportModule, FormsModule],
  declarations: [InputComponent],
  exports: [InputComponent],
})
export class InputModule {}

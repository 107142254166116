import { NgModule } from '@angular/core';

import { RemainingCharCountPipe } from './remaining-char-count.pipe';

@NgModule({
  declarations: [RemainingCharCountPipe],
  exports: [RemainingCharCountPipe],
  providers: [RemainingCharCountPipe],
})
export class RemainingCharCountModule {}

<app-select
  #selectComponent
  (opened)="onOpen()"
  (closed)="onClose()"
  [disabled]="disabled">
  <select-header [appTestId]="selectStateFullIds.HEADER">
    <ng-container *ngIf="selectionModel?.selected.length">
      <div class="ets-selection-text">
        <ng-template
          [ngTemplateOutlet]="selectedTemplate"
          [ngTemplateOutletContext]="{ $implicit: selectionModel.selected[0] }">
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="!selectionModel?.selected.length">
      <ng-template [ngTemplateOutlet]="placeholderTemplate"></ng-template>
    </ng-container>
  </select-header>
  <select-body [title]="title">
    <ng-container *ngIf="showList">
      <div class="select__input-container">
        <app-input
          #searchInput
          *ngIf="showSearch"
          (input)="inputChanged($event)"
          [ngModel]="search"
          [styleClass]="'secondary'"
          [prefixIcon]="true"
          placeholder="Search"
          selectInput>
          <ct-icon prefixIcon [size]="24" name="search"></ct-icon>
        </app-input>
      </div>
      <ng-template [ngTemplateOutlet]="customPreselectTemplate"></ng-template>
      <ng-template *ngIf="customTemplate; else selectTemplate">
        <ng-template [ngTemplateOutlet]="customTemplate"></ng-template>
      </ng-template>
      <ng-template #selectTemplate>
        <ng-container *ngIf="!!selectionModel?.selected.length">
          <select-body-option
            *ngFor="let item of selectionModel?.selected"
            (click)="optionClickedHandler(item)"
            [selected]="item && selectionModel?.selected.includes(item)"
            [selectedClassName]="selectedClassName">
            <ng-template
              *ngIf="item"
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{ $implicit: item }">
            </ng-template>
          </select-body-option>
        </ng-container>

        <ng-container
          *ngIf="
            recentItems?.length &&
            !(
              recentItems?.length === 1 &&
              recentItems[0].id === selectionModel?.selected[0]?.id
            )
          ">
          <div class="select-section-title" i18n>RECENTS</div>
          <select-body-option
            *ngFor="
              let item of recentItems
                | filterPipe : 'id' : selectionModel?.selected[0]?.id
            "
            (click)="optionClickedHandler(item)"
            [selected]="selectionModel?.selected.includes(item)"
            [selectedClassName]="selectedClassName">
            <ng-template
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{ $implicit: item }">
            </ng-template>
          </select-body-option>
        </ng-container>

        <div class="select-section-title" *ngIf="items?.length > 0">
          {{ selectTitle }}
        </div>
        <select-body-option
          *ngFor="
            let item of items
              | filterPipe : 'id' : selectionModel?.selected[0]?.id
          "
          (click)="optionClickedHandler(item)"
          [selected]="selectionModel?.selected.includes(item)"
          [selectedClassName]="selectedClassName"
          [appTestId]="selectStateFullIds.OPTION">
          <ng-template
            [ngTemplateOutlet]="optionTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }">
          </ng-template>
        </select-body-option>
      </ng-template>
      <ng-template
        selectActions
        [ngTemplateOutlet]="actionTemplate"></ng-template>
    </ng-container>
  </select-body>
</app-select>

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EnvironmentService } from '../../../_core/services/environment.service';
import { CallService } from '../../../pages/call/call.service';
import { KeyboardKeyEnum } from '../../../_core/enums/keyboard-key.enum';

export interface RecordItem {
  _id: string;
  internal_name: string;
  isDefault?: boolean; // will be filled with our service
}

@Component({
  selector: 'app-voicemail-drop-select-dialog',
  templateUrl: './voicemail-drop-select-dialog.component.html',
  styleUrls: ['./voicemail-drop-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoicemailDropSelectDialogComponent {
  readonly osAltKey = KeyboardKeyEnum.LEFT_ALT;

  constructor(
    private voicemailDialogRef: MatDialogRef<VoicemailDropSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { voicemailDropsRecords: RecordItem[] },
    public environmentService: EnvironmentService,
    public callService: CallService,
  ) {
    this.callService.voicemailDialogRef = voicemailDialogRef;
  }

  recordItemFn(index: number, item: RecordItem): string {
    return item._id;
  }

  selectVoicemailDrop(record: RecordItem): void {
    this.callService.voicemailDialogRef.close(record);
  }
}

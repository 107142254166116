export enum SmartDialerScreenIdsEnum {
  CHANGE_OUTBOUND_CALLER = 'ChangeOutboundCaller',
  QUE_TAB_BUTTON = 'QueTabButton',
  SCHEDULED_CALLS_TAB_BUTTON = 'ScheduledCallsTabButton',
  START_SESSION_BUTTON = 'StartSessionButton',
  ADD_CONTACT_BUTTON = 'AddContactButton',
  RE_ARRANGE_SUB_MENU_BUTTON = 'ReArrangeSubMenuButton',
  CLEAR_QUE_SUB_MENU_BUTTON = 'ClearQueSubMenuButton',
  MENU_TRIGGER_BUTTON = 'MenuTriggerButton',
  ITEM_MENU_TRIGGER_BUTTON = 'ItemMenuTriggerButton',
  MORE_OPTIONS_OPTIONS_SUBMENU_BUTTON = 'MoreOptionsOptionsSubmenuItem',
  MORE_OPTIONS_SCHEDULE_CALL_SUBMENU_BUTTON = 'MoreOptionsCallSubmenuItem',
  MORE_OPTIONS_REMOVE_FROM_QUE_SUBMENU_BUTTON = 'MoreOptionsRemoveFromQueSubmenuItem',
  SMART_DIALER_LIST_ITEM = 'SmartDialerListItem',
}

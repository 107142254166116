import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { DirectivesModule } from '../../directives/directives.module';
import { OutboundSelectComponent } from './outbound-select.component';
import { CtFlagIconComponent } from '../ct-flag-icon/ct-flag-icon.component';
import { CtIconComponent } from '../ct-icon/ct-icon.component';

@NgModule({
  declarations: [OutboundSelectComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    CtFlagIconComponent,
    CtIconComponent,
    MatIconModule,
  ],
  exports: [OutboundSelectComponent],
})
export class OutboundSelectModule {}

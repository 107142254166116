import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SettingItem } from '../../../models/settings.model';

@Component({
  selector: 'app-router-link-item',
  templateUrl: './router-link-item.component.html',
  styleUrls: ['./router-link-item.component.scss'],
})
export class RouterLinkItemComponent {
  @Input() linkTo: string;
  @Input() icon = 'chevron-right';
  @Input() iconSize = 20;
  @Input() isDisabled = false;
  @Input() set navigationItem(item: SettingItem) {
    if (!item.routerLink.ownRoute) {
      this.routerLinkHref.push('multiple-options');
    }

    if (item.routerLink.link == null) {
      this.routerLinkHref.push(item.key);
      this._navigationItem = item;
      return;
    }

    if (Array.isArray(item.routerLink.link)) {
      this.routerLinkHref = this.routerLinkHref.concat(item.routerLink.link);
    } else {
      this.routerLinkHref.push(item.routerLink.link);
    }

    this.routerLinkHref.unshift('/');
    this._navigationItem = item;
  }
  get navigationItem(): SettingItem {
    return this._navigationItem;
  }

  private _navigationItem: SettingItem = null;

  routerLinkHref: string[] = [];

  constructor(public route: ActivatedRoute) {}
}

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ApplicationRef, enableProdMode, NgModuleRef } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import '@angular/localize/init';

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { CloudtalkDatadogRum } from './app/_core/monitoring/datadog-rum';
import { CloudtalkDatadogLogs } from './app/_core/monitoring/datadog-logs';

// Monitoring tools
new CloudtalkDatadogRum().init();
new CloudtalkDatadogLogs().init();

if (environment.production) {
  enableProdMode();
}

import('./app/app.module').then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, { preserveWhitespaces: true })
    .then((moduleRef: NgModuleRef<AppModule>) => {
      if (!environment.production) {
        const applicationRef = moduleRef.injector.get(ApplicationRef);
        const componentRef = applicationRef.components[0];
        enableDebugTools(componentRef);
      }

      try {
        if ('serviceWorker' in navigator && environment.production) {
          navigator.serviceWorker
            .register('./ngsw-worker.js')
            .then()
            .catch(err => console.error(err));
        }
      } catch (err) {
        console.error(err);
      }
    })
    .catch(err => console.error(err));
});

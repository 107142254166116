<div
  class="ct-flag-icon ct-flag-icon-{{ radiusSize }}"
  [class.ct-flag-icon--rounded]="isRounded"
  [style.height.px]="width"
  [style.width.px]="width"
  [style.background-image]="
    'url(/assets/img/country-flags/' + country + '.svg)'
  "
  [style.-webkit-background-image]="
    'url(/assets/img/country-flags/' + country + '.svg)'
  "></div>

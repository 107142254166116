import { Injectable } from '@angular/core';
import { IpcRenderer } from 'electron';
import Renderer from 'electron/renderer';

@Injectable()
export class ElectronService {
  #electron: typeof Renderer | null;
  constructor() {
    this.#electron = window.require ? window.require('electron') : null;
  }

  public get isElectronApp(): boolean {
    return !!window.navigator.userAgent.match(/Electron/);
  }

  public get ipcRenderer(): IpcRenderer {
    return this.#electron ? this.#electron.ipcRenderer : null;
  }

  get electronVersion(): { chrome: string; electron: string } | null {
    if (!this.isElectronApp) {
      return null;
    }
    const userAgent = navigator.userAgent;
    const electronMatch = userAgent.match(/Electron\/(\d+\.\d+\.\d+)/);
    const chromeMatch = userAgent.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/);

    const [, electronVersion] = electronMatch;
    const [, chromeVersion] = chromeMatch;

    return { chrome: chromeVersion, electron: electronVersion };
  }
}

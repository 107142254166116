import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SnackBarModule } from 'ui-components';

import { SnackBarWrapperComponent } from './snack-bar-wrapper.component';

@NgModule({
  imports: [RouterModule, SnackBarModule],
  declarations: [SnackBarWrapperComponent],
  exports: [SnackBarWrapperComponent],
  entryComponents: [SnackBarWrapperComponent],
})
export class SnackBarWrapperModule {}

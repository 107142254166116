export enum CallActionsButtonIdsEnum {
  MUTE_BUTTON = 'MuteButton',
  HOLD_BUTTON = 'HoldButton',
  LOUD_SPEAKER_BUTTON = 'LoutSpeakerButton',
  TRANSFER_BUTTON = 'TransferButton',
  KEY_PAD_BUTTON = 'KeyPadButton',
  TAGS_BUTTON = 'TagsButton',
  NOTES_BUTTON = 'NotesButton',

  UNMUTE_BUTTON = 'UnmuteButton',
  OFF_HOLD_BUTTON = 'OffHoldButton',
  ADD_CALL_BUTTON = 'AddCallButton',
  EDIT_TAGS_BUTTON = 'EditTagsButton',
  MORE_BUTTON = 'MoreButton',
  MORE_ACTIONS_BUTTON = 'MoreActionsButton',
  MENU_VOICEMAIL_BUTTON = 'MenuVoicemailButton',
  MENU_RAISE_HAND_BUTTON = 'MenuRaiseHandButton',
  MENU_INVITE_AGENT_BUTTON = 'MenuInviteAgentButton',
  MENU_PAUSE_RECORDING_BUTTON = 'MenuPauseRecordingButton',
  MENU_TOGGLE_RECORDING_BUTTON = 'MenuToggleRecordingButton',
  MENU_RESUME_RECORDING_BUTTON = 'MenuResumeRecordingButton',
  MENU_ADD_NUMBER_BUTTON = 'MenuAddNumberButton',
  MENU_RAISE_HAND_CANCEL = 'MenuRaiseHandCancel',
}

import { dynamicId } from '../utils/utils';

export enum CallHistoryScreenIdsEnum {
  NAVBAR_ALL_CALLS_BUTTON = 'NavbarAllCallsButton',
  NAVBAR_MISSED_CALLS_BUTTON = 'NavbarMissedCallsButton',
  CALL_HISTORY_LIST = 'CallHistoryList',
  CALL_HISTORY_CELL = 'CallHistoryCell',
  CALL_HISTORY_CELL_TYPE_ICON = 'CallHistoryCellTypeIcon',
  ALL_CALLS_SECONDARY_BUTTON = 'AllCallsSecondaryButton',
  RESOLVED_CALLS_SECONDARY_BUTTON = 'ResolvedCallsSecondaryButton',
  UNRESOLVED_CALLS_SECONDARY_BUTTON = 'UnresolvedCallsSecondaryButton',
  CALL_MADE = 'call_made',
  CALL_MISSED_OUTGOING = 'call_missed_outgoing',
  CALL_MISSED = 'call_missed',
  CALL_RECEIVED = 'call_received',
  CALL_SPLIT = 'call_split',
  UnresolvedMissedCallIcon = 'UnresolvedMissedCallIcon', // Todo: Add to code
  RESOLVED_MISSED_CALL_ICON = 'ResolvedMissedCallIcon',
  NO_CALLS_ICON = 'NoCallsIcon',
  NO_CALLS_TEXT = 'NoCallsText',
  CALL_HISTORY_FILTER_BUTTON = 'CallHistoryFilterButton',
  CALL_HISTORY_HEADER = 'CallHistoryHeader',
  CALL_HISTORY_ALL_CALLS_FILTER = 'CallHistoryAllCallsFilter',
  CALL_HISTORY_MISSED_CALLS_FILTER = 'CallHistoryMissedCallsFilter',
  CALL_HISTORY_MISSED_CALLS_SUB_FILTER = 'CallHistoryMissedCallsSubFilter',
  CALL_HISTORY_INBOUND_FILTER = 'CallHistoryInboundFilter',
  CALL_HISTORY_OUTBOUND_FILTER = 'CallHistoryOutboundFilter',
  CALL_HISTORY_VOICEMAIL_FILTER = 'CallHistoryVoicemailFilter',
  CALL_HISTORY_INTERNAL_FILTER = 'CallHistoryInternalFilter',
  CALL_HISTORY_FILTER_SUBMIT_BUTTON = 'CallHistoryFilterSubmitButton',
  CALL_HISTORY_ITEM = 'CallHistoryItem',
  CALL_HISTORY_MAKE_CALL = 'CallHistoryMakeCall',
  CALL_HISTORY_SEND_SMS = 'CallHistorySendSms',
  CALL_HISTORY_HOVER = 'CallHistoryHover',
  CALL_HISTORY_ITEM_INFO = 'CallHistoryItemInfo',
  CALL_HISTORY_ITEM_TIME = 'CallHistoryItemTime',
  CALL_HISTORY_ITEM_TYPE_LABEL = 'CallHistoryItemTypeLabel',
}

export const phoneNumber_421949144214 = dynamicId(
  CallHistoryScreenIdsEnum.CALL_HISTORY_CELL,
  '-+421 949 144 214',
);
export const phoneNumber_421123456 = dynamicId(
  CallHistoryScreenIdsEnum.CALL_HISTORY_CELL,
  '-+421 123456',
);
export const phoneNumber_13043471118 = dynamicId(
  CallHistoryScreenIdsEnum.CALL_HISTORY_CELL,
  '-+1 304-347-1118',
);
export const phoneNumber_12065084051 = dynamicId(
  CallHistoryScreenIdsEnum.CALL_HISTORY_CELL,
  '-+1 206-508-4051',
);
export const call_history_made_blake_greenwood = dynamicId(
  CallHistoryScreenIdsEnum.CALL_HISTORY_CELL,
  `-${CallHistoryScreenIdsEnum.CALL_MADE}-Blake Greenwood`,
);

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-unsupported-browser-blur',
  templateUrl: './unsupported-browser-blur.component.html',
  styleUrls: ['./unsupported-browser-blur.component.scss'],
})
export class UnsupportedBrowserBlurComponent {
  @Input() isSupportedBrowser = true;
  @Input() isSupportedVersion = true;
  @Input() currentBrowser = null;
  @Output() updateBrowser: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}
}

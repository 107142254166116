import { NgModule } from '@angular/core';
import { PhoneNumberFormatterModule, FilterPipeModule } from 'ui-components';

import { ClockTickingModule } from './clock-ticking/clock-ticking.module';
import { CountryCodeToRegionModule } from './country-code-to-region/country-code-region.module';
import { NameInitialsModule } from './name-initials/name-initials.module';
import { IsValidPhoneNumberModule } from './is-valid-phone-number/is-valid-phone-number.module';
import { MarkdownModule } from './markdown/markdown.module';
import { ContactNameModule } from './contact-name-pipe/contact-name.module';
import { MapValuesModule } from './map-values/map-values.module';
import { SafeHtmlModule } from './safe-html/safe-html.module';
import { MapToAgentStatusClassPipe } from './calling-connection/map-to-agent-status-class.pipe';
import { MapToAgentStatusLabelPipe } from './calling-connection/map-to-agent-status-label.pipe';
import { RemainingCharCountModule } from './remaining-char-count/remaining-char-count.module';
import { AgentStatusesFilterPipe } from './agent-status/agent-statuses-filter.pipe';
import { IsAbleToCallPipe } from './calling-connection/is-able-to-call.pipe';

@NgModule({
  imports: [
    ClockTickingModule,
    CountryCodeToRegionModule,
    PhoneNumberFormatterModule,
    NameInitialsModule,
    IsValidPhoneNumberModule,
    FilterPipeModule,
    MarkdownModule,
    ContactNameModule,
    SafeHtmlModule,
    MapValuesModule,
    RemainingCharCountModule,
  ],
  exports: [
    ClockTickingModule,
    CountryCodeToRegionModule,
    PhoneNumberFormatterModule,
    NameInitialsModule,
    FilterPipeModule,
    IsValidPhoneNumberModule,
    MarkdownModule,
    ContactNameModule,
    SafeHtmlModule,
    MapValuesModule,
    IsAbleToCallPipe,
    MapToAgentStatusClassPipe,
    MapToAgentStatusLabelPipe,
    RemainingCharCountModule,
    AgentStatusesFilterPipe,
  ],
  declarations: [
    IsAbleToCallPipe,
    MapToAgentStatusClassPipe,
    MapToAgentStatusLabelPipe,
    AgentStatusesFilterPipe,
  ],
})
export class SharedPipesModule {}

import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { EndpointService } from '../../../../_core/services/networking/endpoint.service';
import { UserSettingsService } from '../../../../_core/services/user/user-settings.service';
import { SettingsKeys } from '../../settings';
import { CTResponse } from '../../../../_core/interfaces/ctresponse.interface';

export interface RecordItem {
  _id: string;
  internal_name: string;
  isDefault?: boolean; // will be filled with our service
}

@Injectable()
export class VoicemailDropsService {
  isEnabled = false;

  records$: BehaviorSubject<RecordItem[]> = new BehaviorSubject<RecordItem[]>(
    [],
  );

  constructor(
    private userSettingsService: UserSettingsService,
    private endpointService: EndpointService,
  ) {
    this.getAllRecords().subscribe();
    // after setting update check the default settings
    this.userSettingsService.updateSettings$.subscribe(() =>
      this.checkDefault(),
    );
  }

  emitVoicemailDrop(
    callUuid: string,
    record: RecordItem,
    switchChannels: boolean,
  ): Observable<CTResponse<undefined>> {
    return this.endpointService._post_realtime('voicemail-drops/emit', {
      callUuid,
      recordId: record._id,
      switchChannels,
    });
  }

  checkDefault() {
    const voicemailDrops = this.records;
    voicemailDrops.forEach(
      record =>
        (record.isDefault =
          record._id ===
          this.userSettingsService.settings[
            SettingsKeys.DEFAULT_VOICEMAIL_DROP
          ]),
    );
    // default must be first
    voicemailDrops.sort((x, y) =>
      x.isDefault === y.isDefault ? 0 : x.isDefault ? -1 : 1,
    );
    this.records$.next(voicemailDrops);
  }

  get records(): RecordItem[] {
    return this.records$.getValue();
  }

  async updateVoicemailDefaultSetting(records: RecordItem[]) {
    // clear previous default
    records.map(record => (record.isDefault = false));
    // update default
    const res = await this.userSettingsService.updateUserSettings(
      SettingsKeys.DEFAULT_VOICEMAIL_DROP,
      records[0]._id,
    );
    // set new default
    if (res) {
      records[0].isDefault = true;
    }
    return records;
  }

  getAllRecords() {
    return this.endpointService._get_realtime('voicemail-drops/records').pipe(
      tap((data: RecordItem[]) => {
        this.records$.next(data);
        this.checkDefault();
      }),
    );
  }

  getRecordStream(recordId: string): Observable<string> {
    return this.endpointService._get_realtime<string>(
      `voicemail-drops/record/${recordId}`,
      undefined,
      { responseType: 'blob' },
    );
  }

  createNewRecord(
    record: File,
    recordName: string,
  ): Observable<CTResponse<{ record_id: string }> | HttpErrorResponse> {
    const formData = new FormData();
    formData.append('record', record, recordName);
    return this.endpointService
      ._post_realtime<{ record_id: string }, FormData>(
        'voicemail-drops/record',
        formData,
      )
      .pipe(
        // adding new element to end of list
        tap(async (result: CTResponse<{ record_id: string }>) => {
          if (result?.data) {
            // record list with new records
            const newRecords = [
              ...this.records,
              {
                _id: result.data.record_id,
                internal_name: recordName.replace('.mp3', ''),
              },
            ];
            // if it's first added record set it like default
            if (this.records.length < 1) {
              await this.updateVoicemailDefaultSetting(newRecords);
            }
            this.records$.next(newRecords);
          }
        }),
      );
  }

  editRecordName(recordId, newRecordName) {
    return this.endpointService
      ._patch_realtime('voicemail-drops/record', {
        record_id: recordId,
        record_name: newRecordName,
      })
      .pipe(
        tap(() => {
          const records = this.records;
          const itemToUpdateIndex = records.findIndex(
            record => record._id === recordId,
          );
          records[itemToUpdateIndex].internal_name = newRecordName;
          this.records$.next(records);
        }),
      );
  }

  deleteRecord(recordId) {
    return this.endpointService
      ._delete_realtime(`voicemail-drops/record/${recordId}`)
      .pipe(
        tap(async () => {
          const recordsAfterDelete = this.records.filter(
            record => record._id !== recordId,
          );
          if (
            recordId ===
              this.userSettingsService.settings[
                SettingsKeys.DEFAULT_VOICEMAIL_DROP
              ] &&
            recordsAfterDelete.length > 0
          ) {
            await this.updateVoicemailDefaultSetting(recordsAfterDelete);
          }
          this.records$.next(recordsAfterDelete);
        }),
      );
  }
}

<div class="c-schedule-modal">
  <div class="c-schedule-modal__title">
    <span i18n>Reschedule call</span>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="scheduleForm">
    <mat-dialog-content>
      <mat-calendar
        (selectedChange)="selectedDate = $event"
        [selected]="selectedDate"
        [startAt]="selectedDate"
        class="c-schedule-modal__calendar"
        [minDate]="minDate"
        [appTestId]="schedulleModalEnum.CALENDAR">
      </mat-calendar>

      <div class="c-schedule-modal__inputs">
        <div class="c-schedule-modal__input-time">
          <app-input
            [appTestId]="schedulleModalEnum.TIME_INPUT"
            [invalidate]="
              scheduleForm.controls['time'].touched &&
              !scheduleForm.controls['time'].valid
            "
            formControlName="time"
            name="time"
            type="time"
            [prefixIcon]="true">
            <div prefixIcon></div>
          </app-input>
        </div>

        <div class="c-schedule-modal__input-note">
          <app-input
            [appTestId]="schedulleModalEnum.NOTE_INPUT"
            [prefixIcon]="true"
            formControlName="note"
            name="note"
            type="text">
            <ct-icon prefixIcon name="edit-note" [size]="24"></ct-icon>
          </app-input>
        </div>
        
        <div class="c-schedule-modal__checkbox-sameuser">
            <mat-checkbox
            [checked]="sameUser"
            color="primary"
            (change)="updateSameUser($event)"> 
                <span i18n>Reschedule to me</span>
            </mat-checkbox>
        </div>
      </div>
    </mat-dialog-content>

    <div class="c-schedule-modal__buttons">
      <app-button
        class="c-schedule-modal__buttons--clear"
        [testId]="schedulleModalEnum.CLEAR_BUTTON"
        btnType="icon"
        (clicked)="scheduleForm.reset()"
        [size]="10"
        i18n
        >Clear</app-button
      >
      <app-button
        mat-dialog-close
        class="c-schedule-modal__buttons--close"
        [testId]="schedulleModalEnum.CANCEL_BUTTON"
        btnType="clear"
        [size]="10"
        i18n
        >Cancel</app-button
      >
      <app-button
        class="c-schedule-modal__buttons--save"
        [testId]="schedulleModalEnum.SAVE_BUTTON"
        cdkFocusInitial
        btnType="primary"
        type="submit"
        [size]="10"
        i18n
        >Save</app-button
      >
    </div>
  </form>
</div>

import { Injectable } from '@angular/core';
import { Call, CallContact } from '@cloudtalk/sip-service';

import { CtiServiceBase } from '../CtiServiceBase';
import { CallingService } from '../../_core/services/calling/calling.service';
import { StatusService } from '../../_core/services/status.service';
import { CtiProvider } from '../../_core/models/cti-providers.models';
import { CallMonitorService } from '../../_core/services/call-monitor.service';
import { LoggerUtil } from '../../_shared/utils/logger.util';

declare const ZAFClient: any;

enum ProductTypes {
  SUPPORT = 'support ',
  SELL = 'sell',
}

interface ZendeskClientContext {
  instanceGuid: string;
  product: ProductTypes;
  account: {
    subdomain: string;
  };
  location: string;
  ticketId: number;
}

@Injectable()
export class ZendeskCtiService extends CtiServiceBase {
  scriptId = null;
  client = null;
  productType: ProductTypes = null;
  accountSubdomain: string = null;

  constructor(
    statusService: StatusService,
    callingService: CallingService,
    callMonitorService: CallMonitorService,
  ) {
    super(
      callingService,
      statusService,
      callMonitorService,
      CtiProvider.ZENDESK,
    );
    this.initScript();
  }

  initScript(): void {
    this.scriptId = new Date();
    // initialize zendesk script (ZAF client)
    const script = document.createElement('script');
    script.id = this.scriptId;
    script.src =
      'https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js';
    script.type = 'text/javascript';
    script.onload = () => {
      this.client = ZAFClient.init();
      this.client?.context().then((context: ZendeskClientContext) => {
        this.productType = context.product;
        this.accountSubdomain = context.account?.subdomain;
      });
      this.handleEvent();
    };
    script.onerror = () => {
      LoggerUtil.info('MIS: Zendesk SDK loading error');
      this.removeScript(this.scriptId);
      this.initScript();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  removeScript(id: string): void {
    document.getElementById(id).remove();
  }

  handleEvent(): void {
    if (this.client) {
      this.client.on('voice.dialout', event => {
        this.makeCall(event.number);
      });
    }
  }

  onRinging(call: Call): void {
    super.onRinging(call);
    this.contactSubscription = {
      callID: call.ID,
      subscription: call.contacts$.subscribe(contacts => {
        if (contacts.length > 0) {
          this.openUserTab(this.getUserId(contacts));
        }
      }),
    };
    this.focusApp();
  }

  onDialing(call: Call): void {
    super.onDialing(call);
    this.focusApp();
  }

  focusApp(): void {
    if (this.client) {
      this.client.invoke('popover', 'show');
    }
  }

  openUserTab(userId): void {
    if (userId && this.client && !this.isZendeskSell) {
      this.client.invoke('routeTo', 'user', userId);
    }
  }

  containsAccountSubdomain(link: string): boolean {
    // extracts account subdomain
    const pattern = /:\/\/([^.]+)\./;
    const match = link.match(pattern);
    if (match.length < 2) {
      return false;
    }
    return match[1] === this.accountSubdomain;
  }

  getUserId(contacts: CallContact[]): number {
    let userId = null;
    contacts.forEach(contactItem => {
      contactItem.contact.tags.forEach(contactTag => {
        if (
          contactTag._joinData.integration_name === 'zendesk' &&
          this.containsAccountSubdomain(contactTag._joinData.external_url)
        ) {
          userId = contactTag._joinData.external_url.split('/users/')[1];
        }
      });
    });
    return userId;
  }

  private get isZendeskSell(): boolean {
    return this.productType === ProductTypes.SELL;
  }

  private get isZendeskSupport(): boolean {
    return this.productType === ProductTypes.SUPPORT;
  }
}

import { Injectable } from '@angular/core';

export const PhoneStatusConst = {
  NO_INTERNET_ACCESS: 'no_internet',
  CONNECTED: 'connected',
  NETWORK_CHANGED: 'network_changed',
  NO_MEDIA_STREAM: 'no_media_stream',
  NO_MEDIA_STREAM_OS: 'no_media_stream_os',
  MEDIA_STREAM: 'media_stream',
  MEDIA_STREAM_OS: 'media_stream_os',
  NO_SIP_REGISTRATION_SUCCESS: 'no_sip_registration_success',
  BAD_INTERNET_CONNECTION: 'bad_internet_connection',
};

export const PhoneStatusProperties = {
  INTERNET_ACCESS: 'internetAccess',
  NETWORK_CHANGES: 'networkChanges',
  ALREADY_REGISTERED: 'alreadyRegistered',
  TESTING_VOICE: 'isTestingVoice',
  CORRECTLY_CONNECTED: 'isCorrectlyConnected',
  MEDIA_STREAM: 'mediaStream',
  OS_MEDIA_STREAM: 'mediaStreamOS',
  SIP_STATE: 'state',
  NOTIFICATION_ALLOWED: 'notificationAllowed',
};

export interface Status {
  internetAccess: string;
  networkChanges: any; // object of network information
  alreadyRegistered: boolean;
  mediaStream: string;
  mediaStreamOS: string;
  sipRegistrationSuccess: { state: boolean; message: string };
  isTestingVoice: boolean;
  isCorrectlyConnected: string;
  notificationAllowed: boolean;
}

export class PhoneStatusSimpleChange {
  oldValue: any;
  newValue: any;
  property: string;

  constructor(oldV, newV, prop) {
    this.oldValue = oldV;
    this.newValue = newV;
    this.property = prop;
  }
}

@Injectable()
export class PhoneStatusService {
  private _status: Status;

  public _version: boolean = null;
  private _versionToBeta: boolean = null;

  private _electronVersion: { os: string; channel: string; version: string } =
    null;

  constructor() {
    this.resetStatus();
  }

  get status(): Status {
    return this._status;
  }

  /**
   * Zoznam error kodov
   * Pridava error notifikacie
   *
   * @returns {Array}
   */
  public get errorList() {
    const status = [];
    if (this._status.internetAccess === PhoneStatusConst.NO_INTERNET_ACCESS) {
      status.push(PhoneStatusConst.NO_INTERNET_ACCESS);
    }
    if (this._status.mediaStream === PhoneStatusConst.NO_MEDIA_STREAM) {
      status.push(PhoneStatusConst.NO_MEDIA_STREAM);
    }
    if (this._status.mediaStreamOS === PhoneStatusConst.NO_MEDIA_STREAM_OS) {
      status.push(PhoneStatusConst.NO_MEDIA_STREAM_OS);
    }
    if (!this._status.sipRegistrationSuccess.state) {
      status.push(PhoneStatusConst.NO_SIP_REGISTRATION_SUCCESS);
    }
    if (
      this._status.isCorrectlyConnected ===
      PhoneStatusConst.BAD_INTERNET_CONNECTION
    ) {
      status.push(PhoneStatusConst.BAD_INTERNET_CONNECTION);
    }

    return status;
  }

  /**
   * Vrati prvu chybu v stacku
   * @returns {null}
   */
  public get error() {
    const errors = this.errorList;
    return errors.length > 0 ? this.message(errors[0]) : null;
  }

  public resetStatus() {
    this._status = {
      internetAccess: PhoneStatusConst.CONNECTED,
      networkChanges: null,
      alreadyRegistered: false,
      mediaStream: null,
      mediaStreamOS: null,
      sipRegistrationSuccess: { state: true, message: '' },
      isTestingVoice: false,
      isCorrectlyConnected: null,
      notificationAllowed: true,
    };
  }

  /**
   * Transponovane chyby na messages
   * @param status
   * @param htmlAllowed
   * @returns {null}
   */
  public message(status, htmlAllowed = true) {
    switch (status) {
      case PhoneStatusConst.NO_INTERNET_ACCESS:
        return $localize`No internet connection`;
      case PhoneStatusConst.NO_MEDIA_STREAM:
        return htmlAllowed
          ? $localize`Audio devices are not available or set up incorrectly. Click to check your audio <a id="link" class="link--dialpad" href="/p/settings/voice-settings">settings</a>`
          : $localize`Audio devices are not available or set up incorrectly. Click to check your audio settings.`;
      case PhoneStatusConst.NO_MEDIA_STREAM_OS:
        return $localize`Audio devices may be blocked on your operating system settings, please check it.`;
      case PhoneStatusConst.NO_SIP_REGISTRATION_SUCCESS:
        return this._status.sipRegistrationSuccess.message;
      case PhoneStatusConst.BAD_INTERNET_CONNECTION:
        return $localize`Your internet connection is degraded. Quality of your calls can be low.`;
      default:
        return null;
    }
  }

  /**
   * Pre zistenie ci pouzivatel bol prepnuty do beta verzie
   * @return boolean
   */
  get versionToBeta(): boolean {
    return this._versionToBeta;
  }

  /**
   * Nastavi indikator ze pouzivatel bol prepnuty do beta verzie
   * @return boolean
   */
  set versionToBeta(value: boolean) {
    this._versionToBeta = value;
  }

  /**
   * Nastavi verziu
   * @return boolean
   */
  public get version(): boolean {
    return this._version;
  }

  /**
   * Nastavi verziu
   * @param version
   */
  public set version(version: boolean) {
    this._version = version;
  }

  /**
   * Nastavi verziu
   * @return boolean
   */
  get electronVersion(): { os: string; channel: string; version: string } {
    return this._electronVersion;
  }

  /**
   * Nastavi verziu
   * @param value
   */
  set electronVersion(value: { os: string; channel: string; version: string }) {
    this._electronVersion = value;
  }

  /**
   * Ak je user uz v nejakom inom browseri lognuty
   * @returns {boolean}
   */
  public get isAlreadyRegistered() {
    return this._status.alreadyRegistered;
  }

  /**
   * Ak testuje audio
   * @returns {boolean}
   */
  public get isTestingVoice() {
    return this._status.isTestingVoice;
  }

  /**
   * Ak testuje audio
   * @returns {boolean}
   */
  public get isCorrectlyConnected() {
    return this._status.isCorrectlyConnected;
  }

  /**
   * Problem si pripojenim telefonu
   * @returns {boolean}
   */
  public get hasError() {
    return this.errorList.length > 0;
  }

  /**
   * Ak je vsetko v poriadku
   * @returns {boolean}
   */
  public get isOk() {
    return !this.hasError;
  }
}

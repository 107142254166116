import { NgModule } from '@angular/core';

import { ContactNamePipe } from './contact-name.pipe';

@NgModule({
  declarations: [ContactNamePipe],
  exports: [ContactNamePipe],
  providers: [ContactNamePipe],
})
export class ContactNameModule {}

import { datadogRum } from '@datadog/browser-rum';

import { environment } from '../../../environments/environment';

export class CloudtalkDatadogRum {
  init() {
    let env = environment.name;

    if (env === 'local') {
      return;
    }
    if (
      env === 'prod' &&
      window.location.origin?.includes('phone-beta.cloudtalk.io')
    ) {
      env = 'beta';
    }

    const apis: string[] = Object.values(environment.apis);

    datadogRum.init({
      applicationId: environment.monitoring.datadog.applicationId,
      clientToken: environment.monitoring.datadog.clientToken,
      site: environment.monitoring.datadog.site,
      service: environment.monitoring.datadog.service,
      env,
      version: environment.version,
      sessionSampleRate: environment.monitoring.datadog.sampleRate,
      sessionReplaySampleRate:
        environment.monitoring.datadog.sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
      traceSampleRate: environment.monitoring.datadog.traceSampleRate,
      allowedTracingUrls: [environment.socketUrl, ...apis],
    });
    datadogRum.startSessionReplayRecording();
  }
}

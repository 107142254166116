export enum SettingsPageIdsEnum {
  REFRESH_APP_BUTTON = 'RefreshAppButton',
  SETTING_ITEM = 'SettingItem',
  LOGOUT_BUTTON = 'LogoutButton',
  USER_NAME_ITEM = 'UserNameItem',
  USER_EMAIL_ITEM = 'UserEmailItem',
  COMPANY_NAME_ITEM = 'CompanyNameItem',
  USER_CREDIT_ITEM = 'UserCreditItem',
  LANGUAGE_ITEM = 'LanguageItem',
  SELECT_ITEM = 'SelectItem',
  SELECT_OPTION_ITEM = 'SelectOptionItem',
  SELECT_INBOUND_PICKUP = 'SelectInboundPickup',
  SLIDE_TOGGLE_ITEM = 'SlideToggleItem',
  PROFILE_HEADER_STATUS = 'ProfileHeaderStatus',
}

import { EventEmitter, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IpcEvents } from '../../../../../interfaces/shared-interfaces';
import { LoggerUtil } from '../../../_shared/utils/logger.util';
import {
  defaultFallbackLanguage,
  Language,
  languagesCodes,
} from '../../../languages';
import {
  NotificationPreferenceEnum,
  SettingsKeys,
} from '../../../pages/setting/settings';
import {
  Country,
  CountrySelectService,
} from '../../../ui-components/country-select/country-select.service';
import { APIs } from '../../enums/apis.enum';
import { CTResponse } from '../../interfaces/ctresponse.interface';
import { UsersInfoResponse } from '../../interfaces/users-info-response.interface';
import { EnvironmentService } from '../environment.service';
import { IpcService } from '../ipc.service';
import { LocalStorageService } from '../local-storage.service';
import { LocalizationService } from '../localization.service';
import { EndpointService } from '../networking/endpoint.service';
import { PhoneStatusService } from '../phone-status.service';
import { UserService } from './user.service';

/**
 * Source of setting is Dashboard
 * Stored in MariaDB table users
 * */
interface DashboardUserSettings {
  show_contacts: boolean;
  show_internal_contacts: boolean;
}
/**
 * Source of setting are Calling Apps
 * Stored in MongoDB
 * */
interface PhoneUserSettings {
  user_id: number;
  [SettingsKeys.ENABLE_KEYBOARD_SHORTCUTS]: boolean;
  [SettingsKeys.INCOMING_NOTIFICATION_PREFERENCE]: string;
  [SettingsKeys.COUNTRY_ID]: number;
  [SettingsKeys.APP_LANGUAGE]: string;
  [SettingsKeys.CLICK_TO_CALL_DIAL_IMMEDIATELY]: boolean;
  [SettingsKeys.BRING_RINGING_PHONE_TO_FOREGROUND]: boolean;
  [SettingsKeys.PICKUP_GENERATED_CALLS]: boolean;
  [SettingsKeys.PICKUP_INBOUND_CALLS]: number;
  [SettingsKeys.TIMEZONE_OF_CALLED]: string;
  [SettingsKeys.HAS_AUTOMATIC_OUTBOUND]: boolean;
  [SettingsKeys.DEFAULT_VOICEMAIL_DROP]: string;
  [SettingsKeys.DIALPAD_PREFIX]: boolean;
  [SettingsKeys.USER_STATUS]: string;
  user_environment: string;
  ice_checking_timeout: number;
  // array of setting keys which was set on dashboard
  set_by_company?: string[]; // we receiving them but we don't wanna update them via application
  set_per_agent?: string[]; // we receiving them but we don't wanna update them via application
}

export type UserSettings = PhoneUserSettings & Partial<DashboardUserSettings>;

const localStorageItemKey = 'ct-user-settings';

@UntilDestroy()
@Injectable()
export class UserSettingsService {
  updateSettings$: EventEmitter<any> = new EventEmitter<any>();
  disabledSettings = [];
  country: Country = null;
  language: Language = null;

  userInfo: BehaviorSubject<UsersInfoResponse> = new BehaviorSubject({
    companyInfo: { name: null },
  });

  // DO NOT INCLUDE set_by_company OR set_per_agent WE DONT WANNA UPDATE THEM VIA APP!!!
  private defaultSettings: PhoneUserSettings = {
    user_id: null,
    [SettingsKeys.ENABLE_KEYBOARD_SHORTCUTS]: true,
    [SettingsKeys.INCOMING_NOTIFICATION_PREFERENCE]:
      NotificationPreferenceEnum.PICKUP,
    [SettingsKeys.COUNTRY_ID]: 1,
    [SettingsKeys.APP_LANGUAGE]:
      this.localizationService.getLocale() || defaultFallbackLanguage,
    [SettingsKeys.CLICK_TO_CALL_DIAL_IMMEDIATELY]: false,
    [SettingsKeys.BRING_RINGING_PHONE_TO_FOREGROUND]: false,
    [SettingsKeys.PICKUP_GENERATED_CALLS]: false,
    [SettingsKeys.PICKUP_INBOUND_CALLS]: -1,
    [SettingsKeys.TIMEZONE_OF_CALLED]: 'pm',
    [SettingsKeys.HAS_AUTOMATIC_OUTBOUND]: false,
    [SettingsKeys.DEFAULT_VOICEMAIL_DROP]: null,
    [SettingsKeys.DIALPAD_PREFIX]: true,
    [SettingsKeys.USER_STATUS]: null,
    user_environment: 'prod',
    ice_checking_timeout: 3000,
  };

  private userSettings: UserSettings = {} as UserSettings;

  #userSettings$ = new BehaviorSubject<UserSettings>({} as UserSettings);
  userSettings$ = this.#userSettings$.asObservable();

  constructor(
    private endpointService: EndpointService,
    private ipcService: IpcService,
    private countrySelectService: CountrySelectService,
    private userService: UserService,
    private phoneStatus: PhoneStatusService,
    private environmentService: EnvironmentService,
    private localStorageService: LocalStorageService,
    private localizationService: LocalizationService,
  ) {
    this.init();
  }

  reset(): void {
    this.userSettings = this.defaultSettings;
    this.init();
  }

  get settings(): UserSettings {
    return this.userSettings;
  }

  loadUserSettings(): Observable<UserSettings> {
    return this.endpointService.get(APIs.Realtime, 'app/users/settings');
  }

  async updateUserSettings(
    key: string,
    value: boolean | string | number,
  ): Promise<CTResponse<undefined>> {
    // do not check only !value we can send boolean value false
    if (key && value !== null && value !== undefined) {
      this.settings[key] = value;
      this.localStorageService.setItem(
        localStorageItemKey,
        this.settings as any,
        false,
      );

      this.notifyDesktopApp(this.settings);

      return firstValueFrom(
        this.endpointService.patch<undefined, { [key: string]: unknown }>(
          APIs.Realtime,
          'app/users/settings',
          {
            [key]: value,
          },
        ),
      );
    }
    return { success: false };
  }

  private init(): void {
    // initialization for beta
    this.settings.user_environment = this.environmentService.isBeta()
      ? 'beta'
      : 'prod';
    // init application by localstorage
    this.initAppBySettings(
      this.localStorageService.getItem(localStorageItemKey),
    );
    // restoring language from userToken
    const userLangDefault = this.userService.getUser()
      ? this.userService.getUser().lang
      : null;

    this.settings.app_language = userLangDefault
      ? userLangDefault
      : this.localizationService.getLocale();

    // waiting for countries load
    this.countrySelectService.countries
      .pipe(
        filter((countries: Country[]) => !!countries && !!countries.length),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.initUserSettings().catch((err: Error) =>
          LoggerUtil.info(
            '[UserSettingsService]: initUserSettings() failed',
            {},
            err,
          ),
        );
        this.endpointService
          ._get_realtime('users/info')
          .pipe(untilDestroyed(this))
          .subscribe((data: UsersInfoResponse) => {
            this.userInfo.next(data);
          });
      });
  }

  private async initUserSettings(): Promise<void> {
    // loading settings from localstorage
    const result = await this.loadUserSettings()
      .toPromise()
      .catch(() => null);
    // case when user dont have settings
    if (result === null) {
      const user = this.userService.getUser();
      if (user) {
        this.country = this.countrySelectService.getCountryByCallingCode(
          parseInt(user.default_country_code, 10),
        );
        this.settings.country_id = this.country?.id;
      }
      // settings by default values
      this.setDefaultSettings();
      // initialization of app with default settings
      this.initAppBySettings(this.settings, true);
    } else {
      // checking when user have all settings which are available
      const checked = await this.checkDefaultSettings(result);
      if (checked) {
        // user settings is a response from server
        this.initAppBySettings(result, true);
      }
    }
  }

  private initAppBySettings(settings: UserSettings, save = false): void {
    if (settings) {
      if (save && !this.environmentService.isElectronBeta()) {
        // checking when user_environment its new set
        if (this.settings.user_environment !== settings.user_environment) {
          this.phoneStatus.versionToBeta = true;
          // prevent of double reload, we sending 'prod' to electron
          settings.user_environment = this.settings.user_environment;
        } else {
          this.phoneStatus.versionToBeta = false;
        }
      }

      // get all settings which should be disabled
      const mergedArrays = (settings?.set_by_company || []).concat(
        settings?.set_per_agent || [],
      );
      // removing duplicates
      this.disabledSettings = [...new Set(mergedArrays)];

      // init country object by agent default country setting
      this.country = this.countrySelectService.getCountryById(
        settings.country_id,
      );
      // application language initialization
      this.languageInit(settings.app_language);
      if (save) {
        this.notifyDesktopApp(settings);
      }

      // if save is true we wanna update localStorage and settings variable on the this service
      if (save) {
        this.userSettings = settings;
        this.#userSettings$.next(settings);
        this.localStorageService.setItem(
          localStorageItemKey,
          settings as any,
          false,
        );
      }
    }
    this.updateSettings$.emit();
  }

  private setDefaultSettings(): void {
    this.endpointService
      ._patch_realtime('users/settings', this.settings)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private notifyDesktopApp(settings: UserSettings): void {
    // hotfix for desktop app version 1.1.4
    this.ipcService.send(IpcEvents.SETTINGS, {
      shortcuts: settings.enable_keyboard_shortcuts,
    });
    // sending message to the our desktop app about settings
    this.ipcService.send(IpcEvents.SETTINGS, settings);
  }

  private languageInit(userLanguage: string): void {
    const availableCodes: string[] = [...languagesCodes];

    if (userLanguage && availableCodes.indexOf(userLanguage) !== -1) {
      this.localizationService.changeLocale(userLanguage, false);
    } else if (userLanguage) {
      this.localizationService.changeLocale(defaultFallbackLanguage, false);
    }
  }

  private async checkDefaultSettings(data): Promise<boolean> {
    // getting all keys of loaded data
    const defaultSettings = Object.keys(this.defaultSettings);
    const result = await Promise.all(
      defaultSettings.map(async setting => {
        // checking when data[setting] is null or undefined if yes update by default
        if (data[setting] === null || data[setting] === undefined) {
          let defaultValueOfSetting = this.settings[setting];
          // special condition for unset country_id
          if (setting === 'country_id') {
            const countryDefault =
              this.countrySelectService.getCountryByCallingCode(
                parseInt(this.userService.getUser().default_country_code, 10),
              );
            defaultValueOfSetting = countryDefault?.id;
            this.country = countryDefault;
          }
          if (defaultValueOfSetting !== null) {
            const promise = await this.updateUserSettings(
              setting,
              defaultValueOfSetting,
            );
            if (promise && promise.success) {
              data[setting] = defaultValueOfSetting;
            }
          }
        }
      }),
    );
    return !!result;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform<T>(items: T[], property: string, value: any[]): T[] {
    return property === undefined || value === undefined
      ? items
      : Array.isArray(items) && items.length > 0
      ? items.filter(item => item[property] !== value)
      : items;
  }
}

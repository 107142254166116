import { NgModule } from '@angular/core';

import { CountryCodeToRegionPipe } from './country-code-region.pipe';

@NgModule({
  declarations: [CountryCodeToRegionPipe],
  exports: [CountryCodeToRegionPipe],
  providers: [CountryCodeToRegionPipe],
})
export class CountryCodeToRegionModule {}

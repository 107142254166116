<button class="c-country-code-item" [disabled]="disabled" (click)="onClick()">
  <div
    *ngIf="countryCode && callingCode; else selectCountry"
    class="c-country-code-item__code-container">
    <ct-flag-icon
      class="c-country-code-item__code-{{countryCode}}"
      [width]="24"
      [appTestId]="countryCodeItemIdsEnum.COUNTRY_CODE_FLAG"
      [country]="countryCode"
      [isRounded]="true">
    </ct-flag-icon>
    <span
      class="c-country-code-item__code"
      [appTestId]="countryCodeItemIdsEnum.COUNTRY_CODE"
      >+{{ callingCode }}</span
    >
  </div>

  <ng-template #selectCountry>
    <div class="c-country-code-item__select-icon">
      <mat-icon>add</mat-icon>
    </div>
  </ng-template>
</button>

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AttenderUser, Call, ConnectionState } from '@cloudtalk/sip-service';
import { BehaviorSubject, Subject } from 'rxjs';

import {
  SnackBarTypes,
  SnackBarWrapperService,
} from '../../_shared/components/snack-bar-wrapper/snack-bar-wrapper.service';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { CallMonitorLeaveEnum } from '../enums/call-monitor.enum';
import { ActiveCallData } from '../interfaces/active-call-data';
import { CallMonitorInvitationRejectedReason } from '../interfaces/call-monitor-invitation-rejected-reason';
import { CallMonitoringType } from '../interfaces/call-monitoring-type';
import { SocketService } from './networking/socket.service';
import { StatusService } from './status.service';

@Injectable()
export class CallMonitorService {
  isEnabled = false;

  monitoredCallData: ActiveCallData | null;
  monitoredCallData$ = new BehaviorSubject<ActiveCallData | null>(null);
  currentCallMonitoringType: CallMonitoringType = 'none';
  isMonitoredCallHanguped = false;
  initialMonitoringType: CallMonitoringType = 'spy';
  // Ked je true, tak to znamena, ze na monitoring sme sa pripojili tak, ze nas na to niekto invitol.
  isCurrentMonitoringPrecededByInvite = false;

  numberToCall: string;
  isRingingInvitation = false;
  isMonitorMode = false;

  leaveCallMonitor$: Subject<{ action: CallMonitorLeaveEnum }> = new Subject<{
    action: CallMonitorLeaveEnum;
  }>();

  constructor(
    private socketService: SocketService,
    private statusService: StatusService,
    private snackBarWrapperService: SnackBarWrapperService,
    private router: Router,
    private ngZone: NgZone,
  ) {}

  /**
   * Treba sa uz pri vytacani sa prihlasit do roomu attenderov daneho callu.
   * Je to kvoli tomu, lebo moze sa stat, ze kym sa my pripajame na monitorovanie, ten dotycny,
   * ktoreho chceme monitorovat, sa hangupne. Tym padom sme ho este nezacali v skutocnosti
   * monitorovat, ale treba sa aj nam hangupnut, lebo inak by sme zacali monitorovat taky call,
   * ktory uz neexistuje (teoreticky sa to da, lebo v skutocnosti monitorujeme agenta a nie konkretny
   * hovor, ale nema to vyznam a bolo by to matuce).
   * Tak isto je to kvoli updatom v informaciach monitorovaneho callu (momentalne vo Phone updatujeme
   * dynamicky len tagy). Moze sa stat, ze kym sa my pripajame ku monitorovaniu, agent ktoreho chceme
   * monitorovat, si zmeni tagy. Nam sa uz ale jeho tagy zobrazuju aj pri pripajani sa k jeho monitorovanu,
   * a chceme ich dynamicky zmenit aj v takomto stave.
   */
  joinToCallAttenders(): void {
    if (!this.monitoredCallData || !this.monitoredCallData.call_uuid) {
      return;
    }

    this.socketService.emit('call-monitor-join-to-call-attenders-room', {
      call_uuid: this.monitoredCallData.call_uuid,
    });
  }

  /**
   * Joinne na dany call (teda zacne ho monitorovat, teda zacne sa vytacat cislo #9....).
   * @param data
   */
  joinCall(data: {
    active_call: ActiveCallData | null;
    monitoring_type: CallMonitoringType;
  }): void {
    /**
     * Toto by sa nemalo stat v idealnom pripade, teda ked v realtime nemame nejaku chybu. Kazdopadne
     * to osetrime pre ten pripad.
     */
    if (!data.active_call || !data.monitoring_type) {
      return;
    }

    const { active_call: monitoredCallData, monitoring_type: monitoringType } =
      data;
    const currentConnectionState = this.statusService.callingConnectionState;

    let numberToCall;

    /**
     * Cislo na pripojenie ku danemu callu bude vyzerat nasledovne: #9<cislo klapky agenta>.
     * Klapku agenta budeme zistovat podla toho, ci sa jedna o incoming alebo outgoing call (resp. internal).
     */
    if (
      monitoredCallData.type === 'outgoing' &&
      monitoredCallData.source_user &&
      monitoredCallData.source_user.extension
    ) {
      numberToCall = `#9${monitoredCallData.source_user.extension}`;
    } else if (
      monitoredCallData.type === 'incoming' &&
      monitoredCallData.target_user &&
      monitoredCallData.target_user.extension
    ) {
      numberToCall = `#9${monitoredCallData.target_user.extension}`;
    }

    /**
     * V pripade, ze nahodou prave telefonujeme, tak nepustime vytacanie monitorovacieho hovoru.
     * Call Monitor appka by nas nemala pustit v tom pripade, ze mame uz nadviazany hovor (teda hovor je v tabulke active_calls),
     * ale napriklad v pripade, ze hovor nam len zvoni, tak hov or este nie je v active_calls tabulke, tak v tomto pripade
     * nam cancelne monitoring Phone.
     */
    if (
      !numberToCall ||
      currentConnectionState === ConnectionState.UNCONNECTED
    ) {
      this.cancelMonitoring(monitoredCallData.call_uuid);
      return;
    }

    /**
     * V pripade, ze na monitoring sme sa nepripojili cez invite, tak resetneme data co sa tyka monitorovania a
     * priradime nove udaje.
     * V pripade, ze sme sa pripojili cez invite, tak tu to neresetneme, lebo v tom pripade toto sa uz
     * udialo vtedy, ked zacal zvonit invite do Phonu.
     */
    if (!this.isCurrentMonitoringPrecededByInvite) {
      this.refreshMonitorData();
      this.monitoredCallData = monitoredCallData;
      this.monitoredCallData$.next(monitoredCallData);
      this.initialMonitoringType = monitoringType || 'spy';
    }
    this.numberToCall = numberToCall;
    this.isMonitorMode = true;
    this.ngZone.run(() => {
      this.router.navigateByUrl('/p/call-monitor/call').catch(() => {});
    });
  }

  /**
   * Zbehne vzdy vtedy, ked sme sa uspesne pripojili k danemu callu (teda sa nam uspesne nadviazal hovor na
   * cisle #9....).
   */
  joinedToCall(call?: Call): void {
    if (!this.monitoredCallData || !this.monitoredCallData.call_uuid) {
      return;
    }

    /**
     * Posle sa DTMF kod typu (modu) monitoringu, pre spy je to 4, pre whisper 5 a pre barge 6.
     * Neviem preco, ale ked som okamzite pri zahajeni monitorovacieho hovoru poslal DTMF kod typu monitoringu,
     * tak nemal ziaden efekt, preto som sem dal ten setTimeout. Takto to uz funguje.
     */
    setTimeout(() => {
      call
        ?.sendDtmf(this.getDtmfKeyForMonitoringType(this.initialMonitoringType))
        .catch((err: Error) =>
          LoggerUtil.info(CallMonitorService.name + ':sendDtmf() failed', {
            extraContent: { err },
          }),
        );
    }, 500);

    this.currentCallMonitoringType = this.initialMonitoringType;

    /**
     * Napokon posleme event o nasom pripojeni ku monitoringu, aby o tom vedela aj Call Monitor appka.
     */
    this.socketService.emit('call-monitor-joined-to-call', {
      call_uuid: this.monitoredCallData.call_uuid,
      monitoring_type: this.initialMonitoringType,
    });
  }

  /**
   * Ked sa odpojime z monitorovania tak, ze stlacime Disconnect tlacidlo uz v PRIEBEHU monitorovania (teda nie ked este
   * sa len pripaja na monitorovanie, alebo zatvorime Phone appku).
   */
  leftFromCall(): void {
    this.isMonitorMode = false;
    if (
      !this.isMonitoredCallHanguped &&
      this.monitoredCallData &&
      this.monitoredCallData.call_uuid
    ) {
      this.leaveCallMonitor$.next({ action: CallMonitorLeaveEnum.LEAVE_CALL });
      this.socketService.emit('call-monitor-leaved-from-call', {
        call_uuid: this.monitoredCallData.call_uuid,
      });
    }
  }

  /**
   * Ked sa odpojime od monitorovania este pred vzniknutim monitorovacieho callu (teda ked stlacime Disconnect alebo zatvorime appku
   * vtedy, ked este vidime len Connecting na monitorovacom screene).
   * Mam moznost podat callUuid aj explicitne, v tomto pripade nebude brat do uvahy monitoredCallData ale
   * tento callUuid. Toto je uzitocne vtedy, ked monitoredCallData este nemame k dispozicii.
   */
  cancelMonitoring(callUuid?: string): void {
    if (
      !this.isMonitoredCallHanguped &&
      ((this.monitoredCallData && this.monitoredCallData.call_uuid) || callUuid)
    ) {
      this.socketService.emit('call-monitor-cancel-monitoring', {
        call_uuid: callUuid || this.monitoredCallData!.call_uuid,
      });
    } else {
      LoggerUtil.warn('No callUUID. Monitoring will not be canceled');
    }
  }

  /**
   * Ked sa hangupne hovor, ktoreho monitorujeme. V tomto pripade chcem hangupnut aj nase monitorovanie, nema vyznam
   * aby dalej prebiehal.
   * Flag isMonitoredCallHanguped bude sluzit na to, aby sme naznacili ze toto sa stalo. V tomto pripade neposleme uz na server
   * event 'call-monitor-leaved-from-call' resp. 'call-monitor-cancel-monitoring' (vid funkcie vyssie), lebo server o tomto celom uz bude vediet a v tomto
   * momente uz bude nami monitorovany hovor davno vymazany z tabulky active_calls.
   * @param data
   */
  monitoredCallHangedUp(data: { call_uuid: string }): void {
    if (
      this.monitoredCallData &&
      this.monitoredCallData?.call_uuid === data.call_uuid
    ) {
      this.isMonitoredCallHanguped = true;

      /**
       * V pripade, ze uz vytacame monitorovanie alebo uz monitorujeme, tak hangupneme hovor.
       * V pripade, ze nam este len zvoni invite na monitorovanie, tak staci nam len switchnut event
       * na Registered, lebo zvonenie invitu nie je regularny hovor, len to tak vyzera, ale inac z regularnymi
       * incoming callami moc nema nic spolocne okrem vizualu.
       */
      this.leaveCallMonitor$.next({ action: CallMonitorLeaveEnum.HANGUP });
    }
  }

  /**
   * Ked na nami monitorovanom calli sa nieco updatne. Momentalne to mozu byt len tagy (Raise hand a Attended by sem neposielame).
   * Teoreticky mozeme poslat aj viac typov updatnutych udajov naraz, ale zatial vzdy posielame len jeden typ. Takze ked updatujeme tagy,
   * tak vzdy posielame len tagy a nic ine (no zatial vlastne nic ine tu ani neupdatujeme, takze je to jedno).
   * Vzdy posielame vsetky aktualne udaje a nie len zmeny. Takze ked na monitorovanom calli sa prida jeden novy tag, tak neposleme
   * len ten jeden, ale vsetky, ktore sa nachadzaju na tom calli. Tymto vieme lahsie riesit aj to, ze co sa stane, ked niekto odoberie tag -
   * v tom pripade robime to iste, posleme zoznam aktualnych tagov na tom calli. Netreba tak zvlast eventy na pridavanie a mazanie a tak.
   *
   * Hovor updatujeme uz aj v pripade, ze nam zvoni invite na monitoring - aj vtedy chceme dynamicky menit tagy.
   * @param data
   */
  monitoredCallUpdated(data: { call_uuid: string; payload: any }): void {
    if (
      this.monitoredCallData &&
      this.monitoredCallData.call_uuid &&
      (this.isRingingInvitation || this.isMonitorMode)
    ) {
      if (data.call_uuid === this.monitoredCallData.call_uuid) {
        const thingsToUpdateOnTheCall = Object.keys(data.payload);

        thingsToUpdateOnTheCall.forEach(whatAreWeUpdating => {
          this.monitoredCallData![whatAreWeUpdating] =
            data.payload[whatAreWeUpdating];
        });
        this.monitoredCallData$.next(this.monitoredCallData);
      }
    }
  }

  /**
   * Bude sa volat vtedy, ked nam pride socket event, ze niekto nam poslal invite na monitorovanie.
   * Zobrazi nam screen so zvoniacim invite-om.
   * @param data
   */
  showIncomingInvitationRinging(data: {
    active_call: ActiveCallData;
    monitoring_type: CallMonitoringType;
  }): void {
    if (!data.active_call || !data.monitoring_type) {
      return;
    }
    const { active_call: monitoredCallData, monitoring_type: monitoringType } =
      data;
    const currentConnectionState = this.statusService.callingConnectionState;

    /**
     * V pripade, ze uz mame prebiehajuci call, alebo mame after call work, tak nepustime
     * zvonit invite. V tomto pripade ho automaticky rejectneme a o tom posleme aj event.
     *
     * Ked je uz nadviazany hovor, tak nemalo by sa toto stavat, kedze na invite mozeme privolat
     * len takych agentov, ktory maju status online (teda v zozname agentov maju vedla mena zelenu gulicku).
     * Ten zoznam sa ale neupdatuje realtime, takze moze sa stat, ze nacitame ten zoznam a medzitym niektory
     * agent zacne hovor. Alebo napriklad modra gulicka (teda ze agent telefonuje) sa zobrazuje len vtedy,
     * ked je ten hovor uz nadviazany (teda ked uz je call v tabulke active_calls), tak ze sluzi toto aj
     * na osetrenie takych pripadov. Nieco podobne robime aj v pripade metody joinCall() (vid vyssie), tuto ale
     * musime brat do uvahy aj after call work, aby agentovi nezacal zvonit invite ked
     * este mu nezbehol cas na after call work (tak ako sa to deje aj pri klasickych calloch).
     */
    if (currentConnectionState === ConnectionState.UNCONNECTED) {
      this.rejectInvitation('agentBusy', false, monitoredCallData.call_uuid);
      return;
    }

    this.refreshMonitorData();
    this.monitoredCallData = monitoredCallData;
    this.monitoredCallData$.next(monitoredCallData);
    this.initialMonitoringType = monitoringType || 'spy';
    this.isRingingInvitation = true;
    this.ngZone.run(() => {
      this.router
        .navigateByUrl('/p/call-monitor/invitation-ringing')
        .catch(() => {});
    });
  }

  /**
   * Ked nas niekto privola na invite, ale si to rozmysli a klikne na Cancel. V tomto pripade
   * ma prestat zvonit invite invitenutemu agentovi. Dispatchneme Registered event a posleme socket event,
   * reakciou na ktory nas realtime vyhodi z roomu attenderov daneho callu.
   * @param data
   */
  invitationCanceled(data: { call_uuid: string }): void {
    if (
      this.monitoredCallData &&
      this.monitoredCallData.call_uuid &&
      this.isRingingInvitation
    ) {
      if (data.call_uuid === this.monitoredCallData.call_uuid) {
        this.leaveCallMonitor$.next({ action: CallMonitorLeaveEnum.CANCEL });
        this.socketService.emit('call-monitor-leave-from-call-attenders-room', {
          call_uuid: data.call_uuid,
        });
      }
    }
  }

  /**
   * Zbehne vtedy, ked klikneme na zelene "answer" tlacidlo pri zvoneni inviteu na call. V tomto
   * pripade spravime len tolko, ze zavolame joinCall() a od tohto bodu sa bude diat to iste, akoby sme sa
   * joinli na hovor cez Call Monitor.
   */
  acceptInvitation(): void {
    this.joinCall({
      active_call: this.monitoredCallData,
      monitoring_type: this.initialMonitoringType,
    });
  }

  /**
   * Rejectnutie invite-u na call.
   * Moze sa stat vo viacerych pripadoch:
   * - hangupedByAgent - ked klikneme na cervene "reject" tlacidlo pri zvoneni inviteu na call
   * - agentBusy - ked by chcel zvonit invite vtedy, ked agent telefonuje alebo je na after call work screene
   * - error - ked sa stane nejaky error (momentalne sa nepouziva nikde)
   * - agentsAppClosed - ked agent zatvori appku / zatvori tab z phonom v browsri vtedy ked mu zvoni invite na call
   * @param reason
   * @param leaveFromAttendersRoom
   */
  rejectInvitation(
    reason: CallMonitorInvitationRejectedReason,
    leaveFromAttendersRoom: boolean,
    callUuid?: string,
  ): void {
    if (
      (!this.monitoredCallData || !this.monitoredCallData.call_uuid) &&
      !callUuid
    ) {
      LoggerUtil.warn('No callUUID. Invitation will not be rejected');
      return;
    }

    this.socketService.emit('call-monitor-reject-invitation', {
      call_uuid: callUuid || this.monitoredCallData!.call_uuid,
      reason,
      leave_from_attenders_room: leaveFromAttendersRoom,
    });
    this.leaveCallMonitor$.next({
      action: CallMonitorLeaveEnum.REJECT_INVITATION,
    });
  }

  /**
   * Vrati nam DTMF kod typu (modu) monitorovania.
   * @param monitoringType
   */
  getDtmfKeyForMonitoringType(
    monitoringType: CallMonitoringType,
  ): string | null {
    switch (monitoringType) {
      case 'spy':
        return '4';
      case 'whisper':
        return '5';
      case 'barge':
        return '6';
      default:
        return null;
    }
  }

  /**
   * Ako som pisal v predoslej funkcii, tato funkcia sluzi na emitnutie eventu s ucelom ziskania informacie o tom,
   * ci je aktualny call v tabulke active_calls. Vzdy ho fireneme pri vzniknuti hovoru (z call.componentu).
   */
  checkIfCallIsInActiveCallsTable(call: Call): void {
    /**
     * Ziskame info o tom, ci nas call sa uz nachadza v tabulke active_calls.
     * Ked ano, tak chcekneme, ci nanho neprisiel event o tom, ze sa zmazal z tabulky, kym
     * nam server odpovedal na tento event.
     * To iste naopak: ked nie, tak checkneme ci neprisiel event o tom, ze sa pridal do tabulky.
     */
    if (!call.info.call_uuid) {
      return;
    }

    this.socketService.emit<{
      success: boolean;
      data?: { is_active_call_in_db: boolean };
    }>(
      'is-call-in-active-calls-table',
      {
        call_uuid: call.info.call_uuid,
      },
      response => {
        if (response.success && response.data) {
          /**
           * Moze sa nam stat, ze posleme tento event, ale response sa nam trosku bude meskat (predsa moze sa spomalit DB, a pod.).
           * Moze sa ale stat, ze medzitym dostaneme event 'phone-client-active-call-db-state', ktora nam oznami, ze hovor
           * sa zmazal z active_calls. Kvoli meskaniu response z eventu 'is-call-in-active-calls-table', pomeskany response
           * moze obsahovat zastarale udaje (teda v tomto pripade to, ze hovor je stale v tabulke active_calls). Toto tu osetrujeme.
           */
          if (response.data.is_active_call_in_db) {
            call.info.isInActiveCallsTable =
              call.info.isInActiveCallsTable !== false;
          } else {
            call.info.isInActiveCallsTable =
              call.info.isInActiveCallsTable === true;
          }
        }
      },
    );
  }

  monitorTypeToString(callMonitorType: string): string | null {
    switch (callMonitorType) {
      case 'spy':
        return $localize`Listen to call`;
      case 'whisper':
        return $localize`Talk to agent`;
      case 'barge':
        return $localize`Talk to both`;
      default:
        return null;
    }
  }

  /**
   * Inicializujeme socket listenery tykajuce sa call.componentu (jedna je na reagovanie na zmeny
   * stavu callu v tabulke active_calls a druhy zas na reagovanie zmeny attenderov na calli).
   */
  initSocketListeners(call: Call): void {
    this.socketService.on(
      'phone-client-active-call-db-state',
      (data: { call_uuid: string; is_active_call_in_db: boolean }) => {
        if (call.isCalling && call.info.call_uuid) {
          if (data.call_uuid === call.info.call_uuid) {
            call.info.isInActiveCallsTable = data.is_active_call_in_db;
          }
        }
      },
    );

    this.socketService.on(
      'call-monitor-attenders-changed-on-call',
      (data: { call_uuid: string; attended_by_users: AttenderUser[] }) => {
        call.inviteState.attendedUsersOnMyCall = data.attended_by_users;

        /**
         * Changing attenders and checking if one of attenders didnt be invited, if yes, we set invited agent to default null
         */
        if (
          call.inviteState.invitedAgent &&
          call.inviteState.attendedUsersOnMyCall.some(
            attender => attender.id === call.inviteState.invitedAgent.id,
          )
        ) {
          call.inviteState.invitedAgent = null;
        }
      },
    );

    this.socketService.on(
      'call-monitor-invitation-rejected',
      (data: {
        call_uuid: string;
        rejected_by_agent_id: number;
        reason: CallMonitorInvitationRejectedReason;
      }) => {
        if (call.isCalling && call.info.call_uuid) {
          if (
            data.call_uuid === call.info.call_uuid &&
            call.inviteState.invitedAgent?.id.toString() ===
              data.rejected_by_agent_id.toString()
          ) {
            call.inviteState.invitedAgent = undefined;
            // Ked sa nam uspesne podaril reject, tak zobrazime o tom info vo snackbare, ze preco sa nas hovor rejectol.
            this.snackBarWrapperService.openSnackBar({
              message:
                $localize`Invitation was rejected. Reason:` +
                ' ' +
                this.parseInvitationRejectedReason(data.reason),
              snackType: SnackBarTypes.WARNING,
              duration: 3000,
            });
          }
        }
      },
    );
  }

  initCallMonitorSocketListeners(): void {
    this.joinToCallAttenders();
    /**
     * Inicializujeme socket listenery tykajuce sa monitoring screenu.
     * Jedna bude reagovat na to, ked hovor, ktori monitorujeme sa hangupne a druhy, ked monitorovany hovor
     * sa updatne (napriklad sa updatnu jeho tagy).
     */
    this.socketService.on(
      'call-monitor-monitored-call-hanged-up',
      (data: { call_uuid: string }) => {
        this.monitoredCallHangedUp(data);
      },
    );

    this.socketService.on(
      'call-monitor-invitation-canceled',
      (data: { call_uuid: string }) => {
        this.invitationCanceled(data);
      },
    );

    this.socketService.on(
      'call-monitor-monitored-call-updated',
      (data: { call_uuid: string; payload: unknown }) => {
        this.monitoredCallUpdated(data);
      },
    );
  }

  unsubscribeCallMonitorSocketListeners(): void {
    this.socketService.off('call-monitor-monitored-call-hanged-up');
    this.socketService.off('call-monitor-invitation-canceled');
    this.socketService.off('call-monitor-monitored-call-updated');
  }

  unsubscribeSocketListeners(): void {
    this.socketService.off('phone-client-active-call-db-state');
    this.socketService.off('call-monitor-attenders-changed-on-call');
    this.socketService.off('call-monitor-invitation-rejected');
  }

  /**
   * Nastavi udaje monitorovania na defaultne hodnoty. Vola sa to vzdy pri zacati procesu joinovania na call (teda
   * pri volani funkcie joinCall()).
   */
  private refreshMonitorData() {
    this.monitoredCallData = null;
    this.monitoredCallData$.next(null);
    this.currentCallMonitoringType = 'none';
    this.isMonitoredCallHanguped = false;
    this.initialMonitoringType = 'spy';
    this.isCurrentMonitoringPrecededByInvite = false;
    this.isRingingInvitation = false;
    this.isMonitorMode = false;
  }

  /**
   * Vrati nam text dovodu rejectnutia, ktory sa bude zobrazovat v snackbare ked nam niekto rejectne invite na monitoring.
   * @param reason
   */
  private parseInvitationRejectedReason(
    reason: CallMonitorInvitationRejectedReason,
  ) {
    switch (reason) {
      case 'agentBusy':
        return $localize`Agent is busy`;
      case 'agentsAppClosed':
        return $localize`Agent's app closed`;
      case 'hangupedByAgent':
        return $localize`Rejected by agent`;
      case 'error':
        return $localize`Some error happened`;
      default:
        return $localize`Unknown reason`;
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DirectivesModule } from '../../directives/directives.module';
import { FileUploaderComponent } from './file-uploader.component';

@NgModule({
  declarations: [FileUploaderComponent],
  imports: [DirectivesModule, CommonModule],
  exports: [FileUploaderComponent],
})
export class FileUploaderModule {}

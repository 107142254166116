import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { InitialsComponent } from './initials.component';

@NgModule({
  declarations: [InitialsComponent],
  exports: [InitialsComponent],
  imports: [MatIconModule, CommonModule],
})
export class InitialsModule {}

<div class="root">
  <div class="upper-part">
    <div class="warning-title">
      <mat-icon>report_problem</mat-icon>
      <div *ngIf="isSupportedBrowser && !isSupportedVersion" class="text" i18n>
        We are sorry, this browser version is not compatible, please, update
        your browser
      </div>
      <div *ngIf="!isSupportedBrowser" class="text" i18n>
        We are sorry, your browser is not compatible with application. Please
        install our native application.
      </div>
    </div>
  </div>
  <div class="bottom-part">
    <button
      *ngIf="!isSupportedBrowser"
      mat-raised-button
      onclick="location.href='https://www.cloudtalk.io/download-app'">
      <span i18n>Download CloudTalk Phone</span>
    </button>

    <button
      (click)="updateBrowser.emit()"
      *ngIf="isSupportedBrowser && !isSupportedVersion"
      mat-raised-button>
      <span> <span i18n>Update</span> {{ currentBrowser }} </span>
    </button>

    <div *ngIf="isSupportedBrowser && !isSupportedVersion" class="bottom-text">
      <span i18n> For better experience we recommend using </span>
      <a
        class="blur-overlay__link"
        href="https://www.cloudtalk.io/download-app"
        target="_blank">
        CloudTalk Phone
      </a>
      ;)
    </div>
  </div>
</div>

<div
  class="loading-screen full-height blur-overlay__shadow"
  style="line-height: 50vh; background-color: #2196f3">
  <img
    alt="CloudTalk"
    src="/assets/img/logo-cloudtalk-white.svg"
    style="width: 250px" />
</div>

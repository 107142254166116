import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header.component';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}

import { CallRating } from '../../ui-components/call-rating/call-rating';

export interface Activity {
  id?: number;
  type?: string;
  name?: string;
  status?: string;
  created?: string;
  description?: {
    priority_type?: string;
    stage_status_name?: string;
    org_name?: string;
    description_price?: string;
    description_content?: string;
    description_status_color?: string;
    description_status_text?: string;
    stage_name?: string;
    title?: string;
    next_activity_date?: string;
    next_activity_time?: string;
    value?: number;
    state?: string;
    financial_status?: string;
    folderId?: number;
    TOTAL_PRICE?: { PRICE_TO_PAY?: string };
    CURRENCY?: { CODE?: string };
    id?: number;
    total_price?: string;
    price?: string;
    currency?: string;
    order_currency_code: string;
    total_due: number;
    created_at?: string;
    DATE?: string;
    created?: string;
    internal_signature?: string;
    isDraft?: false;
    mailbox?: { id?: number; name?: string };
    mailboxName?: string;
    modifiedAt?: string;
    number?: number;
    owner?: null;
    preview?: string;
    source?: { type?: string; via?: string };
    status?: string;
    STATUS?: string;
    subject?: string;
    tags?: Tag[];
    threadCount?: number;
    threads?: any[];
    type?: string;
    note?: string;
    createddate?: string;
    description?: string;
  };
  activity_author?: string;
  external_url?: string;
  external_id?: number;
  activity_date?: string;
  external_service?: string;
}

export interface ActivitiesCallHistoryItem {
  id?: number;
  call_notes?: any[];
  call_ratings?: CallRating[];
  call_tags?: any[];
  call_type?: {
    type?: string;
    typeLabel?: string;
    icon?: string;
    color?: string;
  };
  calldate?: string;
  type?: string;
}

export interface ActivityContact {
  contact_name?: string;
  contact_id?: number;
  type?: string;
  company?: string;
  contact_emails?: { email?: string; id?: number }[];
  country?: { id?: number; country_code?: number | string; name_sk?: string };
  country_code?: number;
  id?: number;
  number?: string;
  avatar_color?: string;
  activities?: number;
  contact_notes?: any[];
}

export interface ContactActivities {
  contact: ActivityContact;
  contactInfo: ContactInfoActivity[];
  activity: ActivitiesCallHistoryItem[];
  deals: Activity[];
}

export interface ContactInfoActivity {
  contact_id?: number;
  user_id?: number;
  id?: number;
  key?: string;
  note?: string;
  name?: string;
  type?: string;
  icon?: string;
  creator?: string;
}

export interface Tag {
  id?: number;
  name: string;
  color: string;
  tag?: string;
}

export function getAvatarColor(): string {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

export class ActivitiesArray<T> extends Array<T> {
  totalLengthOfActivities = 0;

  constructor(items: T[], totalLength?: number) {
    super(...items);
    this.totalLengthOfActivities = totalLength || 0;
  }
}

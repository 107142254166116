import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { StorageUtils } from '../../_shared/utils/storage.utils';
import { StorageEnum } from '../enums/storage.enum';

@Injectable()
export class StorageGuard implements CanLoad, CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return StorageUtils.storageAvailable(StorageEnum.LOCAL_STORAGE);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return StorageUtils.storageAvailable(StorageEnum.LOCAL_STORAGE);
  }
}

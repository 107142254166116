// Dashboard 2.0
export const DASHBOARD_VALID_LOGIN_EMAIL = 'martin.pajerchinDEV@cloudtalk.io';
export const DASHBOARD_VALID_LOGIN_PASSWORD = 'Cloudtalk.123';

// Phone
export const PHONE_VALID_LOGIN_EMAIL = 'mobile@automation.com';
export const PHONE_VALID_LOGIN_PASSWORD = '#Cloudtalk123';

// Mobile - iOS
export const IOS_VALID_LOGIN_EMAIL = 'ios@automation.com';
export const IOS_VALID_LOGIN_PASSWORD = '#Cloudtalk123';

// Mobile - Android
export const ANDROID_VALID_LOGIN_EMAIL = 'android@automation.com';
export const ANDROID_VALID_LOGIN_PASSWORD = '#Cloudtalk123';

export const PHONE_GOOGLE_VALID_LOGIN_EMAIL = 'cloudtalktesting@gmail.com';
export const PHONE_GOOGLE_VALID_LOGIN_PASSWORD = 'Testautomation.123';
export const PHONE_SSO_EXTERNAL_PROVIDER = 'Okta';
export const PHONE_VALID_LOGIN_EMAIL_NO_SSO = 'tools@cloudtalk.io';

// Invalid login data
export const INVALID_LOGIN_EMAIL = 'invalidemail.io';
export const INVALID_LOGIN_PASSWORD = 'invalidpassword';

export const ctUidCookieContentStage = {
  id: 91477,
  sub: 91477,
  email: 'automation1@cloudtalk.io',
  name: 'Automation 1',
  avatar: null,
  scope: ['app', 'api', 'scenarios'],
  company_id: 117885,
  outbound_id: 1001,
  lang: '',
  exp: Date.now() + 1000000,
  iat: 1676887443,
  mod: '2023-02-20T09:30:02.000Z',
  default_country_code: '421',
  company: { status: 'live', trial_to: null },
  group: 1,
  extension: '1003',
  features: {
    is_queue_dialer_enabled: true,
    is_voicemail_drops_enabled: true,
    use_queue_dialer: true,
    is_whatsapp_enabled: true,
    is_call_monitor_enabled: true,
    is_webhook_enabled: true,
    is_power_dialer_enabled: true,
  },
  interface: 'app',
  app_channel: 'latest',
  iss: 'https://realtime.cloudtalk.io/',
  format: 'UK',
};

export const ctUidCookieContentProd = {
  id: 266175,
  sub: 266175,
  email: 'automation-parallel@cloudtalk.io',
  name: 'Automation Parallel',
  avatar: null,
  scope: ['app', 'api', 'scenarios'],
  company_id: 204235,
  outbound_id: 1001,
  lang: '',
  exp: Date.now() + 1000000,
  iat: 1676631950,
  mod: '2023-02-16T10:50:19.000Z',
  default_country_code: '421',
  company: { status: 'live', trial_to: null },
  group: 1,
  extension: '1003',
  features: {
    is_queue_dialer_enabled: true,
    is_voicemail_drops_enabled: true,
    use_queue_dialer: true,
    is_whatsapp_enabled: true,
    is_call_monitor_enabled: true,
    is_webhook_enabled: true,
    is_power_dialer_enabled: true,
  },
  interface: 'app',
  app_channel: 'latest',
  iss: 'https://realtime.cloudtalk.io/',
  format: 'UK',
};

import { Pipe, PipeTransform } from '@angular/core';

/**
 * extract values as array from Map
 */
@Pipe({ name: 'mapValues' })
export class MapValuesPipe implements PipeTransform {
  transform(entries: Map<unknown, unknown>): unknown[] {
    return Array.from(entries.values());
  }
}

export enum AfterCallContentIdsEnum {
  DONE_BUTTON = 'AfterCallContentDoneButton',
  ASSIGN_CONTACT_BUTTON = 'AfterCallContentAssignContactButton',
  TAGS_BUTTON = 'AfterCallContentTagsButton',
  NOTE_BUTTON = 'AfterCallContentNoteButton',
  NOTE_CONTENT = 'AfterCallContentNoteContent',
  CALL_QUALITY_BUTTON = 'AfterCallContentCallQuality',
  CALL_ACTION_EDIT = 'AfterCallContentCallActionEdit',
  RATING_ONE = 'AfterCallContentRatingEmojiAngry',
  RATING_TWO = 'AfterCallContentRatingEmojiSad',
  RATING_THREE = 'AfterCallContentRatingEmojiSoSo',
  RATING_FOUR = 'AfterCallContentRatingEmojiSmile',
  RATING_FIVE = 'AfterCallContentRatingEmojiInLove',
  POWER_DIALER_SELECT_CALL_DISPOSITION = 'AfterCallContentPowerDialerSelectCallDispistion',
  MANDATORY_ALERT = 'MandatoryAlert',
  SELECT_CALL_DISPOSITION = 'SelectCallDispistion',
}

import { Injectable } from '@angular/core';
import { ActivatedRoute, UrlSerializer, UrlTree } from '@angular/router';
import { Call } from '@cloudtalk/sip-service';
import AppExtensionsSDK, {
  Command,
  Event,
} from '@pipedrive/app-extensions-sdk';
import { distinctUntilChanged } from 'rxjs';

import { PowerDialerService } from '../../../app/pages/power-dialer/power-dialer.service';
import { CommandBusService } from '../../_core/command-bus.service';
import { ActiveCallData } from '../../_core/interfaces/active-call-data';
import { CtiProvider } from '../../_core/models/cti-providers.models';
import { CallMonitorService } from '../../_core/services/call-monitor.service';
import { CallingService } from '../../_core/services/calling/calling.service';
import { StatusService } from '../../_core/services/status.service';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { CtiServiceBase } from '../CtiServiceBase';

@Injectable()
export class PipedriveService extends CtiServiceBase {
  private _sdk_pipedrive: AppExtensionsSDK;

  constructor(
    private activatedRoute: ActivatedRoute,
    private urlSerializer: UrlSerializer,
    private powerDialerService: PowerDialerService,
    private commandBusService: CommandBusService,
    statusService: StatusService,
    callingService: CallingService,
    callMonitorService: CallMonitorService,
  ) {
    super(
      callingService,
      statusService,
      callMonitorService,
      CtiProvider.PIPEDRIVE,
    );
    this.initSDK()
      .then((sdk: AppExtensionsSDK) => {
        this._sdk_pipedrive = sdk;
        this.initListeners();
      })
      .catch(error => {
        LoggerUtil.error('[PipedriveService]: initializeSdk failed', {}, error);
      });
  }

  async initSDK(): Promise<AppExtensionsSDK> {
    const identifier = this.customUiId;
    return await new AppExtensionsSDK({
      identifier,
    }).initialize({
      size: { width: 425, height: 700 },
    });
  }

  initListeners(): void {
    this._sdk_pipedrive.listen(Event.VISIBILITY, ({ error, data }) => {
      if (error) {
        console.warn('PIPEDRIVE:EVENT:VISIBILITY:ERROR ', error);
      }

      console.warn('PIPEDRIVE:EVENT:VISIBILITY: ', data);

      if (data?.context?.callToNumber) {
        console.warn(
          'PIPEDRIVE:EVENT:OUTBOUND_CALL: ',
          data?.context?.callToNumber,
        );

        this.commandBusService.emit({
          command: 'insert-number',
          data: { number: data.context.callToNumber as string },
        });
      }

      if (data?.is_visible && this.ringing) {
        this.hideCloseButton().catch((err: Error) =>
          LoggerUtil.info(PipedriveService.name + ':hideCloseButton() failed', {
            extraContent: { err },
          }),
        );
      }
    });

    this.powerDialerService.autoPickupTimerRunning$
      .pipe(distinctUntilChanged())
      .subscribe(isTimerRunning => {
        console.warn('PIPEDRIVE:isTimerRunning: ', isTimerRunning);
        if (isTimerRunning) {
          this.hideCloseButton().catch((err: Error) =>
            LoggerUtil.info(
              PipedriveService.name + ':hideCloseButton() failed',
              {
                extraContent: { err },
              },
            ),
          );
        } else {
          this.showCloseButton().catch((err: Error) =>
            LoggerUtil.info(
              PipedriveService.name + ':showCloseButton() failed',
              {
                extraContent: { err },
              },
            ),
          );
        }
      });
  }

  onDialing(call: Call): void {
    super.onDialing(call);
    this.hideCloseButton().catch((err: Error) =>
      LoggerUtil.info(PipedriveService.name + ':hideCloseButton() failed', {
        extraContent: { err },
      }),
    );
    console.warn('PIPEDRIVE:onDialing');
  }

  onRinging(call: Call): void {
    this.ringing = true;
    super.onRinging(call);
    this.showFloatingWindow().catch((err: Error) =>
      LoggerUtil.info(PipedriveService.name + ':showFloatingWindow() failed', {
        extraContent: { err },
      }),
    );
    console.warn('PIPEDRIVE:onRinging');
  }

  onCalling(call: Call): void {
    super.onCalling(call);
    this.hideCloseButton().catch((err: Error) =>
      LoggerUtil.info(PipedriveService.name + ':hideCloseButton() failed', {
        extraContent: { err },
      }),
    );
    console.warn('PIPEDRIVE:onCalling');
  }

  onHangUp(call: Call): void {
    super.onHangUp(call);
    console.warn('PIPEDRIVE:onHangUp');
  }

  onEnded(call: Call): void {
    super.onEnded(call);
    if (!this.isIncomingCallWidget) {
      this.showCloseButton().catch((err: Error) =>
        LoggerUtil.info(PipedriveService.name + ':showCloseButton() failed', {
          extraContent: { err },
        }),
      );
    }
    console.warn('PIPEDRIVE:onEnded');
  }

  onCallMonitorEvent(monitoredCallData: ActiveCallData | null): void {
    super.onCallMonitorEvent(monitoredCallData);
    if (monitoredCallData != null) {
      console.warn('PIPEDRIVE:CALL_MONITOR:FROM_PARENT');
      this.showFloatingWindow()
        .then(() => this.hideCloseButton())
        .catch((err: Error) =>
          LoggerUtil.info(
            PipedriveService.name + ':showFloatingWindow() failed',
            {
              extraContent: { err },
            },
          ),
        );
    }
  }

  onLeaveCallMonitor(): void {
    super.onLeaveCallMonitor();
    console.warn('PIPEDRIVE:CALL_MONITOR:LEFT_CALL_MONITOR');
    this.showCloseButton().catch((err: Error) =>
      LoggerUtil.info(PipedriveService.name + ':showCloseButton() failed', {
        extraContent: { err },
      }),
    );
  }

  async hideCloseButton(): Promise<void> {
    console.warn('PIPEDRIVE:COMMAND:HIDE_CLOSE_BUTTON');
    await this._sdk_pipedrive.execute(Command.SET_FOCUS_MODE, true);
  }

  async showCloseButton(): Promise<void> {
    console.warn('PIPEDRIVE:COMMAND:SHOW_CLOSE_BUTTON');
    await this._sdk_pipedrive.execute(Command.SET_FOCUS_MODE, false);
  }

  async showFloatingWindow(): Promise<void> {
    console.warn('PIPEDRIVE:COMMAND:SHOW_FLOATING_WINDOW');
    await this._sdk_pipedrive.execute(Command.SHOW_FLOATING_WINDOW, {});
  }

  /**
   * Getting custom UI ID that is passed by pipedrive
   * Covering cases
   * 1. Normal load by pipedrive
   * 2. Load with redirect to Login screen
   * */
  private get customUiId(): string {
    const customUiID = this.activatedRoute.snapshot.queryParams['id'];
    if (customUiID != null) {
      return customUiID;
    }
    const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
    if (returnUrl != null) {
      const serializedUrl: UrlTree = this.urlSerializer.parse(returnUrl);
      return serializedUrl.queryParams['id'];
    }
    return undefined;
  }
}

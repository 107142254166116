import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { UnsupportedBrowserBlurComponent } from './unsupported-browser-blur.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [UnsupportedBrowserBlurComponent],
  exports: [UnsupportedBrowserBlurComponent],
})
export class UnsupportedBrowserBlurModule {}

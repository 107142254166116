import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

import { EnvironmentService } from './environment.service';
import { UserService } from './user/user.service';
import { CountryCodeToRegionPipe } from '../../_shared/pipes/country-code-to-region/country-code-region.pipe';
import { Browser } from './browser.service';
import { languagesCodes } from '../../languages';
import { environment } from '../../../environments/environment';
import { PhoneStatusService } from './phone-status.service';
import { CallingService } from './calling/calling.service';
import { User } from '../models/user';
import { DocumentService } from './document.service';
import { AnalyticsDataType } from '../types/analytics-data.type';
import { LocalStorageService } from './local-storage.service';
import { ElectronService } from './electron/electron.service';

@Injectable()
export class AnalyticsService {
  get callTitle(): string {
    return this.title.getTitle() === 'CloudTalk Phone'
      ? null
      : this.title.getTitle();
  }

  callIsMinimized: boolean;

  private actual_url: string = null;
  private path_title: string = null;

  constructor(
    private environmentService: EnvironmentService,
    private router: Router,
    private user: UserService,
    private countryCodeToRegionPipe: CountryCodeToRegionPipe,
    private phoneStatus: PhoneStatusService,
    private electronService: ElectronService,
    private activatedRoute: ActivatedRoute,
    private callingService: CallingService,
    private title: Title,
    private documentService: DocumentService,
    private localStorageService: LocalStorageService,
  ) {}

  initAnalytics(): void {
    if (this.environmentService.isLive()) {
      // GA scripts initialization
      this.headScripts();
      this.bodyNoScript();

      // if we have user init user data in data layer
      if (this.user.getUser()) {
        this.initDataLayer();
      } else {
        // after user login init
        this.user.user$.subscribe((newUser: User) => {
          if (newUser !== null) {
            this.initDataLayer();
          }
        });
      }

      // handle route change
      this.router.events
        .pipe(
          filter((event: RouterEvent) => event instanceof NavigationEnd),
          map((event: NavigationEnd) => {
            let route = this.activatedRoute;
            // setting actual url
            this.actual_url = event.urlAfterRedirects;
            while (route?.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route: ActivatedRoute) => route.outlet === 'primary'),
          map((route: ActivatedRoute) => route.snapshot.data.title),
        )
        .subscribe((pathString: string) => {
          // getting route data title and updating path_title
          this.path_title = pathString;
          this.pushToDataLayer();
        });
    }
  }

  /*
   * we call this method without params when we wanna record actual url (manually)
   * if called when its call, screenTitle() was called to get title by call event
   * if path is not null we concat them to to he pageTitle
   */
  triggerNonRoutePage(
    path: string = null,
    title: string = null,
    rawTitle: string = null,
  ): void {
    let pagePath: string;
    let pageTitle: string;
    if (this.itsCall() && !this.callIsMinimized) {
      pagePath = this.callTitle
        ? this.callTitle.replace(/ /g, '-')?.toLowerCase()
        : '';
      if (path) {
        pagePath = pagePath.concat('/' + path);
      }
      pageTitle = this.callTitle;
      if (title && pageTitle) {
        pageTitle = pageTitle.concat(' ' + title);
      }
    } else {
      pagePath = path;
      pageTitle = title;
    }
    this.pushToDataLayer(pagePath, pageTitle, rawTitle ? rawTitle : title);
  }

  getInitAnalyticsData(user: User): AnalyticsDataType {
    let country = '';
    let analyticsDataType = <AnalyticsDataType>{};

    if (user) {
      if (user.default_country_code) {
        country = this.countryCodeToRegionPipe.transform(
          user.default_country_code,
        );
      }
      const appSettings = this.localStorageService.getItem('ct-user-settings');
      let appLanguage = null;
      if (appSettings) {
        appLanguage =
          languagesCodes.indexOf(appSettings.app_language) !== -1
            ? appSettings.app_language
            : 'en';
        if (
          appLanguage.app_language === 'cs' ||
          appLanguage.app_language === 'cz'
        ) {
          appLanguage = 'cs';
        }
      }
      analyticsDataType = {
        company_id: user.company_id,
        user_id: user.id,
        user_data: { features: user.features, token_expiration: user.exp },
        status:
          user.company && user.company.status ? user.company.status : 'unknown',
        country_code: country,
        language: appLanguage ? appLanguage : 'en',
        browser_language: navigator.language,
        os: this.environmentService.getOS(),
        user_agent: navigator.userAgent,
        web_version: environment.version,
        desktop_app_version: this.environmentService.getElectronAppVersion()
          ? this.environmentService.getElectronAppVersion()
          : null,
        electron_version: this.electronService.electronVersion,
        browser: Browser.info(),
        onboarding: !this.localStorageService.getItem(
          `onboarding-skipped-${user.id}`,
        ), // @ToDo striky - possible bug? !string ('false'/'true') is always false
        environment: this.environmentService.env.env,
        phone_status: this.phoneStatus.status,
        current_url: window.location.href,
      };
    }
    return analyticsDataType;
  }

  // initialization basic data about user
  private initDataLayer(): void {
    const user = this.user.getUser();
    let country = '';
    if (user) {
      if (user.default_country_code) {
        country = this.countryCodeToRegionPipe.transform(
          user.default_country_code,
        );
      }

      this.documentService.nativeWindow.dataLayer.push({
        cId: user.company_id,
        uId: user.id,
        status:
          user.company && user.company.status ? user.company.status : 'unknown',
        countryCode: country,
        language: user.lang ? user.lang : '',
        os: this.environmentService.getOS(),
        version: environment.version,
        onboarding: !this.localStorageService.getItem(
          `onboarding-skipped-${user.id}`,
        ),
        environment: this.environmentService.env.env,
      });
    }
  }

  /*
   * Pushing new PageView object to dataLayer
   *
   * detailedPagePath -> when @path is not null we append @path to this.actual_url and if its screen on the call,
   * add /call between url and @path else this.actual_url and append the /call by the condition
   *
   * pageTitle -> if @title is defined use that else path_title
   *
   * pagePath -> when @rawTitle is not null use that, else use screenTitle when its call ,
   * if its not call and rawTitle is null use this.path_title + @path (if path is not null)
   */
  private pushToDataLayer(path = null, title = null, rawTitle = null): void {
    if (this.environmentService.isLive()) {
      const detailedPagePath = path
        ? this.actual_url +
          (this.itsCall() && !this.callIsMinimized ? '/call' : '') +
          `/${path}`
        : this.actual_url
        ? this.actual_url +
          (this.itsCall() && !this.callIsMinimized ? '/call' : '')
        : 'undefined';
      let pageTitle = title
        ? title
        : this.path_title
        ? this.path_title
        : 'undefined';
      const pagePath = rawTitle
        ? rawTitle
        : this.itsCall() && !this.callIsMinimized
        ? this.callTitle
        : path
        ? this.path_title + '/' + path
        : this.path_title
        ? this.path_title
        : '';
      if (this.itsCall() && this.callIsMinimized) {
        pageTitle = pageTitle.concat(' in call');
      }
      this.documentService.nativeWindow.dataLayer.push({
        event: 'Pageview',
        pagePath:
          '/' + (pagePath ? pagePath.replace(/ /g, '-')?.toLowerCase() : ''),
        detailedPagePath,
        pageTitle,
      });
    }
  }

  private itsCall(): boolean {
    return this.callingService.isCallEvent;
  }

  private headScripts(): void {
    const replaceFirst = document.getElementById('gFiScript');
    const firstScript = document.createElement('script');
    firstScript.dataset.cfasync = 'false';
    firstScript.text = 'window.dataLayer = window.dataLayer || [];';
    if (replaceFirst && firstScript) {
      replaceFirst.parentNode.replaceChild(firstScript, replaceFirst);
    }

    const secondReplace = document.getElementById('gSeScript');
    const secondScript = document.createElement('script');
    secondScript.dataset.cfasync = 'false';
    /* eslint-disable */
    secondScript.text =
      '(function (w, d, s, l, i) {' +
      '    w[l] = w[l] || [];' +
      '    w[l].push({' +
      "      'gtm.start':" +
      "        new Date().getTime(), event: 'gtm.js'" +
      '    });' +
      '    var f = d.getElementsByTagName(s)[0],' +
      "      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';" +
      '    j.async = true;' +
      '    j.src =' +
      "      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;" +
      '    f.parentNode.insertBefore(j, f);' +
      "  })(window, document, 'script', 'dataLayer', 'GTM-T97CTQL');";
    /* eslint-enable */
    if (secondReplace && secondScript) {
      secondReplace.parentNode.replaceChild(secondScript, secondReplace);
    }
  }

  private bodyNoScript(): void {
    const replaceNoScript = document.getElementById('gNoScript');
    const noScript = document.createElement('noscript');
    noScript.innerText =
      '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T97CTQL" ' +
      'height="0" width="0" style="display:none;visibility:hidden"></iframe>';

    if (replaceNoScript && noScript) {
      replaceNoScript.parentNode.replaceChild(noScript, replaceNoScript);
    }
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CallTag } from '@cloudtalk/sip-service';
import { catchError, map, startWith } from 'rxjs/operators';

import { EndpointService } from '../../../_core/services/networking/endpoint.service';
import { LoadStateEnum } from '../../../_core/enums/load-state.enum';
import {
  LoadedDataState,
  LoadedNoSuccessData,
} from '../../../_core/interfaces/loading-status.interface';
import { CTResponse } from '../../../_core/interfaces/ctresponse.interface';

export interface TagChanges {
  id: string;
  activeTags: CallTag[];
}

@Injectable()
export class CallTaggingService {
  tagsChanges$: EventEmitter<TagChanges> = new EventEmitter();

  constructor(private endpointService: EndpointService) {}

  tagsChanged(tagChanges: TagChanges): void {
    this.tagsChanges$.emit(tagChanges);
  }

  loadAllTags(path: string): Observable<LoadedDataState<CallTag[]>> {
    return this.endpointService._get(`call-tags/${path}`).pipe(
      map(tagRes => {
        return {
          data: tagRes,
          status: tagRes ? LoadStateEnum.LOADED : LoadStateEnum.ERROR,
        } as LoadedDataState<CallTag[]>;
      }),
      startWith<LoadedDataState<CallTag[]>>({
        status: LoadStateEnum.LOADING,
      }),
      catchError(
        (): Observable<LoadedNoSuccessData> =>
          of({
            status: LoadStateEnum.ERROR,
          }),
      ),
    );
  }

  loadCallTags(cdrId: number): Observable<CallTag[]> {
    return this.endpointService._get(`call-tags/view/cdr/${cdrId}`);
  }

  saveTags(
    currentTagType: string,
    currentTagId: string,
    tagIds: number[],
  ): Observable<CTResponse<string>> {
    return this.endpointService._patch<string, { call_tag_id: number[] }>(
      `call-tags/add/${currentTagType}/${currentTagId}`,
      {
        call_tag_id: tagIds,
      },
      false,
    );
  }
}

import { Injectable } from '@angular/core';

import { StatusService } from './status.service';
import { EnvironmentService } from './environment.service';

@Injectable()
export class ConnectionService {
  private connection: any;

  constructor(
    private statusService: StatusService,
    private environmentService: EnvironmentService,
  ) {
    this.connection =
      navigator['connection'] ||
      navigator['mozConnection'] ||
      navigator['webkitConnection'];
  }

  requirementsMet() {
    if (this.environmentService.isProduction) {
      this.browserNetworkStats();
    }
  }

  /**
   * Zisti stats z browsera, v pripade zleho connection informuje usera
   */
  browserNetworkStats() {
    this.statusService.isCorrectlyConnected(null);
  }

  /**
   * Nacita obrazok bez cache
   * @param url
   * @returns {Promise<HTMLImageElement>}
   */
  requestImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        reject(url);
      };
      img.src =
        url +
        '?random-no-cache=' +
        Math.floor((1 + Math.random()) * 0x10000).toString(16);
    });
  }

  /**
   * Pinguje url pomocou nacitania obrazka
   * @param  {String} url
   * @param  {Number} multiplier - optional, factor to adjust the ping by.  0.3 works well for HTTP servers.
   * @return {Promise} promise that resolves to a ping (ms, float).
   */
  ping(url, multiplier = 1) {
    return new Promise((resolve, reject) => {
      const start = new Date().getTime();
      const response = () => {
        let delta = new Date().getTime() - start;
        delta *= multiplier || 1;
        resolve(delta);
      };
      this.requestImage(url).then(response).catch(response);

      // Set a timeout for max-pings, 5s.
      setTimeout(function () {
        reject(Error('Timeout'));
      }, 5000);
    });
  }
}

import { dynamicId } from '../utils/utils';

export enum OnboardingIdsEnum {
  LANGUAGE_SELECT_BUTTON = 'languageSelectButton',
  CONNECT_HEADSET_SKIP_LINK_BUTTON = 'connectHeadseSkipLinkButton',
  TEST_SPEAKERS_SKIP_LINK_BUTTON = 'testSpeakersSkipLinkButton',
  TEST_CALL_SKIP_LINK_BUTTON = 'testCallSkipLinkButton',
  OPTIMIZE_CALL_QUALITY_SKIP_LINK_BUTTON = 'optimizeCallSkipLinkButton',
  POOR_QUALITY_SKIP_LINK_BUTTON = 'poorQualitySkipLinkButton',
  SETUP_MICROPHONE_SPEAKERS_SKIP_LINK_BUTTON = 'setupMicrophoneSpeakersSkipLinkButton',
  CONTINUE_BUTTON = 'continueButton',
  NEXT_BUTTON = 'nextButton',
  NEXT_BUTTON_CONNECT_HEADSET = 'nextButtonConnectHeadset',
  NEXT_BUTTON_TIPS = 'Next_Button_Tips',
  NEXT_BUTTON_GREAT = 'Next_Button_Great',
  TEST_CALL_BUTTON = 'testCallButton',
  SAVE_AND_TRY_BUTTON = 'saveAndTryButton',
  SAY_SOMETHING_YES_BUTTON = 'saySomethingYesButton',
  SAY_SOMETHING_NO_BUTTON = 'saySomethingNoButton',
  POOR_QUALITY_TRY_AGAIN_BUTTON = 'PoorQualityTryAgainButton',
  AUDIO_INPUT_SELECT = 'AudionInputSelectDropDown',
  AUDIO_OUTPUT_SELECT = 'AudionOutputSelectDropDown',
  AUDIO_INPUT_SELECT_OPTION = 'AudionInputSelectDropDownOption',
  AUDIO_OUTPUT_SELECT_OPTION = 'AudionOutputSelectDropDownOption',
  TRY_AGAIN = 'NotConnectedTryAgain',

  // href links in onboarding
  LEARN_MORE_CONNECT_LINK = 'learnMoreConnectLink',
  PROFESSIONAL_HEADSET_LINK = 'professionalHeadsetLink',
  HELPDESK_ARTICLE_LINK = 'helpDeskArticleLink',
  POOR_QUALITY_HEADSET_LINK = 'poorQualityHeadsetLink',
  POOR_QUALITY_SYSTEM_REQUIREMENTS_LINK = 'poorQualitySystemRequirementsLink',
  SETTINGS_LINK = 'settingsLink',
  CONTCT_US_LINK = 'contactUsLink',
  NOT_CONNECTED_LINK = 'notConnectedLink',
}

export const englishLanguage = dynamicId(
  OnboardingIdsEnum.LANGUAGE_SELECT_BUTTON,
  '-en',
);

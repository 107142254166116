<a
  class="router-link"
  [routerLink]="linkTo || routerLinkHref"
  routerLinkActive
  #link="routerLinkActive">
  <ct-icon
    *ngIf="icon"
    [size]="iconSize"
    [name]="icon"
    [isActive]="link.isActive"
    [isMaskIcon]="true"
    [isDisabled]="isDisabled"
    class="navigate-item__icon">
  </ct-icon>
</a>

import { EventEmitter, Injectable, Output } from '@angular/core';
import { IpcRendererEvent } from 'electron/main';

import { ElectronService } from './electron/electron.service';

export class IpcCommandTypes {
  static shortcuts = {
    Alt_plus_A: 'Alt+A',
    Alt_plus_H: 'Alt+H',
    Alt_plus_C: 'Alt+C',
    Alt_plus_M: 'Alt+M',
    Alt_plus_V: 'Alt+V',
    Alt_plus_T: 'Alt+T',
  };
  static commands = {
    dial_number: 'dial-number',
    settings: 'settings',
    notification_clicked: 'notification-clicked',
    resize_window: 'resize-window',
  };
  static info = {
    suspend: 'suspend',
    resume: 'resume',
  };
}

export class IpcCommand {
  public event: string;
  public data: any;

  public constructor(event: string, data: any) {
    this.event = event;
    this.data = data;
  }
}

@Injectable()
export class IpcService {
  @Output() commandEmitter = new EventEmitter();

  constructor(private electronService: ElectronService) {}

  register(): void {
    if (this.electronService.ipcRenderer) {
      this.electronService.ipcRenderer.on(
        'shortcut',
        (event: IpcRendererEvent, data: any) => {
          const shortcut = data.length === 1 ? data[0] : data.shift();
          this.commandReceived(new IpcCommand(shortcut, data));
        },
      );
      this.electronService.ipcRenderer.on(
        'dial-number',
        (event: IpcRendererEvent, data: any) => {
          this.commandReceived(
            new IpcCommand(IpcCommandTypes.commands.dial_number, data[0]),
          );
        },
      );
      this.electronService.ipcRenderer.on(
        'settings',
        (event: IpcRendererEvent, data: any) => {
          this.commandReceived(
            new IpcCommand(IpcCommandTypes.commands.settings, JSON.parse(data)),
          );
        },
      );
      this.electronService.ipcRenderer.on(
        'notification-clicked',
        (event: IpcRendererEvent, data: any) => {
          this.commandReceived(
            new IpcCommand(
              IpcCommandTypes.commands.notification_clicked,
              JSON.parse(data),
            ),
          );
        },
      );
      this.electronService.ipcRenderer.on(
        'resize-window',
        (event: IpcRendererEvent, data: any) => {
          this.commandReceived(
            new IpcCommand(
              IpcCommandTypes.commands.resize_window,
              JSON.parse(data),
            ),
          );
        },
      );
    }
  }

  commandReceived(command: IpcCommand): void {
    this.commandEmitter.emit(command);
  }

  send(event: string, data: unknown): boolean {
    if (
      this.electronService.isElectronApp &&
      this.electronService.ipcRenderer
    ) {
      this.electronService.ipcRenderer.send(event, JSON.stringify(data));
      return true;
    }
    return false;
  }
}

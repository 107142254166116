export enum TrackingActionsEnum {
  SMART_DIALER_START_SESSION = 'smart_dialer_start_session',
  CALLED = 'called',
  VISIT = 'visit',
  CALL_TRANSFERRED = 'call_transferred',
  OPEN_DETAIL = 'open_detail',
  LEAVE_DETAIL = 'leave_detail',
  OPEN_DETAIL_DASHBOARD = 'open_detail_dashboard',
  FILTER_MESSAGES = 'filter_messages',
  CHOOSE = 'choose',
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationService } from './authentication.service';
import { AuthenticationRepository } from './authentication.repository';

@NgModule({
  imports: [CommonModule],
  providers: [AuthenticationRepository, AuthenticationService],
})
export class AuthenticationModule {}

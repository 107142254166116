import { dynamicId } from '../utils/utils';

export enum ContactDetailIdsEnum {
  INFORMATIONS_BUTTON = 'InformationsButton',
  HISTORY_BUTTON = 'HistoryButton',
  CONTACT_NAME = 'ContactName',
  CONTACT_NUMBER_CELL = 'ContactNumberCell',
  CONTACT_TAGS_CELL = 'ContactTagsCell',
  CONTACT_EMAIL_CELL = 'ContactEmailCell',
  CONTACT_COMPANY_CELL = 'ContactCompanyCell',
  CONTACT_WEBSITE_CELL = 'ContactWebsiteCell',
  CONTACT_CITY_CELL = 'ContactCityCell',
  CONTACT_COUNTRY_CELL = 'ContactCityCell',
  CONTACT_EDIT_BUTTON = 'ContactEditButton',
  CONTACT_BUTTON_ADD_EDIT_TAGS = 'ContactAddEditTags',
  CONTACT_BUTTON_ADD_NOTE = 'ContactAddNote',
  CONTACT_TAG_BADGE = 'TagBadge',
  CONTACT_TAG_MODAL_BADGE = 'TagBadgeModal',
  CONTACT_BUTTON_BACK = 'ContactButtonBack',
  CONTACT_ACTIVITY_EDIT = 'ContactActivityEdit',
  CONTACT_ACTIVITY_DELETE = 'ContactActivityDelete',
  CONTACT_ADD_NOTE_INPUT = 'AddNoteInput',
  CONTACT_HISTORY_LIST = 'ContactHistoryList',
  CONTACT_HISTORY_LIST_ITEM = 'ContactHistoryListItem',
  CONTACT_HISTORY_LIST_ITEM_TITLE = 'ContactActivityListItemTitle',
  CONTACT_HISTORY_LIST_ITEM_DESCRIPTION = 'ContactActivityListItemDescription',
  CONTACT_HISTORY_LIST_ITEM_AVATAR = 'ContactActivityListItemAvatar',
  CONTACT_BUTTON_SMS = 'ContactButtonSMS',
  CONTACT_BUTTON_CALL = 'ContactButtonCall',
  CONTACT_MODAL = 'ContactModal',
  CONTACT_MODAL_TITLE = 'ContactModalTitle',
  CONTACT_ACTIVITY_ICON = 'ContactActivityIcon',
}

export const contact_number_cell_421221293555 = dynamicId(
  ContactDetailIdsEnum.CONTACT_NUMBER_CELL,
  '-+421221293555',
);
export const contact_emil_cell_jan_jakubec = dynamicId(
  ContactDetailIdsEnum.CONTACT_EMAIL_CELL,
  '-jan.jakubec@cloudtalk.io',
);
export const email_external_detox = dynamicId(
  ContactDetailIdsEnum.CONTACT_EMAIL_CELL,
  '-automation@testing.io',
);

export const number_external_detox = dynamicId(
  ContactDetailIdsEnum.CONTACT_NUMBER_CELL,
  '-+17206178069',
);

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { StatusService } from '../../_core/services/status.service';
import { CallingService } from '../../_core/services/calling/calling.service';

@Injectable()
export class BlurContentService {
  private dismissed: Subject<string> = new Subject();

  dismissed$ = this.dismissed.asObservable();

  constructor(
    private status: StatusService,
    private callingService: CallingService,
  ) {}

  public get hasOverlay() {
    return (
      (this.status.phoneStatus.isAlreadyRegistered &&
        !this.callingService.isCallEvent) ||
      this.status.phoneStatus.isTestingVoice
    );
  }

  public dismiss(type: string) {
    this.dismissed.next(type);
  }
}

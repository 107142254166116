import { Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { StorageGuard } from './_core/guards/storage.guard';

export const appRoutes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        canActivate: [StorageGuard],
        canLoad: [StorageGuard],
        loadChildren: () =>
          import('./pages/phone-client/phone-client.module').then(
            m => m.PhoneClientModule,
          ),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then(m => m.LoginModule),
        data: { title: 'Login' },
      },
      {
        path: '**',
        redirectTo: '',
        data: { title: '404' },
      },
    ],
  },
];

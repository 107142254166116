<app-wrapper>
  <div
    *ngIf="!isLocalStorageEnabled; else showUnsupportedBrowserBlur"
    class="bootstrap__not-supported">
    <div class="bootstrap__not-supported-content">
      <div class="bootstrap__not-supported-content--center">
          <ct-icon
              name="alert-triangle"
              size="48"
              shouldCover="true"
          >
          </ct-icon>
      </div>
      <h1 class="bootstrap__not-supported-title" i18n>
        We are sorry, for using our App requires that Local Storage is enabled
        in your browser
      </h1>
      <h2 class="bootstrap__not-supported-sub-title" i18n>
        Find the browser you are using in the list below and follow the
        instructions to enable cookies.
      </h2>
      <h3>Google Chrome</h3>
      <ol class="bootstrap__not-supported-list">
        <li i18n>
          Click the context menu in the browser toolbar to the right of the
          address bar.
        </li>
        <li i18n>Choose Settings.</li>
        <li i18n>Click "Privacy and Security."</li>
        <li i18n>
          Click "Cookies and other site data" in the Privacy section.
        </li>
        <li i18n>
          Ensure that the bullet for "Allow all Cookies" or "Block third-party
          cookies in Incognito" is checked.
        </li>
        <li i18n>Reload our Application.</li>
      </ol>
      <h3>Opera</h3>
      <ol class="bootstrap__not-supported-list">
        <li i18n>Go to Settings.</li>
        <li i18n>
          Click Advanced in the left sidebar, and click Privacy & security.
        </li>
        <li i18n>Under Privacy and security, click "Site Settings".</li>
        <li i18n>Click "Cookies and site data" in the Content section.</li>
        <li i18n>
          Ensure that the bullet for "Allow all Cookies" or "Block third-party
          cookies in Incognito" is checked.
        </li>
        <li i18n>Reload our Application.</li>
      </ol>
      <div class="bootstrap__not-supported-button-wrap">
          <app-button
                  btnType="primary"
                  (click)="reloadApp()"
                  i18n>
              Reload
          </app-button>
      </div>
    </div>
  </div>
</app-wrapper>
<ng-template #showUnsupportedBrowserBlur>
  <app-unsupported-browser-blur
    *ngIf="!isSupportedBrowser || !isSupportedVersion; else showRouterOutlet"
    (updateBrowser)="updateBrowserUrl()"
    [currentBrowser]="currentBrowser"
    [isSupportedBrowser]="isSupportedBrowser"
    [isSupportedVersion]="isSupportedBrowser"></app-unsupported-browser-blur>
</ng-template>
<ng-template #showRouterOutlet>
  <router-outlet></router-outlet>
</ng-template>

import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CallButtonsIdsEnum, HeaderIdsEnum } from 'test-automation';

@Component({
  templateUrl: './conference-hangup.component.html',
  styleUrls: ['./conference-hangup.component.scss'],
})
export class ConferenceHangupComponent {
  readonly callButtonsIdsEnum = CallButtonsIdsEnum;
  readonly headerIdsEnum = HeaderIdsEnum;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ConferenceHangupComponent>,
  ) {}

  close(event: MouseEvent, result: string = ''): void {
    this.bottomSheetRef.dismiss(result);
    event.preventDefault();
  }
}

import { Call } from '@cloudtalk/sip-service';
import { decode } from 'js-base64';
import moment from 'moment';
import { concat, delay, Observable, of, skip } from 'rxjs';

import { environment } from '../../../environments/environment';
import { WindowSizeStateEnum } from '../../_core/enums/window-size-state.enum';
import { User } from '../../_core/models/user';
import { SupportedSsoProvider } from '../../pages/login/login.types';
import {
  SnackBarTypes,
  SnackBarWrapperService,
} from '../components/snack-bar-wrapper/snack-bar-wrapper.service';
import { LoggerUtil } from '../utils/logger.util';

export function hasOwnProperty(object: any, property: string): boolean {
  if (!object) {
    return false;
  }
  return Object.prototype.hasOwnProperty.call(object, property);
}
export function getNestedProperty<T>(
  obj: T,
  path: string,
): T[keyof T] | undefined {
  return path
    .split('.')
    .reduce(
      (nestedObj, key) =>
        nestedObj && typeof nestedObj === 'object' && key in nestedObj
          ? nestedObj[key]
          : undefined,
      obj as T,
    ) as T[keyof T];
}

export function extractDeepPropertyByMapKey(
  obj: unknown,
  map: string,
): unknown {
  const keys = map.split('.');
  const head = keys.shift();

  return keys.reduce((prop: unknown, key: string) => {
    return prop !== null && prop !== undefined && prop[key] !== undefined
      ? prop[key]
      : undefined;
  }, obj[head || '']);
}
export function finalizeSSOUrl(
  identityProvider: SupportedSsoProvider,
  origin: string,
): string {
  const redirectUri = [
    origin,
    environment.ssoAuthConfig.oauth.redirectSignIn,
  ].join('');
  const responseType =
    environment.ssoAuthConfig.oauth.responseType.toUpperCase();
  const scope = environment.ssoAuthConfig.oauth.scope.join('+');
  return [
    'https://',
    identityProvider === 'Google'
      ? environment.ssoAuthConfig.oauth.newDomain
      : environment.ssoAuthConfig.oauth.domain,
    '/oauth2/authorize?identity_provider=',
    identityProvider,
    '&redirect_uri=',
    redirectUri,
    '&response_type=',
    responseType,
    '&client_id=',
    environment.ssoAuthConfig.userPoolWebClientId,
    '&scope=',
    scope,
  ].join('');
}

export function decodeEntities(encodedString) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return textArea.value;
}

export function parseJwt(token: string): User {
  {
    if (!token) {
      return null;
    }
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return Object.assign(new User(), JSON.parse(decode(base64)));
    } catch (e) {
      LoggerUtil.error('[HelperFunctions]: Parse JWT failed', {}, e);
      return null;
    }
  }
}

export function getMomentFromTimestamp(timestamp: number): moment.Moment {
  return moment.unix(timestamp);
}

export function getDiffDays(timestamp: number): number {
  return moment.unix(timestamp).diff(moment(), 'days');
}

export function getDiffHours(timestamp: number): number {
  return moment.unix(timestamp).diff(moment(), 'hours');
}

export function copyToClipboard(
  text: string | undefined,
  message: string,
  snackBarRef: SnackBarWrapperService,
): void {
  if (text === undefined) {
    snackBarRef.openSnackBar({
      message: $localize`Could not copy text`,
      snackType: SnackBarTypes.ERROR,
    });
    return;
  }

  navigator.clipboard
    .writeText(text)
    .then(() => {
      snackBarRef.openSnackBar({
        message: $localize`${message}`,
        snackType: SnackBarTypes.INFO,
      });
    })
    .catch(err => {
      console.error('Async: Could not copy text: ', err);
      snackBarRef.openSnackBar({
        message: $localize`Could not copy text`,
        snackType: SnackBarTypes.ERROR,
      });
    });
}

export function getPanelClasses(
  currentWindowSizeState: WindowSizeStateEnum,
  panelClassesInput: string[] = ['call-contact-sheet'],
): string[] {
  const panelClasses = panelClassesInput;

  if (currentWindowSizeState === WindowSizeStateEnum.EXPANDED) {
    panelClasses.push('expanded-view');
  }

  return panelClasses;
}

// for testing purposes
export function delayObservable<T>(
  observable: Observable<T>,
  time: number,
): Observable<T> {
  return concat(of({ success: true }).pipe(delay(time)), observable).pipe(
    skip(1),
  ) as Observable<T>;
}

/**
 * Get phone number from call info
 * @param call
 * @returns
 */
export function getPhoneNumberFromCallInfo(call: Call): string {
  let phoneNumber = call.info.number;

  if (
    call.info.assertedIdentityNumber !== null &&
    call.info.number !== call.info.assertedIdentityNumber
  ) {
    phoneNumber = call.info.assertedIdentityNumber;
  }

  return phoneNumber;
}

export function removeSpecialChars(number: string): string {
  return number.replace(/[-()/]/g, '');
}

export function removeWhiteSpaces(text: string): string {
  return text.replace(/\s/g, '');
}

export function cleanPhoneNumber(number: string): string {
  return removeWhiteSpaces(removeSpecialChars(number));
}

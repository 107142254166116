import { NgModule } from '@angular/core';

import { ClockTickingPipe } from './clock-ticking.pipe';

@NgModule({
  declarations: [ClockTickingPipe],
  exports: [ClockTickingPipe],
  providers: [ClockTickingPipe],
})
export class ClockTickingModule {}

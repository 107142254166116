import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OptionDialogIdsEnum } from 'test-automation';

import { OptionDialogData } from './option-dialog-data.interface';

@Component({
  selector: 'app-option-dialog',
  templateUrl: './option-dialog.component.html',
  styleUrls: ['./option-dialog.component.scss'],
})
export class OptionDialogComponent {
  @Input() resultData;
  // TODO modify to generic naming
  optionDialogIdsEnum = OptionDialogIdsEnum;

  constructor(
    public dialogRef: MatDialogRef<OptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: OptionDialogData,
  ) {}

  cancel(): void {
    this.dialogRef.close(false);
  }

  proceed(): void {
    this.dialogRef.close(this.resultData ?? true);
  }
}

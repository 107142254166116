import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '../../directives/directives.module';

export type ButtonTypes =
  | 'primary'
  | 'primary-new'
  | 'secondary'
  | 'outline-primary'
  | 'gray'
  | 'light-gray'
  | 'clear'
  | 'clear-new'
  | 'icon'
  | 'transparent'
  | 'texts'
  | 'warn'
  | 'success';

@Component({
  standalone: true,
  imports: [CommonModule, DirectivesModule],
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() size = 12;
  @Input() btnType: ButtonTypes;
  @Input() isDisabled: boolean;
  @Input() showSpinner: boolean;
  @Input() testId = 'app-button';
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}

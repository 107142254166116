import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

import { EndpointService } from './networking/endpoint.service';
import { Agent } from '../models/agent';
import { StatusService } from './status.service';

export interface AgentListResult {
  alphabet: Agent[];
  allElements: Agent[];
  agentList: Agent[];
  groupList: Agent[];
}

export enum AgentError {
  NOT_PERMITTED = 'not-permitted',
  NETWORK_ERROR = 'network-error',
}

@Injectable()
export class AgentService {
  error: AgentError;
  allElements: Agent[] = null;

  constructor(
    private endpoint: EndpointService,
    private statusService: StatusService,
    private snackBar: MatSnackBar,
  ) {}

  getAgentList(
    whatToGet: 'agentList' | 'transferList',
  ): Observable<AgentListResult> {
    const agentsUrl =
      whatToGet === 'transferList'
        ? 'agents/transfer-list'
        : 'agents/agent-list';

    return forkJoin({
      agents: this.endpoint._get<Agent[]>(agentsUrl),
      groups: this.endpoint._get<Agent[]>('groups/index'),
      idleStatusSettingRefresh: this.statusService.loadIdleStatusTypeSetting(),
    }).pipe(
      map(results => this.processAgentList(results.agents, results.groups)),
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.code === 403) {
          this.error = AgentError.NOT_PERMITTED;
        } else {
          this.error = AgentError.NETWORK_ERROR;
        }
        this.processHttpError(error);
        return of({
          alphabet: [],
          allElements: [],
          agentList: [],
          groupList: [],
        });
      }),
    );
  }

  getInviteAbleAgentList(): Observable<AgentListResult> {
    return forkJoin({
      agentRequestResults: this.endpoint._get_realtime<{
        invitable_agents: Agent[];
      }>('invitable-agents-to-call', 'me/'),
      idleStatusSettingRefresh: this.statusService.loadIdleStatusTypeSetting(),
    }).pipe(
      map(results =>
        this.processAgentList(
          results?.agentRequestResults?.invitable_agents ?? [],
        ),
      ),
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.code === 403) {
          this.error = AgentError.NOT_PERMITTED;
        } else {
          this.error = AgentError.NETWORK_ERROR;
        }
        this.processHttpError(error);
        return of({
          alphabet: [],
          allElements: [],
          agentList: [],
          groupList: [],
        });
      }),
    );
  }

  getAgentById(id: number): Agent {
    return this.allElements?.find((agent: Agent) => agent.id === id);
  }

  private processAgentList(agents = [], groups = []): AgentListResult {
    const agentList = agents.map(
      agent =>
        new Agent({
          id: agent.id,
          type: 'agent',
          extension: agent.extension,
          firstname: agent.firstname,
          lastname: agent.lastname,
          online_status: agent.online_status,
        }),
    );
    const groupList = groups.map(
      group =>
        new Agent({
          id: group.id,
          type: 'group',
          internal_name: group.internal_name,
          email: group.email,
          name: group.name,
          is_member: group.is_member,
        }),
    );

    const allElements = [...agentList, ...groupList];

    allElements.sort((first: Agent, second: Agent) =>
      first.fullname
        ?.toLowerCase()
        .localeCompare(second.fullname?.toLowerCase()),
    );

    const alphabet = this.getAlphabetChar(allElements);
    this.allElements = allElements;
    return { alphabet, allElements, agentList, groupList };
  }

  private getAlphabetChar(list: Agent[]) {
    const alphabet = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const letter = item.fullname.substr(0, 1).toUpperCase() || '';

      const found = alphabet.some((agent: Agent) => agent.letter === letter);
      item.searchable =
        item?.fullname?.toLowerCase() +
        ' ' +
        item?.extension?.replace(/ /g, '');
      if ((!found || alphabet.length === 0) && this.isAllowedAlphabet(letter)) {
        item.letter = letter;
        alphabet.push(item);
      }
    }

    return alphabet;
  }

  private processHttpError(err: HttpErrorResponse, displayError = true) {
    if (
      displayError &&
      err instanceof HttpErrorResponse &&
      err.error &&
      err.error.message === 'not-permitted-agents-index'
    ) {
      this.snackBar.open(
        $localize`You are not allowed to display internal contacts`,
        '',
        {
          duration: 5000,
          panelClass: ['snack-warning'],
        },
      );
    }
  }

  private isAllowedAlphabet(char: string): boolean {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(char) !== -1;
  }
}

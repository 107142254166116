import { Injectable } from '@angular/core';
import { Call } from '@cloudtalk/sip-service';
import CallingExtensions from '@hubspot/calling-extensions-sdk';

import { environment } from '../../../environments/environment';
import { CtiProvider } from '../../_core/models/cti-providers.models';
import { User } from '../../_core/models/user';
import { CallMonitorService } from '../../_core/services/call-monitor.service';
import { CallingService } from '../../_core/services/calling/calling.service';
import { StatusService } from '../../_core/services/status.service';
import { UserService } from '../../_core/services/user/user.service';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { CtiServiceBase } from '../CtiServiceBase';

interface DialNumberData {
  /* The phone nubmer to dial */
  phoneNumber: string;
  /* The id of the logged in user.   */
  ownerId: number;
  /* The id of the hubspot account */
  portalId: number;
  /* HubSpot object Id of the phoneNumber */
  objectId: number;
  /* HubSpot  object type of the phoneNumber */
  objectType: 'CONTACT' | 'COMPANY';
}

@Injectable()
export class HubspotService extends CtiServiceBase {
  private _callingExtension: CallingExtensions = null;
  private _number = null;
  private _engagementId = null;

  constructor(
    private userService: UserService,
    statusService: StatusService,
    callingService: CallingService,
    callMonitorService: CallMonitorService,
  ) {
    super(
      callingService,
      statusService,
      callMonitorService,
      CtiProvider.HUBSPOT,
    );
    this.createCallingExtension();
  }

  createCallingExtension(): void {
    if (this._callingExtension === null) {
      this._callingExtension = new CallingExtensions({
        debugMode:
          !environment.production ||
          [139595, 100552].includes(this.userService.getUser()?.company_id),
        eventHandlers: {
          onReady: () => {
            /* HubSpot is ready to receive messages. */
            this.initializeSdk();
          },
          /* eslint-disable */
          onDialNumber: (data: DialNumberData) => {
            /* Dial a number */
            this._number = data.phoneNumber;
            this.makeCall(this._number);
          },
          onEngagementCreated: (data: { engagementId: number }) => {
            const { engagementId } = data;
            this._engagementId = engagementId;
          },
          onCreateEngagementSucceeded: event => {
            /* HubSpot has created an engagement for this call. */
          },
          onEngagementCreatedFailed: event => {
            /* HubSpot has failed to create an engagement for this call. */
          },
          onUpdateEngagementSucceeded: event => {
            /* HubSpot has updated an engagement for this call. */
          },
          onUpdateEngagementFailed: event => {
            /* HubSpot has failed to update an engagement for this call. */
          },
          onVisibilityChanged: event => {
            /* Call widget's visibility is changed. */
          },
          defaultEventHandler: event => {
            /* Other events */
          },
        },
      });
      return;
    }
  }

  initializeSdk(): void {
    try {
      const logged = !!this.userService.getUser();
      if (this._callingExtension) {
        this._callingExtension.initialized({
          isLoggedIn: logged,
          sizeInfo: {
            width: 400,
            height: 620,
          },
        });
        if (this._number && this.callingService?.activeCalls?.length < 1) {
          this.makeCall(this._number);
        }
      }
    } catch (error) {
      LoggerUtil.error(
        '[HubspotService]: Error initializing HubSpot SDK',
        {},
        error,
      );
    }
  }

  subscriptions(): void {
    this.userService.user$.subscribe((user: User) => {
      if (user === null) {
        this._callingExtension.userLoggedOut();
      } else {
        this._callingExtension.userLoggedIn();
      }
    });
  }

  onDialing(call: Call): void {
    super.onDialing(call);
    this._callingExtension?.outgoingCall({
      createEngagement: false,
      phoneNumber: this._number,
    });
  }

  onRinging(call: Call): void {
    this.ringing = true;
    super.onRinging(call);
    this._callingExtension.incomingCall({
      phoneNumber: call.info.number,
      createEngagement: true,
    });
  }

  onCalling(call: Call): void {
    super.onCalling(call);
    this._callingExtension.callAnswered();
  }

  onHangUp(call: Call): void {
    super.onHangUp(call);
    setTimeout(() => {
      this._callingExtension.callEnded();
    }, 500);
  }

  onEnded(call: Call): void {
    super.onEnded(call);
    this._callingExtension.callCompleted({
      hideWidget: false,
    });
  }
}

import { dynamicId } from '../utils/utils';

export enum SmsScreenIdsEnum {
  SMS_REFRESH_BUTTON = 'SmsRefreshButton',
  SMS_ITEM = 'SmsItem',
  TEMPLATE_HEADER = 'TemplateHeader',
  TEMPLATE_ITEM = 'TemplateItem',
  SMS_SEARCH = 'SmsSearch',
  SMS_MESSAGE = 'SmsMessage',
  SMS_MESSAGE_ACTIONS = 'SmsMessageActions',
  SMS_MESSAGE_DELETE = 'SmsMessageDelete',
  PLUS_BUTTON = 'PlusButton',
  REFRESH_BUTTON = 'RefreshButton',
  TEMPLATE_BUTTON = 'TemplateButton',
  SMS_HEADER = 'SmsHeader',
  OUTBOUND_NUMBER = 'SmsOutboundNumber',
  SMS_SELECT_CONTACT = 'SmsSelectContact',
  SMS_CONVERSATION_ITEM = 'SmsConversationItem',
  SMS_CONVERSATION_ITEM_DESCRIPTION = 'SmsConversationItemDescription',
  SMS_CONVERSATION_ACTIONS = 'SmsConversationActions',
  SMS_CONVERSATION_DELETE = 'SmsConversationDelete',
  SMS_LIST = 'SmsList',
  ADD_TEMPLATE = 'AddTemplate',
  CREATE_TEMPLATE = 'CreateTemplate',
  TEMPLATE_NAME = 'TemplateName',
  TEMPLATE_MESSAGE = 'TemplateMessage',
  TEMPLATE_SAVE = 'TemplateSave',
  TEMPLATE_CANCEL = 'TemplateCancel',
  TEMPLATE_TITLE = 'TemplateTitle',
  TEMPLATE_CONTENT = 'TemplateContent',
  TEMPLATE_EDIT = 'TemplatEdit',
  TEMPLATE_DELETE = 'TemplateDelete',
  TEMPLATE_ACTION_MENU = 'TemplateActionMenu',
  SMS_SELECT_CONTACT_CONFIRM = 'SmsSelectContactConfirm',
  SMS_MESSAGE_DELETED = 'SmsMessageDeleted',
  WHATSAPP_CONVERSATION_ITEM = 'WhatsappConversationItem',
  CONVERSATION_ICON = 'ConversationIcon',
  CONVERSATION_PARTNER_NAME = 'ConversationPartnerName',
  CONVERSATION_LAST_MESSAGE = 'ConversationLastMessage',
  CONVERSATION_INFO = 'ConversationInfo',
}

export const conversationDetox = dynamicId(
  SmsScreenIdsEnum.SMS_CONVERSATION_ITEM,
  '-Detox Test',
);

import { NgModule } from '@angular/core';
import { ThemeService } from 'ui-components';

import { StatusService } from './services/status.service';
import { VoiceSettingService } from './services/audio/voice-setting.service';
import { VoiceProviderService } from './services/audio/voice-provider.service';
import { PhoneStatusService } from './services/phone-status.service';
import { CountrySelectService } from '../ui-components/country-select/country-select.service';
import { UserSettingsService } from './services/user/user-settings.service';
import { CallingService } from './services/calling/calling.service';
import { BrowserNotificationService } from './services/browser-notification.service';
import { PhoneGuard } from './guards/phone.guard';
import { FirstLoadGuard } from './guards/first-load.guard';
import { WindowGuard } from './guards/window.guard';
import { StatusGuard } from './guards/status.guard';
import { CallMonitorService } from './services/call-monitor.service';
import { CesSurveyService } from '../ui-components/ces-survey/ces-survey.service';
import { ElectronService } from './services/electron/electron.service';

@NgModule({
  providers: [
    BrowserNotificationService,
    PhoneGuard,
    FirstLoadGuard,
    StatusGuard,
    WindowGuard,
    ElectronService,

    CallingService,
    CountrySelectService,
    UserSettingsService,

    PhoneStatusService,
    StatusService,
    // TODO: resolve call events via Event Bus (do not inject at root level)
    CallMonitorService,
    VoiceSettingService,
    VoiceProviderService,
    ThemeService,
    CesSurveyService,
  ],
})
export class CoreProvidersModule {}

import { AttenderUser, Call } from '@cloudtalk/sip-service';
import { EventEmitter } from '@angular/core';

import { Agent } from '../../_core/models/agent';
import { CallMonitoringType } from '../../_core/interfaces/call-monitoring-type';

export const CallWrapperActions = {
  CLOSE: 'close' as const,
  SHOW_NAVIGATION_BAR: 'showNavigationBar' as const,
  HIDE_NAVIGATION_BAR: 'hideNavigationBar' as const,
  MOVE_TO_NORMAL_CALL: 'moveToNormalCall' as const,
};

export interface CallInterface {
  call: Call;
  callWrapperEventEmitter: EventEmitter<CallWrapperEvent>;
}

export type CallWrapperEventAction =
  | typeof CallWrapperActions.CLOSE
  | typeof CallWrapperActions.SHOW_NAVIGATION_BAR
  | typeof CallWrapperActions.HIDE_NAVIGATION_BAR
  | typeof CallWrapperActions.MOVE_TO_NORMAL_CALL;

export interface CallWrapperEvent {
  action: CallWrapperEventAction;
  callID: number;
  callToReplace?: Call;
  originCall?: Call;
}

/**
 * All allowed actions for call
 * */
export const ControllingActions = {
  DTMF: 'dtmf' as const,
  TRANSFER: 'transfer' as const,
  WARM_TRANSFER: 'warm-transfer' as const,
  CONFERENCE: 'conference' as const,
  INVITE_AGENT: 'invite-agent' as const,
  HANGUP: 'hangup' as const,
  ANSWER: 'answer' as const,
  MINIMIZE: 'minimize' as const,
  VOICEMAIL_DROP: 'voicemail_drop' as const,
};

export type TransferType = 'internal' | 'external';

export type ControlType =
  | typeof ControllingActions.DTMF
  | typeof ControllingActions.TRANSFER
  | typeof ControllingActions.WARM_TRANSFER
  | typeof ControllingActions.CONFERENCE
  | typeof ControllingActions.INVITE_AGENT
  | typeof ControllingActions.HANGUP
  | typeof ControllingActions.ANSWER
  | typeof ControllingActions.MINIMIZE
  | typeof ControllingActions.VOICEMAIL_DROP;

export interface ControllingEvent {
  action?: ControlType;
}

export interface ControllingEventForCall extends ControllingEvent {
  call_uuid: string;
}

export interface DtmfControlEvent extends ControllingEventForCall {
  dtmf: string;
}

export interface TransferControlEvent extends ControllingEventForCall {
  type: TransferType;
  target: string;
}

export interface WarmTransferControlEvent extends ControllingEventForCall {
  agent?: Agent;
  externalNumber?: string;
}

export interface ConferenceControlEvent extends ControllingEventForCall {
  externalNumber?: string;
}

export interface InviteAgentControlEvent extends ControllingEventForCall {
  agent?: {
    id: number;
    firstname: string;
    lastname: string;
    monitoring_type: CallMonitoringType;
  };
  number?: string;
}

export interface CallAttendersControlEvent extends ControllingEventForCall {
  attended_by_users: AttenderUser[];
}

export interface MinimizeControlEvent extends ControllingEvent {
  minimize: boolean;
}

export enum Mandatories {
  TAGGING = 'tagging',
  POWER_DIALER_ACTIONS = 'powerDialerActions',
  CALL_DISPOSITION = 'callDisposition',
}

export enum SalesforceCallDisposition {
  CALL_BACK_LATER = 'Call Back Later',
  LEFT_VOICEMAIL = 'Left Voicemail',
  MEANINGFUL_CONNECT = 'Meaningful Connect',
  NOT_INTERESTED = 'Not Interested',
  UNQUALIFIED = 'Unqualified',
  CANCELED = 'Call Canceled',
  REJECTED = 'Call Rejected',
}

// Mapped localized error messages to SIP service error codes
export const ErrorCodeToMessage = (
  code: number,
  defaultMessage: string,
): string => {
  switch (code) {
    case 403:
    case 404:
    case 408:
    case 480:
    case 483:
    case 488:
    case 503:
    case 603:
      return $localize`Something went wrong. Please check the number and try calling again.`;
    case 500:
    case 501:
    case 502:
      return $localize`An unexpected error occurred. Please contact our Support for help.`;
    case 486:
      return $localize`The call was declined by the recipient.`;
    default:
      return defaultMessage;
  }
};

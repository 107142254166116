import { AgentStatusTypeEnum } from '../../enums/agent-status-type.enum';

export enum AgentStatusActAs {
  ONLINE = 'online',
  IDLE = 'idle',
  OFFLINE = 'offline',
}

export interface AgentStatuses {
  system: AgentStatus[];
  predefined: AgentStatus[];
}

export interface AgentStatus {
  id: number;
  act_as: AgentStatusActAs;
  label: string;
  color: string;
  type: string;
}
export interface CustomAgentStatus extends AgentStatus {
  user_status: {
    title: string;
  };
}

export interface AgentStatusPayload {
  id: number;
  user_status?: {
    title: string;
  };
}

export const DefaultAgentStatuses = [
  {
    id: 7,
    label: 'Online',
    act_as: AgentStatusActAs.ONLINE,
    color: '#53c432',
    type: AgentStatusTypeEnum.SYSTEM,
  },
  {
    id: 9,
    label: 'Idle',
    act_as: AgentStatusActAs.IDLE,
    color: '#ffa626',
    type: AgentStatusTypeEnum.SYSTEM,
  },
  {
    id: 11,
    label: 'Offline',
    act_as: AgentStatusActAs.OFFLINE,
    color: '#e84e40',
    type: AgentStatusTypeEnum.SYSTEM,
  },
];

export const KEY_CUSTOM_STATUSES = 'custom-statuses';
export const MAX_CUSTOM_STATUSES = 4;
export const MAX_CUSTOM_STATUS_CHAR_COUNT = 30;
export const MAX_CUSTOM_STATUS_CHAR_COUNT_ZENDESK = 18;
export const DEFAULT_IDLE_STATUS = $localize`Busy`;

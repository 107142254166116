import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountryCodeItemComponent } from './country-code-item.component';
import { MaterialElementImportModule } from '../material-element-import.module';
import { DirectivesModule } from '../../directives/directives.module';
import { CtFlagIconComponent } from '../ct-flag-icon/ct-flag-icon.component';

@NgModule({
  declarations: [CountryCodeItemComponent],
  imports: [
    CommonModule,
    MaterialElementImportModule,
    DirectivesModule,
    CtFlagIconComponent,
  ],
  exports: [CountryCodeItemComponent],
})
export class CountryCodeItemModule {}

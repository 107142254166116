import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

import { CallingService } from '../services/calling/calling.service';

@Injectable()
export class PhoneGuard implements CanActivate {
  constructor(private callingService: CallingService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return !this.callingService.isCallEvent;
  }
}

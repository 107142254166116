<div (click)="toggle($event)">
  <ng-content select="select-header"></ng-content>
</div>

<ng-template [ngIf]="standalone">
  <ng-content select="[standalone]"></ng-content>
</ng-template>

<ng-template #bodyContent>
  <div class="select-container">
    <ng-content select="select-body"></ng-content>
  </div>
</ng-template>

<div
  class="c-header"
  [ngClass]="{ 'c-header--bg-gray': backgroundColor === 'gray' }">
  <div *ngIf="titlePosition !== 'left'" class="c-header__action-container-left">
    <ng-content select="[left-content]"></ng-content>
  </div>

  <div
    class="c-header__title"
    [appTestId]="headerIdsEnum.HEADER_TITLE"
    [ngClass]="{
      clickable: clickable,
      'c-header__title--left': titlePosition === 'left',
      'c-header__title--right': titlePosition === 'right'
    }"
    (click)="onTitleClick()">
    <span *ngIf="title" i18n>{{ title }}</span>
  </div>

  <div
    *ngIf="titlePosition !== 'right'"
    class="c-header__action-container-right">
    <ng-content select="[right-content]"></ng-content>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIs } from '../../enums/apis.enum';
import { CTResponse } from '../../interfaces/ctresponse.interface';
import { LoginResponse } from '../../interfaces/login-response.interface';
import { Credentials } from '../../models/credentials';
import { InitAuthModel } from '../../models/init-auth.model';
import { LoginSsoModel } from '../../models/login-sso.model';
import { AuthLoginSsoType } from '../../types/auth-login-sso.type';
import { EnvironmentService } from '../environment.service';
import { EndpointService } from '../networking/endpoint.service';

type RepositoryResponse<T> = Observable<CTResponse<T>>;

@Injectable()
export class AuthenticationRepository {
  constructor(
    private endpointService: EndpointService,
    private environmentService: EnvironmentService,
  ) {}

  login({ email, password }: Credentials): RepositoryResponse<LoginResponse> {
    return this.endpointService.post(
      APIs.Auth,
      'login/credentials/password',
      {
        email,
        password,
        source: this.environmentService.getElectronAppVersion()
          ? 'desktop'
          : 'browser',
      },
      { withCredentials: true },
    );
  }

  refreshToken(refreshToken: string): RepositoryResponse<LoginSsoModel> {
    return this.endpointService.post<CTResponse<LoginSsoModel>, undefined>(
      APIs.Auth,
      'refresh-token',
      undefined,
      {
        withCredentials: true,
        headers: {
          'x-ct-refresh-token': `${refreshToken}`,
        },
      },
    );
  }

  logout(): RepositoryResponse<unknown> {
    return this.endpointService.post<null, null>(APIs.Auth, 'logout', null, {
      withCredentials: true,
    });
  }

  ssoLogin(
    authLoginSsoType: AuthLoginSsoType,
  ): RepositoryResponse<LoginSsoModel> {
    return this.endpointService.post<CTResponse<LoginSsoModel>, unknown>(
      APIs.Auth,
      'login-sso',
      authLoginSsoType,
      {
        withCredentials: true,
      },
    );
  }
  ssoAuthInit(email: string): RepositoryResponse<InitAuthModel> {
    return this.endpointService.post<
      CTResponse<InitAuthModel>,
      { email: string }
    >(APIs.Auth, 'init-auth', {
      email,
    });
  }
}

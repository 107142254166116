import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OutboundSelectIdsEnum } from 'test-automation';

@Component({
  selector: 'app-outbound-select',
  templateUrl: './outbound-select.component.html',
  styleUrls: ['./outbound-select.component.scss'],
})
export class OutboundSelectComponent {
  @Input() prefix?: string;
  @Input() countryCode?: string;
  @Input() mainLabel: string;
  @Input() subLabel?: string;
  @Input() disabled = false;
  @Input() anonymous = false;
  @Output() selectOutbound: EventEmitter<void> = new EventEmitter<void>();

  readonly outboundSelectIdsEnum = OutboundSelectIdsEnum;

  onClick(): void {
    if (!this.disabled) {
      this.selectOutbound.emit();
    }
  }
}

<div class="select-body-title" *ngIf="title">
  <h4 class="title">{{ title }}</h4>
  <div class="close-dialog" (click)="closeDialog()">Close</div>
</div>
<ng-container>
  <ng-content select="[selectInput]"></ng-content>
</ng-container>
<div class="select-body-scroll">
  <ng-content></ng-content>
</div>
<div class="select-body-actions">
  <ng-content select="[selectActions]"></ng-content>
</div>

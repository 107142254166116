import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialElementImportModule } from '../material-element-import.module';
import { SearchSelectItemComponent } from './search-select-item.component';
import { DirectivesModule } from '../../directives/directives.module';
import { CtFlagIconComponent } from '../ct-flag-icon/ct-flag-icon.component';
import { CtIconComponent } from '../ct-icon/ct-icon.component';

@NgModule({
  declarations: [SearchSelectItemComponent],
  imports: [
    CommonModule,
    MaterialElementImportModule,
    DirectivesModule,
    CtFlagIconComponent,
    CtIconComponent,
  ],
  exports: [SearchSelectItemComponent],
})
export class SearchSelectItemModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WrapperComponent } from './wrapper.component';
import { MaterialElementImportModule } from '../material-element-import.module';

@NgModule({
  imports: [CommonModule, MaterialElementImportModule],
  declarations: [WrapperComponent],
  exports: [WrapperComponent],
})
export class WrapperModule {}

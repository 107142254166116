import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { User } from '../models/user';
import { EnvironmentService } from './environment.service';
import { UserService } from './user/user.service';

export const CT_PHONE_META = 'ct-phone-meta';
export const DEBUG_STORAGE_KEY = 'ct-debug';
export const SMS_DRAFTS_STORAGE_KEY = 'ct-sms-drafts';
export const SMS_FAILED_MESSAGES_KEY = 'ct-sms-failed-messages';

@Injectable()
export class LocalStorageService {
  updated$: Subject<string> = new Subject<string>();

  constructor(
    private userService: UserService,
    private environmentService: EnvironmentService,
  ) {}

  private generateStorageKey(
    key: string,
    withUserId: boolean = true,
  ): string | null {
    const user: User = this.userService.getUser();
    if (withUserId && user === null) {
      LoggerUtil.info(
        'User id not found. Unable to store or update data in local storage.',
        { key },
      );
      return null;
    } else {
      return withUserId ? `${key}-${user.id}` : key;
    }
  }

  setItem(key: string, value: any | string, withUserId: boolean = true): void {
    const storageKey = this.generateStorageKey(key, withUserId);

    if (storageKey) {
      let dataToStore = CryptoJS.AES.encrypt(
        JSON.stringify(value),
        environment.enc.b64k,
      ).toString();

      if (this.environmentService.isDevelopment()) {
        dataToStore = JSON.stringify(value);
      }

      localStorage.setItem(storageKey, dataToStore);

      this.updated$.next(storageKey);
    }
  }

  updateItem(key: string, value: any, withUserId: boolean = true): void {
    const storageKey = this.generateStorageKey(key, withUserId);

    if (storageKey) {
      let itemToUpdate = this.getItem(key);
      if (!itemToUpdate) {
        itemToUpdate = {};
      }
      const payload = {
        ...itemToUpdate,
        ...value,
      };

      try {
        const valueKeys = Object.keys(value);
        if (valueKeys.length) {
          for (const k of valueKeys) {
            if (value[k] === null) {
              delete payload[k];
            }
          }
        }
      } catch (e) {
        LoggerUtil.error(
          '[LocalStorageService]: Error updating item',
          {
            extraContent: e,
          },
          e,
        );
      }

      this.setItem(key, payload, withUserId);
      this.updated$.next(storageKey);
    }
  }

  removeItem(key: string, withUserId: boolean = true): void {
    const storageKey = this.generateStorageKey(key, withUserId);

    if (storageKey) {
      localStorage.removeItem(storageKey);
    }
  }

  getItem(key: string, withUserId: boolean = true): any {
    const storageKey = this.generateStorageKey(key, withUserId);

    if (storageKey) {
      const value = localStorage.getItem(storageKey);

      if (this.isValidJson(value)) {
        return JSON.parse(value);
      }

      let response = value;

      try {
        response = JSON.parse(
          CryptoJS.AES.decrypt(value, environment.enc.b64k).toString(
            CryptoJS.enc.Utf8,
          ),
        );
      } catch (error) {
        LoggerUtil.error(
          '[LocalStorageService]: Error getting item',
          {
            key,
            extraContent: error,
          },
          error,
        );
      }

      return response ? response : null;
    }
  }

  isValidJson(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

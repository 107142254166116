import { Pipe, PipeTransform } from '@angular/core';

import {
  PhoneNumberFormat,
  PhoneNumberUtilService,
} from '../../services/phone-number-util.service';

export { PhoneNumberFormat };

/**
 * Sformatuje cislo do medz. formatu
 *
 * value: cislo bez predvolby
 * countryCode: napr. 421 pre SK
 */
@Pipe({ name: 'phoneNumber' })
export class PhoneNumberFormatterPipe implements PipeTransform {
  /**
   * Sformatuje cislo do daneho formatu
   *
   * @param value
   * @param countryCode
   * @param format
   * @returns {string}
   */
  transform(
    value: string,
    countryCode?: string,
    format?: number,
    shouldFormatNumber = true,
  ): any {
    if (!shouldFormatNumber) {
      return value;
    }
    try {
      return PhoneNumberUtilService.format(value, countryCode, format);
    } catch (e) {
      return value;
    }
  }
}

import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { ActivityContact } from '@cloudtalk/sip-service';
import { partition, sum } from 'lodash-es';

import {
  ActivitiesArray,
  ActivitiesCallHistoryItem,
  Activity,
  ContactActivities,
  ContactInfoActivity,
  getAvatarColor,
} from '../../_core/models/activity';
import { EndpointService } from '../../_core/services/networking/endpoint.service';
import { EnvironmentService } from '../../_core/services/environment.service';
import { AgentService } from '../../_core/services/agent.service';
import { UserService } from '../../_core/services/user/user.service';
import { ContactNameTypeEnum } from '../../_core/enums/contact-name-type.enum';

@Injectable()
export class ActivitiesService {
  hiddenActivity: boolean;

  constructor(
    private endpoint: EndpointService,
    private agentService: AgentService,
    private userService: UserService,
    private environmentService: EnvironmentService,
  ) {}

  loadActivities(
    contacts: any[],
  ): Observable<ActivitiesArray<ContactActivities>> {
    this.hiddenActivity =
      contacts[0]?.contact?.name === ContactNameTypeEnum.HIDDEN_CALLER;
    if (this.hiddenActivity) {
      return of(new ActivitiesArray<ContactActivities>([]));
    }
    if (contacts && !this.environmentService.isCTIIntegration()) {
      return forkJoin(
        contacts.map(singleContact => {
          return this.loadActivityForSingleContact(singleContact);
        }),
      ).pipe(
        shareReplay(1),
        map((activities: ContactActivities[]) => {
          const contactActivities = new ActivitiesArray<ContactActivities>(
            activities,
            this.calculateTotalNumberOfActivities(activities),
          );

          return contactActivities.sort(
            (first: ContactActivities, second: ContactActivities) => {
              return first.contact.contact_id - second.contact.contact_id;
            },
          );
        }),
      );
    } else {
      return of(new ActivitiesArray<ContactActivities>([]));
    }
  }

  private calculateTotalNumberOfActivities(
    activities: ContactActivities[],
  ): number {
    return sum([
      ...activities.map(
        ({ activity, deals, contactInfo }) =>
          activity.length + deals.length + contactInfo.length,
      ),
    ]);
  }

  private loadActivityForSingleContact(
    singleContact: any,
  ): Observable<ContactActivities> {
    const { contact } = singleContact;
    contact.activities = 0;
    return this.getActivities(contact.id).pipe(
      catchError(() => of([])),
      map((activities: Activity[]) =>
        activities.filter(
          (activity: Activity) =>
            activity.external_service?.toLowerCase() !== 'zapier',
        ),
      ),
      map((activities: Activity[]) => {
        return this.createContactActivity(activities, singleContact);
      }),
      switchMap((contactActivity: ContactActivities) =>
        this.extendActivitiesByHistory(contact, contactActivity),
      ),
      map((contactActivity: ContactActivities) => {
        // sorting by date
        contactActivity.activity = this.sortActivities(
          contactActivity.activity,
        );
        contactActivity.deals = this.sortActivities(contactActivity.deals);

        // for passing number of contact activities for integrations icons
        contact.activities =
          contactActivity.activity.length +
          contactActivity.deals.length +
          contactActivity.contactInfo.length;

        return contactActivity;
      }),
    );
  }

  private createContactActivity(
    activities: Activity[],
    singleContact: any,
  ): ContactActivities {
    const contactInfo = this.getContactInfo(singleContact.contact);
    const [deals, activity] = partition(
      activities,
      item => item.type === 'deal',
    );
    return {
      contact: this.getContactForActivities(singleContact),
      contactInfo,
      activity,
      deals,
    };
  }

  private getContactInfo(contact: any): ContactInfoActivity[] {
    if (contact?.contact_notes) {
      return [
        ...contact.contact_notes
          .map(({ id, note, user_id }) =>
            this.getContactInfoNoteObject(id, note, user_id),
          )
          .sort((a, b) => b.id - a.id),
      ];
    } else {
      return [];
    }
  }

  /**
   * Template of contact info object of note
   * */
  getContactInfoNoteObject(
    id: number,
    note: string,
    creatorId: number,
  ): ContactInfoActivity {
    const creatorName =
      creatorId === this.userService.getUser()?.id
        ? this.userService.getUser().name
        : this.agentService.getAgentById(creatorId)?.fullname;
    return {
      name: 'Note',
      key: 'note',
      note,
      id,
      type: 'contact_info',
      icon: 'text_snippet',
      creator: creatorName,
    };
  }

  private sortActivities(
    activities: Activity[] | ActivitiesCallHistoryItem[],
  ): Activity[] | ActivitiesCallHistoryItem[] {
    const activitiesInternal = activities;
    activitiesInternal.map(item => {
      if (item && item.calldate) {
        item.activity_date = item.calldate;
      }
    });
    activitiesInternal.sort((first: Activity, second: Activity) => {
      return moment
        .utc(second.activity_date)
        .diff(moment.utc(first.activity_date).valueOf());
    });

    return activitiesInternal.slice(0, 100);
  }

  private extendActivitiesByHistory(
    contact: { id: number },
    contactActivity: ContactActivities,
  ): Observable<ContactActivities> {
    return this.getCallHistoryActivities(contact.id).pipe(
      catchError(() => of([])),
      map((response: ActivitiesCallHistoryItem[]) => {
        // merge with call history
        contactActivity.activity = contactActivity.activity.concat(response);
        return contactActivity;
      }),
    );
  }

  private getContactForActivities({
    contact,
    country_code_id,
    public_number,
  }: any): ActivityContact {
    let country = null;
    if (
      contact.contact_numbers &&
      contact.contact_numbers.length > 0 &&
      contact.contact_numbers[0].country_code_id
    ) {
      country = contact.contact_numbers[0].country_code_id;
    }
    return {
      contact_id: contact.id,
      contact_name: contact?.name,
      number: public_number,
      type: contact.type,
      company: contact?.company,
      country: country_code_id
        ? { country_code: country_code_id }
        : { country_code: country },
      contact_emails: contact?.contact_emails,
      avatar_color: getAvatarColor(),
    };
  }

  private getActivities(contactId: number): Observable<Activity[]> {
    return contactId
      ? this.endpoint._get(`contact-activities/index/${contactId}/100`)
      : of([]);
  }

  private getCallHistoryActivities(
    contactId: number,
  ): Observable<ActivitiesCallHistoryItem[]> {
    if (!contactId) {
      return of([]);
    }
    return this.endpoint._get(`call-history/contact/${contactId}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { TrackingEventEnum } from '../../_core/enums/tracking-event.enum';
import { CesSurveyTrackingProperties } from '../../_core/models/tracking-properties.model';
import { TrackingService } from '../../_core/services/tracking.service';
import {
  SnackBarTypes,
  SnackBarWrapperService,
} from '../../_shared/components/snack-bar-wrapper/snack-bar-wrapper.service';
import { CesSurveyService } from './ces-survey.service';

interface CesRadioOption {
  score: number;
  label: string;
}

@Component({
  templateUrl: './ces-survey.component.html',
  styleUrls: ['./ces-survey.component.scss'],
})
export class CesSurveyComponent implements OnInit {
  ratingSent$: Observable<boolean>;
  answer: string;
  trackedScore: CesRadioOption | undefined;

  cesRadioOptions: CesRadioOption[] = [
    { score: 1, label: $localize`Strongly Disagree` },
    { score: 2, label: $localize`Disagree` },
    { score: 3, label: $localize`Somewhat Disagree` },
    { score: 4, label: $localize`Undecided` },
    { score: 5, label: $localize`Somewhat Agree` },
    { score: 6, label: $localize`Agree` },
    { score: 7, label: $localize`Strongly Agree` },
  ];

  constructor(
    private bottomSheetRef: MatBottomSheetRef<CesSurveyComponent>,
    private trackingService: TrackingService,
    private snackBarWrapperService: SnackBarWrapperService,
    private cesSurveyService: CesSurveyService,
  ) {}

  ngOnInit(): void {
    this.trackingService
      .trackActionEvent(TrackingEventEnum.CES_SURVEY_STARTED)
      .subscribe({ error: () => this.handleFailedRequest() });
  }

  close(): void {
    if (this.ratingSent$ === undefined) {
      this.cesSurveyService.setNextSurveyDate(60).subscribe();
    }
    this.bottomSheetRef.dismiss();
  }

  askLater(): void {
    this.trackingService
      .trackActionEvent<Partial<CesSurveyTrackingProperties>>(
        TrackingEventEnum.CES_ASK_LATER,
        undefined,
        {
          ask_me_later_selected_at: moment().toISOString(),
        },
      )
      .pipe(
        mergeMap(() => this.cesSurveyService.setNextSurveyDate(14)),
        catchError(() => this.handleFailedRequest()),
      )
      .subscribe();
    this.bottomSheetRef.dismiss();
  }

  sendRating(): void {
    this.ratingSent$ = this.trackingService
      .trackActionEvent<Partial<CesSurveyTrackingProperties>>(
        TrackingEventEnum.CES_SCORE,
        undefined,
        {
          score: this.trackedScore?.score,
          question: 'Most of the time I experience no issues with calling.',
          answer: this.trackedScore?.label,
          score_scale: '1-7',
        },
      )
      .pipe(
        mergeMap(() => this.cesSurveyService.setNextSurveyDate(60)),
        catchError(() => {
          this.trackedScore = undefined;
          return this.handleFailedRequest();
        }),
      );
  }

  ratingSelected(score: CesRadioOption): void {
    this.trackedScore = score;
  }

  submitAnswer(): void {
    this.trackingService
      .trackActionEvent<Partial<CesSurveyTrackingProperties>>(
        TrackingEventEnum.CES_FEEDBACK,
        null,
        {
          feedback: this.answer,
          question:
            'What could we have done differently in order to improve your calling experience?',
        },
      )
      .pipe(
        mergeMap(() => this.cesSurveyService.setNextSurveyDate(60)),
        catchError(() => this.handleFailedRequest()),
      )
      .subscribe();
    this.close();
  }

  private handleFailedRequest(): Observable<boolean> {
    this.snackBarWrapperService.openSnackBar({
      message: $localize`Something went wrong with survey, please try again later`,
      snackType: SnackBarTypes.ERROR,
    });
    this.bottomSheetRef.dismiss();
    return of(false);
  }
}

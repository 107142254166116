import { Injectable } from '@angular/core';

import { SocketService } from './networking/socket.service';
import { EndpointService } from './networking/endpoint.service';
import { CallingService } from './calling/calling.service';

/**
 * Service na posielanie socket eventov do realtimu pre webhooky. Tieto eventy sa potom
 * na realtime spracuju a budu sa dalej posielat do Kafky.
 */
@Injectable()
export class RealtimeTriggersService {
  public isEnabled = false;

  constructor(
    private socketService: SocketService,
    private endpointService: EndpointService,
    private callingService: CallingService,
  ) {}

  notifyWebhooksAboutCallModified({ callUuid = null, cdrId = null } = {}) {
    if (!callUuid && !cdrId && !this.isEnabled) {
      return;
    }

    /**
     * Je to asi jasne ze co preco, jedine to is_call_active. Ked mame is_call_active: true, tak to
     * znamena, ze call sa nachadza v tabulke active_calls. Tym padom jeho udaje mozeme na realtime vytiahnut odtial.
     * Ked ake is_call_active je false, tak zaznam o calli uz v active_calls nenajdeme, takze budeme musiet hladat
     * v asterisk_cdr tabulke, podla call_uuid. Tato situacia nastane na call summary/after call work screene, ked este
     * nevieme o skoncenom hovore, ze ake ma cdr_id, ale hovor sa uz v active_calls nenachadza.
     * cdr_id posielame vtedy, ked zmenime tag/note/atd. na hovore uz tak, ze ho vyhladame cez call history.
     */
    if (callUuid) {
      this.socketService.emit('webhooks-call-modified', {
        call_uuid: callUuid,
        is_call_active: this.callingService.isCalling,
      });
    } else if (cdrId) {
      this.socketService.emit('webhooks-call-modified', {
        cdr_id: cdrId,
      });
    }
  }
}

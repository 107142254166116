import { dynamicId } from '../utils/utils';

export enum SmsConversationIdsEnum {
  SEND_VIA_FIELD = 'SendViaField',
  RECIPIENT_NUMBER = 'RecipientNumber',
  MESSAGE = 'SmsConversationMessage',
  MESSAGE_INPUT_FIELD = 'SmsConversationMessageInputField',
  MESSAGE_TOOLTIP_WRAP = 'SmsConversationMessageTooltipWrap',
  MESSAGE_TOOLTIP = 'SmsConversationMessageTooltip',
  MESSAGE_COPY = 'SmsConversationMessageCopy',
  MESSAGE_DELETE = 'SmsConversationMessageDelete',
  MESSAGE_CONFIRM_DELETE = 'SmsConversationMessageConfirmDelete',
  MESSAGE_TEMPLATE_SELECT = 'SmsConversationMessageTemplateSelect',
  SEND_BUTTON = 'SmsConversationSendButton',
  MESSAGE_ENTRY = 'MessageEntry',
  BACK_BUTTON = 'BackButton',
  DELETE_BUTTON = 'DeleteButton',
  UPLOAD_BUTTON = 'UploadButton',
  TEMPLATE_SELECT_BUTTON = 'TemplateSelectButton',
  SMS_CONVERSATION_LIST = 'SmsConversationList',
  SMS_CONVERSATION_ITEM = 'SmsConversationItem',
  SMS_CONVERSATION_DELETE = 'SmsConversationItemDelete',
  OUTBOUND_NUMBER = 'SmsConversationOutboundNumber',
  INPUT_COUNTER = 'InputCounter',
  SMS_REFRESH_BUTTON = 'SmsRefreshButton',
  COUNTDOWN_TEXT = 'CountdownText',
  ERROR_SNACK_BAR = 'ErrorSnackBar',
  TEMPLATES_BUTTON = 'TemplatesButton',
}

export const conversation_message_test = dynamicId(
  SmsConversationIdsEnum.MESSAGE,
  '-Test',
);

export const conversation_message_love_detox = dynamicId(
  SmsConversationIdsEnum.MESSAGE,
  '-I love detox',
);

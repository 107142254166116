import { NgModule } from '@angular/core';

import { FilterListPipe } from './filter-list.pipe';
import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [FilterPipe, FilterListPipe],
  exports: [FilterPipe, FilterListPipe],
  providers: [FilterPipe, FilterListPipe],
})
export class FilterPipeModule {}

<mat-option
  [disabled]="isDisabled"
  class="c-search-select-item {{ customClass }}"
  matTooltip="{{ tooltip }}"
  [ngClass]="{ 'c-search-select-item--selected': isSelected }">
  <div
    *ngIf="displayFlag"
    class="c-search-select-item__column c-search-select-item__column-flag">
    <ct-flag-icon
      [country]="countryCode"
      [isRounded]="true"
      [width]="30"></ct-flag-icon>
  </div>
  <div
    *ngIf="icon"
    class="c-search-select-item__column c-search-select-item__column-icon">
    <ct-icon [name]="icon" [size]="36" [shouldCover]="true"></ct-icon>
  </div>
  <div class="c-search-select-item__column c-search-select-item__column-text">
    <ng-template
      [ngIf]="mainLabelTemplate"
      [ngIfElse]="mainLabelDefault"
      class="c-search-select-item__main-label"
      [ngTemplateOutlet]="mainLabelTemplate"></ng-template>
    <ng-template #mainLabelDefault>
      <div
        class="c-search-select-item__main-label"
        [appTestId]="searchSelectIdsEnum.SEARCH_SELECT_MAIN_LABEL">
        {{ mainLabel }}
      </div>
    </ng-template>
    <div
      class="c-search-select-item__sub-label"
      [appTestId]="searchSelectIdsEnum.SEARCH_SELECT_SUB_LABEL">
      {{ subLabel }}
      <ng-content></ng-content>
    </div>
  </div>
</mat-option>

<app-select #selectComponent [standalone]="true">
  <select-body standalone [title]="data.title">
    <ng-container *ngIf="data?.items?.length > 0; else empty">
      <select-body-option
        *ngFor="let item of data.items"
        (click)="select(item)"
        [selectedClassName]="'all-selected'"
        [selected]="selectedId === item.id">
        <app-search-select-item
          [displayFlag]="data.displayFlag"
          [countryCode]="
            item.countryCode || (item.number | regionFromNumber : null : true)
          "
          [mainLabel]="
            item.mainLabel ||
            (item.number | phoneNumber : (item.number | regionFromNumber) : 1)
          "
          [subLabel]="item.subLabel">
        </app-search-select-item>
      </select-body-option>
    </ng-container>

    <ng-template #empty
      ><h5 class="simple-select__no-contacts">
        {{ data.emptyText }}
      </h5>
    </ng-template>
  </select-body>
</app-select>

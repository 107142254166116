import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: string): string {
    if (value?.length === 0) {
      return '';
    }
    try {
      const splitWords = value
        .split(' ')
        .filter((word: string) => word !== '(deleted)');
      if (splitWords.length > 1) {
        return splitWords
          .map((n: string) => n[0])
          .join('')
          .slice(0, 2)
          .toUpperCase();
      } else {
        return splitWords
          .map((n: string) => n[0] + n[1])
          .join('')
          .toUpperCase();
      }
    } catch (e) {
      return '';
    }
  }
}

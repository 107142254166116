import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtilService } from 'ui-components';

@Pipe({
  name: 'isValidPhoneNumber',
})
export class IsValidPhoneNumberPipe implements PipeTransform {
  transform(phoneNumber: string): any {
    let isPhoneNumberValid = true;

    try {
      isPhoneNumberValid = PhoneNumberUtilService.isValidNumber(phoneNumber);
    } catch (e) {
      isPhoneNumberValid = false;
    }

    return isPhoneNumberValid;
  }
}

<div class="selection-content">
  <ng-content></ng-content>
</div>
<label class="checkbox-wrapper {{ customLabelClass }}">
  <input
    type="checkbox"
    class="checkbox"
    [checked]="selected"
    (click)="click.emit()" />

  <ct-icon
    class="checkbox-wrapper__icon"
    *ngIf="selected"
    [size]="20"
    name="check-circle"
    [shouldCover]="true"></ct-icon>
</label>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { Call, CallEvent, CallInfo } from '@cloudtalk/sip-service';

import { TrackingEventEnum } from '../enums/tracking-event.enum';
import { TrackingService } from './tracking.service';
import { TrackingActionsEnum } from '../enums/tracking-actions.enum';
import { CallTrackingProperties } from '../models/tracking-properties.model';
import { Tag } from '../models/activity';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CallTrackingService {
  constructor(
    private trackingService: TrackingService,
    private router: Router,
  ) {}

  handleCallEventChanges(call: Call): void {
    call?.eventChanges
      .pipe(untilDestroyed(this))
      .subscribe((callEvent: CallEvent) => {
        switch (callEvent) {
          case CallEvent.Ended:
            if (call.conferenceCall$.value) {
              this.handleCallEventChanges(call.conferenceCall$.value);
              return;
            }
            this.callEndedTrackEvent(call);
            break;
          default:
            break;
        }
      });
  }

  callEndedTrackEvent(call: Call): void {
    const callTrackingProperties: CallTrackingProperties = {
      call_id: call?.ID,
      start_ringing: this.startRingingTime(call),
      result: call?.info?.calling_duration > 0 ? 'answered' : 'failed',
      direction: call?.isIncoming ? 'inbound' : 'outbound',
      waiting_time: call?.info?.dialing_duration ?? 0,
      talking_time: call?.info?.calling_duration ?? 0,
      wrap_up_time: this.wrapUpTime(call),
      internal_call: call?.isInternalCall ?? false,
      generated_call: call?.info?.isGeneratedCall ?? false,
      generated_call_reason: this.generatedCallReason(call?.info) ?? '',
      mandatory_tagging: call?.settings?.is_mandatory_tagging_enabled ?? false,
      tags: call?.tags?.map((tag: Tag) => tag?.name) ?? [],
      call_rating: call?.rating?.rating ?? 0,
      call_quality_rating: call?.qualityRating?.rating ?? 0,
      call_quality_tags: call?.qualityRating?.tags ?? [],
      hung_by_user: call?.state?.hungByUser ?? false,
      hand_raised: call?.inviteState?.isHandRaised ?? false,
      invited_agent: call?.inviteState?.invitedAgent?.id ?? 0,
      failure_code: call?.error?.code,
      failed_reason: call?.error?.message ?? '',
      after_call_work: call?.isAfterCallWorkOrCallSummary ?? false,
      notes: call?.note?.note?.length > 0,
      callback: call?.isCallBack ?? false,
      missed_call_improvement:
        call?.settings?.is_missed_call_improvement_enabled ?? false,
      voicemail_drop_used: call?.info?.droppedVoicemail !== undefined,
      call_transferred: call?.warmTransferCall !== null,
    };

    this.trackingService
      .trackActionEvent<CallTrackingProperties>(
        TrackingEventEnum.CALL,
        TrackingActionsEnum.CALLED,
        callTrackingProperties,
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private startRingingTime(call: Call): string {
    return call?.ID ? moment(call.ID).toISOString() : moment().toISOString();
  }

  private wrapUpTime(call: Call): number {
    const callingDuration = call?.info?.calling_duration ?? 0;
    if (callingDuration === 0) {
      return 0;
    }

    const dialingDuration = call?.info?.dialing_duration ?? 0;
    const totalTime = dialingDuration + callingDuration;
    const wrapUpTime =
      moment().diff(this.startRingingTime(call), 'seconds') - totalTime;
    return wrapUpTime > 0 ? wrapUpTime : 0;
  }

  private generatedCallReason(callInfo: CallInfo): string {
    if (callInfo?.isCallBack) {
      return 'callack';
    } else if (callInfo?.isPowerDialer) {
      return 'powerdialer';
    } else if (callInfo?.isGeneratedCall && callInfo?.isInternalCall) {
      return 'predictivedialer';
    } else if (this.router.url.includes('smart-dialer')) {
      return 'smartdialer';
    } else {
      return null;
    }
  }
}

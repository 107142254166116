import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderIdsEnum } from 'test-automation';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string;
  @Input() titlePosition: 'left' | 'right' | 'center' = 'center';
  @Input() backgroundColor: 'gray' | 'transparent' = 'transparent';
  @Input() clickable: boolean;
  @Output() titleClick: EventEmitter<void> = new EventEmitter();

  readonly headerIdsEnum = HeaderIdsEnum;

  onTitleClick(): void {
    if (this.clickable) {
      this.titleClick.emit();
    }
  }
}

export class User {
  id: number;
  group: number;
  sub: number;
  email: string;
  extension: string;
  name: string;
  avatar: string;
  scope: string[];
  features: { [key: string]: boolean };
  interface: string;
  company_id: number;
  outbound_id: number;
  lang: string;
  mod: string;
  company: {
    status: string;
    trial_to: number;
  };
  app_channel: 'beta' | 'prod';
  exp: number;
  iat: number;
  format: 'UK' | 'US';
  default_country_code: string;

  constructor() {
    this.id = null;
    this.sub = null;
    this.email = null;
    this.name = null;
    this.avatar = null;
    this.scope = null;
    this.company_id = null;
    this.outbound_id = null;
    this.lang = null;
    this.exp = null;
    this.iat = null;
    this.mod = null;
    this.default_country_code = null;
    this.company = { status: null, trial_to: null };
  }
}

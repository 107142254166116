import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListItemBaseComponent } from './list-item-base.component';
import { CtFlagIconComponent } from '../ct-flag-icon/ct-flag-icon.component';

@NgModule({
  declarations: [ListItemBaseComponent],
  imports: [CommonModule, CtFlagIconComponent],
  exports: [ListItemBaseComponent],
})
export class ListItemBaseModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CallHistoryScreenIdsEnum } from 'test-automation';

@Component({
  selector: 'app-mat-tab-wrapper',
  templateUrl: './mat-tab-wrapper.component.html',
  styleUrls: ['./mat-tab-wrapper.component.scss'],
})
export class MatTabWrapperComponent {
  @Input() tabNames: string[];
  @Output() readonly selectedTabEvent = new EventEmitter<number>();

  readonly callHistoryScreenIdsEnum = CallHistoryScreenIdsEnum;

  switchMissedCalls(event: MatTabChangeEvent): void {
    this.selectedTabEvent.emit(event.index);
  }
}

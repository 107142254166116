export enum AddEditContactIdsEnum {
  SAVE_BUTTON = 'SaveButton',
  CANCEL_BUTTON = 'CancelButton',
  NAME_INPUT = 'NameInput',
  PHONE_NUMBER_INPUT = 'PhoneNumberInput',
  EMAIL_INPUT = 'EmailInput',
  ADD_PHONE_NUMBER_BUTTON = 'AddPhoneNumberButton',
  ADD_EMAIL_BUTTON = 'AddEmailButton',
  ADD_TAG_INPUT = 'AddTagInput',
  TITLE_INPUT = 'TitleInput',
  COMPANY_INPUT = 'CompanyInput',
  INDUSTRY_INPUT = 'IndustryInput',
  WEBSITE_INPUT = 'WebsiteInput',
  STREET_INPUT = 'StreetInput',
  CITY_INPUT = 'CityInput',
  ZIP_INPUT = 'ZipInput',
  COUNTRY_DROP_DOWN = 'CountryDropDown',
  COUNTRY_DROP_DOWN_ITEM = 'CountryDropDownItem',
  FAVORITE_AGENT = 'FavoriteAgent',
  FAVORITE_AGENT_ITEM = 'FavoriteAgentItem',
  COUNTRY_ITEM = 'CountryItem',
  AGENT_ITEM = 'AgentItem',
  CUSTOM_FIELD = 'CustomField',
}

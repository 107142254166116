import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'select-body',
  templateUrl: './select-body.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBodyComponent {
  @Input()
  title = '';
  @HostBinding('class')
  class = 'select-body';
  @Input()
  showInput = false;

  @Output() readonly close = new EventEmitter<void>();

  closeDialog(): void {
    this.close.emit();
  }
}

<div [attr.data-test-id]="dataTestId" class="navigate-item {{ classes }}">
  <ct-icon
    *ngIf="iconLeft !== ''"
    class="navigate-item__icon-left"
    [name]="iconLeft"
    [size]="20"></ct-icon>

  <span class="navigate-item__title">{{ title }}</span>
  <ng-content></ng-content>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { VoicemailDropSelectDialogComponent } from './voicemail-drop-select-dialog.component';
import { CtIconComponent } from '../ct-icon/ct-icon.component';
import { OsKeyNamePipe } from '../../../_shared/pipes/shortcuts/os-key-name';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    CtIconComponent,
    OsKeyNamePipe,
  ],
  declarations: [VoicemailDropSelectDialogComponent],
  entryComponents: [VoicemailDropSelectDialogComponent],
})
export class VoicemailDropSelectDialogModule {}

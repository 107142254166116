import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AnalyticsService } from '../../../_core/services/analytics.service';
import {
  OverlayInputItemType,
  OverlayItem,
} from '../_overlay-item/overlay-item';
import { CallQualityResult } from '../call-quality-overlay/call-quality';
import { OverlayTypes, OverlayTypesType } from './overlay-types';

// Data which describes the overlays, helping with user tracking
const OVERLAYS_METADATA = {
  [OverlayTypes.ContactDetailOverlay]: {
    title: 'Contact detail',
    path: 'contact-detail',
  },
  [OverlayTypes.AddEditContactOverlay]: {
    title: 'Add/Edit contact',
    path: 'contact-add-edit',
  },
  [OverlayTypes.CallHistoryDetailOverlay]: {
    title: 'Call history detail',
    path: 'call-history-detail',
  },
  [OverlayTypes.CallContactAssignOverlay]: {
    title: 'Assign contact',
    path: 'assign-contact',
  },
  [OverlayTypes.ActivitiesListOverlay]: {
    title: 'Activities detail',
    path: 'activities-detail',
  },
  [OverlayTypes.CallQualityOverlay]: {
    title: 'Call quality',
    path: 'call-quality',
  },
  [OverlayTypes.SmsConversationOverlay]: {
    title: 'SMS',
    path: 'sms-conversation',
  },
  [OverlayTypes.SmsSelectContactOverlay]: {
    title: 'Select contact',
    path: 'sms-select-contact',
  },
  [OverlayTypes.TransferListOverlay]: {
    title: 'Transfer list',
    path: 'transfer-list',
  },
  [OverlayTypes.InviteAgentOverlay]: {
    title: 'Invite agent',
    path: 'invite-agent',
  },
  [OverlayTypes.CueCardsOverlay]: { title: 'Cue Cards', path: 'cue-cards' },
  [OverlayTypes.DialpadOverlay]: { title: 'Dialpad', path: 'conference' },
  [OverlayTypes.SmsTemplatesOverlay]: {
    title: 'Templates',
    path: 'templates',
  },
};

// definition of overlay result event variable result is a dynamic by the overlay
export interface OverlayResultEvent {
  overlayType: string;
  result: CallQualityResult;
}

export const OVERLAY_ACTIONS = {
  ADD: 'add' as const,
  REMOVE: 'remove' as const,
  CLEAR: 'clear' as const,
};

export interface OverlayEvent {
  action: string;
  affectedIndex: number;
  overlays: OverlayItem[];
}

@Injectable()
export class OverlayService {
  #overlays: BehaviorSubject<OverlayEvent> = new BehaviorSubject<OverlayEvent>({
    action: null,
    affectedIndex: -1,
    overlays: [],
  });
  overlays$ = this.#overlays.asObservable();
  overlayResult: EventEmitter<OverlayResultEvent> =
    new EventEmitter<OverlayResultEvent>();

  private debounceTime = false;
  private reopen: OverlayItem[] = [];

  constructor(private analyticsService: AnalyticsService) {}

  /**
   * @param{OverlayInputItem} overlayInputItem
   * @param{string} type
   * */
  addOverlay(
    overlayInputItem: OverlayInputItemType,
    type: OverlayTypesType,
  ): OverlayItem | null {
    overlayInputItem.overlayType = type;
    return this.pushOverlay(overlayInputItem);
  }

  overlayItemRemove(index): void {
    if (
      this.overlayItems[index]?.item?.overlayType ===
      OverlayTypes.AddEditContactOverlay
    ) {
      this.reopen = [];
    }
    this.#overlays.getValue().overlays.splice(index, 1);
    const overlays = this.#overlays.getValue().overlays;
    this.#overlays.next({
      action: OVERLAY_ACTIONS.REMOVE,
      affectedIndex: index,
      overlays,
    });
    this.analyticsHandler();
  }

  overlayEmitResult(overlayType: string, result): void {
    this.overlayResult.emit({ overlayType, result });
  }

  isOverlayOpened(type: OverlayTypesType): boolean {
    return !!this.#overlays
      .getValue()
      .overlays.find(
        (overlay: OverlayItem) => overlay.item.overlayType === type,
      );
  }

  private pushOverlay(item: OverlayInputItemType): OverlayItem | null {
    if (!this.debounceTime) {
      this.debounceTime = true;
      const overlays = this.#overlays.getValue().overlays;
      const overlayItem = new OverlayItem(item);
      overlays.push(overlayItem);
      this.#overlays.next({
        action: OVERLAY_ACTIONS.ADD,
        affectedIndex: overlays.length - 1,
        overlays,
      });
      this.analyticsHandler();
      setTimeout(() => {
        this.debounceTime = false;
      }, 1000);

      return overlayItem;
    }
    return null;
  }

  private get overlayItems(): OverlayItem[] {
    return this.#overlays.getValue().overlays;
  }

  hasOverlays(): boolean {
    return this.#overlays.getValue().overlays.length > 0;
  }

  hideAll(callIsComing = false): void {
    if (this.hasOverlays()) {
      if (callIsComing) {
        const addEditOverlayItem = this.overlayItems.find(
          element =>
            element.item.overlayType === OverlayTypes.AddEditContactOverlay,
        );
        if (addEditOverlayItem) {
          this.reopen = [addEditOverlayItem];
        }
      }
    }
    this.#overlays.next({ action: 'clear', affectedIndex: -1, overlays: [] });
  }

  checkReopen(): void {
    this.reopen.forEach(element => this.pushOverlay(element.item));
  }

  private analyticsHandler(): void {
    let path = '';
    let title = '';
    this.overlayItems.forEach((element, elementIndex) => {
      const overlayType = element.item.overlayType;
      if (elementIndex > 0) {
        path = path.concat('/');
      }
      path = path.concat(OVERLAYS_METADATA[overlayType].path);
      title = OVERLAYS_METADATA[overlayType].title;
    });
    const lastOverlay = this.overlayItems[this.overlayItems.length - 1]
      ? this.overlayItems[this.overlayItems.length - 1].item.overlayType
      : null;
    this.analyticsService.triggerNonRoutePage(
      path,
      title,
      lastOverlay ? OVERLAYS_METADATA[lastOverlay].title : null,
    );
  }
}

import { dynamicId } from '../utils/utils';

export enum OutboundCallerPickerIdsEnum {
  CALL_VIA_BUTTON = 'CallViaButton',
  OUTBOUND_CALLER = 'OutboundCaller',
  OUTBOUND_CALLER_SELECT = 'OutboundCallerSelect',
  AUTOMATIC_OUTBOUND_CALLER = 'AutomaticOutboundCaller',
  OUTBOUND_CONTACT_CELL = 'InternalContactCell',
  SEARCH_INPUT = 'SearchInput',
  ANONYMOUS_CALL = 'AnonymousCall',
  ANONYMOUS_EYE = 'AnonymousEye',
}

export const outbound_test_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CONTACT_CELL,
  '-Test number',
);

export const outbound_ios_test_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CONTACT_CELL,
  '-iOS Test Number',
);

export const outbound_android_test_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CONTACT_CELL,
  '-Android Test Number',
);

export const outbound_ios_sms_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CONTACT_CELL,
  '-iOS SMS Test Number',
);

export const outbound_android_sms_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CONTACT_CELL,
  '-Android SMS Test Number',
);

export const outbound_selected_test_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CALLER_SELECT,
  '-+1 213-325-6429',
);

export const outbound_selected_ios_test_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CALLER_SELECT,
  '-+44 20 3868 7191',
);

export const outbound_selected_android_test_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CALLER_SELECT,
  '-+421 2/222 001 52',
);

export const outbound_selected_ios_sms_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CALLER_SELECT,
  '-+44 7426 049829',
);

export const outbound_selected_android_sms_number = dynamicId(
  OutboundCallerPickerIdsEnum.OUTBOUND_CALLER_SELECT,
  '-+44 7723 738358',
);

import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

/**
 * Sformatuje cislo do medz. formatu
 *
 * countryCode: napr. 421 pre SK transformuje na SK
 */
@Pipe({ name: 'countryCodeToRegion' })
export class CountryCodeToRegionPipe implements PipeTransform {
  transform(countryCode: string): string {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.getRegionCodeForCountryCode(parseInt(countryCode, 10));
  }
}

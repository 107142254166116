import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';

import { SelectComponent } from './select.component';
import { SelectBodyComponent } from './select-body/select-body.component';
import { SelectBodyOptionComponent } from './select-body/select-body-option/select-body-option.component';
import { SelectHeaderComponent } from './select-header/select-header.component';
import { SelectInputDirective } from './select-input.directive';
import { CtIconComponent } from '../../ct-icon/ct-icon.component';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
    MatBottomSheetModule,
    MatIconModule,
    ReactiveFormsModule,
    CtIconComponent,
  ],
  declarations: [
    SelectComponent,
    SelectBodyComponent,
    SelectBodyOptionComponent,
    SelectHeaderComponent,
    SelectInputDirective,
  ],
  exports: [
    SelectComponent,
    SelectBodyComponent,
    SelectBodyOptionComponent,
    SelectHeaderComponent,
    SelectInputDirective,
  ],
})
export class SelectModule {}
export {
  SelectComponent,
  SelectBodyComponent,
  SelectBodyOptionComponent,
  SelectHeaderComponent,
};

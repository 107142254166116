import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { CustomWindowInterface } from '../interfaces/custom-window.interface';

@Injectable()
export class DocumentService {
  get nativeWindow(): CustomWindowInterface | null {
    return this.document.defaultView as CustomWindowInterface &
      typeof globalThis;
  }

  get nativeDocument(): Document {
    return this.nativeWindow.document;
  }
  get documentParams(): URLSearchParams {
    return new URLSearchParams(this.nativeWindow.location.search);
  }

  constructor(@Inject(DOCUMENT) private document: Document) {}
}

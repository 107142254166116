import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const selectedItem = trigger('selectedItem', [
  transition('normal => selected', [
    animate(
      '1s',
      keyframes([
        style({ backgroundColor: 'transparent', offset: 0 }),
        style({
          transform: 'translateX(0%)',
          backgroundColor: 'rgba(35, 38, 57, 0.2)',
          offset: 0.2,
        }),
        style({
          transform: 'translateX(50%)',
          backgroundColor: 'rgba(35, 38, 57, 0.13)',
          offset: 0.4,
        }),
        style({
          transform: 'translateX(140%)',
          backgroundColor: 'rgba(35, 38, 57, 0.07)',
          offset: 0.6,
        }),
        style({
          transform: 'translateX(180%)',
          backgroundColor: 'rgba(35, 38, 57, 0.02)',
          offset: 0.8,
        }),
        style({ backgroundColor: 'transparent', offset: 1 }),
      ]),
    ),
  ]),
]);

<div class="cloudtalk-input cloudtalk-input--{{ styleClass }}">
  <label *ngIf="label" class="cloudtalk-input__label">{{ label }}</label>
  <div class="cloudtalk-input__container">
    <div *ngIf="countrySelect" class="cloudtalk-input__countrySelect">
      <ng-content select="[countrySelect]"></ng-content>
    </div>

    <div
      *ngIf="prefixIcon"
      class="cloudtalk-input__prefix"
      (click)="handlePrefixClick()">
      <ng-content select="[prefixIcon]"></ng-content>
    </div>
    <input
      #inputRef
      [type]="type"
      [attr.placeholder]="placeholder"
      [ngClass]="{
        'cloudtalk-input__input--error': invalidate,
        'cloudtalk-input__input--prefix': prefixIcon,
        'cloudtalk-input__input--prefix-dialpad-disabled':
          !prefixIcon && styleClass === 'dialpad',
        'cloudtalk-input__input--suffix': suffixIcon,
        'cloudtalk-input__input--country-select': countrySelect,
        'cloudtalk-input__input--clickable': clickAble
      }"
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      (blur)="onTouch(); handleBlur($event)"
      (focus)="handleFocus($event)"
      class="cloudtalk-input__input cloudtalk-input__input-{{
        !value ? 'empty' : 'filled'
      }}"
      [disabled]="disabled"
      [readonly]="readOnly"
      i18n-placeholder />

    <div
      clickable
      *ngIf="suffixIcon"
      class="cloudtalk-input__suffix"
      (click)="handleSuffixClick()">
      <ng-content select="[suffixIcon]"></ng-content>
    </div>
  </div>

  <p *ngIf="invalidate && errorText" class="cloudtalk-input__error">
    {{ errorText }}
  </p>
</div>

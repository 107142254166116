import { Injectable } from '@angular/core';
import { Call, CallContact } from '@cloudtalk/sip-service';
import { PhoneNumberFormat } from 'google-libphonenumber';
import {
  Contact,
  IntegrationLinks,
} from '@cloudtalk/sip-service/lib/models/call/callContact';

import { StatusService } from '../_core/services/status.service';
import { CtiServiceBase } from './CtiServiceBase';
import { CallingService } from '../_core/services/calling/calling.service';
import { CtiProvider } from '../_core/models/cti-providers.models';
import { CallMonitorService } from '../_core/services/call-monitor.service';
import { LocalStorageService } from '../_core/services/local-storage.service';
import { DocumentService } from '../_core/services/document.service';
import { LoggerUtil } from '../_shared/utils/logger.util';
import { PhoneNumberFormatterPipe } from '../lib/pipes/phone-number-formatter/phone-number-formatter.pipe';

export const CtiMessage = {
  RINGING: 'ringing',
  DIALING: 'dialing',
  HANGUP: 'hangup',
  CALLING: 'calling',
  ENDED: 'ended',
  CONTACT_INFO: 'contact_info',
};

interface ExternalUrlObject {
  external_system: string;
  external_url: string;
}

interface CtiContact {
  id: number;
  name: string;
  company: string;
  external_urls: ExternalUrlObject[];
  contact_emails: string[];
  tags: string[];
  type: string;
}

interface CtiEventObject {
  event: string;
  properties?: {
    internal_number?: string;
    external_number?: string;
    call_uuid?: string;
    contact?: CtiContact;
  };
}

export const CrmParamKey = 'partner';
export const CustomCTIPartnerStorageKey = 'ct-cti-partner-id';

@Injectable()
export class CustomCtiService extends CtiServiceBase {
  partner = null;

  constructor(
    public callingService: CallingService,
    public statusService: StatusService,
    public callMonitorService: CallMonitorService,
    private documentService: DocumentService,
    private localStorageService: LocalStorageService,
    private phoneNumberPipe: PhoneNumberFormatterPipe,
  ) {
    super(
      callingService,
      statusService,
      callMonitorService,
      CtiProvider.CUSTOM,
    );
  }

  public setPartner(partner: string) {
    this.partner = partner;
    this.localStorageService.setItem(
      CustomCTIPartnerStorageKey,
      partner,
      false,
    );
  }

  onRinging(call: Call): void {
    this.sendCallInfoEvent(call, CtiMessage.RINGING);
    this.sendContactProperties(call);
  }

  onDialing(call: Call): void {
    this.sendCallInfoEvent(call, CtiMessage.DIALING);
    this.sendContactProperties(call);
  }

  onCalling(call: Call): void {
    this.sendCallInfoEvent(call, CtiMessage.CALLING);
  }

  onHangUp(call: Call): void {
    this.sendCallInfoEvent(call, CtiMessage.HANGUP);
  }

  onEnded(call: Call): void {
    this.sendCallInfoEvent(call, CtiMessage.ENDED);
  }

  private sendCallInfoEvent(call: Call, event: string) {
    const outBoundCaller = this.callingService.outBoundCaller;
    this.sendMessage({
      event,
      properties: {
        internal_number: outBoundCaller
          ? this.phoneNumberPipe.transform(
              outBoundCaller.public_caller_id,
              outBoundCaller?.country_code?.toString(),
              PhoneNumberFormat.E164,
            )
          : null,
        external_number: call.info.number,
        call_uuid: call.info.call_uuid,
      },
    });
  }

  sendContactProperties(call: Call): void {
    this.contactSubscription = {
      callID: call.ID,
      subscription: call.contacts$.subscribe((contacts: CallContact[]) => {
        if (contacts.length > 0) {
          this.sendMessage({
            event: CtiMessage.CONTACT_INFO,
            properties: {
              contact: this.parseContactInformation(contacts),
            },
          });
        }
      }),
    };
  }

  /**
   * Method for sending messages to parent (iframe)
   * @param{CtiEventObject} message
   * */
  sendMessage(message: CtiEventObject): void {
    if (this.partner) {
      LoggerUtil.info('Custom CTI message', message);
      this.documentService.nativeWindow.parent.postMessage(
        JSON.stringify(message),
        '*',
      );
    }
  }

  /**
   * This function will help us to parse PhoneContact|Contact to correct format for external CRM implementation
   * @return{CtiContact}
   * */
  parseContactInformation(contacts: CallContact[]): CtiContact {
    const ctiContact: CtiContact = {
      id: null,
      name: null,
      company: null,
      external_urls: [],
      contact_emails: [],
      tags: [],
      type: null,
    };

    if (contacts && contacts.length > 0) {
      const phoneContact = contacts[0].contact ?? null;
      if (phoneContact) {
        this.mapPhoneContactToCtiContact(ctiContact, phoneContact);
      }
    }
    return ctiContact;
  }

  private mapPhoneContactToCtiContact(
    ctiContact: CtiContact,
    phoneContact: Contact,
  ) {
    ctiContact.id = phoneContact.id;
    ctiContact.name = phoneContact.name;
    ctiContact.company = phoneContact.company;
    if (Array.isArray(phoneContact.contact_emails)) {
      ctiContact.contact_emails = phoneContact.contact_emails.map(
        item => item.email,
      );
    }
    ctiContact.external_urls = this.getExternalUrlsObject(
      phoneContact.integration_links,
    );
    if (Array.isArray(phoneContact.tags)) {
      ctiContact.tags = phoneContact.tags.map(item => item.name);
    }

    ctiContact.type = phoneContact?.type ?? null;
  }

  /**
   * This function will help us to parse integrationLinks to correct format for external CRM implementation
   * @return{Array<ExternalUrlObject>}
   * */
  getExternalUrlsObject(
    integrationLinks: IntegrationLinks,
  ): ExternalUrlObject[] {
    if (Object.keys(integrationLinks).length === 0) {
      return [];
    }

    const externalUrls: ExternalUrlObject[] = [];
    Object.keys(integrationLinks).forEach(key =>
      externalUrls.push({
        external_system: key,
        external_url: integrationLinks[key],
      }),
    );
    return externalUrls;
  }
}

<div
  *ngIf="data.header"
  [appTestId]="optionDialogIdsEnum.OPTION_DIALOG_HEADER"
  class="c-contact-delete-dialog-modal__header">
  <span i18n>{{ data.header }}</span>
</div>
<div
  [appTestId]="optionDialogIdsEnum.OPTION_DIALOG_TITLE"
  class="c-contact-delete-dialog-modal__title">
  {{ data.title }}
</div>
<div *ngIf="data.content" class="c-contact-delete-dialog-modal__text">
  <span i18n>{{ data.content }}</span>
</div>

<ng-content></ng-content>

<div class="c-contact-delete-dialog-modal__row">
  <app-button
    *ngIf="data.leftButton"
    [testId]="data.leftButton.testId ?? optionDialogIdsEnum.CANCEL_BUTTON"
    (click)="cancel()"
    class="c-contact-delete-dialog-modal__button"
    [btnType]="data.leftButton?.class ? data.leftButton.class : 'basic'">
    {{ data.leftButton.name }}</app-button
  >

  <app-button
    *ngIf="data.rightButton"
    [testId]="data.rightButton.testId ?? optionDialogIdsEnum.CONFIRM_BUTTON"
    (click)="proceed()"
    class="c-contact-delete-dialog-modal__button"
    [btnType]="data.rightButton?.class ? data.rightButton.class : 'warn'"
    >{{ data.rightButton.name }}</app-button
  >
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SnackBarComponent } from './snack-bar.component';
import { DirectivesModule } from '../../directives/directives.module';
import { CtIconComponent } from '../ct-icon/ct-icon.component';

@NgModule({
  declarations: [SnackBarComponent],
  imports: [CommonModule, DirectivesModule, CtIconComponent],
  exports: [SnackBarComponent],
})
export class SnackBarModule {}

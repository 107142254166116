import { Injectable } from '@angular/core';
import { ConnectionState } from '@cloudtalk/sip-service';
import deepEqual from 'deep-equal';
import {
  BehaviorSubject,
  firstValueFrom,
  interval,
  Observable,
  Subscription,
} from 'rxjs';
import { skip } from 'rxjs/operators';

import { SnackBarTypes } from '../../../_shared/components/snack-bar-wrapper/snack-bar-errors';
import { SnackBarWrapperService } from '../../../_shared/components/snack-bar-wrapper/snack-bar-wrapper.service';
import { LoggerUtil } from '../../../_shared/utils/logger.util';
import { AgentStatusTypeEnum } from '../../enums/agent-status-type.enum';
import {
  AgentStatus,
  AgentStatusActAs,
  AgentStatuses,
  CustomAgentStatus,
  DefaultAgentStatuses,
  MAX_CUSTOM_STATUS_CHAR_COUNT,
  MAX_CUSTOM_STATUS_CHAR_COUNT_ZENDESK,
  MAX_CUSTOM_STATUSES,
} from '../../interfaces/agent-status/agent-status.interface';
import { AuthenticationService } from '../auth/authentication.service';
import { CallingService } from '../calling/calling.service';
import { EnvironmentService } from '../environment.service';
import { SocketService } from '../networking/socket.service';
import { StatusService } from '../status.service';
import { AgentStatusRepositoryService } from './agent-status-repository.service';

const IncorrectStatusLimit = 60 * 1000;

const ConnectionStateMessage = {
  [ConnectionState.UNCONNECTED]: $localize`Poor internet connection. Some services might not work. Trying to reconnect...`,
  [ConnectionState.CONNECTED]: $localize`Reconnected`,
};

@Injectable({
  providedIn: 'root',
})
export class AgentStatusService {
  #agentStatuses: AgentStatuses = {
    system: DefaultAgentStatuses,
    predefined: [],
  };
  #agentStatus$: BehaviorSubject<AgentStatus> =
    new BehaviorSubject<AgentStatus>(
      this.getSystemStatusByActAs(AgentStatusActAs.OFFLINE),
    );
  #incorrectStatusSubscription: Subscription;
  #incorrectStatusIntervalSubscription: Subscription;
  #incorrectStatusReporter$ = interval(IncorrectStatusLimit);
  #callingConnectionStateHandler$ =
    this.statusService.callingConnectionState$.pipe(skip(1));
  #messageSubscription: Subscription;
  readonly agentStatuses: AgentStatuses = this.#agentStatuses;
  readonly agentStatus$: Observable<AgentStatus> =
    this.#agentStatus$.asObservable();
  agentStatus: AgentStatus = this.getSystemStatusByActAs(
    AgentStatusActAs.OFFLINE,
  );

  constructor(
    private callingService: CallingService,
    private socketService: SocketService,
    private statusService: StatusService,
    private snackBarWrapperService: SnackBarWrapperService,
    private authenticationService: AuthenticationService,
    private environmentService: EnvironmentService,
    private agentStatusRepositoryService: AgentStatusRepositoryService,
  ) {
    this.authenticationService.onLogout$.subscribe(
      this.onLogoutUnloadHandler.bind(this),
    );
  }

  init(): void {
    this.socketService.on(
      'user-status-changed',
      (receivedAgentStatus: AgentStatus) => {
        this.setAgentStatus(receivedAgentStatus).catch(e => {
          LoggerUtil.error(
            '[AgentStatusService]: setAgentStatus failed',
            {},
            e,
          );
        });
      },
    );

    if (!this.environmentService.isSalesforce()) {
      const defaultOnline = this.getSystemStatusByActAs(
        AgentStatusActAs.ONLINE,
      );
      this.changeAgentStatus(defaultOnline).catch(e => {
        LoggerUtil.error('[AgentStatusService]: init failed', {}, e);
      });
    }

    this.loadAgentStatuses().subscribe((agentStatuses: AgentStatuses) => {
      this.#agentStatuses.system =
        agentStatuses?.system ?? DefaultAgentStatuses;
      this.#agentStatuses.predefined = agentStatuses?.predefined ?? [];
    });
  }

  loadAgentStatuses(): Observable<AgentStatuses> {
    return this.agentStatusRepositoryService.agentStatuses();
  }

  async changeAgentStatus(
    agentStatus: AgentStatus,
  ): Promise<AgentStatus | undefined> {
    await this.setAgentStatus(agentStatus);
    try {
      const setStatusPromise =
        this.agentStatusRepositoryService.setAgentStatus(agentStatus);
      return await firstValueFrom(setStatusPromise);
    } catch (error) {
      LoggerUtil.error(
        '[AgentStatusService]: changeAgentStatus failed',
        {},
        error,
      );
    }
    return undefined;
  }

  get maxCharactersForCustomAgentStatus(): number {
    return this.environmentService.isZendesk()
      ? MAX_CUSTOM_STATUS_CHAR_COUNT_ZENDESK
      : MAX_CUSTOM_STATUS_CHAR_COUNT;
  }

  get shouldBeConnectedToSipServer(): boolean {
    return this.agentStatus.act_as !== AgentStatusActAs.OFFLINE;
  }

  get agentSystemStatuses(): AgentStatus[] {
    return this.#agentStatuses.system;
  }

  get agentPredefinedStatuses(): AgentStatus[] {
    return this.#agentStatuses.predefined;
  }

  get hasPredefinedStatus(): boolean {
    return (
      this.#agentStatus$.getValue()?.type === AgentStatusTypeEnum.PREDEFINED
    );
  }

  getSystemStatusByActAs(actAs: AgentStatusActAs): AgentStatus {
    return this.agentSystemStatuses.find(
      (agentStatus: AgentStatus) => agentStatus.act_as === actAs,
    );
  }

  addRecentStatus(customStatus: CustomAgentStatus): AgentStatus {
    const customStatuses =
      this.agentStatusRepositoryService.agentCustomStatuses();

    const existingIndex = customStatuses.findIndex(
      (status: CustomAgentStatus) =>
        status.user_status.title === customStatus.user_status.title,
    );

    if (existingIndex !== -1) {
      // If a duplicate exists, remove it from the array
      customStatuses.splice(existingIndex, 1);
    }

    customStatuses.unshift(customStatus);

    if (customStatuses.length > MAX_CUSTOM_STATUSES) {
      customStatuses.pop();
    }

    this.agentStatusRepositoryService.setCustomStatuses(customStatuses);

    return customStatus;
  }

  private messageHandler(connectionState: ConnectionState): void {
    if (connectionState === ConnectionState.CONNECTING) {
      return;
    }
    if (
      (this.agentStatus.act_as !== AgentStatusActAs.OFFLINE &&
        connectionState === ConnectionState.UNCONNECTED) ||
      (this.agentStatus.act_as === AgentStatusActAs.ONLINE &&
        connectionState === ConnectionState.CONNECTED)
    ) {
      this.snackBarWrapperService.openSnackBar({
        message: ConnectionStateMessage[connectionState],
        snackType:
          connectionState === ConnectionState.CONNECTED
            ? SnackBarTypes.CONNECTION_SUCCESSFUL
            : SnackBarTypes.CONNECTION_ERROR,
      });
    }
  }

  private async onLogoutUnloadHandler(): Promise<void> {
    if (this.#messageSubscription) {
      this.#messageSubscription.unsubscribe();
    }
    const offlineStatus = this.getSystemStatusByActAs(AgentStatusActAs.OFFLINE);
    this.changeAgentStatus(offlineStatus).catch(e => {
      LoggerUtil.error(
        '[AgentStatusService]: onLogoutUnloadHandler failed',
        {},
        e,
      );
    });
  }

  private async setAgentStatus(newAgentStatus: AgentStatus): Promise<void> {
    if (
      deepEqual(newAgentStatus, this.#agentStatus$.getValue(), {
        strict: true,
      }) ||
      !newAgentStatus
    ) {
      return;
    }

    const { act_as } = newAgentStatus;
    const callingConnectionState = this.statusService.callingConnectionState;

    if (
      act_as !== AgentStatusActAs.OFFLINE &&
      callingConnectionState === ConnectionState.UNCONNECTED
    ) {
      try {
        await this.callingService.connect('setAgentStatus');
        this.updateAgentStatus(newAgentStatus);
        this.setupHandlers();
      } catch (error) {
        LoggerUtil.info(
          AgentStatusService.name + ':connect(setAgentStatus) failed',
          {
            extraContent: { error },
          },
        );
      }
      return;
    }

    this.updateAgentStatus(newAgentStatus);

    if (
      act_as === AgentStatusActAs.OFFLINE &&
      callingConnectionState !== ConnectionState.UNCONNECTED
    ) {
      this.callingService.stop();
      this.cleanHandlers();
    }
  }

  updateAgentStatus(agentStatus: AgentStatus): void {
    this.#agentStatus$.next(agentStatus);
    this.agentStatus = agentStatus;
  }

  private cleanHandlers() {
    if (this.#messageSubscription) {
      this.#messageSubscription.unsubscribe();
      this.#messageSubscription = undefined;
    }
    if (this.#incorrectStatusIntervalSubscription) {
      this.#incorrectStatusIntervalSubscription.unsubscribe();
      this.#incorrectStatusIntervalSubscription = undefined;
    }
    if (this.#incorrectStatusSubscription) {
      this.#incorrectStatusSubscription.unsubscribe();
      this.#incorrectStatusSubscription = undefined;
    }
  }

  private setupHandlers() {
    this.#messageSubscription = this.#callingConnectionStateHandler$.subscribe(
      this.messageHandler.bind(this),
    );
    this.#incorrectStatusSubscription =
      this.#callingConnectionStateHandler$.subscribe(
        (connectionState: ConnectionState) => {
          if (connectionState !== ConnectionState.CONNECTED) {
            // in case of connectionStatus switched between CONNECTING and UNCONNECTED
            // we want to reset previous subscription and create new one
            if (this.#incorrectStatusIntervalSubscription) {
              this.#incorrectStatusIntervalSubscription.unsubscribe();
              this.#incorrectStatusIntervalSubscription = undefined;
            }
            this.#incorrectStatusIntervalSubscription =
              this.#incorrectStatusReporter$.subscribe((last: number) => {
                this.reportIncorrectStatus(connectionState, last);
              });
            return;
          }
          if (connectionState === ConnectionState.CONNECTED) {
            if (this.#incorrectStatusIntervalSubscription) {
              this.#incorrectStatusIntervalSubscription.unsubscribe();
              this.#incorrectStatusIntervalSubscription = undefined;
            }
          }
        },
      );
  }

  private reportIncorrectStatus(
    connectionState: ConnectionState,
    last: number,
  ): void {
    if (this.agentStatus.act_as === AgentStatusActAs.OFFLINE) {
      LoggerUtil.warn('Wrongly reporting incorrect status');
      return;
    }
    LoggerUtil.error(
      '[AgentStatusService]: incorrect Connection State',
      {
        last,
        online: navigator.onLine,
        rtSocketConnected: this.socketService.isConnected,
        connectionState,
        agentStatus: this.agentStatus.act_as,
        stopRequested: this.callingService.stopRequested,
      },
      'Incorrect Connection State',
    );
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EndpointService } from '../../_core/services/networking/endpoint.service';

export interface Country {
  id: number;
  country_code: string;
  calling_code: number;
  name: string;
}

@Injectable()
export class CountrySelectService {
  countries: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);

  constructor(private endpoint: EndpointService) {}

  get countriesArray(): Country[] {
    return this.countries.getValue();
  }

  loadCountries(): Observable<Country[]> {
    return this.endpoint
      ._get('countries')
      .pipe(tap((countries: Country[]) => this.countries.next(countries)));
  }

  getCountryById(id: number): Country {
    return this.countries
      .getValue()
      .find((country: Country) => country.id === id);
  }

  getCountryByCallingCode(
    calling_code: number | string,
    country_code: string = null,
  ): Country {
    if (calling_code) {
      if (country_code == null) {
        // hot fix Russia
        if (calling_code === 7) {
          return this.getCountryById(182);
        }

        // hotfix for GB -> Isle of Man
        else if (calling_code === 44) {
          return this.getCountryById(235);
        }
      }

      return this.countries
        .getValue()
        .find((country: Country) =>
          this.isCountrySelected(country, calling_code, country_code),
        );
    }
    return null;
  }

  isCountrySelected(
    country: Country,
    calling_code: number | string,
    country_code: string = null,
  ): boolean {
    return country_code
      ? country?.calling_code === Number(calling_code) &&
          country_code === country?.country_code
      : country?.calling_code === Number(calling_code);
  }

  getCountriesByCountryCode(country_code: string): Country {
    return this.countries
      .getValue()
      .find((country: Country) => country.country_code === country_code);
  }
}

import { ErrorHandler, Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable()
export class CloudtalkErrorHandler extends ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      if (!environment.production) {
        super.handleError(error);
      }
    }
  }
}

export enum CallHistoryDetailIdsEnum {
  CALL_HISTORY_DETAIL_LIST = 'CallHistoryDetailList',
  AGENT_NUMBER_TEXT = 'AgentNumberText',
  AGENT_COUNTRY_ICON = 'AgentCountryIcon',
  AGENT_DIAL_ICON = 'AgentDialIcon',
  CALL_INFO_ICON = 'CallInfoIcon',
  CALL_INFO_PRIMARY_TEXT = 'CallInfoPrimaryText',
  CALL_INFO_SECONDARY_TEXT = 'CallInfoSecondaryText',
  CALL_INFO_DURATION_TEXT = 'CallInfoDurationText',
  CALL_VIA_PRIMARY_TEXT = 'CallViaPrimaryText',
  CALL_VIA_SECONDARY_TEXT = 'CallViaSecondaryText',
  AGENT_CELL = 'AgentCell',
  AGENT_ICON = 'AgentIcon',
  AGENT_ICON_CIRCLE = 'AgentIconCircle',
  CALL_HISTORY_DETAIL_HEADER = 'CallHistoryDetailHeader',
  CALL_HISTORY_DETAIL_MORE = 'CallHistoryDetailMore',
  CALL_HISTORY_DETAIL_ID = 'CallHistoryDetailID',
  CALL_HISTORY_DETAIL_NAME = 'CallHistoryDetailName',
  CALL_HISTORY_DETAIL_SEND_SMS = 'CallHistoryDetailSendSms',
  CALL_HISTORY_DETAIL_MAKE_CALL = 'CallHistoryDetailMakeCall',
  CALL_HISTORY_CALL_INFO = 'CallHistoryDetailCallInfo',
  CALL_HISTORY_DETAIL_AGENT = 'CallHistoryDetailAgent',
  CALL_HISTORY_DETAIL_AGENT_EMAIL = 'CallHistoryDetailAgentEmail',
  CALL_HISTORY_DETAIL_COMPANY = 'CallHistoryDetailCompany',
  CALL_HISTORY_DETAIL_CALL_DURATION = 'CallHistoryDetailCallDuration',
  CALL_HISTORY_DETAIL_RECORDING = 'CallHistoryDetailRecording',
  CALL_HISTORY_DETAIL_TAGS = 'CallHistoryDetailTags',
  CALL_HISTORY_DETAIL_NOTES = 'CallHistoryDetailNotes',
  CALL_HISTORY_DETAIL_RATING = 'CallHistoryDetailRating',
  CALL_HISTORY_DETAIL_VIA = 'CallHistoryDetailVia',
  CALL_HISTORY_DETAIL_CLOSE_MODAL = 'CallHistoryDetailCloseModal',
}

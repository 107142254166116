import { NgModule } from '@angular/core';

import { TestIdDirective } from './test-id.directive';

@NgModule({
  imports: [],
  declarations: [TestIdDirective],
  exports: [TestIdDirective],
})
export class DirectivesModule {}

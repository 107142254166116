import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Provider,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { SelectStateFullIds } from 'test-automation';

import { SelectComponent } from '../select/select.component';
import { InputComponent } from '../../input/input.component';

export const SELECT_STATE_FULL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectStateFulComponent),
  multi: true,
};

@Component({
  selector: 'app-stateful-select',
  templateUrl: './select-stateful.component.html',
  styleUrls: ['./select-stateful.component.scss'],
  providers: [SELECT_STATE_FULL_VALUE_ACCESSOR],
})
export class SelectStateFulComponent<T>
  implements OnInit, ControlValueAccessor
{
  @Input()
  selectedClassName: 'all-selected' | 'icon-selected' = 'icon-selected';
  @Input() items: T[];
  @Input() recentItems: T[];
  @Input() multi = false;
  @Input() showSearch = false;
  @Input() unselect = true;
  @Input() disabled = false;
  @Input() set selectTitle(value: string) {
    this.#selectTitle = $localize`${value}`;
  }

  get selectTitle(): string {
    return this.#selectTitle;
  }

  @Input() set title(value: string) {
    this.#title = $localize`${value}`;
  }

  get title(): string {
    return this.#title;
  }

  @Output() readonly selected = new EventEmitter<any>();
  @Output() readonly searchChanged = new EventEmitter<any>();

  @ContentChild('optionTemplate', { static: false })
  optionTemplate: TemplateRef<any>;

  @ContentChild('selectedTemplate', { static: false })
  selectedTemplate: TemplateRef<any>;

  @ContentChild('placeholderTemplate', { static: false })
  placeholderTemplate: TemplateRef<any>;

  @ContentChild('customPreselectTemplate', { static: false })
  customPreselectTemplate: TemplateRef<any>;

  @ContentChild('customTemplate', { static: false })
  customTemplate: TemplateRef<any>;

  @ContentChild('actionTemplate', { static: false })
  actionTemplate: TemplateRef<any>;

  @ContentChild('headerTemplate', { static: false })
  headerTemplate: TemplateRef<any>;

  @ViewChild(SelectComponent, { static: false })
  selectComponentRef: SelectComponent;

  @ViewChild('searchInput', { static: false })
  searchInput: InputComponent;

  selectStateFullIds = SelectStateFullIds;

  search: string;

  showList = false;

  #title = '';
  #selectTitle = 'OTHER NUMBERS';

  onChange: (val: any) => void;
  onTouched: () => void;
  selectionModel: SelectionModel<any> | null;
  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selectionModel = new SelectionModel(this.multi);
  }

  writeValue(item: unknown): void {
    if (this.selectionModel && item) {
      this.selectionModel.select(item);
    }
    this.cd.markForCheck();
  }

  registerOnChange(fn: (val: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  optionClickedHandler(item: Record<string | number, unknown>): void {
    if (this.selectionModel) {
      this.selectionModel.select(item);
      this.selected.emit(item);
      if (this.onChange) {
        this.onChange(this.selectionModel.selected[0]);
        this.cd.markForCheck();
        this.cd.detectChanges();
      }
    }

    if (this.onTouched) {
      this.onTouched();
    }

    this.selectComponentRef.close();
    this.cd.markForCheck();
  }

  inputChanged(event: Event): void {
    this.search = (event.target as HTMLTextAreaElement).value;
    this.searchChanged.emit(this.search);
  }

  onOpen() {
    this.showList = true;
    setTimeout(() => this.searchInput?.forceFocus(), 100);
  }

  onClose(): void {
    this.resetSearch();
    if (this.onTouched) {
      this.onTouched();
    }
  }

  resetSearch(): void {
    this.search = '';
    this.searchChanged.emit(this.search);
  }
}

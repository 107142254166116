/**
 * Uschovava mozne staty nacitavania udajov vo phone. Kde to treba, tam sa pouziva toto
 * namiesto true/false flagov isLoading.
 */
export enum LoadStateEnum {
  STANDBY,
  LOADING,
  RELOADING,
  LOADED,
  ERROR,
  RELOADING_ERROR,
}

import { KeyboardKeyEnum } from './keyboard-key.enum';

export enum OsEnum {
  MACOS = 'MacOS',
  WINDOWS = 'Windows',
  UNIX = 'UNIX',
  LINUX = 'Linux',
  UNKNOWN = 'Unknown',
}

export interface OsKeyPropertiesType {
  short_name_default: string;
  short_name_macos?: string;
  short_name_windows?: string;
  short_name_linux?: string;
  short_name_unix?: string;
}

// todo add more keys types
export type OsKeyListType = KeyboardKeyEnum.LEFT_ALT | KeyboardKeyEnum.ENTER;

export const OsKeysDefinitions: Record<OsKeyListType, OsKeyPropertiesType> = {
  [KeyboardKeyEnum.LEFT_ALT]: {
    short_name_default: 'Alt',
    short_name_macos: 'Opt',
  },
  [KeyboardKeyEnum.ENTER]: {
    short_name_default: 'Enter',
  },
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

import { ChipComponent } from './chip.component';

@NgModule({
  imports: [CommonModule, MatRippleModule],
  declarations: [ChipComponent],
  exports: [ChipComponent],
})
export class ChipModule {}

<div class="snack-bar snack-bar--{{ data.snackType }}">
  <div class="snack-bar__icon-type">
    <ct-icon [name]="'icon-' + data.snackType" [size]="20"></ct-icon>
  </div>
  <div class="snack-bar__message-container">
    <span
      class="snack-bar__message-text"
      [appTestId]="snackbarIdsEnum.MESSAGE"
      innerHTML="{{ data.message }}"></span>
  </div>
  <ct-icon
    class="snack-bar__close-icon"
    clickable
    *ngIf="!data.closeHidden"
    name="close"
    [size]="16"
    (click)="close.emit()"></ct-icon>
</div>

import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import compareVersions from 'compare-versions';
import { Subject } from 'rxjs';

import { IpcEvents } from '../../../../interfaces/shared-interfaces';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { OsEnum } from '../enums/os.enum';
import { EnvironmentService } from './environment.service';
import { IpcService } from './ipc.service';
import { StatusService } from './status.service';
import { UserSettingsActionHandlerService } from './user-settings-action-handler.service';

interface CloudTalkNotification {
  title?: string;
  body?: string;
}

export interface NotificationClickEvent {
  event: Event;
  notification: Notification;
}

@Injectable()
export class BrowserNotificationService {
  onClick$: Subject<NotificationClickEvent> =
    new Subject<NotificationClickEvent>();

  private icon = '/assets/img/logo-cloudtalk.png';
  private notification: Notification = null;
  private actionText = null;
  private bodyText = null;

  constructor(
    private environmentService: EnvironmentService,
    private ipcService: IpcService,
    private userSettingsActionHandlerService: UserSettingsActionHandlerService,
    private statusService: StatusService,
    private swPush: SwPush,
  ) {}

  show(cloudTalkNotification: CloudTalkNotification): void {
    // update text of action and body by user preferences
    this.actionText =
      this.userSettingsActionHandlerService.updateNotificationAction();
    this.bodyText = cloudTalkNotification?.body
      ? cloudTalkNotification.body
      : this.userSettingsActionHandlerService.updateNotificationText();
    // get desktop app version
    const desktopAppVersion = this.environmentService
      .getElectronAppVersion()
      .match(/\d.\d.\d/g);
    if (desktopAppVersion !== null && desktopAppVersion.length > 0) {
      // checking if the desktop app supporting the new notification or use old one
      if (
        compareVersions(desktopAppVersion[0], '2.2.2') > -1 &&
        this.environmentService.getOS() === OsEnum.MACOS
      ) {
        this.ipcService.send(IpcEvents.SHOW_NOTIFICATION, {
          title: cloudTalkNotification.title,
          message: this.bodyText,
          actions: this.actionText,
        });
      } else {
        // TODO: refactor this using async await
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.fallbackNotification(cloudTalkNotification);
      }
      // if it's not desktop (electron app) go to the case of web app
    } else {
      // check if is serviceWorkerAvailable
      if (
        !this.checkServiceWorkerCompatibility(
          this.environmentService.isProduction,
        )
      ) {
        // if no, then go for notification API
        // TODO: refactor this using async await
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.fallbackNotification(cloudTalkNotification);
      } else {
        this.requestPermission()
          .then((permission: NotificationPermission) =>
            this.isPermissionGranted(permission),
          )
          .then(() =>
            this.createServiceWorkerNotification(cloudTalkNotification),
          )
          .catch((err: Error) =>
            LoggerUtil.info(
              BrowserNotificationService.name + ':requestPermission() failed',
              {
                extraContent: { err, notification: cloudTalkNotification },
              },
            ),
          );
      }
    }
  }

  // return only result, reject will be displayed through status
  requestPermission(): Promise<NotificationPermission> {
    return new Promise<NotificationPermission>(resolve => {
      try {
        Notification.requestPermission()
          .then((notificationPermission: NotificationPermission) => {
            if (notificationPermission === 'granted') {
              this.statusService.notificationAllowed(true);
              return resolve(notificationPermission);
            }
            this.statusService.notificationAllowed(false);
          })
          .catch(error => {
            LoggerUtil.error(
              'Notification.RequestPermission failed',
              {
                extraContent: { error },
              },
              error,
            );
            this.statusService.notificationAllowed(false);
          });
      } catch (error) {
        LoggerUtil.error(
          'RequestPermission failed',
          {
            extraContent: { error },
          },
          error,
        );
      }
    });
  }

  close(): void {
    if (this.notification) {
      this.notification.close();
    }
    try {
      navigator.serviceWorker
        .getRegistration()
        .then((reg: ServiceWorkerRegistration | undefined) => {
          if (reg) {
            reg
              .getNotifications()
              .then((notifications: Notification[]) =>
                notifications.forEach((notification: Notification) =>
                  notification.close(),
                ),
              )
              .catch((err: Error) =>
                LoggerUtil.info(
                  BrowserNotificationService.name +
                    ':navigator.serviceWorker:getNotifications() failed',
                  {
                    extraContent: { err },
                  },
                ),
              );
          }
        })
        .catch((err: Error) =>
          LoggerUtil.info(
            BrowserNotificationService.name +
              ':navigator.serviceWorker:getRegistration() failed',
            {
              extraContent: { err },
            },
          ),
        );
    } catch (error) {
      console.error(error);
    }
  }

  private createServiceWorkerNotification(
    cloudTalkNotification: CloudTalkNotification,
  ) {
    try {
      navigator.serviceWorker
        .getRegistration()
        .then((reg: ServiceWorkerRegistration | undefined) => {
          if (reg) {
            reg
              .showNotification(cloudTalkNotification.title, {
                body: this.bodyText,
                icon: this.icon,
                actions: [
                  {
                    title: this.actionText,
                    action: 'actionClicked',
                  },
                  {
                    title: 'Mute',
                    action: 'closed',
                  },
                ],
                dir: 'auto',
                requireInteraction: true,
                vibrate: [200, 100],
              })
              .catch(error => {
                console.error(error);
              });

            this.attachEventHandlers();
          }
        })
        .catch((err: Error) =>
          LoggerUtil.info(
            BrowserNotificationService.name +
              ':navigator.serviceWorker:getRegistration() failed',
            {
              extraContent: { err, notification: cloudTalkNotification },
            },
          ),
        );
    } catch (error) {
      console.error(error);
    }
  }

  private fallbackNotification(cloudTalkNotification: CloudTalkNotification) {
    if (!this.checkNotificationCompatiblity()) {
      return LoggerUtil.info('Notification API not available in this browser.');
    } else {
      // if we are on electron (or we are on dev mode(localhost)) and our version does not support the new notifications
      return this.requestPermission()
        .then((permission: NotificationPermission) =>
          this.isPermissionGranted(permission),
        )
        .then(() => this.createBasicNotification(cloudTalkNotification))
        .catch((err: Error) =>
          LoggerUtil.info(
            BrowserNotificationService.name + ':fallbackNotification() failed',
            {
              extraContent: { err, notification: cloudTalkNotification },
            },
          ),
        );
    }
  }

  private createBasicNotification(
    cloudTalkNotification: CloudTalkNotification,
  ) {
    this.notification = new Notification(cloudTalkNotification.title, {
      body: this.bodyText,
      icon: this.icon,
      dir: 'auto',
      requireInteraction: true,
      vibrate: [200, 100],
    });

    this.notification.onclick = (event: Event) => {
      this.onClick$.next({ event, notification: this.notification });
    };

    return this.notification;
  }

  private attachEventHandlers(): void {
    this.swPush.notificationClicks.subscribe(({ action }) => {
      if (action) {
        this.onClick$.next({
          event: new Event(action),
          notification: undefined,
        });
      }
    });
  }

  private checkServiceWorkerCompatibility(env: boolean): boolean {
    try {
      return 'serviceWorker' in navigator && env;
    } catch (error) {
      return false;
    }
  }

  private checkNotificationCompatiblity(): boolean {
    try {
      return 'Notification' in window;
    } catch (error) {
      return false;
    }
  }

  private isPermissionGranted(
    notificationPermission: NotificationPermission,
  ): Promise<void> {
    return notificationPermission === 'granted'
      ? Promise.resolve()
      : Promise.reject('permission-not-granted');
  }
}
